import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ component: Component, ...rest }) => {
	const User = useSelector((state) => state.loginRedux);
	return (
		<Route
			{...rest}
			render={(props) => {
				if (User.isLogged) return <Component {...props} />;
				else return <Redirect to={'/login'} />;
			}}
		/>
	);
};

export default ProtectedRoute;
