let initialState = {
	stocks: [],
};

const StocksReturnsReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_STOCKS_RETURNS':
			return {
				...state,
				stocks: action.payload,
			};
		default:
			return state;
	}
};

export { StocksReturnsReducer };
