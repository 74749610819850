import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "antd";
import moment from "moment";
import { SERVER_IP } from "assets/Config";
import { ACTIONS, API_STATUS } from "constants/app-constants";
import { postApi } from "redux/sagas/postApiDataSaga";
import { resetApiStatus } from "redux/reducers/globals/globalActions";
import { putApi } from "redux/sagas/putApiSaga";
import { getApi } from "redux/sagas/getApiDataSaga";
import AddCreatedLoadPresentational from "./add-create-load-presentational";

const AddCreatedLoadFunctional = ({ state, setState, refreshList }) => {
	const [form] = Form.useForm();
	const globalRedux = useSelector((state) => state.globalRedux);
	const vendors = globalRedux?.vendors;
	const vehicles = globalRedux?.vehicles;
	const { classes = [] } = globalRedux;
	const dispatch = useDispatch();

	useEffect(() => {
		if (state?.selectedRow) {
			form.setFieldsValue({
				...state?.selectedRow,
				vehicleId: state?.selectedRow?.vehicleId?._id,
				loadTo: state?.selectedRow?.loadTo?._id,
				loadFrom: state?.selectedRow?.loadFrom?._id,
				loadDate: moment(state?.selectedRow?.loadDate),
			});
		} else {
			form.resetFields();
		}
	}, [state?.selectedRow]);

	const getVehicle = () => {
		let url = `${SERVER_IP}vehicle?orgId=${globalRedux.selectedOrganization._id}`;
		dispatch(getApi("GET_VEHICLES", url));
	};

	const getVendor = () => {
		let url = `${SERVER_IP}vendor?orgId=${globalRedux.selectedOrganization._id}`;
		dispatch(getApi("GET_VENDORS", url));
	};

	useEffect(() => {
		if (state?.visible) {
			getVendor();
			getVehicle();
		}
	}, [state?.visible]);

	useEffect(() => {
		if (
			globalRedux.apiStatus.ADD_LOAD === API_STATUS.SUCCESS ||
			globalRedux.apiStatus.EDIT_LOAD === API_STATUS.SUCCESS
		) {
			setState({ ...state, visible: false });
			refreshList();
			dispatch(
				resetApiStatus(
					state?.selectedRow ? ACTIONS.EDIT_LOAD : ACTIONS.ADD_LOAD
				)
			);
		}
	}, [globalRedux.apiStatus]);

	const handleSubmit = (values) => {
		const request = {
			orgId: globalRedux.selectedOrganization._id,
			...values,
			freight: values?.freight || 0,
			commission: values?.commission || 0,
		};
		dispatch(postApi(request, ACTIONS.ADD_LOAD));
	};

	const handleEdit = (values) => {
		let data = {
			...values,
			freight: values?.freight || 0,
			commission: values?.commission || 0,
			orgId: globalRedux.selectedOrganization._id,
		};
		let url = `${SERVER_IP}load/${state?.selectedRow?._id}`;
		dispatch(putApi(data, ACTIONS.EDIT_LOAD, url));
	};

	const loading =
		globalRedux.apiStatus.ADD_LOAD === API_STATUS.PENDING ||
		globalRedux.apiStatus.EDIT_LOAD === API_STATUS.PENDING;

	return (
		<AddCreatedLoadPresentational
			{...{
				state,
				setState,
				classes,
				loading,
				form,
				handleSubmit,
				handleEdit,
				vendors,
				vehicles,
			}}
		/>
	);
};

export default AddCreatedLoadFunctional;
