import React, { useCallback } from "react";
import {
	Button,
	Form,
	Input,
	Select,
	Modal,
	Drawer,
	Row,
	Col,
	InputNumber,
	Divider,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { DATE_FORMAT } from "constants/app-constants";
import DatePicker from "components/date-picker";
import TableComponent from "components/table-component";

const { confirm } = Modal;

const formItemLayout = {
	labelCol: { span: 24 },
	wrapperCol: { span: 22 },
};

const AddBookingPresentational = ({
	state,
	setState,
	form,
	handleSubmit,
	loading,
	handleEdit,
	vehicles,
	customers,
	materials,
	drivers,
	gpss,
	cities,
	columns,
	tableData,
}) => {
	const showConfirm = useCallback(() => {
		confirm({
			title: "Do you want to close this window?",
			icon: <ExclamationCircleOutlined />,
			content: "You will lose all the details you have entered here.",
			onOk() {
				setState({
					...state,
					visible: false,
				});
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	}, []);

	const isEditMode = !!state?.selectedRow;

	return (
		<Drawer
			maskClosable={false}
			title={`${isEditMode ? "Edit" : "Add New"} Booking`}
			placement="right"
			width={"70%"}
			open={state?.visible}
			destroyOnClose
			className="add_booking"
			onClose={showConfirm}
			footer={
				<Row>
					<Col
						xl={{
							span: 9,
							offset: 15,
						}}
						md={12}
					>
						<Row gutter={[10, 10]} style={{ width: "100%" }} justify="end">
							<Col>
								<Button
									onClick={() =>
										setState({
											...state,
											visible: false,
										})
									}
								>
									Cancel
								</Button>
							</Col>
							<Col>
								<Button
									loading={loading}
									type="primary"
									htmlType="submit"
									onClick={() => form.submit()}
								>
									{isEditMode ? "Update" : "Submit"}
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			}
		>
			<Row justify="center">
				<Col xl={24} md={24}>
					<Form
						form={form}
						className="form-container"
						{...formItemLayout}
						onFinish={(values) =>
							state?.selectedRow ? handleEdit(values) : handleSubmit(values)
						}
					>
						<Divider>Customer & Booking Details</Divider>
						<Row gutter={[0, 10]}>
							<Col xl={6} md={6}>
								<Form.Item
									label="Consignor(From)"
									rules={[{ required: true }]}
									name="consignor"
								>
									<Select
										showSearch
										filterOption={(input, option) =>
											option.props.children
												.toLowerCase()
												.indexOf(input.toLowerCase()) >= 0 ||
											option.props.value
												.toLowerCase()
												.indexOf(input.toLowerCase()) >= 0
										}
										placeholder="Select consignor"
									>
										{customers?.map((customer) => (
											<Select.Option key={customer?._id} value={customer?._id}>
												{customer?.displayName}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xl={6} md={6}>
								<Form.Item
									label="Consignee(To)"
									rules={[{ required: true }]}
									name="consignee"
								>
									<Select
										showSearch
										filterOption={(input, option) =>
											option.props.children
												.toLowerCase()
												.indexOf(input.toLowerCase()) >= 0 ||
											option.props.value
												.toLowerCase()
												.indexOf(input.toLowerCase()) >= 0
										}
										placeholder="Select consignee"
									>
										{customers?.map((customer) => (
											<Select.Option key={customer?._id} value={customer?._id}>
												{customer?.displayName}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xl={6} md={6}>
								<Form.Item
									label="Booking date"
									initialValue={moment()}
									rules={[{ required: true }]}
									name="bookingDate"
								>
									<DatePicker
										style={{ width: "100%" }}
										format={DATE_FORMAT.DD_MM_YYYY}
										placeholder="Select load date"
									/>
								</Form.Item>
							</Col>
							<Col xl={6} md={6}>
								<Form.Item
									label="From(City)"
									rules={[{ required: true }]}
									name="from"
								>
									<Select
										showSearch
										filterOption={(input, option) =>
											option.props.children
												.toLowerCase()
												.indexOf(input.toLowerCase()) >= 0 ||
											option.props.value
												.toLowerCase()
												.indexOf(input.toLowerCase()) >= 0
										}
										placeholder="Select load from"
									>
										{cities?.map((city) => (
											<Select.Option key={city?._id} value={city?._id}>
												{city?.cityName}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xl={6} md={6}>
								<Form.Item
									label="To(City)"
									rules={[{ required: true }]}
									name="to"
								>
									<Select
										showSearch
										filterOption={(input, option) =>
											option.props.children
												.toLowerCase()
												.indexOf(input.toLowerCase()) >= 0 ||
											option.props.value
												.toLowerCase()
												.indexOf(input.toLowerCase()) >= 0
										}
										placeholder="Select load to"
									>
										{cities?.map((city) => (
											<Select.Option key={city?._id} value={city?._id}>
												{city?.cityName}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xl={6} md={6}>
								<Form.Item
									label="Freight Rs."
									name="customerFreight"
									rules={[{ required: false }]}
								>
									<InputNumber
										style={{ width: "100%" }}
										placeholder="Enter customer freight"
									/>
								</Form.Item>
							</Col>
						</Row>
						<Divider>Consignment Details</Divider>
						<Row
							justify="center"
							style={{
								paddingBottom: 20,
							}}
						>
							<Col xl={24} md={24}>
								<TableComponent
									{...{
										columns,
										dataSource: tableData,
										pagination: false,
									}}
								/>
							</Col>
						</Row>
						<Divider>Vehicle Details</Divider>
						<Row gutter={[0, 10]}>
							<Col xl={6} md={6}>
								<Form.Item
									label="Vehicle"
									rules={[{ required: true }]}
									name="vehicleId"
								>
									<Select
										showSearch
										filterOption={(input, option) =>
											option.props.children
												.toLowerCase()
												.indexOf(input.toLowerCase()) >= 0 ||
											option.props.value
												.toLowerCase()
												.indexOf(input.toLowerCase()) >= 0
										}
										placeholder="Select vehicle"
									>
										{vehicles?.map((vehicle) => (
											<Select.Option key={vehicle?._id} value={vehicle?._id}>
												{vehicle?.vehicleNumber}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xl={6} md={6}>
								<Form.Item
									label="Driver"
									rules={[{ required: true }]}
									name="driverId"
								>
									<Select
										showSearch
										filterOption={(input, option) =>
											option.props.children
												.toLowerCase()
												.indexOf(input.toLowerCase()) >= 0 ||
											option.props.value
												.toLowerCase()
												.indexOf(input.toLowerCase()) >= 0
										}
										placeholder="Select driver"
									>
										{drivers?.map((driver) => (
											<Select.Option key={driver?._id} value={driver?._id}>
												{driver?.driverName}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xl={6} md={6}>
								<Form.Item
									label="GPS"
									rules={[{ required: true }]}
									name="gpsId"
								>
									<Select
										showSearch
										filterOption={(input, option) =>
											option.props.children
												.toLowerCase()
												.indexOf(input.toLowerCase()) >= 0 ||
											option.props.value
												.toLowerCase()
												.indexOf(input.toLowerCase()) >= 0
										}
										placeholder="Select gps"
									>
										{gpss?.map((gps) => (
											<Select.Option key={gps?._id} value={gps?._id}>
												{gps?.gpsName}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>

							<Col xl={6} md={6}>
								<Form.Item
									label="LR Number(Manual)"
									rules={[{ required: false }]}
									name="lrNumber_manual"
								>
									<Input
										style={{ width: "100%" }}
										placeholder="Enter LR number (manual)"
									/>
								</Form.Item>
							</Col>

							<Col xl={6} md={6}>
								<Form.Item
									label="Vehicle Freight Rs."
									rules={[{ required: false }]}
									name="vehicleFreight"
								>
									<InputNumber
										style={{ width: "100%" }}
										placeholder="Enter vehicle freight"
									/>
								</Form.Item>
							</Col>
						</Row>
						<Divider>Other Details</Divider>
						<Row gutter={[0, 10]}>
							<Col xl={12} md={12}>
								<Form.Item label="Remarks" name="remarks">
									<Input.TextArea placeholder="Enter remarks" />
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Col>
			</Row>
		</Drawer>
	);
};

export default AddBookingPresentational;
