import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import moment from 'moment';
import { SERVER_IP } from 'assets/Config';
import { ACTIONS, API_STATUS } from 'constants/app-constants';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { putApi } from 'redux/sagas/putApiSaga';
import AddReceivedEntryPresentational from './add-received-entry-presentational';

const AddReceivedEntryFunctional = ({ state, setState, refreshList }) => {
	const [form] = Form.useForm();
	const globalRedux = useSelector((state) => state.globalRedux);
	const vendors = globalRedux?.vendors;
	const vehicles = globalRedux?.vehicles;
	const { classes = [] } = globalRedux;
	const dispatch = useDispatch();

	useEffect(() => {
		if (state?.selectedRow) {
			form.setFieldsValue({
				...state?.selectedRow,
				classId: state?.selectedRow?.classId?._id,
				dateofBirth: moment(state?.selectedRow?.dateofBirth),
				dateofJoin: moment(state?.selectedRow?.dateofJoin),
			});
		} else {
			form.resetFields();
		}
	}, [state?.selectedRow]);

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_RECEIVED_ENTRY === API_STATUS.SUCCESS || globalRedux.apiStatus.EDIT_STUDENT === API_STATUS.SUCCESS) {
			setState({ ...state, visible: false });
			refreshList();
			dispatch(resetApiStatus(state?.selectedRow ? ACTIONS.EDIT_STUDENT : ACTIONS.ADD_RECEIVED_ENTRY));
		}
	}, [globalRedux.apiStatus]);

	const handleSubmit = (values) => {
		const request = {
			orgId: globalRedux.selectedOrganization._id,
			// currentBatchId: globalRedux?.activeBatch,
			// students: [{ ...values }],
			...values,
		};
		dispatch(postApi(request, ACTIONS.ADD_RECEIVED_ENTRY));
	};

	const handleEdit = (values) => {
		let data = {
			...values,
			orgId: globalRedux.selectedOrganization._id,
			currentBatchId: globalRedux?.activeBatch,
		};
		let url = `${SERVER_IP}student/${state?.selectedRow?._id}`;
		dispatch(putApi(data, ACTIONS.EDIT_STUDENT, url));
	};

	const loading = globalRedux.apiStatus.ADD_RECEIVED_ENTRY === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_STUDENT === API_STATUS.PENDING;

	return (
		<AddReceivedEntryPresentational
			{...{
				state,
				setState,
				classes,
				loading,
				form,
				handleSubmit,
				handleEdit,
				vendors,
				vehicles,
			}}
		/>
	);
};

export default AddReceivedEntryFunctional;
