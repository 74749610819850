import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { SERVER_IP } from 'assets/Config';
import { ACTIONS, API_STATUS, DATE_FORMAT } from 'constants/app-constants';
import HighlightComponent from 'components/HighlightComponent';
import { objToQs } from 'helpers';
import StockByLocationPresentational from './stock-by-location-list-presentational';
import RouteCardToPrint from './route-card-to-print';

const initialPageSize = 10;
const intialPageSizeOptions = [10, 15, 20];

const StockByLocationFunctional = React.memo((props) => {
	const componentRef = React.useRef();
	const [state, setState] = useState({
		visible: false,
	});
	const [filterOptions, setFilterOptions] = useState({
		vendorId: '',
		partNumberId: '',
		fromDate: '',
		toDate: '',
		invoiceNumber: '',
		lrNumber: '',
		status: [],
	});
	const [searchKey, setSearchKey] = useState('');
	const [selectedRow, setSelectedRow] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const globalRedux = useSelector((state) => state.globalRedux);
	const stocks = useSelector((state) => state?.stocksByLocationsRedux?.stocksByLocations || []);
	const [selectedRecordToPrint, setSelectedRecordToPrint] = useState(null);
	const [tableData, setTableData] = useState(stocks || []);
	const partNumbers = globalRedux?.partNumbers;
	const vendors = globalRedux?.vendors;
	const locations = globalRedux?.locations;
	const dispatch = useDispatch();

	const getStock = () => {
		let params = objToQs({
			orgId: globalRedux.selectedOrganization._id,
			...filterOptions,
			...(!!!filterOptions?.status.length && { status: '' }),
		});
		dispatch(getApi(ACTIONS.GET_STOCKS_BY_LOCATIONS, `${SERVER_IP}stock/location?${params}`));
	};

	const handleFilterOptions = (key, value) => {
		setFilterOptions({
			...filterOptions,
			[key]: value,
		});
	};
	
	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((record) => {
			return (
				(record?.ticketType || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.priority || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.customerName || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.mobile || '')?.toLowerCase().includes(searchKey.toLowerCase())
			);
		});
	}, [tableData, searchKey]);

	const getLocations = () => {
		let url = `${SERVER_IP}location?orgId=${globalRedux.selectedOrganization._id}`;
		dispatch(getApi('GET_LOCATIONS', url));
	};

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_UNLOAD === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_UNLOAD'));
			doIt = true;
		}
		if (doIt) {
			getStock();
		}
	}, [globalRedux.apiStatus]);

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage === 0 ? 1 : currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const handleAfterPrint = () => {
		setSelectedRecordToPrint(null);
	};

	useEffect(() => {
		getLocations();
	}, []);

	useEffect(() => {
		getStock(filterOptions);
	}, [filterOptions]);

	useEffect(() => {
		setTableData(stocks);
	}, [stocks]);

	useEffect(() => {
		selectedRecordToPrint && handlePrint();
	}, [selectedRecordToPrint]);

	useEffect(() => {
		selectedRow &&
			setState({
				...state,
				visible: true,
			});
	}, [selectedRow]);

	useEffect(() => {
		!state?.visible && setSelectedRow(null);
	}, [state?.visible]);

	const reactToPrintContent = React.useCallback(() => {
		return componentRef.current;
	}, [componentRef.current]);

	const handlePrint = useReactToPrint({
		content: reactToPrintContent,
		documentTitle: 'Receipt',
		onAfterPrint: handleAfterPrint,
		removeAfterPrint: true,
	});

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_STOCKS_BY_LOCATIONS === API_STATUS.PENDING, [globalRedux.apiStatus]);

	const column = [
		{
			title: 'Vendor Name',
			dataIndex: 'vendorId',
			key: 'vendorId',
			sorter: (a, b) => a?.vendorId?.vendorName?.localeCompare(b?.vendorId?.vendorName),
			width: '17%',
			render: (value) => <span>{value?.vendorName}</span>,
		},
		{
			title: 'Part Number',
			dataIndex: 'partNumberId',
			key: 'partNumberId',
			sorter: (a, b) => a?.partNumberId?.partNumber?.localeCompare(b?.partNumberId?.partNumber),
			width: '17%',
			render: (value) => <span>{value?.partNumber}</span>,
		},
		// {
		// 	title: 'Sap Number',
		// 	dataIndex: 'driverName',
		// 	key: 'driverName',
		// 	render: (value) => <span>{value?.sapNumber}</span>,
		// },
		{
			title: 'Invoice Number',
			dataIndex: 'invoiceNumber',
			sorter: (a, b) => a?.invoiceNumber - b?.invoiceNumber,
			key: 'invoiceNumber',
			width: '17%',
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value || ''} />
			),
		},
		{
			title: 'Invoice Date',
			dataIndex: 'invoiceDate',
			sorter: (a, b) => new Date(a.invoiceDate) - new Date(b.invoiceDate),
			key: 'invoiceDate',
			width: '17%',
			render: (value) => <span>{moment(value).format(DATE_FORMAT.DD_MM_YYYY)}</span>,
		},
		// {
		// 	title: 'LR Number',
		// 	dataIndex: 'lrNumber',
		// 	align: 'left',
		// 	sorter: (a, b) => a?.lrNumber - b?.lrNumber,
		// },
		// {
		// 	title: 'Invoice Qty',
		// 	dataIndex: 'invoiceQty',
		// 	align: 'left',
		// 	sorter: (a, b) => a?.invoiceQty - b?.invoiceQty,
		// },
		// {
		// 	title: 'Invoice Weight',
		// 	dataIndex: 'invoiceWeight',
		// 	align: 'left',
		// 	sorter: (a, b) => a?.invoiceWeight - b?.invoiceWeight,
		// },
		// {
		// 	title: 'Status',
		// 	dataIndex: 'status',
		// 	align: 'left',
		// 	sorter: (a, b) => a?.status?.localeCompare(b?.status),
		// 	render: (value) => <Tag color={STATUS[value]}>{value}</Tag>,
		// },
		// {
		// 	title: 'Dispatch Qty',
		// 	dataIndex: '_id',
		// 	align: 'center',
		// 	render: (value, row) =>
		// 		selectedRowKeys.includes(value) && (
		// 			<Button onClick={() => handleSelectRow({ _id: row?._id, stockSummary: row?.stockSummary })}>
		// 				{row?.stockSummary.reduce((acc, curr) => acc + parseInt(curr?.dispatchedQty || 0), 0) || 0}
		// 			</Button>
		// 		),
		// },
		{
			title: 'Location',
			dataIndex: 'locationId',
			key: 'locationId',
			sorter: (a, b) => a?.locationId?.locationName?.localeCompare(b?.vendorId?.locationName),
			width: '17%',
			render: (value) => <span>{value?.locationName}</span>,
		},
		{
			title: 'Stock Qty',
			dataIndex: 'stockQty',
			key: 'stockQty',
			align: 'right',
			sorter: (a, b) => a?.stockQty?.localeCompare(b?.stockQty),
			width: '17%',
			render: (value) => <span>{value}</span>,
		},
		{
			title: 'Action',
			align: 'center',
			dataIndex: 'stockQty',
			width: '5%',
			render: (value, row) => {
				return (
					<Row gutter={10} justify="center">
						<Col onClick={() => setSelectedRecordToPrint(row)} className="edit_icon">
							<PrinterOutlined />
						</Col>
					</Row>
				);
			},
		},
	];

	const exportData = useMemo(() => {
		return {
			headers: column?.map((col) => col?.title) || [],
			data:
				filteredData.map((stock) => ({
					[column[0]?.title]: stock?.vendorId?.vendorName,
					[column[1]?.title]: stock?.partNumberId?.partNumber,
					[column[2]?.title]: stock?.invoiceNumber,
					[column[3]?.title]: moment(stock?.invoiceDate).format(DATE_FORMAT.DD_MM_YYYY),
					[column[4]?.title]: stock?.locationId?.locationName,
					[column[5]?.title]: stock?.stockQty,
				})) || [],
		};
	}, [filteredData, column]);

	return (
		<>
			<div style={{ display: 'none' }}>
				<RouteCardToPrint ref={componentRef} data={selectedRecordToPrint} />
			</div>
			<StockByLocationPresentational
				{...{
					column,
					filteredData,
					handleTableChange,
					getStartingValue,
					getEndingValue,
					pageSize,
					intialPageSizeOptions,
					initialPageSize,
					currentPage,
					tableLoading,
					partNumbers,
					vendors,
					locations,
					handleFilterOptions,
					exportData,
				}}
			/>
		</>
	);
});

export default StockByLocationFunctional;
