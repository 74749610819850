import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PrinterOutlined } from '@ant-design/icons';
import { Tag, Row, Col } from 'antd';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { SERVER_IP } from 'assets/Config';
import { ACTIONS, API_STATUS, DATE_FORMAT, STATUS } from 'constants/app-constants';
import HighlightComponent from 'components/HighlightComponent';
import { objToQs } from 'helpers';
// import ComponentToPrint from 'components/component-to-print';
import LRCopyToPrint from './lr-copy-to-print';
import StockPresentational from './stock-list-presentational';

const initialPageSize = 10;
const intialPageSizeOptions = [10, 15, 20];

const StockFunctional = React.memo(({}) => {
	const componentRef = React.useRef();
	const [state, setState] = useState({
		visible: false,
	});
	const [filterOptions, setFilterOptions] = useState({
		vendorId: '',
		partNumberId: '',
		fromDate: '',
		toDate: '',
		fromDate: '',
		toDate: '',
		lrNumber: '',
		status: [],
		requestFor: 'report',
	});
	const [searchKey, setSearchKey] = useState('');
	const [selectedRow, setSelectedRow] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const globalRedux = useSelector((state) => state.globalRedux);
	const stocks = useSelector((state) => state.stocksRedux?.stocks);
	const [selectedRecordToPrint, setSelectedRecordToPrint] = useState(null);
	const [tableData, setTableData] = useState(stocks || []);
	const [selectedViewRow, setSelectedViewRow] = useState(null);
	const partNumbers = globalRedux?.partNumbers;
	const vehicles = globalRedux?.vehicles;
	const vendors = globalRedux?.vendors;
	const dispatch = useDispatch();

	const getStock = () => {
		let params = objToQs({
			orgId: globalRedux.selectedOrganization._id,
			...filterOptions,
			...(!!!filterOptions?.status.length && { status: '' }),
		});
		dispatch(getApi(ACTIONS.GET_STOCKS, `${SERVER_IP}stock?${params}`));
	};

	const handleFilterOptions = (key, value) => {
		if (key === 'dates') {
			setFilterOptions({
				...filterOptions,
				fromDate: value?.[0] ? moment(value?.[0]).format(DATE_FORMAT.YYYY_MM_DD) : '',
				toDate: value?.[1] ? moment(value?.[1]).format(DATE_FORMAT.YYYY_MM_DD) : '',
			});
		} else if (key === 'entryDates') {
			setFilterOptions({
				...filterOptions,
				fromDateIn: value?.[0] ? moment(value?.[0]).format(DATE_FORMAT.YYYY_MM_DD) : '',
				toDateIn: value?.[1] ? moment(value?.[1]).format(DATE_FORMAT.YYYY_MM_DD) : '',
			});
		} else {
			setFilterOptions({
				...filterOptions,
				[key]: value,
			});
		}
	};

	const getVehicles = () => {
		let url = `${SERVER_IP}vehicle?orgId=${globalRedux.selectedOrganization._id}`;
		dispatch(getApi('GET_VEHICLES', url));
	};

	useEffect(() => {
		getVehicles();
	}, []);

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((record) => {
			return (
				(record?.ticketType || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.priority || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.customerName || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.mobile || '')?.toLowerCase().includes(searchKey.toLowerCase())
			);
		});
	}, [tableData, searchKey]);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_UNLOAD === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_UNLOAD'));
			doIt = true;
		}
		if (doIt) {
			getStock();
		}
	}, [globalRedux.apiStatus]);

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage === 0 ? 1 : currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const handleAfterPrint = () => {
		setSelectedRecordToPrint(null);
	};

	// useEffect(() => {
	// 	getStock();
	// }, []);

	useEffect(() => {
		setTableData(stocks);
	}, [stocks]);

	useEffect(() => {
		selectedRecordToPrint && handlePrint();
	}, [selectedRecordToPrint]);

	useEffect(() => {
		selectedRow &&
			setState({
				...state,
				visible: true,
			});
	}, [selectedRow]);

	useEffect(() => {
		!state?.visible && setSelectedRow(null);
	}, [state?.visible]);

	const reactToPrintContent = React.useCallback(() => {
		return componentRef.current;
	}, [componentRef.current]);

	const handlePrint = useReactToPrint({
		content: reactToPrintContent,
		documentTitle: 'Receipt',
		onAfterPrint: handleAfterPrint,
		removeAfterPrint: true,
	});

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_STOCKS === API_STATUS.PENDING, [globalRedux.apiStatus]);

	const column = [
		{
			title: 'LR #',
			dataIndex: 'lrNumber',
			align: 'left',
			fixed: 'left',
			width: 100,
			sorter: (a, b) => a?.lrNumber - b?.lrNumber,
		},
		{
			title: 'Inbound No',
			dataIndex: 'loadId',
			align: 'left',
			fixed: 'left',
			width: 100,
			sorter: (a, b) => a?.loadId?.loadNumber - b?.loadId?.loadNumber,
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.loadNumber?.toString() || ''}
				/>
			),
		},
		{
			title: 'Inbound Date',
			dataIndex: 'loadId',
			align: 'left',
			width: 150,
			sorter: (a, b) => new Date(a.loadId?.loadInDate) - new Date(b.loadId?.loadInDate),
			render: (value) => <span>{moment(value?.loadInDate).format(DATE_FORMAT.DD_MM_YYYY)}</span>,
		},
		{
			title: 'Vehicle No',
			dataIndex: 'loadId',
			sorter: (a, b) => a?.loadId?.vehicleId?.regNumber - b?.loadId?.vehicleId?.regNumber,
			key: 'loadId',
			// fixed: 'left',
			width: 150,
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.vehicleId?.regNumber || ''}
				/>
			),
		},
		{
			title: 'Vendor Name',
			dataIndex: 'vendorId',
			key: 'vendorId',
			sorter: (a, b) => a?.vendorId?.vendorName?.localeCompare(b?.vendorId?.vendorName),
			width: 150,
			render: (value) => <span>{value?.vendorName}</span>,
		},
		{
			title: 'Part No',
			dataIndex: 'partNumberId',
			key: 'partNumberId',
			sorter: (a, b) => a?.partNumberId?.partNumber?.localeCompare(b?.partNumberId?.partNumber),
			width: 100,
			render: (value) => <span>{value?.partNumber}</span>,
		},
		{
			title: 'Sap No',
			dataIndex: 'partNumberId',
			key: 'partNumberId',
			width: 100,
			render: (value) => <span>{value?.sapNumber}</span>,
		},
		{
			title: 'Invoice No',
			dataIndex: 'invoiceNumber',
			width: 100,
			sorter: (a, b) => a?.invoiceNumber - b?.invoiceNumber,
			key: 'invoiceNumber',
			render: (value, row) => (
				<>
					{row?.status === 'InStock' ? (
						value
					) : (
						<div
							style={{
								cursor: 'pointer',
								color: '#1b59a8',
								textDecoration: 'underline',
							}}
							onClick={() => {
								setSelectedViewRow(row);
							}}>
							<HighlightComponent
								highlightClassName="highlightClass"
								searchWords={[searchKey]}
								autoEscape={true}
								textToHighlight={value || ''}
							/>
						</div>
					)}
				</>
			),
		},
		{
			title: 'ASN No',
			dataIndex: 'asnNumber',
			sorter: (a, b) => a?.asnNumber - b?.asnNumber,
			key: 'asnNumber',
			width: 100,
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value || ''} />
			),
		},
		{
			title: 'Invoice Date',
			dataIndex: 'invoiceDate',
			sorter: (a, b) => new Date(a.invoiceDate) - new Date(b.invoiceDate),
			key: 'invoiceDate',
			width: 100,
			render: (value) => <span>{moment(value).format(DATE_FORMAT.DD_MM_YYYY)}</span>,
		},
		{
			title: 'Invoice Qty',
			dataIndex: 'invoiceQty',
			align: 'left',
			width: 100,
			sorter: (a, b) => a?.invoiceQty - b?.invoiceQty,
		},
		{
			title: 'Invoice Weight',
			dataIndex: 'invoiceWeight',
			align: 'left',
			width: 100,
			sorter: (a, b) => a?.invoiceWeight - b?.invoiceWeight,
		},
		{
			title: 'Stock Qty',
			dataIndex: 'stockQty',
			align: 'left',
			width: 100,
			sorter: (a, b) => a?.stockQty - b?.stockQty,
		},
		{
			title: 'Status',
			dataIndex: 'status',
			align: 'left',
			width: 100,
			sorter: (a, b) => a?.status?.localeCompare(b?.status),
			render: (value) => <Tag color={STATUS[value]}>{value}</Tag>,
		},
		{
			title: 'LR',
			dataIndex: '_id',
			align: 'center',
			fixed: 'right',
			width: 60,
			render: (value, row) => (
				<Row gutter={10} justify="center">
					<Col
						onClick={() => setSelectedRecordToPrint(row)}
						// onClick={() =>
						// 	row?.dispatchDetails?.length === 0 ? null : setSelectedRecordToPrint(row)
						// }
						className="edit_icon">
						<PrinterOutlined />
					</Col>
				</Row>
			),
		},
	];

	// Inbound No
	// Inbound Date
	// INBOUND Vehicle No
	// OUTBOUND NO
	// OUTBOUND DATE
	// OUTBOUND VEHICLE NO
	// OUTBOUND TIME
	// Load In-THIS COLUMN NOT REQUIRED
	// Vendor Name
	// Part No
	// Sap No
	// Invoice No
	// Invoice Date
	// LR #
	// Invoice Qty
	// Invoice Weight
	// Stock Qty
	// Tax Value
	// Total Value
	// Status
	// ASN No

	const exportData = useMemo(() => {
		const head = [
			'Inbound No',
			'Inbound Date',
			'Vehicle No',
			'Load In',
			'Vendor Name',
			'Part No',
			'Sap No',
			'Invoice No',
			'Invoice Date',
			'LR #',
			'Invoice Qty',
			'Invoice Weight',
			'Status',
			'ASN No',
		];
		const filteredDataCopy = JSON.parse(JSON.stringify(filteredData)).sort((a, b) => a?.lrNumber - b?.lrNumber);
		return {
			headers: head,
			// headers: (column.filter((col) => col?.title !== 'LR')?.map((col) => col?.title) || []).map((title) =>
			// 	title === 'Stock Qty' ? 'Load In' : title
			// ),
			data:
				filteredDataCopy.map((stock) => ({
					[head[0]]: stock?.loadId?.loadNumber,
					[head[1]]: moment(stock?.loadId?.loadInDate).format(DATE_FORMAT.DD_MM_YYYY),
					[head[2]]: stock?.loadId?.vehicleId?.regNumber,
					[head[3]]: moment(stock?.loadInDate).format(DATE_FORMAT.DD_MM_YYYY),
					[head[4]]: stock?.vendorId?.vendorName,
					[head[5]]: stock?.partNumberId?.partNumber,
					[head[6]]: stock?.partNumberId?.sapNumber,
					[head[7]]: stock?.invoiceNumber,
					[head[8]]: stock?.lrNumber,
					[head[9]]: moment(stock?.invoiceDate).format(DATE_FORMAT.DD_MM_YYYY),
					[head[10]]: stock?.invoiceQty,
					[head[11]]: stock?.invoiceWeight,
					[head[12]]: stock?.status,
					[head[13]]: stock?.asnNumber,
				})) || [],
		};
	}, [filteredData, column]);

	// const extraFields = useMemo(() => {
	// 	const head = [
	// 		'LR #',
	// 		'Inbound No',
	// 		'Inbound Date',
	// 		'Vehicle No',
	// 		'Load In',
	// 		'Invoice Date',
	// 		'Vendor Name',
	// 		'Part No',
	// 		'Sap No',
	// 		'ASN No',
	// 		'Invoice No',
	// 		'Invoice Qty',
	// 		'Invoice Weight',
	// 		'Stock Qty',
	// 		'Status',
	// 		'Tax Value',
	// 		'Total Value',
	// 	];
	// 	const filteredDataCopy = JSON.parse(JSON.stringify(filteredData)).sort((a, b) => a?.lrNumber - b?.lrNumber)
	// 	// const excelColumns =
	// 	// 	[...column.filter((col) => col?.title !== 'LR')?.map((col) => col?.title), 'Vehicle No', 'In Date', 'Tax Value', 'Total Value'] || [];
	// 	return {
	// 		headers: head,
	// 		data:
	// 		filteredDataCopy.map((stock) => ({
	// 				[head[0]]: stock?.lrNumber,
	// 				[head[1]]: stock?.loadId?.loadNumber,
	// 				[head[2]]: moment(stock?.loadId?.loadInDate).format(DATE_FORMAT.DD_MM_YYYY),
	// 				[head[3]]: stock?.loadId?.vehicleId?.regNumber,
	// 				[head[4]]: moment(stock?.loadInDate).format(DATE_FORMAT.DD_MM_YYYY),
	// 				[head[5]]: moment(stock?.invoiceDate).format(DATE_FORMAT.DD_MM_YYYY),
	// 				[head[6]]: stock?.vendorId?.vendorName,
	// 				[head[7]]: stock?.partNumberId?.partNumber,
	// 				[head[8]]: stock?.partNumberId?.sapNumber,
	// 				[head[9]]: stock?.asnNumber,
	// 				[head[10]]: stock?.invoiceNumber,
	// 				[head[11]]: stock?.invoiceQty,
	// 				[head[12]]: stock?.invoiceWeight,
	// 				[head[13]]: stock?.stockQty,
	// 				[head[14]]: stock?.status,
	// 				[head[15]]: parseFloat(stock?.taxableValue).toFixed(2),
	// 				[head[16]]: parseFloat(stock?.totalValue).toFixed(2),
	// 			})) || [],
	// 	};
	// }, [filteredData, column]);

	const extraFields = useMemo(() => {
		const head = [
			'Inbound No',
			'Inbound Date',
			'Vehicle No',
			'Load In',
			'Vendor Name',
			'Part No',
			'Sap No',
			'Invoice No',
			'Invoice Date',
			'LR #',
			'Invoice Qty',
			'Invoice Weight',
			'Stock Qty',
			'Tax Value',
			'Total Value',
			'Status',
			'ASN No',
		];
		const filteredDataCopy = JSON.parse(JSON.stringify(filteredData)).sort((a, b) => a?.lrNumber - b?.lrNumber);
		// const excelColumns =
		// 	[...column.filter((col) => col?.title !== 'LR')?.map((col) => col?.title), 'Vehicle No', 'In Date', 'Tax Value', 'Total Value'] || [];
		return {
			headers: head,
			data:
				filteredDataCopy.map((stock) => ({
					[head[0]]: stock?.loadId?.loadNumber,
					[head[1]]: moment(stock?.loadId?.loadInDate).format(DATE_FORMAT.DD_MM_YYYY),
					[head[2]]: stock?.loadId?.vehicleId?.regNumber,
					[head[3]]: moment(stock?.loadInDate).format(DATE_FORMAT.DD_MM_YYYY),
					[head[4]]: stock?.vendorId?.vendorName,
					[head[5]]: stock?.partNumberId?.partNumber,
					[head[6]]: stock?.partNumberId?.sapNumber,
					[head[7]]: stock?.invoiceNumber,
					[head[8]]: moment(stock?.invoiceDate).format(DATE_FORMAT.DD_MM_YYYY),
					[head[9]]: stock?.lrNumber,
					[head[10]]: stock?.invoiceQty,
					[head[11]]: stock?.invoiceWeight,
					[head[12]]: stock?.stockQty,
					[head[13]]: parseFloat(stock?.taxableValue).toFixed(2),
					[head[14]]: parseFloat(stock?.totalValue).toFixed(2),
					[head[15]]: stock?.status,
					[head[16]]: stock?.asnNumber,
				})) || [],
		};
	}, [filteredData, column]);

	return (
		<>
			<div style={{ display: 'none' }}>
				<LRCopyToPrint ref={componentRef} data={selectedRecordToPrint} />
			</div>
			<StockPresentational
				{...{
					column,
					filteredData,
					handleTableChange,
					getStartingValue,
					getEndingValue,
					pageSize,
					intialPageSizeOptions,
					initialPageSize,
					currentPage,
					tableLoading,
					partNumbers,
					vendors,
					handleFilterOptions,
					exportData,
					extraFields,
					vehicles,
					getStock,
					selectedViewRow,
					setSelectedViewRow,
				}}
			/>
		</>
	);
});

export default StockFunctional;
