import { ACTIONS } from "../../../constants/app-constants";

function setApiStatus(apiName, status) {
	return {
		type: "SET_API_STATUS",
		payload: {
			[apiName]: status,
		},
	};
}

function resetApiStatus(apiName) {
	return {
		type: "SET_API_STATUS",
		payload: {
			[apiName]: null,
		},
	};
}

function setOrganizations(data) {
	return {
		type: "SET_ORGANIZATIONS",
		payload: data,
	};
}

function setSelectedOrganization(data) {
	return {
		type: "SET_SELECTED_ORGANIZATION",
		payload: data,
	};
}

function setCurrentPage(data) {
	return {
		type: "SET_CURRENT_PAGE",
		payload: data,
	};
}

function setAccountBooks(data) {
	return {
		type: ACTIONS.SET_ACCOUNT_BOOKS,
		payload: data,
	};
}

function setClasses(data) {
	return {
		type: "SET_CLASSES",
		payload: data,
	};
}

function setVoucherHead(data) {
	return {
		type: "SET_VOUCHERS_HEAD",
		payload: data,
	};
}
function setAssets(data) {
	return {
		type: "SET_ASSETS",
		payload: data,
	};
}
function setVehicles(data) {
	return {
		type: "SET_VEHICLES",
		payload: data,
	};
}
function setVendors(data) {
	return {
		type: "SET_VENDORS",
		payload: data,
	};
}
function setVendorsMapping(data) {
	return {
		type: "SET_VENDOR_MAPPING",
		payload: data,
	};
}
function setApplicationUsers(data) {
	return {
		type: "SET_APPLICATION_USERS",
		payload: data,
	};
}
function setCredentials(data) {
	return {
		type: "SET_CREDENTIALS",
		payload: data,
	};
}
function setPartNumbers(data) {
	return {
		type: "SET_PART_NUMBERS",
		payload: data,
	};
}
function setBatch(data) {
	return {
		type: "SET_BATCHES",
		payload: data,
	};
}
function setStates(data) {
	return {
		type: "SET_STATES",
		payload: data,
	};
}
function setLocations(data) {
	return {
		type: "SET_LOCATIONS",
		payload: data,
	};
}
function setUsers(data) {
	return {
		type: "SET_USERS",
		payload: data,
	};
}

function setMaterials(data) {
	return {
		type: ACTIONS.SET_MATERIALS,
		payload: data,
	};
}

function setGPSs(data) {
	return {
		type: ACTIONS.SET_GPSS,
		payload: data,
	};
}

function setGrades(data) {
	return {
		type: ACTIONS.SET_GRADES,
		payload: data,
	};
}

function setCities(data) {
	return {
		type: ACTIONS.SET_CITIES,
		payload: data,
	};
}

function setParties(data) {
	return {
		type: ACTIONS.SET_PARTIES,
		payload: data,
	};
}

function setBankDetails(data) {
	return {
		type: ACTIONS.SET_BANK_DETAILS,
		payload: data,
	};
}

function setRcOwner(data) {
	return {
		type: ACTIONS.SET_RC_OWNERS,
		payload: data,
	};
}

function setDrivers(data) {
	return {
		type: ACTIONS.SET_DRIVERS,
		payload: data,
	};
}

export {
	setApiStatus,
	resetApiStatus,
	setOrganizations,
	setSelectedOrganization,
	setCurrentPage,
	setAccountBooks,
	setStates,
	setClasses,
	setVoucherHead,
	setAssets,
	setVehicles,
	setBatch,
	setPartNumbers,
	setVendors,
	setVendorsMapping,
	setApplicationUsers,
	setCredentials,
	setUsers,
	setLocations,
	setMaterials,
	setGPSs,
	setGrades,
	setCities,
	setParties,
	setBankDetails,
	setRcOwner,
	setDrivers,
};
