let initialState = {
    customers : [
      {
        customerId : 1,
        customerName: "Saravanan",
        mobileNumber: "9090909090",
        gstNumber : "ADAD11111111",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "01/02/2021",
      },
      {
        customerId : 2,
        customerName: "Siva",
        mobileNumber: "2222222222222",
        gstNumber : "ADAD11111112",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "02/09/2020",
      },
      {
        customerId : 1,
        customerName: "Saravanan",
        mobileNumber: "9090909090",
        gstNumber : "ADAD11111111",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "01/02/2021",
      },
      {
        customerId : 2,
        customerName: "Siva",
        mobileNumber: "2222222222222",
        gstNumber : "ADAD11111112",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "02/09/2020",
      },
      {
        customerId : 1,
        customerName: "Saravanan",
        mobileNumber: "9090909090",
        gstNumber : "ADAD11111111",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "01/02/2021",
      },
      {
        customerId : 2,
        customerName: "Siva",
        mobileNumber: "2222222222222",
        gstNumber : "ADAD11111112",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "02/09/2020",
      },
      {
        customerId : 1,
        customerName: "Saravanan",
        mobileNumber: "9090909090",
        gstNumber : "ADAD11111111",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "01/02/2021",
      },
      {
        customerId : 2,
        customerName: "Siva",
        mobileNumber: "2222222222222",
        gstNumber : "ADAD11111112",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "02/09/2020",
      },
      {
        customerId : 1,
        customerName: "Saravanan",
        mobileNumber: "9090909090",
        gstNumber : "ADAD11111111",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "01/02/2021",
      },
      {
        customerId : 2,
        customerName: "Siva",
        mobileNumber: "2222222222222",
        gstNumber : "ADAD11111112",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "02/09/2020",
      },
      {
        customerId : 1,
        customerName: "Saravanan",
        mobileNumber: "9090909090",
        gstNumber : "ADAD11111111",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "01/02/2021",
      },
      {
        customerId : 2,
        customerName: "Siva",
        mobileNumber: "2222222222222",
        gstNumber : "ADAD11111112",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "02/09/2020",
      },
      {
        customerId : 1,
        customerName: "Saravanan",
        mobileNumber: "9090909090",
        gstNumber : "ADAD11111111",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "01/02/2021",
      },
      {
        customerId : 2,
        customerName: "Siva",
        mobileNumber: "2222222222222",
        gstNumber : "ADAD11111112",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "02/09/2020",
      },
      {
        customerId : 1,
        customerName: "Saravanan",
        mobileNumber: "9090909090",
        gstNumber : "ADAD11111111",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "01/02/2021",
      },
      {
        customerId : 2,
        customerName: "Siva",
        mobileNumber: "2222222222222",
        gstNumber : "ADAD11111112",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "02/09/2020",
      },
      {
        customerId : 1,
        customerName: "Saravanan",
        mobileNumber: "9090909090",
        gstNumber : "ADAD11111111",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "01/02/2021",
      },
      {
        customerId : 2,
        customerName: "Siva",
        mobileNumber: "2222222222222",
        gstNumber : "ADAD11111112",
        address : "Vettavampalayam",
        outstanding : 10,
        createdAt: "02/09/2020",
      },
      {
        customerId : 1,
        customerName: "Saravanan",
        mobileNumber: "9090909090",
        gstNumber : "ADAD11111111",
        address : "Vettavampalayam",
        outstanding : 25,
        createdAt: "01/02/2021",
      },
      {
        customerId : 2,
        customerName: "Siva",
        mobileNumber: "2222222222222",
        gstNumber : "ADAD11111112",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "02/09/2020",
      },
      {
        customerId : 1,
        customerName: "Saravanan",
        mobileNumber: "9090909090",
        gstNumber : "ADAD11111111",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "01/02/2021",
      },
      {
        customerId : 2,
        customerName: "Siva",
        mobileNumber: "2222222222222",
        gstNumber : "ADAD11111112",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "02/09/2020",
      },
      {
        customerId : 1,
        customerName: "Saravanan",
        mobileNumber: "9090909090",
        gstNumber : "ADAD11111111",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "01/02/2021",
      },
      {
        customerId : 1,
        customerName: "Saravanan",
        mobileNumber: "9090909090",
        gstNumber : "ADAD11111111",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "01/02/2021",
      },
      {
        customerId : 2,
        customerName: "Siva",
        mobileNumber: "2222222222222",
        gstNumber : "ADAD11111112",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "02/09/2020",
      },
      {
        customerId : 1,
        customerName: "Saravanan",
        mobileNumber: "9090909090",
        gstNumber : "ADAD11111111",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "01/02/2021",
      },
      {
        customerId : 2,
        customerName: "Siva",
        mobileNumber: "2222222222222",
        gstNumber : "ADAD11111112",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "02/09/2020",
      },
      {
        customerId : 1,
        customerName: "Saravanan",
        mobileNumber: "9090909090",
        gstNumber : "ADAD11111111",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "01/02/2021",
      },
      {
        customerId : 2,
        customerName: "Siva",
        mobileNumber: "2222222222222",
        gstNumber : "ADAD11111112",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "02/09/2020",
      },
      {
        customerId : 1,
        customerName: "Saravanan",
        mobileNumber: "9090909090",
        gstNumber : "ADAD11111111",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "01/02/2021",
      },
      {
        customerId : 2,
        customerName: "Siva",
        mobileNumber: "2222222222222",
        gstNumber : "ADAD11111112",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "02/09/2020",
      },
      {
        customerId : 1,
        customerName: "Saravanan",
        mobileNumber: "9090909090",
        gstNumber : "ADAD11111111",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "01/02/2021",
      },
      {
        customerId : 2,
        customerName: "Siva",
        mobileNumber: "2222222222222",
        gstNumber : "ADAD11111112",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "02/09/2020",
      },
      {
        customerId : 1,
        customerName: "Saravanan",
        mobileNumber: "9090909090",
        gstNumber : "ADAD11111111",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "01/02/2021",
      },
      {
        customerId : 2,
        customerName: "Siva",
        mobileNumber: "2222222222222",
        gstNumber : "ADAD11111112",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "02/09/2020",
      },
      {
        customerId : 1,
        customerName: "Saravanan",
        mobileNumber: "9090909090",
        gstNumber : "ADAD11111111",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "01/02/2021",
      },
      {
        customerId : 2,
        customerName: "Siva",
        mobileNumber: "2222222222222",
        gstNumber : "ADAD11111112",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "02/09/2020",
      },
      {
        customerId : 1,
        customerName: "Saravanan",
        mobileNumber: "9090909090",
        gstNumber : "ADAD11111111",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "01/02/2021",
      },
      {
        customerId : 2,
        customerName: "Siva",
        mobileNumber: "2222222222222",
        gstNumber : "ADAD11111112",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "02/09/2020",
      },
      {
        customerId : 1,
        customerName: "Saravanan",
        mobileNumber: "9090909090",
        gstNumber : "ADAD11111111",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "01/02/2021",
      },
      {
        customerId : 2,
        customerName: "Siva",
        mobileNumber: "2222222222222",
        gstNumber : "ADAD11111112",
        address : "Vettavampalayam",
        outstanding : 10,
        createdAt: "02/09/2020",
      },
      {
        customerId : 1,
        customerName: "Saravanan",
        mobileNumber: "9090909090",
        gstNumber : "ADAD11111111",
        address : "Vettavampalayam",
        outstanding : 25,
        createdAt: "01/02/2021",
      },
      {
        customerId : 2,
        customerName: "Siva",
        mobileNumber: "2222222222222",
        gstNumber : "ADAD11111112",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "02/09/2020",
      },
      {
        customerId : 1,
        customerName: "Saravanan",
        mobileNumber: "9090909090",
        gstNumber : "ADAD11111111",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "01/02/2021",
      },
      {
        customerId : 2,
        customerName: "Siva",
        mobileNumber: "2222222222222",
        gstNumber : "ADAD11111112",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "02/09/2020",
      },
      {
        customerId : 1,
        customerName: "Saravanan",
        mobileNumber: "9090909090",
        gstNumber : "ADAD11111111",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "01/02/2021",
      },
      {
        customerId : 2,
        customerName: "Siva",
        mobileNumber: "2222222222222",
        gstNumber : "ADAD11111112",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "02/09/2020",
      },      
      {
        customerId : 1,
        customerName: "Saravanan",
        mobileNumber: "9090909090",
        gstNumber : "ADAD11111111",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "01/02/2021",
      },
      {
        customerId : 2,
        customerName: "Siva",
        mobileNumber: "2222222222222",
        gstNumber : "ADAD11111112",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "02/09/2020",
      },
      {
        customerId : 1,
        customerName: "Saravanan",
        mobileNumber: "9090909090",
        gstNumber : "ADAD11111111",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "01/02/2021",
      },
      {
        customerId : 2,
        customerName: "Siva",
        mobileNumber: "2222222222222",
        gstNumber : "ADAD11111112",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "02/09/2020",
      },
      {
        customerId : 1,
        customerName: "Saravanan",
        mobileNumber: "9090909090",
        gstNumber : "ADAD11111111",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "01/02/2021",
      },
      {
        customerId : 2,
        customerName: "Siva",
        mobileNumber: "2222222222222",
        gstNumber : "ADAD11111112",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "02/09/2020",
      },
      {
        customerId : 1,
        customerName: "Saravanan",
        mobileNumber: "9090909090",
        gstNumber : "ADAD11111111",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "01/02/2021",
      },
      {
        customerId : 2,
        customerName: "Siva",
        mobileNumber: "2222222222222",
        gstNumber : "ADAD11111112",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "02/09/2020",
      },
      {
        customerId : 1,
        customerName: "Saravanan",
        mobileNumber: "9090909090",
        gstNumber : "ADAD11111111",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "01/02/2021",
      },
      {
        customerId : 2,
        customerName: "Siva",
        mobileNumber: "2222222222222",
        gstNumber : "ADAD11111112",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "02/09/2020",
      },
      {
        customerId : 1,
        customerName: "Saravanan",
        mobileNumber: "9090909090",
        gstNumber : "ADAD11111111",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "01/02/2021",
      },
      {
        customerId : 2,
        customerName: "Siva",
        mobileNumber: "2222222222222",
        gstNumber : "ADAD11111112",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "02/09/2020",
      },
      {
        customerId : 1,
        customerName: "Saravanan",
        mobileNumber: "9090909090",
        gstNumber : "ADAD11111111",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "01/02/2021",
      },
      {
        customerId : 2,
        customerName: "Siva",
        mobileNumber: "2222222222222",
        gstNumber : "ADAD11111112",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "02/09/2020",
      },
      {
        customerId : 1,
        customerName: "Saravanan",
        mobileNumber: "9090909090",
        gstNumber : "ADAD11111111",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "01/02/2021",
      },
      {
        customerId : 2,
        customerName: "Siva",
        mobileNumber: "2222222222222",
        gstNumber : "ADAD11111112",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "02/09/2020",
      },
      {
        customerId : 1,
        customerName: "Saravanan",
        mobileNumber: "9090909090",
        gstNumber : "ADAD11111111",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "01/02/2021",
      },
      {
        customerId : 2,
        customerName: "Siva",
        mobileNumber: "2222222222222",
        gstNumber : "ADAD11111112",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "02/09/2020",
      },
      {
        customerId : 1,
        customerName: "Saravanan",
        mobileNumber: "9090909090",
        gstNumber : "ADAD11111111",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "01/02/2021",
      },
      {
        customerId : 2,
        customerName: "Siva",
        mobileNumber: "2222222222222",
        gstNumber : "ADAD11111112",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "02/09/2020",
      },
      {
        customerId : 1,
        customerName: "Saravanan",
        mobileNumber: "9090909090",
        gstNumber : "ADAD11111111",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "01/02/2021",
      },
      {
        customerId : 2,
        customerName: "Siva",
        mobileNumber: "2222222222222",
        gstNumber : "ADAD11111112",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "02/09/2020",
      },
      {
        customerId : 1,
        customerName: "Saravanan",
        mobileNumber: "9090909090",
        gstNumber : "ADAD11111111",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "01/02/2021",
      },
      {
        customerId : 2,
        customerName: "Siva",
        mobileNumber: "2222222222222",
        gstNumber : "ADAD11111112",
        address : "Vettavampalayam",
        outstanding : 0,
        createdAt: "02/09/2020",
      },  
    ],
    selectedCustomer : null,
};

const customerReducer = (state = initialState, action) => {

  switch (action.type) {

    case "LOGOUT" : {
      return initialState
    }
  
    case "SET_CUSTOMERS" : 
      return {
        ...state,
        customers : action.payload
      }   

    case "SET_SELECTED_CUSTOMER" : 
      return {
        ...state,
        selectedCustomer : action.payload
      }      

    
    default : return state
  }

}

export {customerReducer}
