import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Popconfirm, Button, Pagination, Row, Col, Input } from 'antd';
import { AiOutlineSearch } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import AddDrawer from 'components/drawer-component';
import { generatePagination } from 'helpers';
import TableComponent from 'components/table-component';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { SERVER_IP } from 'assets/Config';
import { getDateFormat } from 'services/Utils';
import { deleteApi } from 'redux/sagas/deleteApiSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import HighlightComponent from 'components/HighlightComponent';
import { API_STATUS } from 'constants/app-constants';

const initialPageSize = 10;
const intialPageSizeOptions = [10, 15, 20];

const RcOwners = React.memo(() => {
	const globalRedux = useSelector((state) => state?.globalRedux);
	const [rcOwnerAddModal, setRcOwnerAddModal] = useState(false);
	const [searchKey, setSearchKey] = useState('');
	const [tableData, setTableData] = useState(globalRedux?.rcOwners || []);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const [editRcOwner, setEditRcOwner] = useState(null);
	const dispatch = useDispatch();

	const getRcOwners = useCallback(() => {
		let url = `${SERVER_IP}rcowner/?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(getApi('GET_RC_OWNERS', url));
	}, [dispatch, globalRedux?.selectedOrganization?._id]);

	useEffect(() => {
		getRcOwners();
	}, [getRcOwners]);

	useEffect(() => {
		generatePagination(tableData);
	}, [tableData]);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_DRIVER === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_DRIVER'));
			doIt = true;
		}
		if (doIt) {
			getRcOwners();
		}
	}, [globalRedux.apiStatus, getRcOwners, dispatch]);

	useEffect(() => {
		setTableData(globalRedux?.rcOwners || []);
	}, [globalRedux?.rcOwners]);

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((rcOwner) => {
			return (
				(rcOwner?.rcownerName || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(rcOwner?.mobile || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(rcOwner?.address || '')?.toLowerCase().includes(searchKey.toLowerCase())
			);
		});
	}, [tableData, searchKey]);

	const handleDrawer = (rowData) => {
		setEditRcOwner(rowData);
		setRcOwnerAddModal(true);
	};

	const handleAdd = () => {
		setRcOwnerAddModal(true);
		setEditRcOwner(null);
	};

	const column = [
		{
			title: 'RcOwner name',
			dataIndex: 'rcownerName',
			key: 'rcownerName',
			sorter: (a, b) => a.rcownerName.localeCompare(b.rcownerName),
			fixed: 'left',
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />
			),
		},
		{
			title: 'Mobile',
			dataIndex: 'mobile',
			key: 'mobile',
			sorter: (a, b) => a.mobile.localeCompare(b.mobile),
			fixed: 'left',
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />
			),
		},
		{
			title: 'Address',
			dataIndex: 'address',
			key: 'address',
			sorter: (a, b) => a.address.localeCompare(b.address),
			fixed: 'left',
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />
			),
		},
		{
			title: 'Remarks',
			dataIndex: 'remarks',
			key: 'remarks',
			// sorter: (a, b) => a.displayName.localeCompare(b.displayName),
			fixed: 'left',
		},
		{
			title: 'Created Date',
			dataIndex: 'createdAt',
			align: 'left',
			// sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
			render: (value) => getDateFormat(value),
		},
		{
			title: 'Created By',
			dataIndex: 'createdBy',
			align: 'left',
			// sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
			render: (value) => value?.firstName,
		},
		{
			title: 'Action',
			align: 'center',
			dataIndex: 'rcOwnerName',
			render: (value, row) => {
				return (
					<Row gutter={10} justify="center">
						<Col onClick={() => handleDrawer(row)} className="edit_icon">
							<EditOutlined />
						</Col>
						<Col className="delete_icon">
							<Popconfirm
								title={`Are You Sure to Delete ${value}?`}
								okText="Delete"
								cancelText="No"
								onConfirm={() => {
									let url = `${SERVER_IP}rcowner/${row._id}?orgId=${globalRedux?.selectedOrganization?._id}`;
									dispatch(deleteApi('DELETE_DRIVER', url));
								}}
								placement="rightTop">
								<CloseOutlined />
							</Popconfirm>
						</Col>
					</Row>
				);
			},
		},
	];

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const loading = useMemo(() => globalRedux.apiStatus.GET_RC_OWNERS === API_STATUS.PENDING, [globalRedux.apiStatus]);

	return (
		<>
			<Row style={{ padding: '20px 10px' }}>
				<Col xl={24}>
					<TableComponent
						loading={loading}
						className="custom-table"
						style={{ width: '100%' }}
						columns={column}
						bordered
						rowKey={(record) => record._id}
						dataSource={filteredData}
						title={() => (
							<Row justify="space-between">
								<Col md={3}>
									<Input
										placeholder="Search"
										suffix={<AiOutlineSearch />}
										style={{ height: '30px', borderRadius: '8px' }}
										onChange={({ target: { value } }) => setSearchKey(value)}
									/>
								</Col>
								<Col md={7}></Col>
								<Col>
									<Button type="primary" style={{ height: '30px', width: '100%' }} onClick={() => handleAdd(true)}>
										Add Rc Owner
									</Button>
								</Col>
							</Row>
						)}
						pagination={{
							current: currentPage,
							pageSize: pageSize,
							position: ['none', 'none'],
						}}
						footer={() => (
							<Row justify="space-between">
								<Col>
									<div>
										{!!filteredData.length &&
											`Showing ${getStartingValue()} - ${getEndingValue()} of ${filteredData.length} Data`}
									</div>
								</Col>
								<Col md={8}>
									<div style={{ textAlign: 'right' }}>
										<Pagination
											pageSizeOptions={intialPageSizeOptions}
											defaultPageSize={initialPageSize}
											showSizeChanger={true}
											total={filteredData.length}
											onChange={handleTableChange}
											responsive
										/>
									</div>
								</Col>
							</Row>
						)}
						// onChange={handleTableChange}
					/>
				</Col>
			</Row>
			<AddDrawer {...{ rcOwnerAddModal, setRcOwnerAddModal, getRcOwners, editRcOwner }} />
		</>
	);
});

export default RcOwners;
