import { ConfigProvider } from "antd";
import { Toaster } from "react-hot-toast";
import useToast from "redux/sagas/useToast";
import Routes from "./routes/Routes";
import "antd/dist/reset.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./css/theme.scss";
import "./css/custom.scss";
import "./style.scss";

function App() {
	const {
		// toast
	} = useToast();
	return (
		<ConfigProvider
			theme={{
				token: {
					colorPrimary: "#1979ba",
					// borderRadiusBase: "4px",
				},
				hashed: false,
			}}
		>
			<Routes />
			<Toaster />
		</ConfigProvider>
		// <>
		// 	<Routes />
		// 	<Toaster />
		// 	<ToastContainer autoClose={1000} hideProgressBar style={{ width: '400px' }} />
		// </>
	);
}

export default App;
