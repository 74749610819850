import React, { useEffect } from "react";
import { Input, Button, Row, Col, Form, DatePicker } from "antd";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { postApi } from "../../redux/sagas/postApiDataSaga";
import { resetApiStatus } from "../../redux/reducers/globals/globalActions";
import { putApi } from "redux/sagas/putApiSaga";
import { SERVER_IP } from "assets/Config";
import { API_STATUS } from "constants/app-constants";

const { TextArea } = Input;

// let contactPersonData = {
// 	id: null,
// 	salutation: null,
// 	firstName: null,
// 	lastName: null,
// 	email: null,
// 	designation: null,
// 	mobile: null,
// };

const AddAccountBook = ({
	handleClose,
	editAccountBooks,
	setAccBookAddModal,
}) => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const globalRedux = useSelector((state) => state.globalRedux);

	const addAccBook = (values) => {
		let data = {
			...values,

			displayName: values.displayName,
			email: values.email,
			mobile: values.mobile,
			orgId: globalRedux.selectedOrganization._id,
		};

		dispatch(postApi(data, "ADD_ACCOUNT_BOOK"));
		// console.log('🚀 ~ file: AddAccountBook.jsx ~ line 42 ~ addAccBook ~ addAccBook', data);
	};
	const handleEdit = (values) => {
		let data = {
			...values,
			orgId: globalRedux.selectedOrganization._id,
		};
		let url = `${SERVER_IP}accBook/${editAccountBooks._id}?orgId=${globalRedux.selectedOrganization._id}`;
		dispatch(putApi(data, "EDIT_ACC_BOOK", url));
	};

	useEffect(() => {
		if (
			globalRedux.apiStatus.ADD_ACCOUNT_BOOK === "SUCCESS" ||
			globalRedux.apiStatus.EDIT_ACC_BOOK === "SUCCESS"
		) {
			dispatch(
				resetApiStatus(editAccountBooks ? "EDIT_ACC_BOOK" : "ADD_ACCOUNT_BOOK")
			);
			setAccBookAddModal(false);
			handleClose();
		}
	}, [globalRedux.apiStatus, dispatch, editAccountBooks, handleClose, setAccBookAddModal]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const loading =
		globalRedux.apiStatus.ADD_ACCOUNT_BOOK === API_STATUS.PENDING ||
		globalRedux.apiStatus.EDIT_ACC_BOOK === API_STATUS.PENDING;

	return (
		<Row>
			<Col span={24}>
				{/* <Row style={{ backgroundColor: '#fff', padding: '10px 0px' }}>
				<Col>
					<h6 style={{ marginBottom: '0px' }}>New Account book</h6>
				</Col>
			</Row>
			<Divider /> */}
				<Row style={{ paddingTop: 0 }}>
					<Col span={24}>
						<Form
							name="add-staff"
							style={{}}
							colon={false}
							labelAlign="left"
							form={form}
							onFinish={!editAccountBooks ? addAccBook : handleEdit}
							{...layer1FormCol}
						>
							<Form.Item
								label="Account Book Name"
								name="accbookName"
								initialValue={editAccountBooks?.accbookName}
								rules={[
									{
										required: true,
										message: "This Field is Required!",
									},
								]}
							>
								<Input placeholder="Account Book Name" />
							</Form.Item>
							<Form.Item
								label="Opening Balance"
								name="openingBalance"
								initialValue={editAccountBooks?.openingBalance || 0}
							>
								<Form.Item name="openingBalance">
									<Input type="number" placeholder="Account Balance" />
								</Form.Item>
							</Form.Item>
							<Form.Item
								label="Opening Date"
								name="openingDate"
								initialValue={moment(editAccountBooks?.openingDate)}
								rules={[
									{
										required: true,
										message: "This Field is Required!",
									},
								]}
							>
								<DatePicker
									defaultValue={moment(editAccountBooks?.openingDate)}
									format="DD/MM/YYYY"
								/>
							</Form.Item>
							<Form.Item
								label="Remarks"
								name="remarks"
								initialValue={editAccountBooks?.remarks}
							>
								<TextArea />
							</Form.Item>
							<Form.Item
								wrapperCol={{
									offset: 0,
									span: 24,
								}}
							>
								<Row justify="space-between">
									<Button
										onClick={() => setAccBookAddModal(false)}
										style={{ width: "49%" }}
										danger
									>
										Cancel
									</Button>
									<Button
										loading={loading}
										type="primary"
										style={{ width: "49%", marginRight: 5 }}
										htmlType="submit"
									>
										Create
									</Button>
								</Row>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default AddAccountBook;
