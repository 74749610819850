import React from 'react';
import { Pagination, Row, Col, Tabs, Input } from 'antd';
import TableComponent from 'components/table-component';
import AddLoadIn from 'pages/load-in/add-load-in';
import { AiOutlineSearch } from 'react-icons/ai';

const { TabPane } = Tabs;

const LoadInPresentational = ({
	column,
	filteredData,
	handleTableChange,
	getStartingValue,
	getEndingValue,
	pageSize,
	intialPageSizeOptions,
	initialPageSize,
	currentPage,
	refreshList,
	tableLoading,
	state,
	setState,
	editData,
	activeTab,
	setActiveTab,
	setSearchKey,
	handleSelectRow
}) => {
	return (
		<Row style={{ padding: '20px 10px' }}>
			<Col xl={24} md={24}>
				<Tabs defaultActiveKey="0" destroyInactiveTabPane onChange={setActiveTab}>
					<TabPane tab={'Undelivered'} key={'0'}>
						<TableComponent
							// onRow={(record) => ({ 
							// 	onClick: () => { handleSelectRow(record) } 
							//   })}
							loading={tableLoading}
							className="custom-table"
							style={{ width: '100%' }}
							rowKey={(record) => record._id}
							dataSource={filteredData}
							title={() => (
								<Row justify="space-between">
									<Col md={3}>
										<Input
											placeholder="Search"
											suffix={<AiOutlineSearch />}
											style={{ height: '30px' }}
											onChange={({ target: { value } }) => setSearchKey(value)}
										/>
									</Col>
								</Row>
							)}
							{...{
								columns: column,
								pagination: { current: currentPage, pageSize: pageSize, position: ['none', 'none'] },
								...(!!filteredData?.length && {
									footer: () => (
										<Row justify="space-between">
											<Col span={12}>
												{!!filteredData?.length &&
													`Showing ${getStartingValue()} - ${getEndingValue()} of ${filteredData?.length} Data`}
											</Col>
											<Col span={12}>
												<div style={{ textAlign: 'right' }}>
													<Pagination
														pageSizeOptions={intialPageSizeOptions}
														defaultPageSize={initialPageSize}
														showSizeChanger={true}
														total={filteredData?.length}
														onChange={handleTableChange}
														responsive
													/>
												</div>
											</Col>
										</Row>
									),
								}),
							}}
						/>
					</TabPane>
					<TabPane tab={'Delivered'} key={'1'}>
						<TableComponent
							loading={tableLoading}
							className="custom-table"
							style={{ width: '100%' }}
							rowKey={(record) => record._id}
							dataSource={filteredData}
							title={() => (
								<Row justify="space-between">
									<Col md={3}>
										<Input
											placeholder="Search"
											suffix={<AiOutlineSearch />}
											style={{ height: '30px' }}
											onChange={({ target: { value } }) => setSearchKey(value)}
										/>
									</Col>
								</Row>
							)}
							// title={() => (
							// 	<Row justify="space-between">
							// 		<Col xl={8} md={8}></Col>
							// 		<Col>
							// 			<Button
							// 				type="primary"
							// 				onClick={() =>
							// 					setState({
							// 						...state,
							// 						visible: true,
							// 					})
							// 				}>
							// 				Add New Unload
							// 			</Button>
							// 		</Col>
							// 	</Row>
							// )}
							{...{
								columns: column,
								pagination: { current: currentPage, pageSize: pageSize, position: ['none', 'none'] },
								...(!!filteredData?.length && {
									footer: () => (
										<Row justify="space-between">
											<Col span={12}>
												{!!filteredData?.length &&
													`Showing ${getStartingValue()} - ${getEndingValue()} of ${filteredData?.length} Data`}
											</Col>
											<Col span={12}>
												<div style={{ textAlign: 'right' }}>
													<Pagination
														pageSizeOptions={intialPageSizeOptions}
														defaultPageSize={initialPageSize}
														showSizeChanger={true}
														total={filteredData?.length}
														onChange={handleTableChange}
														responsive
													/>
												</div>
											</Col>
										</Row>
									),
								}),
							}}
						/>
					</TabPane>
				</Tabs>
			</Col>
			<AddLoadIn {...{ state, setState, refreshList, editData, activeTab }} />
		</Row>
	);
};

export default LoadInPresentational;
