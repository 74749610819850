import React from "react";
import {
	Pagination,
	Row,
	Col,
	Select,
	DatePicker,
	Table,
	Typography,
} from "antd";
import TableComponent from "components/table-component";
import { DATE_FORMAT, DEPARTMENT_LIST } from "constants/app-constants";
import moment from "moment";
import ExcelDownload from "components/excel-download";
import PdfDownload from "components/pdf-download";

const { Text } = Typography;

const OutStandingReportListPresentational = ({
	column,
	filteredData,
	handleTableChange,
	getStartingValue,
	getEndingValue,
	pageSize,
	intialPageSizeOptions,
	initialPageSize,
	currentPage,
	tableLoading,
	customers,
	handleFilterOptions,
	exportData,
}) => {
	return (
		<Row style={{ padding: "20px 10px" }}>
			<Col xl={24} md={24}>
				<TableComponent
					loading={tableLoading}
					className="custom-table"
					style={{ width: "100%" }}
					rowKey={(record) => record._id}
					dataSource={filteredData}
					title={() => (
						<Row justify="space-between">
							<Col xl={24} md={24}>
								<Row gutter={[10, 10]} style={{ width: "100%" }}>
									<Col xl={4} md={4}>
										<Select
											showSearch
											allowClear
											placeholder="Select Customer"
											optionFilterProp="children"
											style={{ width: "100%" }}
											filterOption={(input, option) =>
												option.props.children
													.toLowerCase()
													.indexOf(input.toLowerCase()) >= 0 ||
												option.props.value
													.toLowerCase()
													.indexOf(input.toLowerCase()) >= 0
											}
											onChange={(value) =>
												handleFilterOptions("customerId", value)
											}
										>
											{customers?.map((customer) => (
												<Select.Option value={customer._id}>
													{customer?.displayName}
												</Select.Option>
											))}
										</Select>
									</Col>
									<Col span={4}>
										<Select
											allowClear
											onChange={(value) =>
												handleFilterOptions("department", value)
											}
											style={{ width: "100%" }}
											placeholder="select department"
										>
											{DEPARTMENT_LIST?.map((department) => (
												<Select.Option value={department}>
													{department}
												</Select.Option>
											))}
										</Select>
									</Col>
									<Col xl={4} md={4}>
										<DatePicker
											placeholder="Select From Date"
											style={{ width: "100%" }}
											format={DATE_FORMAT.DD_MM_YYYY}
											onChange={(value) => {
												handleFilterOptions(
													"fromDate",
													value && value !== "Invalid date"
														? (value).format(DATE_FORMAT.YYYY_MM_DD)
														: ""
												);
											}}
										/>
									</Col>
									<Col xl={4} md={4}>
										<DatePicker
											placeholder="Select To Date"
											style={{ width: "100%" }}
											format={DATE_FORMAT.DD_MM_YYYY}
											onChange={(value) =>
												handleFilterOptions(
													"toDate",
													value && value !== "Invalid date"
														? (value).format(DATE_FORMAT.YYYY_MM_DD)
														: ""
												)
											}
										/>
									</Col>
								</Row>
							</Col>
							<Col xl={24} md={24} style={{ paddingTop: 10 }}>
								<Row gutter={[10, 10]}>
									<Col xl={4} md={4}>
										<PdfDownload
											{...{
												...exportData,
												columnStyles: {
													5: { halign: "right" },
													6: { halign: "right" },
													7: { halign: "right" },
												},
												headStyles: {
													5: { halign: "right" },
													6: { halign: "right" },
													7: { halign: "right" },
												},
											}}
										/>
									</Col>
									<Col xl={4} md={4}>
										<ExcelDownload {...{ ...exportData }} />
									</Col>
								</Row>
							</Col>
						</Row>
					)}
					{...{
						summary: (pageData) => {
							let totalAmountRS = 0;
							let receiptTotalRS = 0;
							let balanceAmountRS = 0;

							pageData.forEach(
								({ totalAmount, receiptTotal, balanceAmount }) => {
									totalAmountRS += totalAmount;
									receiptTotalRS += receiptTotal;
									balanceAmountRS += balanceAmount;
								}
							);

							return (
								<Table.Summary.Row>
									<Table.Summary.Cell>
										<div
											style={{
												display: "flex",
												justifyContent: "flex-start",
												paddingLeft: 10,
											}}
										>
											<Text style={{ fontWeight: "bold" }}>Total</Text>
										</div>
									</Table.Summary.Cell>
									<Table.Summary.Cell />
									<Table.Summary.Cell />
									<Table.Summary.Cell />
									<Table.Summary.Cell />
									<Table.Summary.Cell>
										<div
											style={{
												display: "flex",
												justifyContent: "flex-end",
												paddingRight: 10,
											}}
										>
											<Text style={{ fontWeight: "bold" }}>
												{parseFloat(totalAmountRS || 0).toFixed(2)}
											</Text>
										</div>
									</Table.Summary.Cell>
									<Table.Summary.Cell style={{ textAlign: "right" }}>
										<div
											style={{
												display: "flex",
												justifyContent: "flex-end",
												paddingRight: 10,
											}}
										>
											<Text style={{ fontWeight: "bold" }}>
												{parseFloat(receiptTotalRS || 0).toFixed(2)}
											</Text>
										</div>
									</Table.Summary.Cell>
									<Table.Summary.Cell style={{ textAlign: "right" }}>
										<div
											style={{
												display: "flex",
												justifyContent: "flex-end",
												paddingRight: 10,
											}}
										>
											<Text style={{ fontWeight: "bold" }}>
												{parseFloat(balanceAmountRS || 0).toFixed(2)}
											</Text>
										</div>
									</Table.Summary.Cell>
								</Table.Summary.Row>
							);
						},
						columns: column,
						pagination: {
							current: currentPage,
							pageSize: pageSize,
							position: ["none", "none"],
						},
						...(!!filteredData?.length && {
							footer: () => (
								<Row justify="space-between">
									<Col span={12}>
										{!!filteredData?.length &&
											`Showing ${getStartingValue()} - ${getEndingValue()} of ${
												filteredData?.length
											} Data`}
									</Col>
									<Col span={12}>
										<div style={{ textAlign: "right" }}>
											<Pagination
												current={currentPage}
												pageSizeOptions={intialPageSizeOptions}
												defaultPageSize={initialPageSize}
												showSizeChanger={true}
												total={filteredData?.length}
												onChange={handleTableChange}
												responsive
											/>
										</div>
									</Col>
								</Row>
							),
						}),
					}}
				/>
			</Col>
		</Row>
	);
};

export default OutStandingReportListPresentational;
