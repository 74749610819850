import React from "react";
import { Drawer } from "antd";
import AddSalesPerson from "pages/master-pages/sales-person/add-sales-person";
import AddAccountBook from "pages/master-pages/acc-book/add-acc-book";
import AddMaterial from "pages/master-pages/materials/add-material";
import AddCity from "pages/master-pages/cities/add-city";
import AddDriver from "pages/master-pages/drivers/add-driver";
import AddRcOwner from "pages/master-pages/rc-owners/add-rc-owner";
import AddGrade from "pages/master-pages/grades/add-grade";
import AddParty from "pages/master-pages/parties/add-party";
import AddBankDetail from "pages/master-pages/bank-details/add-bank-detail";
import AddCustomer from "pages/master-pages/customers/add-customer";
import AddGPS from "pages/master-pages/gps/add-gps";

const AddDrawer = ({
	width,
	setSupplierAddModal = null,
	setAgentAddModal = null,
	getAgents = null,
	refreshList,
	getStaffs,
	getAssets,
	editVehicle,
	accBookAddModal,
	setAccBookAddModal,
	getAccountBooks,
	editAccountBooks,
	accountBookModal,
	setAccountBookModal,
	getVouchers,
	editVoucher,
	vehicleAddModal,
	setVehicleAddModal,
	getVehicle,
	getClass,
	getVendor,
	getCredential,
	getItems,
	salesPersonAddModal,
	setSalesPersonAddModal,
	getSalesPersons,
	editSalesPerson,
	materialAddModal,
	setMaterialAddModal,
	getMaterials,
	editMaterials,
	cityAddModal,
	setCityAddModal,
	getCities,
	editCity,
	driverAddModal,
	setDriverAddModal,
	getDrivers,
	editDriver,
	rcOwnerAddModal,
	setRcOwnerAddModal,
	getRcOwners,
	editRcOwner,
	gradeAddModal,
	setGradeAddModal,
	getGrades,
	editGrade,
	partyAddModal,
	setPartyAddModal,
	editParty,
	bankDetailAddModal,
	setBankDetailAddModal,
	getBankDetails,
	editBankDetail,
	gpsAddModal,
	setGPSAddModal,
	getGPSs,
	editGPS,
	customerAddModal,
	setCustomerAddModal,
	getCustomers,
	editCustomer,
}) => {
	const handleClose = () => {
		setSupplierAddModal?.(false);
		setAgentAddModal?.(false);
		getAgents?.();
		refreshList?.();
		getStaffs?.();
		getAssets?.();
		getAccountBooks?.();
		getVouchers?.();
		getItems?.();
		getVehicle?.();
		getClass?.();
		getVendor?.();
		getCredential?.();
		getCustomers?.();
		getItems?.();
		getSalesPersons?.();
		getMaterials?.();
		getCities?.();
		getDrivers?.();
		getRcOwners?.();
		getGrades?.();
		getBankDetails?.();
		getGPSs?.();
		getCustomers?.();
	};

	const getTitle = () => {
		if (accBookAddModal)
			return editAccountBooks ? "Edit Account Book" : "New Account Book";
		if (materialAddModal)
			return editMaterials ? "Edit Material" : "New Material";
		if (gradeAddModal) return editGrade ? "Edit Grade" : "New Grade";
		if (salesPersonAddModal)
			return editSalesPerson ? "Edit Sales Person" : "New Sales Person";
		if (cityAddModal) return editCity ? "Edit City" : "New City";
		if (driverAddModal) return editDriver ? "Edit Driver" : "New Driver";
		if (rcOwnerAddModal) return editRcOwner ? "Edit RC Owner" : "New RC Owner";
		if (partyAddModal) return editParty ? "Edit Party" : "New Party";
		if (bankDetailAddModal)
			return editBankDetail ? "Edit Bank Detail" : "New Bank Detail";
		if (vehicleAddModal) return editVehicle ? "Edit Vehicle" : "New Vehicle";
		if (gpsAddModal) return editGPS ? "Edit GPS" : "New GPS";
		if (customerAddModal)
			return editCustomer ? "Edit Customer" : "New Customer";
		return "New Entry";
	};

	return (
		<Drawer
			title={getTitle()}
			className="customer-form"
			destroyOnClose
			placement="right"
			width={width || "40%"}
			onClose={() => {
				setAccBookAddModal?.(false);
				setMaterialAddModal?.(false);
				setGradeAddModal?.(false);
				setCityAddModal?.(false);
				setCustomerAddModal?.(false);
				setVehicleAddModal?.(false);
				setDriverAddModal?.(false);
				setRcOwnerAddModal?.(false);
				setSalesPersonAddModal?.(false);
				setPartyAddModal?.(false);
				setBankDetailAddModal?.(false);
				setGPSAddModal?.(false);

				setCustomerAddModal?.(false);
			}}
			open={
				customerAddModal ||
				accBookAddModal ||
				accountBookModal ||
				vehicleAddModal ||
				salesPersonAddModal ||
				materialAddModal ||
				cityAddModal ||
				driverAddModal ||
				rcOwnerAddModal ||
				gradeAddModal ||
				partyAddModal ||
				bankDetailAddModal ||
				gpsAddModal ||
				customerAddModal
			}
		>
			{accBookAddModal && (
				<AddAccountBook
					{...{ handleClose, editAccountBooks, setAccBookAddModal }}
				/>
			)}
			{materialAddModal && (
				<AddMaterial {...{ handleClose, setMaterialAddModal, editMaterials }} />
			)}
			{gradeAddModal && (
				<AddGrade {...{ handleClose, setGradeAddModal, editGrade }} />
			)}
			{cityAddModal && (
				<AddCity {...{ handleClose, setCityAddModal, editCity }} />
			)}
			{partyAddModal && (
				<AddParty {...{ handleClose, setPartyAddModal, editParty }} />
			)}
			{customerAddModal && (
				<AddCustomer {...{ handleClose, setCustomerAddModal, editCustomer }} />
			)}
			{bankDetailAddModal && (
				<AddBankDetail
					{...{ handleClose, setBankDetailAddModal, editBankDetail }}
				/>
			)}
			{/* {vehicleAddModal && <AddVehicle {...{ handleClose, setVehicleAddModal, editVehicle }} />} */}
			{driverAddModal && (
				<AddDriver {...{ handleClose, setDriverAddModal, editDriver }} />
			)}
			{rcOwnerAddModal && (
				<AddRcOwner {...{ handleClose, setRcOwnerAddModal, editRcOwner }} />
			)}
			{salesPersonAddModal && (
				<AddSalesPerson
					{...{ handleClose, editSalesPerson, setSalesPersonAddModal }}
				/>
			)}
			{gpsAddModal && <AddGPS {...{ handleClose, editGPS, setGPSAddModal }} />}
		</Drawer>
	);
};

export default AddDrawer;
