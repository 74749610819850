import React, { useEffect, useMemo, useState } from "react";
import { Popconfirm, Row, Col, Tag } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getApi } from "redux/sagas/getApiDataSaga";
import { SERVER_IP } from "assets/Config";
import { deleteApi } from "redux/sagas/deleteApiSaga";
import { resetApiStatus } from "redux/reducers/globals/globalActions";
import HighlightComponent from "components/HighlightComponent";
import {
	ACTIONS,
	API_STATUS,
	DATE_FORMAT,
	STATUS,
} from "constants/app-constants";
import { generatePagination } from "helpers";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import { getDateFormat } from "services/Utils";
import BookingsListPresentational from "./bookings-list-presentational";

const initialPageSize = 20;
const intialPageSizeOptions = [20, 40, 80];

const BookingsListFunctional = React.memo((props) => {
	const loadsRedux = useSelector((state) => state.loadsRedux);
	const users = useSelector((state) => state?.userRedux?.users);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [visible, toggleVisible] = useState(false);
	const [searchKey, setSearchKey] = useState("");
	const [selectedRow, setSelectedRow] = useState(null);
	const [tableData, setTableData] = useState(loadsRedux?.loads || []);
	const [currentPage, setCurrentPage] = useState(1);
	const [state, setState] = useState({
		selectedRow: null,
		visible: false,
	});
	const [pageSize, setPageSize] = useState(initialPageSize);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const dispatch = useDispatch();

	const getLoads = () => {
		dispatch(
			getApi(
				ACTIONS.GET_LOADS,
				`${SERVER_IP}booking?orgId=${globalRedux.selectedOrganization._id}&status=InTransit,Halting`
			)
		);
	};

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_LOAD === "SUCCESS") {
			dispatch(resetApiStatus("DELETE_LOAD"));
			doIt = true;
		}
		if (doIt) {
			getLoads();
		}
	}, [globalRedux.apiStatus]);

	useEffect(() => {
		getLoads();
		generatePagination(tableData);
	}, []);

	useEffect(() => {
		setTableData(loadsRedux?.loads);
	}, [loadsRedux?.loads]);

	const filteredData = useMemo(() => {
		if (searchKey === "") {
			return tableData;
		}
		const searchLower = searchKey.toLowerCase();
		return tableData.filter((record) => {
			const checkField = (field) =>
				field ? field.toString().toLowerCase().includes(searchLower) : false;
			return (
				checkField(record?.lrNumber) ||
				// checkField(record?.bookingDate) ||
				checkField(record?.from?.cityName) ||
				checkField(record?.to?.cityName) ||
				checkField(record?.consignor?.displayName) ||
				checkField(record?.consignee?.displayName) ||
				checkField(record?.vehicleId?.vehicleNumber) ||
				checkField(record?.driverId?.driverName) ||
				checkField(record?.trackingNumber) ||
				checkField(record?.customerFreight) ||
				checkField(record?.vehicleFreight) ||
				checkField(record?.remarks) ||
				checkField(record?.status)
			);
		});
	}, [tableData, searchKey]);

	const handleEdit = (rowData) => {
		setState({
			...state,
			selectedRow: rowData,
			visible: true,
		});
	};

	const column = [
		{
			title: "Track#",
			dataIndex: "trackingNumber",
			key: "trackingNumber",
			fixed: "left",
			width: 80,
			sorter: (a, b) => a?.trackingNumber?.localeCompare(b?.trackingNumber),
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value || "-"}
				/>
			),
		},
		{
			title: "Booking Date",
			sorter: (a, b) => new Date(a.bookingDate) - new Date(b.bookingDate),
			dataIndex: "bookingDate",
			key: "bookingDate",
			fixed: "left",
			width: 100,
			render: (value) => getDateFormat(value, DATE_FORMAT.DD_MM_YYYY),
		},
		{
			title: "From(City)",
			dataIndex: "from",
			key: "from",
			fixed: "left",
			sorter: (a, b) => a?.from?.cityName?.localeCompare(b?.from?.cityName),
			width: 150,
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.cityName}
				/>
			),
		},
		{
			title: "To(City)",
			dataIndex: "to",
			sorter: (a, b) => a?.to?.cityName?.localeCompare(b?.to?.cityName),
			key: "to",
			fixed: "left",
			width: 150,
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.cityName}
				/>
			),
		},
		{
			title: "Consignor",
			dataIndex: "consignor",
			key: "consignor",
			width: 120,
			sorter: (a, b) =>
				a?.consignor?.displayName?.localeCompare(b?.consignor?.displayName),
			fixed: "left",
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.displayName || "-"}
				/>
			),
		},
		{
			title: "Consignee",
			dataIndex: "consignee",
			key: "consignee",
			width: 120,
			sorter: (a, b) =>
				a?.consignee?.displayName?.localeCompare(b?.consignee?.displayName),
			fixed: "left",
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.displayName || "-"}
				/>
			),
		},
		{
			title: "Vehicle",
			dataIndex: "vehicleId",
			key: "vehicleId",
			width: 100,
			sorter: (a, b) =>
				a?.vehicleId?.vehicleNumber?.localeCompare(b?.vehicleId?.vehicleNumber),
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.vehicleNumber?.toString() || "-"}
				/>
			),
		},
		{
			title: "Driver Name",
			dataIndex: "driverId",
			key: "driverId",
			sorter: (a, b) =>
				a?.driverId?.driverName?.localeCompare(b?.driverId?.driverName),
			width: 150,
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.driverName || "-"}
				/>
			),
		},
		{
			title: "Driver Mob.",
			dataIndex: "driverId",
			key: "driverId",
			sorter: (a, b) =>
				a?.driverId?.driverMobile?.localeCompare(b?.driverId?.driverMobile),
			width: 150,
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.driverMobile || "-"}
				/>
			),
		},
		{
			title: "Freight Rs.",
			dataIndex: "customerFreight",
			key: "customerFreight",
			sorter: (a, b) => a.customerFreight - b.customerFreight,
			width: 150,
			align: "right",
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.toString() || "-"}
				/>
			),
		},
		{
			title: "Vehicle Freight Rs.",
			dataIndex: "vehicleFreight",
			key: "vehicleFreight",
			align: "right",
			sorter: (a, b) => a.vehicleFreight - b.vehicleFreight,
			width: 150,
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.toString() || "-"}
				/>
			),
		},
		{
			title: "Remarks",
			dataIndex: "remarks",
			key: "remarks",
			// fixed: 'left',
			width: 100,
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value}
				/>
			),
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			fixed: "right",
			width: 100,
			render: (value) =>
				value ? <Tag color={STATUS[value]}>{value}</Tag> : null,
		},
		{
			title: "Action",
			align: "center",
			dataIndex: "studentName",
			fixed: "right",
			width: 100,
			render: (value, row) => {
				return (
					<Row gutter={10} justify="center">
						<Col
							onClick={(e) => {
								e.stopPropagation();
								handleEdit(row);
							}}
							className="edit_icon"
						>
							<EditOutlined />
						</Col>
						{/* <Col className="delete_icon">
							<Popconfirm
								title={`Are You Sure to Delete?`}
								okText="Delete"
								cancelText="No"
								placement="rightTop"
								onConfirm={() => {
									let url = `${SERVER_IP}booking/${row._id}?orgId=${globalRedux.selectedOrganization._id}`;
									dispatch(deleteApi("DELETE_LOAD", url));
								}}
							>
								<CloseOutlined />
							</Popconfirm>
						</Col> */}
					</Row>
				);
			},
		},
	];

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1)
			return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const tableLoading = useMemo(
		() => globalRedux.apiStatus.GET_TICKETS === API_STATUS.PENDING,
		[globalRedux.apiStatus]
	);

	const onSelectChange = (selectedRowKeys) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	};

	return (
		<BookingsListPresentational
			{...{
				column,
				filteredData,
				visible,
				toggleVisible,
				handleTableChange,
				getStartingValue,
				getEndingValue,
				pageSize,
				intialPageSizeOptions,
				initialPageSize,
				currentPage,
				setSearchKey,
				refreshList: getLoads,
				tableLoading,
				rowSelection,
				users,
				state,
				setState,
				selectedRowKeys,
				selectedRow,
				setSelectedRow,
			}}
		/>
	);
});

export default BookingsListFunctional;
