import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { SERVER_IP } from 'assets/Config';
import { ACTIONS, API_STATUS, DATE_FORMAT, STATUS } from 'constants/app-constants';
import HighlightComponent from 'components/HighlightComponent';
import OutstandingReportToPrint from './outstanding-report-to-print';
import { objToQs } from 'helpers';
import OutStandingReportListPresentational from './outstanding-report-list-presentational';

const initialPageSize = 10;
const intialPageSizeOptions = [10, 15, 20];

const OutStandingReportListFunctional = React.memo((props) => {
	const componentRef = React.useRef();
	const [state, setState] = useState({
		visible: false,
	});
	const [filterOptions, setFilterOptions] = useState({
		customerId: '',
		department: '',
		fromDate: '',
		toDate: '',
	});
	const [searchKey, setSearchKey] = useState('');
	const [selectedRow, setSelectedRow] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const globalRedux = useSelector((state) => state.globalRedux);
	const outstandingReports = useSelector((state) => state.outstandingReportsRedux?.outstandingReports);
	const [selectedRecordToPrint, setSelectedRecordToPrint] = useState(null);
	const [tableData, setTableData] = useState(outstandingReports || []);
	const customers = useSelector((state) => state?.customerRedux?.customers);
	const dispatch = useDispatch();

	const getCustomers = useCallback(() => {
		let url = `${SERVER_IP}customer/?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(getApi("GET_CUSTOMERS", url));
	}, [dispatch, globalRedux?.selectedOrganization?._id]);

	const getOutStandingReportList = () => {
		let params = objToQs({
			orgId: globalRedux.selectedOrganization._id,
			...filterOptions,
		});
		dispatch(getApi(ACTIONS.GET_OUTSTANDING_REPORTS, `${SERVER_IP}invoice?${params}`));
	};

	useEffect(() => {
		getCustomers();
	}, []);

	const handleFilterOptions = (key, value) => {
		setFilterOptions({
			...filterOptions,
			[key]: value,
		});
	};

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((record) => {
			return (
				(record?.invoiceNumber || '')?.toString()?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.customerId?.displayName || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.billingAddress || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.notes || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.poNumber || '')?.toString()?.toLowerCase().includes(searchKey.toLowerCase())
			);
		});
	}, [tableData, searchKey]);

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage === 0 ? 1 : currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const handleAfterPrint = () => {
		setSelectedRecordToPrint(null);
	};

	useEffect(() => {
		getOutStandingReportList(filterOptions);
	}, [filterOptions]);

	useEffect(() => {
		setTableData(outstandingReports);
	}, [outstandingReports]);

	useEffect(() => {
		selectedRecordToPrint && handlePrint();
	}, [selectedRecordToPrint]);

	useEffect(() => {
		selectedRow &&
			setState({
				...state,
				visible: true,
			});
	}, [selectedRow]);

	useEffect(() => {
		!state?.visible && setSelectedRow(null);
	}, [state?.visible]);

	const reactToPrintContent = React.useCallback(() => {
		return componentRef.current;
	}, [componentRef.current]);

	const handlePrint = useReactToPrint({
		content: reactToPrintContent,
		documentTitle: 'Receipt',
		onAfterPrint: handleAfterPrint,
		removeAfterPrint: true,
	});

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_OUTSTANDING_REPORTS === API_STATUS.PENDING, [globalRedux.apiStatus]);

	const column = [
		{
			title: 'Invoice No#',
			dataIndex: 'invoiceNumber',
			key: 'invoiceNumber',
			width: '8%',
			sorter: (a, b) => a?.invoiceNumber - b?.invoiceNumber,
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.toString()}
				/>
			),
		},
		{
			title: 'Invoice Date',
			dataIndex: 'invoiceDate',
			key: 'invoiceDate',
			sorter: (a, b) => new Date(a.invoiceDate) - new Date(b.invoiceDate),
			width: '10%',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={moment(value).format(DATE_FORMAT.DD_MM_YYYY)}
				/>
			),
		},
		{
			title: 'Vendor Name',
			sorter: (a, b) => a?.customerId?.displayName?.localeCompare(b?.customerId?.displayName),
			dataIndex: 'customerId',
			key: 'customerId',
			width: '15%',
			render: (value) => (
				<HighlightComponent className="bold" searchWords={[searchKey]} autoEscape={true} textToHighlight={value?.displayName || ''} />
			),
		},
		{
			title: 'Invoice Type',
			dataIndex: 'invoiceType',
			sorter: (a, b) => a?.invoiceType?.localeCompare(b?.invoiceType),
			key: 'invoiceType',
			width: '12%',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.toString()}
				/>
			),
		},
		{
			title: 'Department',
			dataIndex: 'department',
			key: 'department',
			sorter: (a, b) => a?.department?.localeCompare(b?.department),
			width: '12%',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.toString()}
				/>
			),
		},
		{
			title: 'Invoice Amount',
			dataIndex: 'totalAmount',
			key: 'totalAmount',
			width: '10%',
			align: 'right',
			sorter: (a, b) => a?.totalAmount - b?.totalAmount,
			render: (value) => parseFloat(value || 0).toFixed(2),
		},
		{
			title: 'Payment Received',
			dataIndex: 'paid',
			key: 'paid',
			width: '8%',
			align: 'right',
			sorter: (a, b) => a?.paid - b?.paid,
			render: (value) => parseFloat(value || 0).toFixed(2),
		},
		{
			title: 'Balance Amount',
			dataIndex: 'balance',
			key: 'balance',
			width: '8%',
			align: 'right',
			sorter: (a, b) => a?.balance - b?.balance,
			render: (value) => parseFloat(value || 0).toFixed(2),
		},
	];

	const exportData = useMemo(() => {
		let totalAmountRS = 0;
		let receiptTotalRS = 0;
		let balanceAmountRS = 0;

		filteredData.forEach(({ totalAmount, paid, balance }) => {
			totalAmountRS += totalAmount;
			receiptTotalRS += paid;
			balanceAmountRS += balance;
		});

		const data = {
			headers: column?.map((col) => col?.title) || [],
			data: [
				...(filteredData.map((stock) => ({
					[column[0]?.title]: stock?.invoiceNumber,
					[column[1]?.title]: moment(stock?.invoiceDate).format(DATE_FORMAT.DD_MM_YYYY),
					[column[2]?.title]: stock?.customerId?.displayName,
					[column[3]?.title]: stock?.invoiceType,
					[column[4]?.title]: stock?.department,
					[column[5]?.title]: stock?.totalAmount,
					[column[6]?.title]: stock?.paid,
					[column[7]?.title]: stock?.balance,
				})) || []),
			],
		};

		if (filteredData?.length > 0) {
			data?.data?.push({
				[column[0]?.title]: 'Total',
				[column[1]?.title]: '',
				[column[2]?.title]: '',
				[column[3]?.title]: '',
				[column[4]?.title]: '',
				[column[5]?.title]: parseFloat(totalAmountRS || 0).toFixed(2),
				[column[6]?.title]: parseFloat(receiptTotalRS || 0).toFixed(2),
				[column[7]?.title]: parseFloat(balanceAmountRS || 0).toFixed(2),
			});
		}

		return data;
	}, [filteredData, column]);

	return (
		<>
			<div style={{ display: 'none' }}>
				<OutstandingReportToPrint ref={componentRef} data={selectedRecordToPrint} />
			</div>
			<OutStandingReportListPresentational
				{...{
					column,
					filteredData,
					handleTableChange,
					getStartingValue,
					getEndingValue,
					pageSize,
					intialPageSizeOptions,
					initialPageSize,
					currentPage,
					tableLoading,
					customers,
					handleFilterOptions,
					exportData,
				}}
			/>
		</>
	);
});

export default OutStandingReportListFunctional;
