import React, { useEffect, useState } from "react";
import { Row, Col, Tabs } from "antd";
import AccountBook from "pages/master-pages/AccountBook";
import Vehicles from "pages/master-pages/Vehicles";
import Credentials from "pages/master-pages/Credentials";
import ApplicationUsers from "pages/master-pages/ApplicationUsers";
import { useSelector } from "react-redux";
import Materials from "pages/master-pages/materials";
import Grades from "pages/master-pages/grades";
import Cities from "pages/master-pages/cities";
import Parties from "pages/master-pages/parties";
import Drivers from "pages/master-pages/drivers";
import RcOwners from "pages/master-pages/rc-owners";
import BankDetails from "pages/master-pages/bank-details";
import GPSs from "pages/master-pages/gps";
import Customers from "pages/master-pages/customers";
import UserList from "pages/users/user-list";
// import Voucher from 'pages/master-pages/Voucher';
// import Assets from 'pages/master-pages/Assets';
// import Batch from 'pages/master-pages/Batch';
// import Class from 'pages/master-pages/Classes';
// import PartNumbers from 'pages/master-pages/PartNumbers';
// import Vendors from 'pages/master-pages/Vendors';
// import Locations from 'pages/master-pages/Locations';
// import VendorsMapping from 'pages/master-pages/VendorsMapping';

const { TabPane } = Tabs;
let MASTER_TAB = [
	{
		tabName: "Acc Books",
		component: AccountBook,
	},
	{
		tabName: "Materials",
		component: Materials,
	},
	{
		tabName: "Material Grades",
		component: Grades,
	},
	{
		tabName: "Cities",
		component: Cities,
	},
	{
		tabName: "Customers",
		component: Customers,
	},
	{
		tabName: "Vehicles",
		component: Vehicles,
	},
	{
		tabName: "Drivers",
		component: Drivers,
	},
	{
		tabName: "Rc Owners",
		component: RcOwners,
	},
	{
		tabName: "Bank Details",
		component: BankDetails,
	},
	{
		tabName: "GPSs",
		component: GPSs,
	},
];

const Master = () => {
	const loginRedux = useSelector((state) => state.loginRedux);
	const [tabs, setTabs] = useState(MASTER_TAB);

	useEffect(() => {
		if (loginRedux?.role === 'Manager' || loginRedux?.role === 'Admin') {
			setTabs([
				...tabs,
				{
					tabName: 'Users',
					component: UserList,
				},
				// {
				// 	tabName: 'Users',
				// 	component: ApplicationUsers,
				// },
				// {
				// 	tabName: 'Credentials',
				// 	component: Credentials,
				// },
			]);
		}
	}, [loginRedux?.accessLevel]);

	return (
		<Row style={{ padding: "20px 10px" }}>
			<Col xl={24}>
				<Tabs destroyInactiveTabPane defaultActiveKey="0">
					{tabs.map(({ tabName, component }, index) => {
						const Component = component;
						return (
							<TabPane tab={tabName} key={index}>
								<Component />
							</TabPane>
						);
					})}
				</Tabs>
			</Col>
		</Row>
	);
};

export default Master;
