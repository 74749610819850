import { useState } from "react";
import {
	GoogleMap,
	Marker,
	useLoadScript,
	InfoWindow,
} from "@react-google-maps/api";
// import { mapStyles } from "./mapStyle";

const ReactGoogleMaps = ({ position, data = {}, dataLoading = false }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [mapRef, setMapRef] = useState(null);
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: "AIzaSyC-MupABXMQkL6c8YzroPXg45fXqYTCX1Q",
	});

	const onMapLoad = (map) => {
		setMapRef(map);
		const bounds = new window.google.maps.LatLngBounds();
		bounds.extend(position);
		map.fitBounds(bounds);
	};

	const handleMarkerClick = () => {
		mapRef.panTo(position);
		setIsOpen(true);
	};

	return (
		<div style={{ width: "100%", height: "100%" }}>
			{!isLoaded || dataLoading ? (
				<h4>Loading map...</h4>
			) : (
				<GoogleMap
					// options={{ styles: mapStyles }}
					mapContainerClassName="map-container"
					onLoad={onMapLoad}
					onClick={() => setIsOpen(false)}
					zoom={15}
				>
					<Marker position={position} onClick={handleMarkerClick}>
						{/* {isOpen && (
							<InfoWindow onCloseClick={() => setIsOpen(false)}>
								<div className="info_container">
									<h3 className="asset_title">Asset Information</h3>
									<div className="info_container_row">
										<div className="left">
											<span>Latitude</span>
											<p className="value">{position.lat}</p>
										</div>
										<div className="right">
											<span>Longitude</span>
											<p className="value">{position.lng}</p>
										</div>
									</div>
								</div>
							</InfoWindow>
						)} */}
					</Marker>
				</GoogleMap>
			)}
		</div>
	);
};

export default ReactGoogleMaps;
