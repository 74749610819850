import { ACTIONS } from '../../../constants/app-constants';

function setWhInvoices(data) {
	return {
		type: ACTIONS.GET_WH_INVOICES,
		payload: data,
	};
}

export { setWhInvoices };
