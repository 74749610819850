import React from 'react';
import { Pagination, Row, Col, Select, DatePicker, Input } from 'antd';
import TableComponent from 'components/table-component';
import { DATE_FORMAT } from 'constants/app-constants';
import moment from 'moment';
import { debounce } from 'lodash';
import ExcelDownload from 'components/excel-download';
import PdfDownload from 'components/pdf-download';

const StockByLocationPresentational = ({
	column,
	filteredData,
	handleTableChange,
	getStartingValue,
	getEndingValue,
	pageSize,
	intialPageSizeOptions,
	initialPageSize,
	currentPage,
	tableLoading,
	partNumbers,
	vendors,
	locations,
	handleFilterOptions,
	exportData,
}) => {
	return (
		<Row style={{ padding: '20px 10px' }}>
			<Col xl={24} md={24}>
				<TableComponent
					loading={tableLoading}
					className="custom-table"
					style={{ width: '100%' }}
					rowKey={(record) => record._id}
					dataSource={filteredData}
					title={() => (
						<Row justify="space-between">
							<Col xl={24} md={24}>
								<Row gutter={[10, 10]} style={{ width: '100%' }}>
									<Col xl={4} md={4}>
										<Select
											showSearch
											allowClear
											optionFilterProp="children"
											filterOption={(input, option) =>
												option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
												option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
											style={{ width: '100%' }}
											placeholder="Select location"
											onChange={(value) => handleFilterOptions('locationId', value)}>
											{locations?.map((location) => (
												<Select.Option value={location._id}>{location?.locationName}</Select.Option>
											))}
										</Select>
									</Col>
									<Col xl={4} md={4}>
										<Select
											showSearch
											allowClear
											placeholder="Select Vendor"
											optionFilterProp="children"
											style={{ width: '100%' }}
											filterOption={(input, option) =>
												option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
												option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
											onChange={(value) => handleFilterOptions('vendorId', value)}>
											{vendors?.map((vendor) => (
												<Select.Option value={vendor._id}>{vendor?.vendorName}</Select.Option>
											))}
										</Select>
									</Col>
									<Col xl={6} md={6}>
										<Select
											showSearch
											allowClear
											optionFilterProp="children"
											filterOption={(input, option) =>
												option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
												option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
											style={{ width: '100%' }}
											placeholder="Select Partnumber"
											onChange={(value) => handleFilterOptions('partNumberId', value)}>
											{partNumbers?.map((partNumber) => (
												<Select.Option value={partNumber._id}>{partNumber?.partNumber}</Select.Option>
											))}
										</Select>
									</Col>
									{/* <Col xl={4} md={4}>
										<DatePicker
											placeholder="Select From Date"
											style={{ width: '100%' }}
											format={DATE_FORMAT.DD_MM_YYYY}
											onChange={(value) =>
												handleFilterOptions(
													'fromDate',
													value === 'Invalid date' ? '' : value ? moment(value).format(DATE_FORMAT.YYYY_MM_DD) : ''
												)
											}
										/>
									</Col>
									<Col xl={4} md={4}>
										<DatePicker
											placeholder="Select To Date"
											style={{ width: '100%' }}
											format={DATE_FORMAT.DD_MM_YYYY}
											onChange={(value) =>
												handleFilterOptions(
													'toDate',
													value === 'Invalid date' ? '' : value ? moment(value).format(DATE_FORMAT.YYYY_MM_DD) : ''
												)
											}
										/>
									</Col> */}
									<Col xl={4} md={4}>
										<Input
											placeholder="Invoice No"
											onChange={debounce(({ target: { value } }) => handleFilterOptions('invoiceNumber', value), 500)}
										/>
									</Col>
								</Row>
							</Col>
							<Col xl={24} md={24} style={{ paddingTop: 10 }}>
								<Row gutter={[10, 10]}>
									<Col xl={4} md={4}>
										<PdfDownload {...{ ...exportData }} />
									</Col>
									<Col xl={4} md={4}>
										<ExcelDownload {...{ ...exportData }} />
									</Col>
								</Row>
							</Col>
						</Row>
					)}
					{...{
						columns: column,
						pagination: { current: currentPage, pageSize: pageSize, position: ['none', 'none'] },
						...(!!filteredData?.length && {
							footer: () => (
								<Row justify="space-between">
									<Col md={16}>
										{!!filteredData?.length &&
											`Showing ${getStartingValue()} - ${getEndingValue()} of ${filteredData?.length} Data`}
									</Col>
									<Col md={8}>
										<div style={{ textAlign: 'right' }}>
											<Pagination
												pageSizeOptions={intialPageSizeOptions}
												defaultPageSize={initialPageSize}
												showSizeChanger={true}
												total={filteredData?.length}
												onChange={handleTableChange}
												responsive
											/>
										</div>
									</Col>
								</Row>
							),
						}),
					}}
				/>
			</Col>
		</Row>
	);
};

export default StockByLocationPresentational;
