import React, { useEffect, useCallback } from "react";
import { Input, Button, Divider, Form, Row, Col, Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { putApi } from "redux/sagas/putApiSaga";
import { SERVER_IP } from "assets/Config";
import { API_STATUS } from "constants/app-constants";
import { resetApiStatus } from "redux/reducers/globals/globalActions";
import { postApi } from "redux/sagas/postApiDataSaga";
import { getApi } from "redux/sagas/getApiDataSaga";

const { TextArea } = Input;

const AddGrade = ({ handleClose, editGrade, setGradeAddModal }) => {
	const [form] = Form.useForm();
	const globalRedux = useSelector((state) => state?.globalRedux);
	const materials = globalRedux?.materials || [];
	const dispatch = useDispatch();

	const getMaterials = useCallback(() => {
		let url = `${SERVER_IP}material/?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(getApi("GET_MATERIALS", url));
	}, [dispatch, globalRedux?.selectedOrganization?._id]);

	useEffect(() => {
		getMaterials();
	}, [getMaterials]);

	const addGrade = (values) => {
		let data = {
			...values,
			orgId: globalRedux?.selectedOrganization?._id,
		};
		dispatch(postApi(data, "ADD_GRADE"));
	};

	const handleEdit = (values) => {
		let data = {
			...values,
			orgId: globalRedux?.selectedOrganization?._id,
		};
		let url = `${SERVER_IP}grade/${editGrade._id}?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(putApi(data, "EDIT_GRADE", url));
	};

	useEffect(() => {
		if (
			globalRedux.apiStatus.ADD_GRADE === "SUCCESS" ||
			globalRedux.apiStatus.EDIT_GRADE === "SUCCESS"
		) {
			dispatch(resetApiStatus(editGrade ? "EDIT_GRADE" : "ADD_GRADE"));
			setGradeAddModal(false);
			handleClose();
		}
	}, [
		globalRedux.apiStatus,
		editGrade,
		setGradeAddModal,
		handleClose,
		dispatch,
	]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const loading =
		globalRedux.apiStatus.ADD_GRADE === API_STATUS.PENDING ||
		globalRedux.apiStatus.EDIT_GRADE === API_STATUS.PENDING;

	return (
		<Row>
			<Col span={24}>
				{/* <Row style={{ backgroundColor: '#fff', padding: '10px 0px' }}>
					<Col>
						<h6 style={{ marginBottom: '0px' }}>{editGrade ? 'Edit' : 'New'} Grade</h6>
					</Col>
				</Row>
				<Divider /> */}
				<Row style={{ paddingTop: 0 }}>
					<Col span={24}>
						<Form
							name="add-grade"
							className="required_in_right"
							labelAlign="left"
							form={form}
							onFinish={!editGrade ? addGrade : handleEdit}
							{...layer1FormCol}
						>
							<Form.Item
								label="Material"
								name="materialId"
								initialValue={editGrade?.materialId?._id}
								rules={[
									{
										required: true,
										message: "This Field is required!",
									},
								]}
							>
								<Select
									allowClear
									showSearch
									filterOption={(input, option) =>
										option.props.children
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0 ||
										option.props.value
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
									}
									placeholder="select material"
								>
									{materials?.map((material) => (
										<Select.Option value={material?._id}>
											{material?.materialName}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								label="Grade Name"
								name="gradeName"
								initialValue={editGrade?.gradeName}
								rules={[
									{
										required: true,
										message: "This Field is Required!",
									},
								]}
							>
								<Input placeholder="Grade Name" />
							</Form.Item>
							<Form.Item
								label="Remarks"
								name="remarks"
								initialValue={editGrade?.remarks}
							>
								<TextArea />
							</Form.Item>
							<Form.Item
								wrapperCol={{
									offset: 0,
									span: 24,
								}}
							>
								<Row justify="space-between">
									<Button
										onClick={() => setGradeAddModal(false)}
										style={{ width: "49%" }}
										danger
									>
										Cancel
									</Button>
									<Button
										loading={loading}
										type="primary"
										style={{ width: "49%", marginRight: 5 }}
										htmlType="submit"
									>
										{editGrade ? "Update" : "Create"}
									</Button>
								</Row>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default AddGrade;
