import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Row, Col, Divider, Button, Spin } from "antd";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import ReactGoogleMaps from "components/react-google-maps";
import { resetApiStatus } from "redux/reducers/globals/globalActions";
import { API_STATUS, DATE_FORMAT } from "constants/app-constants";
import { sendGetRequest } from "redux/sagas/utils";
import { SERVER_IP } from "assets/Config";
import "./styles.scss";

const LabelValue = ({ label, value }) => {
	return (
		<Row className="label_value">
			<Col span={10}>
				<span className="label">{label}</span>
			</Col>
			<Col span={1}>
				<span className="colon">:</span>
			</Col>
			<Col span={13}>
				<span className="value">{value}</span>
			</Col>
		</Row>
	);
};

// const RESPONSE = {
// 	data: {
// 		_id: "66ab2c4766a5011c6814da6a",
// 		vehicleId: {
// 			_id: "66a9fafa3ad0dc2314eaf457",
// 			vehicleNumber: "2123123123",
// 		},
// 		driverId: {
// 			_id: "66a25dede87c2811e4827cbf",
// 			driverName: "Sathish",
// 		},
// 		gpsId: {
// 			_id: "66a932fa3ad0dc2314eaedc0",
// 			gpsName: "No.2",
// 			imei: "0869689043714898",
// 			sim: "435435",
// 		},
// 		invoiceDate: "2024-08-01T09:46:14.981Z",
// 		netValue: 8778,
// 		grossValue: 76,
// 		lrNumber_manual: "",
// 		bookingDate: "2024-08-01T09:46:14.981Z",
// 		completedDate: null,
// 		customerFreight: 6000,
// 		vehicleFreight: 5000,
// 		netMargin: 0,
// 		remarks: null,
// 		status: "InTransit",
// 		isDeleted: false,
// 		trackingNumber: "3535",
// 		customerId: null,
// 		from: {
// 			_id: "66a25c88e87c2811e4827c52",
// 			cityName: "City name",
// 		},
// 		to: {
// 			_id: "66a25c88e87c2811e4827c52",
// 			cityName: "City name",
// 		},
// 		invoiceNumber: "576",
// 		materialId: "66a25c6be87c2811e4827c3a",
// 		orgId: "66a13b93f84af72a18cab557",
// 		createdBy: {
// 			_id: "66a13b5ef84af72a18cab547",
// 			firstName: "Prabhu",
// 			lastName: "Palanisamy",
// 			email: "successprabhu@gmail.com",
// 		},
// 		createdAt: "2024-08-01T06:33:43.460Z",
// 		updatedAt: "2024-08-01T10:03:32.611Z",
// 		lrNumber: 6,
// 		__v: 0,
// 	},
// 	trackingDetails: {
// 		latitude: 12.9655,
// 		longitude: 80.21658333333333,
// 		speed: 37,
// 		date: 1722684568000,
// 		isoDate: "2024-08-03T11:29:28.000Z",
// 		odoDistance: 551.6498903416658,
// 		ignitionStatus: "OFF",
// 		status: "OFF",
// 		vehicleStatus: "MOVING",
// 		address:
// 			"2 kms from Sheela Nagar, Ward 188, Zone 14 Perungudi, Chennai, Chennai district, Tamil Nadu, 600091, India",
// 		regNo: "DEVICE-1",
// 		vehicleType: "BIKE",
// 		vehicleId: "rflRvV",
// 		deviceId: "0869689043714898",
// 		expiryDate: "2025-07-29",
// 		onboardDate: "2024-07-29",
// 		fuelLitre: 0,
// 		bearing: 21,
// 		serverTime: 1722684577902,
// 		insideGeoFence: "N",
// 		triggeredGeoFences: [],
// 		rowId: 0,
// 	},
// };

const TrackingPagePresentational = () => {
	const globalRedux = useSelector((state) => state.globalRedux);
	const [trackingNumber, setTrackingNumber] = useState("");
	const [form] = Form.useForm();
	const [mapData, setMapData] = useState({
		data: null,
		loading: false,
	});
	const statusValue = Form.useWatch("status", form);
	const isFirstRender = useRef(true);
	const dispatch = useDispatch();

	const getData = async () => {
		try {
			await setMapData({
				...mapData,
				loading: true,
			});
			const res = await sendGetRequest(
				null,
				`${SERVER_IP}booking/track/${trackingNumber}`
			);
			await setMapData({
				...mapData,
				loading: false,
				// data: res?.data?.trackingDetails,
				data: res?.data || {},
			});
		} catch (error) {
			console.log("🚀 ~ useEffect ~ error:", error);
		}
	};

	useEffect(() => {
		if (!isFirstRender.current && statusValue) {
			// handleStatusSubmit({ status: statusValue });
		}
		isFirstRender.current = false; // After the first render, set the flag to false
	}, [statusValue]);

	useEffect(() => {
		if (globalRedux.apiStatus.UPDATE_BOOKING_STATUS === API_STATUS.SUCCESS) {
			dispatch(resetApiStatus("UPDATE_BOOKING_STATUS"));
		}
	}, [globalRedux.apiStatus, dispatch]);
	// console.log("🚀 ~ TrackingPagePresentational ~ apiStatus:", mapData);

	return (
		<Row gutter={[0, 0]} className="tracking-page">
			<Col
				span={8}
				style={{
					overflowY: "scroll",
					padding: 20,
				}}
				className="left_area"
			>
				<Row justify="center">
					<Col xl={24} md={24}>
						<Row
							gutter={[10, 10]}
							align={"middle"}
							style={{
								paddingBottom: 10,
							}}
						>
							<Col span={12}>
								<Input
									placeholder="Enter tracking number"
									value={trackingNumber}
									onChange={(e) => setTrackingNumber(e.target.value)}
								/>
							</Col>
							<Col span={12}>
								<Button
									disabled={!trackingNumber}
									loading={mapData?.loading}
									icon={<SearchOutlined />}
									type="primary"
									onClick={() => {
										getData();
									}}
								>
									Track
								</Button>
							</Col>
						</Row>
					</Col>
					<Divider></Divider>
					<Col
						xl={24}
						md={24}
						style={{
							paddingTop: 10,
							paddingBottom: 5,
						}}
					>
						<h3>Tracking Details</h3>
					</Col>
					<Col span={24}>
						<Row align={"middle"} justify={"center"}>
							{!mapData?.loading ? (
								<Col
									xl={24}
									md={24}
									style={{
										height: "100%",
									}}
								>
									<LabelValue
										label="Booking date"
										value={
											mapData?.data?.data?.bookingDate
												? moment(mapData?.data?.data?.bookingDate).format(
														DATE_FORMAT.DD_MM_YYYY
												  )
												: ""
										}
									/>
									<LabelValue
										label="Vehicle Number"
										value={mapData?.data?.data?.vehicleId?.vehicleNumber}
									/>
									<LabelValue
										label="Driver Name"
										value={mapData?.data?.data?.driverId?.driverName}
									/>
									<LabelValue
										label="GPS Name"
										value={mapData?.data?.data?.gpsId?.gpsName}
									/>
									<LabelValue
										label="GPS IMEI"
										value={mapData?.data?.data?.gpsId?.imei}
									/>
									<LabelValue
										label="Invoice Number"
										value={mapData?.data?.data?.invoiceNumber}
									/>
									<LabelValue
										label="Invoice Date"
										value={
											mapData?.data?.data?.invoiceDate
												? moment(mapData?.data?.data?.invoiceDate).format(
														DATE_FORMAT.DD_MM_YYYY
												  )
												: ""
										}
									/>
									<LabelValue
										label="Net Value"
										value={mapData?.data?.data?.netValue}
									/>
									<LabelValue
										label="Gross Value"
										value={mapData?.data?.data?.grossValue}
									/>
									<LabelValue
										label="Customer Freight"
										value={mapData?.data?.data?.customerFreight}
									/>
									<LabelValue
										label="Vehicle Freight"
										value={mapData?.data?.data?.vehicleFreight}
									/>
									<LabelValue
										label="From"
										value={mapData?.data?.data?.from?.cityName}
									/>
									<LabelValue
										label="To"
										value={mapData?.data?.data?.to?.cityName}
									/>
									<LabelValue
										label="Status"
										value={mapData?.data?.data?.status}
									/>
								</Col>
							) : (
								<Col
									style={{
										height: "100%",
									}}
								>
									<Spin />
								</Col>
							)}
						</Row>
					</Col>
				</Row>
			</Col>
			<Col span={16}>
				{mapData?.data?.trackingDetails?.latitude ? (
					<ReactGoogleMaps
						position={{
							lat: mapData?.data?.trackingDetails?.latitude,
							lng: mapData?.data?.trackingDetails?.longitude,
						}}
						dataLoading={mapData?.loading}
						data={mapData?.data}
					/>
				) : (
					<Row justify="center" align="middle" style={{ height: "100%" }}>
						<Col>
							<h3>No map data found</h3>
						</Col>
					</Row>
				)}
			</Col>
		</Row>
	);
};

export default TrackingPagePresentational;
