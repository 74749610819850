import React from "react";
import { Switch } from "react-router-dom";
import { Col, Row } from "antd";
import { Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { getApi } from "redux/sagas/getApiDataSaga";
import { SERVER_IP } from "assets/Config";
import LottieFile from "assets/lottie-files";
import { ROUTES } from "constants/app-constants";
import ProtectedRoute from "routes/ProtectedRoute";
import Sidebar from "./Sidebar";
import PageHeader from "./page-header";
import LottieComponent from "./lottie-component";

const MainRoutes = () => (
	<Switch>
		{ROUTES.map((menu, index) => (
			<ProtectedRoute
				exact
				key={index}
				path={menu?.route}
				component={menu?.component}
			/>
		))}
		<ProtectedRoute exact component={() => <div>Coming Soon</div>} />
	</Switch>
);

const PageLayout = () => {
	const globalRedux = useSelector((state) => state.globalRedux);
	const dispatch = useDispatch();

	React.useEffect(() => {
		if (globalRedux?.selectedOrganization?._id) {
			dispatch(
				getApi(
					"GET_ACCOUNT_BOOKS",
					`${SERVER_IP}accbook/?orgId=${globalRedux.selectedOrganization._id}`
				)
			);
			// dispatch(getApi('GET_PART_NUMBERS', `${SERVER_IP}partnumber?orgId=${globalRedux.selectedOrganization._id}`));
			// dispatch(getApi('GET_VEHICLES', `${SERVER_IP}category?orgId=${globalRedux.selectedOrganization._id}`));
			// dispatch(getApi('GET_VOUCHERS_HEAD', `${SERVER_IP}voucherhead/?orgId=${globalRedux.selectedOrganization._id}`));
			// dispatch(getApi('GET_CLASSES', `${SERVER_IP}class?orgId=${globalRedux.selectedOrganization._id}`));
			// dispatch(getApi('GET_ASSETS', `${SERVER_IP}asset?orgId=${globalRedux.selectedOrganization._id}`));
			// dispatch(getApi('GET_BATCHES', `${SERVER_IP}batch?orgId=${globalRedux.selectedOrganization._id}`));
			// dispatch(getApi('GET_STATES', `${SERVER_IP}state?orgId=${globalRedux.selectedOrganization._id}`));
			// dispatch(getApi('GET_LOCATIONS', `${SERVER_IP}location?orgId=${globalRedux.selectedOrganization._id}`));
			// dispatch(getApi('GET_VENDORS', `${SERVER_IP}vendor?orgId=${globalRedux.selectedOrganization._id}`));
			dispatch(
				getApi(
					"GET_VEHICLES",
					`${SERVER_IP}vehicle?orgId=${globalRedux.selectedOrganization._id}`
				)
			);
		}
	}, [globalRedux.selectedOrganization._id]);
	return (
		<div className="">
			<PageHeader />
			<Row className={`loader`} style={{}} align="middle">
				{/* <Row className={`loader ${!isLoading ? 'hide' : ''}`} style={{}} align="middle"> */}
				<Col span={24}>
					<LottieComponent file={LottieFile.Loading} width="15%" height="15%" />
				</Col>
			</Row>
			<div className="d-flex admin-content">
				<Sidebar />
				<Container
					fluid
					style={{ backgroundColor: "#fff", overflow: "auto", padding: 0 }}
				>
					<MainRoutes />
				</Container>
			</div>
		</div>
	);
};

export default PageLayout;
