import React from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select } from 'antd';
import { API_STATUS, DATE_FORMAT } from 'constants/app-constants';
import TableComponent from 'components/table-component';
import { SERVER_IP } from 'assets/Config';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { stubFalse } from 'lodash';

const layer1FormCol = {
	labelCol: {
		span: 24,
	},
	wrapperCol: {
		span: 24,
	},
};

const DispatchModal = ({ dispatchModalVisible, setDispatchModalVisible, tableData, selectedRowKeys = [], refreshList }) => {
	const [form] = Form.useForm();
	const globalRedux = useSelector((state) => state.globalRedux);
	const dispatch = useDispatch();
	const vehicles = globalRedux?.vehicles;

	const componentRef = React.useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		documentTitle: 'Dispatch',
		removeAfterPrint: true,
	});

	React.useEffect(() => {
		!dispatchModalVisible && form.resetFields();
	}, [dispatchModalVisible]);

	const dataSource = React.useMemo(
		() =>
			tableData?.filter(
				(data) =>
					selectedRowKeys?.includes(data?._id) &&
					parseInt(data?.stockSummary?.reduce((acc, curr) => acc + parseInt(curr?.dispatchedQty || 0), 0) || 0) > 0
			),
		[selectedRowKeys, tableData]
	);

	const handleSubmit = (values) => {
		const loadDetails = dataSource?.map((data) => {
			const locationDetails = data?.stockSummary
				?.filter((location) => !!location?.dispatchedQty)
				?.map((location) => {
					return { locationId: location?.locationId?._id, dispatchedQty: location?.dispatchedQty || 0 };
				});

			const totalStock = data?.stockSummary?.reduce((acc, curr) => acc + parseInt(curr?.stockQty || 0), 0);
			const totalDispatched = locationDetails.reduce((acc, curr) => acc + parseInt(curr?.dispatchedQty || 0), 0);
			return {
				stockId: data?._id,
				locationDetails,
				balanceQty: parseInt(totalStock - totalDispatched),
			};
		});

		let url = `${SERVER_IP}dispatch`;
		const orgId = globalRedux.selectedOrganization._id;
		const request = {
			...values,
			orgId,
			dispatchDetails: loadDetails,
		};
		dispatch(postApi(request, 'DISPATCH_LOAD_OUT', url));
	};

	React.useEffect(() => {
		if (globalRedux.apiStatus.DISPATCH_LOAD_OUT === 'SUCCESS') {
			setDispatchModalVisible(false);
			refreshList();
			dispatch(resetApiStatus('DISPATCH_LOAD_OUT'));
		}
	}, [globalRedux.apiStatus]);

	const dispatching = React.useMemo(() => globalRedux.apiStatus.DISPATCH_LOAD_OUT === API_STATUS.PENDING, [globalRedux.apiStatus]);

	const columns = [
		{
			title: 'Vendor Name',
			dataIndex: 'vendorId',
			key: 'vendorId',
			width: '15%',
			render: (value) => <span>{value?.vendorName}</span>,
		},
		{
			title: 'Part Number',
			dataIndex: 'partNumberId',
			key: 'partNumberId',
			width: '15%',
			render: (value) => <span>{value?.partNumber}</span>,
		},
		// {
		// 	title: 'Sap Number',
		// 	dataIndex: 'driverName',
		// 	key: 'driverName',
		// 	render: (value) => <span>{value?.sapNumber}</span>,
		// },
		{
			title: 'Invoice Number',
			dataIndex: 'invoiceNumber',
			key: 'invoiceNumber',
		},
		{
			title: 'LR Number',
			dataIndex: 'lrNumber',
			align: 'left',
		},
		{
			title: 'Invoice Qty',
			dataIndex: 'invoiceQty',
			align: 'left',
		},
		{
			title: 'Total Weight',
			dataIndex: 'invoiceWeight',
			align: 'left',
		},
		{
			title: 'Dispatch Qty',
			dataIndex: '_id',
			align: 'center',
			render: (value, row) => row?.stockSummary?.reduce((acc, curr) => acc + parseInt(curr?.dispatchedQty || 0), 0) || 0,
		},
	];

	return (
		<Modal
			title="Verify and Proceed"
			visible={dispatchModalVisible}
			centered
			okButtonProps={{
				disabled: dataSource?.length < 1,
				loading: dispatching,
			}}
			okText={dataSource?.length < 1 ? `Sorry you don't have any to proceed` : 'Dispatch'}
			width={'80%'}
			onCancel={() => setDispatchModalVisible(false)}
			onOk={form.submit}>
			<Row>
				<Col xl={24} md={24}>
					<Form name="add-vehicle" style={{}} form={form} onFinish={handleSubmit} {...layer1FormCol}>
						<Row gutter={[10, 10]} style={{ width: '100%' }}>
							<Col xl={6} md={6}>
								<Form.Item
									label="Vehicle"
									name="vehicleId"
									rules={[
										{
											required: true,
											message: 'This Field is required!',
										},
									]}>
									<Select
										showSearch
										allowClear
										optionFilterProp="children"
										filterOption={(input, option) =>
											option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
											option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
										}
										placeholder="select vehicle">
										{vehicles?.map((vehicle) => (
											<Select.Option value={vehicle._id}>{vehicle?.regNumber}</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xl={6} md={6}>
								<Form.Item
									label="Dispatch Date"
									name="dispatchDate"
									initialValue={moment()}
									rules={[
										{
											required: true,
											message: 'This Field is required!',
										},
									]}>
									<DatePicker style={{ width: '100%' }} format={DATE_FORMAT.DD_MM_YYYY} />
								</Form.Item>
							</Col>
							<Col xl={6} md={6}>
								<Form.Item
									label="Requested Date"
									name="requestedDate"
									initialValue={moment()}
									rules={[
										{
											required: true,
											message: 'This Field is required!',
										},
									]}>
									<DatePicker
										showTime={{ format: 'hh:mm A', use12Hours: true }}
										format="DD-MM-YYYY hh:mm A"
										style={{ width: '100%' }}
									/>
								</Form.Item>
							</Col>
							<Col xl={6} md={6}>
								<Form.Item
									label="Driver Name"
									name="driverName"
									rules={[
										{
											required: false,
											message: 'This Field is required!',
										},
									]}>
									<Input placeholder="enter driver name" />
								</Form.Item>
							</Col>
							<Col xl={6} md={6}>
								<Form.Item
									label="Driver Mobile"
									name="driverMobile"
									rules={[
										{
											required: false,
											message: 'This Field is required!',
										},
									]}>
									<Input maxLength={10} placeholder="enter driver mobile" style={{ width: '100%' }} />
								</Form.Item>
							</Col>
							<Col xl={12} md={12}>
								<Form.Item initialValue="" label="Remarks" name="remarks">
									<Input.TextArea placeholder="remarks" style={{ width: '100%' }} />
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Col>
				<Col xl={24} md={24} style={{ textAlign: 'right', paddingBottom: 10 }}>
					<Button onClick={handlePrint} type="primary">
						Print
					</Button>
				</Col>
				<Col xl={24} md={24}>
					<TableComponent {...{ dataSource, columns, pagination: false }} />
				</Col>
			</Row>
			<ComponentToPrint ref={componentRef} {...{ dataSource, columns, pagination: false }} />
		</Modal>
	);
};

const ComponentToPrint = React.forwardRef((props, ref) => {
	const { dataSource, columns, pagination } = props;
	return (
		<div style={{ display: 'none' }}>
			<div ref={ref}>
				<TableComponent {...{ dataSource, columns, pagination }} />
			</div>
		</div>
	);
});

export default DispatchModal;
