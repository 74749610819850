import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import PageLayout from 'components/page-layout';
import Register from 'pages/pre_login_pages/RegisterPage';
import TrackingPage from 'pages/tracking-page';
import Login from 'pages/pre_login_pages/LoginPage';
import ProtectedRoute from './ProtectedRoute';
import OrganizationList from '../pages/organization/Organization';

const Routes = () => {
	// const loginRedux = useSelector((state) => state.loginRedux);

	return (
		<div
			className="app_container"
			style={{
				overflowY: 'auto',
				overflowX: 'hidden',
				minHeight: '100vh',
			}}>
			<BrowserRouter>
				<Switch>
					<Route exact path="/login" component={Login} />
					<Route exact path="/tracking-page" component={TrackingPage} />
					<Route exact path="/register" component={Register} />
					<Route exact path="/register" component={Register} />
					<Route exact path="/organization" component={OrganizationList} />
					<ProtectedRoute path="/" component={PageLayout} />
				</Switch>
			</BrowserRouter>
		</div>
	);
};

export default Routes;
