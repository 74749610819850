import React from 'react';
import moment from 'moment';
import { debounce } from 'lodash';
import { Pagination, Row, Col, Select, DatePicker, Input } from 'antd';
import TableComponent from 'components/table-component';
import { DATE_FORMAT } from 'constants/app-constants';
import ExcelDownload from 'components/excel-download';
import PdfDownload from 'components/pdf-download';

const StockReturnListPresentational = ({
	column,
	filteredData,
	handleTableChange,
	getStartingValue,
	getEndingValue,
	pageSize,
	intialPageSizeOptions,
	initialPageSize,
	currentPage,
	tableLoading,
	partNumbers,
	vendors,
	handleFilterOptions,
	exportData,
	extraFields,
	vehicles,
}) => {
	return (
		<Row style={{ padding: '20px 10px' }}>
			<Col xl={24} md={24}>
				<TableComponent
					scroll={{ x: 1300 }}
					loading={tableLoading}
					className="custom-table"
					style={{ width: '100%' }}
					rowKey={(record) => record._id}
					dataSource={filteredData}
					title={() => (
						<Row justify="space-between">
							<Col xl={24} md={24}>
								<Row gutter={[10, 10]} style={{ width: '100%' }}>
									<Col xl={4} md={4}>
										<Input
											placeholder="LR Number"
											onChange={debounce(({ target: { value } }) => handleFilterOptions('lrNumber', value), 1000)}
										/>
									</Col>
								</Row>
							</Col>

							{/* <Col xl={24} md={24} style={{ paddingTop: 10 }}>
								<Col xl={8} md={8}>
									<Select
										showSearch
										allowClear
										maxTagCount="responsive"
										mode="multiple"
										placeholder="Select Status"
										optionFilterProp="children"
										style={{ width: '100%' }}
										filterOption={(input, option) =>
											option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
											option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
										}
										onChange={(value) => handleFilterOptions('status', value)}>
										{[
											{
												label: 'In Stock',
												value: 'InStock',
											},
											{
												label: 'Dispatched',
												value: 'Dispatched',
											},
											{
												label: 'Partially_Dispatched',
												value: 'Partially_Dispatched',
											},
										]?.map((status) => (
											<Select.Option value={status.value}>{status?.label}</Select.Option>
										))}
									</Select>
								</Col>
							</Col> */}
							{/* <Col xl={24} md={24} style={{ paddingTop: 10 }}>
								<Row gutter={[10, 10]}>
									<Col xl={4} md={4}>
										<Select
											showSearch
											allowClear
											maxTagCount="responsive"
											optionFilterProp="children"
											style={{ width: '100%' }}
											// filterOption={(input, option) =>
											// 	option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
											// 	option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
											// }
											onChange={(value) => handleFilterOptions('vehicleId', value)}
											placeholder="Select Vehicle">
											{vehicles?.map((vehicle) => (
												<Select.Option value={vehicle?._id}>{vehicle?.regNumber}</Select.Option>
											))}
										</Select>
									</Col>
									<Col xl={4} md={4}>
										<PdfDownload {...{ ...exportData }} />
									</Col>
									<Col xl={4} md={4}>
										<ExcelDownload {...{ ...extraFields }} />
									</Col>
								</Row>
							</Col> */}
						</Row>
					)}
					{...{
						columns: column,
						pagination: { current: currentPage, pageSize: pageSize, position: ['none', 'none'] },
						...(!!filteredData?.length && {
							footer: () => (
								<Row justify="space-between">
									<Col md={16}>
										{!!filteredData?.length &&
											`Showing ${getStartingValue()} - ${getEndingValue()} of ${filteredData?.length} Data`}
									</Col>
									<Col md={8}>
										<div style={{ textAlign: 'right' }}>
											<Pagination
												pageSizeOptions={intialPageSizeOptions}
												defaultPageSize={initialPageSize}
												showSizeChanger={true}
												total={filteredData?.length}
												onChange={handleTableChange}
												responsive
											/>
										</div>
									</Col>
								</Row>
							),
						}),
					}}
				/>
			</Col>
		</Row>
	);
};

export default StockReturnListPresentational;
