import React, { useEffect } from "react";
import { Input, Button, Divider, Form, Row, Col, Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { putApi } from "redux/sagas/putApiSaga";
import { SERVER_IP } from "assets/Config";
import { API_STATUS, PARTY_TYPE } from "constants/app-constants";
import { resetApiStatus } from "redux/reducers/globals/globalActions";
import { postApi } from "redux/sagas/postApiDataSaga";

const { TextArea } = Input;

const AddParty = ({ handleClose, editParty, setPartyAddModal }) => {
	const [form] = Form.useForm();
	const globalRedux = useSelector((state) => state?.globalRedux);
	const dispatch = useDispatch();

	const addParty = (values) => {
		let data = {
			...values,
			orgId: globalRedux?.selectedOrganization?._id,
		};
		dispatch(postApi(data, "ADD_PARTY"));
	};

	const handleEdit = (values) => {
		let data = {
			...values,
			orgId: globalRedux?.selectedOrganization?._id,
		};
		let url = `${SERVER_IP}party/${editParty._id}?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(putApi(data, "EDIT_PARTY", url));
	};

	useEffect(() => {
		if (
			globalRedux.apiStatus.ADD_PARTY === "SUCCESS" ||
			globalRedux.apiStatus.EDIT_PARTY === "SUCCESS"
		) {
			dispatch(resetApiStatus(editParty ? "EDIT_PARTY" : "ADD_PARTY"));
			setPartyAddModal(false);
			handleClose();
		}
	}, [
		globalRedux.apiStatus,
		editParty,
		setPartyAddModal,
		handleClose,
		dispatch,
	]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const loading =
		globalRedux.apiStatus.ADD_PARTY === API_STATUS.PENDING ||
		globalRedux.apiStatus.EDIT_PARTY === API_STATUS.PENDING;

	return (
		<Row>
			<Col span={24}>
				{/* <Row style={{ backgroundColor: '#fff', padding: '10px 0px' }}>
					<Col>
						<h6 style={{ marginBottom: '0px' }}>{editParty ? 'Edit' : 'New'} Party</h6>
					</Col>
				</Row>
				<Divider /> */}
				<Row style={{ paddingTop: 0 }}>
					<Col span={24}>
						<Form
							name="add-party"
							className="required_in_right"
							labelAlign="left"
							form={form}
							onFinish={!editParty ? addParty : handleEdit}
							{...layer1FormCol}
						>
							<Form.Item
								label="Party Type"
								name="partyType"
								initialValue={editParty?.partyType}
								rules={[
									{
										required: true,
										message: "This Field is required!",
									},
								]}
							>
								<Select placeholder="select party type">
									{PARTY_TYPE.map((type) => (
										<Select.Option key={type} value={type}>
											{type}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								label="Party Name"
								name="partyName"
								initialValue={editParty?.partyName}
								rules={[
									{
										required: true,
										message: "This Field is Required!",
									},
								]}
							>
								<Input placeholder="Party Name" />
							</Form.Item>
							<Form.Item
								label="Email"
								name="email"
								initialValue={editParty?.email}
							>
								<Input placeholder="Email" />
							</Form.Item>
							<Form.Item
								label="Mobile"
								name="mobile"
								initialValue={editParty?.mobile}
							>
								<Input type="number" maxLength={10} placeholder="Mobile" />
							</Form.Item>
							<Form.Item
								label="GSTIN"
								name="gstin"
								initialValue={editParty?.gstin}
							>
								<Input placeholder="GSTIN" />
							</Form.Item>
							<Form.Item
								label="Opening Balance"
								name="openingBalance"
								initialValue={editParty?.openingBalance}
							>
								<Input type="number" placeholder="Opening Balance" />
							</Form.Item>
							<Form.Item
								label="Billing Address"
								name="billingAddress"
								initialValue={editParty?.billingAddress}
							>
								<TextArea placeholder="Billing Address" />
							</Form.Item>
							<Form.Item
								label="Shipping Address"
								name="shippingAddress"
								initialValue={editParty?.shippingAddress}
							>
								<TextArea placeholder="Shipping Address" />
							</Form.Item>
							<Form.Item
								label="Remarks"
								name="remarks"
								initialValue={editParty?.remarks}
							>
								<TextArea placeholder="Remarks" />
							</Form.Item>
							<Form.Item
								wrapperCol={{
									offset: 0,
									span: 24,
								}}
							>
								<Row justify="space-between">
									<Button
										onClick={() => setPartyAddModal(false)}
										style={{ width: "49%" }}
										danger
									>
										Cancel
									</Button>
									<Button
										loading={loading}
										type="primary"
										style={{ width: "49%", marginRight: 5 }}
										htmlType="submit"
									>
										{editParty ? "Update" : "Create"}
									</Button>
								</Row>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default AddParty;
