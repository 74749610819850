let initialState = {
	apiStatus: {},
	accountBooks: [],
	vouchers: [],
	organizations: [],
	selectedOrganization: null,
	currentPage: "1",
	currentPageTitle: "Dashboard",
	states: [],
	locations: [],
	users: [],
	classes: [],
	assets: [],
	vehicles: [],
	vendors: [],
	vendorsMapping: [],
	applicationUsers: [],
	batches: [],
	activeBatch: null,
	partNumbers: [],
	credentials: [],
	materials: [],
	cities: [],
	parties: [],
	bankDetails: [],
	drivers: [],
	rcOwners: [],
	grades: [],
	gpss:[]
};

const globalReducer = (state = initialState, action) => {
	switch (action.type) {
		case "LOGOUT": {
			return initialState;
		}
		case "SET_API_STATUS":
			return {
				...state,
				apiStatus: {
					...state.apiStatus,
					...action.payload,
				},
			};
		case "SET_ORGANIZATIONS":
			return {
				...state,
				organizations: action.payload,
			};
		case "SET_CURRENT_PAGE":
			return {
				...state,
				currentPage: action.payload.currentPage,
				currentPageTitle: action.payload.currentPageTitle,
			};
		case "SET_SELECTED_ORGANIZATION":
			return {
				...state,
				selectedOrganization: action.payload,
			};
		case "SET_ACCOUNT_BOOKS":
			return {
				...state,
				accountBooks: action.payload,
			};
		case "SET_STATES":
			return {
				...state,
				states: action.payload,
			};
		case "SET_LOCATIONS":
			return {
				...state,
				locations: action.payload,
			};
		case "SET_USERS":
			return {
				...state,
				users: action.payload,
			};
		case "SET_CLASSES":
			return {
				...state,
				classes: action.payload,
			};
		case "SET_VOUCHERS_HEAD":
			return {
				...state,
				vouchers: action.payload,
			};
		case "SET_ASSETS":
			return {
				...state,
				assets: action.payload,
			};
		case "SET_VEHICLES":
			return {
				...state,
				vehicles: action.payload,
			};
		case "SET_VENDORS":
			return {
				...state,
				vendors: action.payload,
			};
		case "SET_VENDOR_MAPPING":
			return {
				...state,
				vendorsMapping: action.payload,
			};
		case "SET_APPLICATION_USERS":
			return {
				...state,
				applicationUsers: action.payload,
			};
		case "SET_CREDENTIALS":
			return {
				...state,
				credentials: action.payload,
			};
		case "SET_PART_NUMBERS":
			return {
				...state,
				partNumbers: action.payload,
			};
		case "SET_BATCHES":
			return {
				...state,
				batches: action.payload,
				activeBatch: action.payload.find((batch) => batch.isActive)?._id,
			};

		case "SET_MATERIALS":
			return {
				...state,
				materials: action.payload,
			};
		case "SET_GRADES":
			return {
				...state,
				grades: action.payload,
			};
		case "SET_GPSS":
			return {
				...state,
				gpss: action.payload,
			};
		case "SET_CITIES":
			return {
				...state,
				cities: action.payload,
			};
		case "SET_PARTIES":
			return {
				...state,
				parties: action.payload,
			};
		case "SET_BANK_DETAILS":
			return {
				...state,
				bankDetails: action.payload,
			};
		case "SET_DRIVERS":
			return {
				...state,
				drivers: action.payload,
			};
		case "SET_RC_OWNERS":
			return {
				...state,
				rcOwners: action.payload,
			};
		default:
			return state;
	}
};

export { globalReducer };
