import React, { forwardRef } from "react";
import moment from "moment";
import { DATE_FORMAT } from "constants/app-constants";
// import ESign from "assets/images/eSign.jpeg";

const BANK_DETAILS = {
	accountName: "MMT SUPPLY CHAIN SYSTEMS",
	accountNumber: "218905002990",
	ifscCode: "ICIC0002189",
	bank: "ICICI BANK",
	branch: "SARAVANAMPATTI",
	accountType: "CURRENT",
};

const COMPANY_NAME = "MMT SUPPLY CHAIN SYSTEMS";
const COMPANY_ADDRESS =
	"NO 43 ADITHYA COMPLEX, VALLALAR NAGAR, Vilankurichi Road, Saravanampatti Post, Coimbatore - 641035.";
const EMAIL = "Tmttransportcbe@gmail.com";
const CONTACT_NUMBER = "9659534566";
const WEBSITE = "www.mmttransport.in";
const GSTIN = "33BCGPS9283N1ZR";

const LabelValue = ({ label, value }) => (
	<div className="label_value">
		<div className="label">{label}</div>
		<div className="colon">:</div>
		<div className="value">{value}</div>
	</div>
);

const TransportDetailsToPrint = forwardRef((props, ref) => {
	const {
		sgst = 0,
		cgst = 0,
		igst = 0,
		gstin = 0,
		notes,
		subTotal = 0,
		lrCharges = 0,
		totalAmount = 0,
		amountInWords,
		roundOff,
		poNumber,
		transportItems,
		invoiceNumber,
		billingAddress,
		customerId,
		invoiceDate,
		rateType,
	} = props?.data || {};
	console.log("🚀 ~ TransportDetailsToPrint ~ props?.data:", props?.data)

	const printoutType = props?.printoutType;

	return (
		<div className="invoice-pdf" ref={ref}>
			<div className="invoice-pdf-container">
				<table cellPadding="0" cellSpacing="0">
					<tr className="top">
						<td colSpan="12" style={{ padding: 0, paddingTop: 0 }}>
							<table className="bordered-bottom">
								<tr>
									<td className="title">
										<div className="left">
											<div className="content">
												<div className="name" style={{ color: "#000" }}>
													{COMPANY_NAME}
												</div>
												<div className="address">{COMPANY_ADDRESS}</div>
												<div className="contact">
													{/* <span>Email: {EMAIL}</span> */}
													<div>Contact: {CONTACT_NUMBER}</div>
													<div>{WEBSITE}</div>
													<div>GSTIN : {GSTIN} / SAC NO : 9965</div>
												</div>
												{/* <div
													className="contact"
													style={{
														marginTop: 10,
													}}
												>
													<span>Account Name: {BANK_DETAILS.accountName}</span>
													<div>Account No: {BANK_DETAILS.accountNumber}</div>
													<div>IFSC Code: {BANK_DETAILS.ifscCode}</div>
													<div>Branch Name: {BANK_DETAILS.branch}</div>
													<div>Bank Name: {BANK_DETAILS.bank}</div>
													<div>Account Type: {BANK_DETAILS.accountType}</div>
												</div> */}
											</div>
										</div>
										<div className="right">
											<div className="content">
												<div className="tax-invoice">TAX INVOICE</div>
												<div className="copy">({printoutType} Copy)</div>
											</div>
										</div>
									</td>
								</tr>
							</table>
						</td>
					</tr>
					<tr className="information">
						<td colSpan="12">
							<table className="bordered-bottom">
								<tr>
									<td style={{ width: "50%" }}>
										<div className="toArea">
											<p
												style={{
													margin: 0,
													lineHeight: "normal",
												}}
											>
												To:
											</p>
											<div className="details">
												<div style={{ width: "100%", fontWeight: 'bold' }}>
													{customerId?.displayName}
												</div>
												<div style={{ width: "100%" }}>{billingAddress}</div>
												<div>{`GSTIN : ${gstin || ""}`}</div>
											</div>
										</div>
									</td>
									<td />
									<td className="no_padding">
										<div style={{ paddingTop: 20, fontSize: 14 }}>
											<div className="row">
												<div className="label">Bill No:</div>
												<div className="value">{invoiceNumber}</div>
											</div>
											<div className="row">
												<div className="label">Date:</div>
												<div className="value">
													{moment(invoiceDate).format(
														`${DATE_FORMAT.DD_MM_YYYY}`
													) || ""}
												</div>
											</div>
											<div className="row">
												<div className="label">PO No:</div>
												<div className="value">{poNumber}</div>
											</div>
										</div>
									</td>
								</tr>
							</table>
						</td>
					</tr>
					<tr className="heading custom-heading">
						<td>S.No</td>
						<td>LR No</td>
						<td>Trip No</td>
						<td>Date</td>
						<td>{rateType === "Warehouse" ? "WH" : "KGS"}</td>
						<td>{rateType === "Warehouse" ? "WH" : "Rate/KGS"}</td>
						<td>{rateType === "Warehouse" ? "WH" : "Fright(₹)"}</td>
						{/* <td>Amc(₹)</td> */}
						<td>Loading Halt</td>
						<td>Unloading Halt</td>
						<td>Others</td>
						<td>LR(₹)</td>
						<td>Total(₹)</td>
					</tr>
					{transportItems?.slice(0, 15)?.map((data, index) => (
						<tr className="item custom-item" key={index}>
							<td style={{ fontWeight: "bold" }}>{index + 1}</td>
							<td style={{ fontWeight: "bold" }}>{data?.lrNumber}</td>
							<td style={{ fontWeight: "bold" }}>
								{data?.tripNo || ""}
								{/* {rateType === "Warehouse" ? "996729" : "996519"} */}
							</td>
							<td style={{ fontWeight: "bold" }}>
								{moment(data?.date).format(`${DATE_FORMAT.DD_MM_YYYY}`) || ""}
							</td>
							<td style={{ fontWeight: "bold" }}>
								{parseFloat(data?.frightKG || 0).toFixed(2)}
							</td>
							<td style={{ fontWeight: "bold" }}>
								{parseFloat(data?.rate || 0).toFixed(2)}
							</td>
							<td style={{ fontWeight: "bold" }}>
								{(data?.frightRs || 0).toFixed(2)}
							</td>
							{/* <td style={{ fontWeight: "bold" }}>
								{parseFloat(data?.amcAmount || 0).toFixed(2)}
							</td> */}
							<td style={{ fontWeight: "bold" }}>
								{parseFloat(data?.loadingHalt || 0).toFixed(2)}
							</td>
							<td style={{ fontWeight: "bold" }}>
								{parseFloat(data?.unloadingHalt || 0).toFixed(2)}
							</td>
							<td style={{ fontWeight: "bold" }}>
								{parseFloat(data?.others || 0).toFixed(2)}
							</td>
							<td style={{ fontWeight: "bold" }}>
								{parseFloat(data?.lrCharges || 0).toFixed(2)}
							</td>
							<td style={{ fontWeight: "bold" }}>
								{parseFloat(data?.totalAmount || 0).toFixed(2)}
							</td>
						</tr>
					))}
				</table>
				<div className="invoice-footer">
					<div
						style={{ fontSize: 14, width: "100%" }}
						className="amount_gst_area"
					>
						<div className="amount_area">
							<div
								className="amount_area_container"
								style={{ lineHeight: "normal", height: "100%" }}
							>
								<div>
									<div className="bold" style={{ paddingRight: 10 }}>
										Amount in words
									</div>
									<div>{amountInWords}</div>
								</div>
								<div>
									<div className="bold" style={{ paddingRight: 10 }}>
										Notes:
									</div>
									<div>{notes}</div>
								</div>
							</div>
						</div>
						<div className="gst_area">
							<LabelValue
								label="Sub Total"
								value={parseFloat(subTotal).toFixed(2)}
							/>
							{/* <LabelValue
								label="LR Charges"
								value={parseFloat(lrCharges || 0).toFixed(2)}
							/> */}
							{/* <LabelValue
								label={`SGST ${(props?.data?.gstRate || 0) / 2}%`}
								value={sgst || 0}
							/>
							<LabelValue
								label={`CGST ${(props?.data?.gstRate || 0) / 2}%`}
								value={cgst || 0}
							/> */}
							{/* <LabelValue
								label={`IGST ${props?.data?.gstRate || 0}%`}
								value={igst || 0}
							/> */}
							<LabelValue label="Round Off (+/-)" value={roundOff || 0} />
							<LabelValue
								label="Grand Total"
								value={parseFloat(totalAmount).toFixed(2)}
							/>
						</div>
					</div>
				</div>
				<div className="signArea">
					<div className="left">
						<div
							className="account_details"
							style={{
								marginTop: 10,
							}}
						>
							<span>Account Name: {BANK_DETAILS.accountName}</span>
							<div>Account No: {BANK_DETAILS.accountNumber}</div>
							<div>IFSC Code: {BANK_DETAILS.ifscCode}</div>
							<div>Branch Name: {BANK_DETAILS.branch}</div>
							<div>Bank Name: {BANK_DETAILS.bank}</div>
							<div>Account Type: {BANK_DETAILS.accountType}</div>
						</div>
						{/* <div className="account_divider"></div> */}
					</div>
					<div className="sign">
						<span style={{ color: "#000" }}>For {COMPANY_NAME}</span>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								width: "100%",
								height: "80%",
							}}
						>
							{/* <img src={ESign} alt="e-sign" height="80px" width="80px" /> */}
						</div>
					</div>
				</div>
				<div className="disclosed" style={{ color: '#000' }}>
					<div className="title">Document Enclosed</div>
					<div
						className="paragraph"
						style={{ textAlign: "center", fontWeight: "bold" }}
					>
						Whether liable to pay under RCM Yes
					</div>
					<div
						className="paragraph"
						style={{ textAlign: "center", fontWeight: "bold" }}
					>
						{transportItems?.length || 0} LR Copies
					</div>
					<div className="paragraph m-t-10">
						<div>
							1. All payments to be made by crossed Cheque/DD in favour of the{" "}
							{COMPANY_NAME}
						</div>
						<div>
							2. Interest will be charged at 18% per annum on outstanding after
							one week from the date of this bill until realisation
						</div>
					</div>
				</div>
			</div>
		</div>
	);
});

export default TransportDetailsToPrint;
