import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Input, Button, Divider, Form, Select, InputNumber } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { putApi } from 'redux/sagas/putApiSaga';
import { SERVER_IP } from 'assets/Config';
import { postApi } from '../../redux/sagas/postApiDataSaga';
import { resetApiStatus } from '../../redux/reducers/globals/globalActions';
import { API_STATUS } from 'constants/app-constants';

const AddPartNumber = ({ handleClose, editPartNumber, setPartNumberAddModal }) => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const globalRedux = useSelector((state) => state.globalRedux);

	const addPartNumber = (values) => {
		let data = {
			...values,
			orgId: globalRedux.selectedOrganization._id,
		};
		dispatch(postApi(data, 'ADD_PART_NUMBER'));
	};
	const handleEdit = (values) => {
		let data = {
			...values,
			orgId: globalRedux.selectedOrganization._id,
		};
		let url = `${SERVER_IP}partnumber/${editPartNumber._id}?orgId=${globalRedux.selectedOrganization._id}`;
		dispatch(putApi(data, 'EDIT_PART_NUMBER', url));
	};

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_PART_NUMBER === 'SUCCESS' || globalRedux.apiStatus.EDIT_PART_NUMBER === 'SUCCESS') {
			dispatch(resetApiStatus(editPartNumber ? 'EDIT_PART_NUMBER' : 'ADD_PART_NUMBER'));
			setPartNumberAddModal(false);
			handleClose();
		}
	}, [globalRedux.apiStatus]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const loading = globalRedux.apiStatus.ADD_PART_NUMBER === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_PART_NUMBER === API_STATUS.PENDING;

	return (
		<Container fluid>
			{/* <Row style={{ backgroundColor: '#fff', padding: '10px 0px' }}>
				<Col>
					<h6 style={{ marginBottom: '0px' }}>{editPartNumber ? 'Edit' : 'New'} Part Number</h6>
				</Col>
			</Row> */}
			{/* <Divider /> */}
			<Row className="mt-3 m-2 pt-2">
				<Form
					name="add-partNumber"
					style={{}}
					colon={false}
					labelAlign="left"
					form={form}
					onFinish={!editPartNumber ? addPartNumber : handleEdit}
					{...layer1FormCol}>
					<Form.Item
						label="Part Number"
						name="partNumber"
						initialValue={editPartNumber?.partNumber}
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<Input />
					</Form.Item>
					<Form.Item
						label="SAP Number"
						name="sapNumber"
						initialValue={editPartNumber?.sapNumber}
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<Input />
					</Form.Item>
					<Form.Item
						label="Warehouse Type"
						name="warehouseType"
						placeholder="select warehouse type"
						initialValue={editPartNumber?.warehouseType}
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<Select placeholder="select part number type">
							<Select.Option value={'Casting'}>Casting</Select.Option>
							<Select.Option value={'Coil'}>Coil</Select.Option>
							<Select.Option value={'Import'}>Import</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item label="Remarks" name="remarks" initialValue={editPartNumber?.remarks}>
						<Input.TextArea />
					</Form.Item>

					<Form.Item
						wrapperCol={{
							offset: 0,
							span: 24,
						}}>
						<Row className="space-between" style={{ paddingTop: 20, width: '100%', margin: 0 }}>
							<Button danger style={{ width: '49%' }} onClick={() => setPartNumberAddModal(false)}>
								Cancel
							</Button>
							<Button loading={loading} style={{ width: '49%' }} type="primary" htmlType="submit">
								{editPartNumber ? 'Update' : 'Save'}
							</Button>
						</Row>
					</Form.Item>
				</Form>
			</Row>
		</Container>
	);
};

export default AddPartNumber;
