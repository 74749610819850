import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Select } from "antd";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { SERVER_IP } from "assets/Config";
import { ACTIONS, API_STATUS, DATE_FORMAT } from "constants/app-constants";
import { postApi } from "redux/sagas/postApiDataSaga";
import { resetApiStatus } from "redux/reducers/globals/globalActions";
import { putApi } from "redux/sagas/putApiSaga";
import { getApi } from "redux/sagas/getApiDataSaga";
import AddBookingPresentational from "./add-booking-presentational";
import DatePicker from "components/date-picker";
import { DeleteOutlined } from "@ant-design/icons";

let itemDefaultRecord = {
	sno: null,
	invoiceNumber: null,
	ewayBillNo: null,
	invoiceDate: moment(),
	materialId: null, // material api
	netValue: null,
	grossValue: null,
	id: uuidv4(),
};

const AddBookingFunctional = ({ state, setState, refreshList }) => {
	const [form] = Form.useForm();
	const globalRedux = useSelector((state) => state.globalRedux);
	const customers = useSelector((state) => state?.customerRedux?.customers);
	const materials = useSelector((state) => state?.globalRedux?.materials);
	const drivers = useSelector((state) => state?.globalRedux?.drivers);
	const cities = useSelector((state) => state?.globalRedux?.cities);
	const gpss = useSelector((state) => state?.globalRedux?.gpss);
	const [tableData, setTableData] = useState([
		{ ...itemDefaultRecord, id: uuidv4() },
	]);
	const vendors = globalRedux?.vendors;
	const vehicles = globalRedux?.vehicles;
	const { classes = [] } = globalRedux;
	const dispatch = useDispatch();

	useEffect(() => {
		if (state?.selectedRow && state?.visible) {
			form.setFieldsValue({
				bookingDate: moment(state?.selectedRow?.bookingDate),
				to: state?.selectedRow?.to?._id,
				from: state?.selectedRow?.from?._id,
				consignee: state?.selectedRow?.consignee?._id,
				consignor: state?.selectedRow?.consignor?._id,
				customerFreight: state?.selectedRow?.customerFreight || 0,
				invoiceNumber: state?.selectedRow?.invoiceNumber,
				invoiceDate: moment(state?.selectedRow?.invoiceDate),
				materialId: state?.selectedRow?.materialId?._id,
				netValue: state?.selectedRow?.netValue || 0,
				grossValue: state?.selectedRow?.grossValue || 0,
				vehicleId: state?.selectedRow?.vehicleId?._id,
				driverId: state?.selectedRow?.driverId?._id,
				gpsId: state?.selectedRow?.gpsId?._id,
				lrNumber_manual: state?.selectedRow?.lrNumber_manual,
				vehicleFreight: state?.selectedRow?.vehicleFreight || 0,
				remarks: state?.selectedRow?.remarks,
			});
		} else {
			form.resetFields();
			setTableData([{ ...itemDefaultRecord, id: uuidv4() }]);
		}
	}, [state?.selectedRow?._id, state?.visible]);

	const handleInputChange = useCallback(
		(label, value, rowId) => {
			const data = tableData.map((data) => {
				if (data.id === rowId) {
					return {
						...data,
						[label]: value,
					};
				} else {
					return data;
				}
			});
			setTableData([...data]);
		},
		[tableData]
	);

	const columns = [
		{
			title: "S No",
			dataIndex: "sno",
			key: "sno",
			width: "5%",
			render: (value, record, index) => <div>{index + 1}</div>,
		},
		{
			title: "Invoice Number",
			dataIndex: "invoiceNumber",
			key: "invoiceNumber",
			width: "8%",
			render: (value, record, index) => (
				<Input
					value={value}
					// disabled
					placeholder="invoiceNumber"
					// className={`${!value ? 'error' : ''}`}
					onChange={({ target: { value } }) =>
						handleInputChange("invoiceNumber", value, record?.id)
					}
				/>
			),
		},
		{
			title: "Eway Bill No",
			dataIndex: "ewayBillNo",
			key: "ewayBillNo",
			width: "8%",
			render: (value, record, index) => (
				<Input
					value={value}
					// disabled
					placeholder="ewayBillNo"
					// className={`${!value ? 'error' : ''}`}
					onChange={({ target: { value } }) =>
						handleInputChange("ewayBillNo", value, record?.id)
					}
				/>
			),
		},
		{
			title: "Invoice Date",
			dataIndex: "invoiceDate",
			key: "invoiceDate",
			width: "10%",
			render: (value, record) => (
				<DatePicker
					value={value}
					className={`${!value ? "error" : ""}`}
					style={{ width: "100%" }}
					format={DATE_FORMAT.DD_MM_YYYY}
					onChange={(value) =>
						handleInputChange(
							"invoiceDate",
							value === "Invalid date" ? "" : value,
							record?.id
						)
					}
				/>
			),
		},
		{
			title: "Material",
			dataIndex: "materialId",
			key: "materialId",
			width: "10%",
			// align: 'right',
			render: (value, record) => (
				<Select
					showSearch
					filterOption={(input, option) =>
						option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
							0 ||
						option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
					}
					placeholder="Select consignment"
					value={value}
					onChange={(value) =>
						handleInputChange("materialId", value, record?.id)
					}
					style={{
						width: "100%",
					}}
				>
					{materials?.map((material) => (
						<Select.Option key={material?._id} value={material?._id}>
							{material?.materialName}
						</Select.Option>
					))}
				</Select>
			),
		},
		{
			title: "Net Value",
			dataIndex: "netValue",
			key: "netValue",
			width: "10%",
			// align: 'right',
			render: (value, record) => (
				<Input
					type="number"
					pattern="^-?[0-9]\d*\.?\d*$"
					value={value}
					placeholder="netValue"
					// className={`${!value ? 'error' : ''}`}
					onChange={({ target: { value } }) =>
						handleInputChange("netValue", parseFloat(value), record?.id)
					}
				/>
			),
		},
		{
			title: "Gross Value",
			dataIndex: "grossValue",
			key: "grossValue",
			width: "10%",
			// align: 'right',
			render: (value, record) => (
				<Input
					type="number"
					pattern="^-?[0-9]\d*\.?\d*$"
					value={value}
					placeholder="grossValue"
					// className={`${!value ? 'error' : ''}`}
					onChange={({ target: { value } }) =>
						handleInputChange("grossValue", parseFloat(value), record?.id)
					}
				/>
			),
		},
		{
			title: "",
			dataIndex: "item",
			key: "item",
			align: "center",
			width: "3%",
			render: (value, record) =>
				tableData.length > 1 ? (
					<DeleteOutlined
						style={{ color: "red" }}
						onClick={() => handleRemove(record.id)}
					/>
				) : null,
		},
	];

	const handleAddTableData = useCallback(() => {
		let data = [...tableData];
		data.push({
			...itemDefaultRecord,
			id: uuidv4(),
		});
		setTableData(data);
	}, [tableData]);

	useEffect(() => {
		const filledList = tableData
			?.map((data) => data?.invoiceNumber)
			.filter((data) => data);
		if (tableData?.length === filledList.length) {
			handleAddTableData();
		}
	}, [tableData]);

	const handleRemove = (id) => {
		const data = tableData.filter((data) => data.id !== id);
		setTableData([...data]);
	};

	useEffect(() => {
		if (!state?.visible) {
			form.resetFields();
			setState({
				...state,
				selectedRow: null,
			});
		}
	}, [state?.visible]);

	const getVehicle = () => {
		let url = `${SERVER_IP}vehicle?orgId=${globalRedux.selectedOrganization._id}`;
		dispatch(getApi("GET_VEHICLES", url));
	};

	const getDrivers = useCallback(() => {
		let url = `${SERVER_IP}driver/?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(getApi("GET_DRIVERS", url));
	}, [dispatch, globalRedux?.selectedOrganization?._id]);

	useEffect(() => {
		if (state?.visible) {
			getVehicle();
			getDrivers();
		}
	}, [state?.visible]);

	const generateTableData = (editData) => {
		if (editData?.items?.length > 0) {
			const data = editData?.items?.map((item) => ({
				invoiceNumber: item?.invoiceNumber || "",
				ewayBillNo: item?.ewayBillNo || "",
				invoiceDate: moment(item?.invoiceDate),
				materialId: item?.materialId?.id || "",
				netValue: item?.netValue || 0,
				grossValue: item?.grossValue || 0,
				id: uuidv4(),
			}));
			setTableData([...data]);
		}
	};

	useEffect(() => {
		if (
			globalRedux.apiStatus.ADD_BOOKING === API_STATUS.SUCCESS ||
			globalRedux.apiStatus.EDIT_BOOKING === API_STATUS.SUCCESS
		) {
			setState({ ...state, visible: false });
			refreshList();
			dispatch(
				resetApiStatus(
					state?.selectedRow ? ACTIONS.EDIT_BOOKING : ACTIONS.ADD_BOOKING
				)
			);
		}
	}, [globalRedux.apiStatus]);

	useEffect(() => {
		if (state?.selectedRow) {
			generateTableData(state?.selectedRow);
		}
	}, [state?.selectedRow]);

	const handleSubmit = (values) => {
		const request = {
			orgId: globalRedux.selectedOrganization._id,
			...values,
		};
		request.items = tableData
			.filter((data) => data?.invoiceNumber)
			.map((data, index) => {
				delete data.id;
				if(!(Boolean(data?.ewayBillNo))) {
					delete data.ewayBillNo;
				}
				return {
					...data,
					sno: (index + 1)?.toString(),
				};
			});
		dispatch(postApi(request, ACTIONS.ADD_BOOKING));
	};

	const handleEdit = (values) => {
		const request = {
			orgId: globalRedux.selectedOrganization._id,
			...values,
		};
		request.items = tableData
			.filter((data) => data?.invoiceNumber)
			.map((data, index) => {
				delete data.id;
				if(!(Boolean(data?.ewayBillNo))) {
					delete data.ewayBillNo;
				}
				return {
					...data,
					sno: (index + 1)?.toString(),
				};
			});
		let url = `${SERVER_IP}booking/${state?.selectedRow?._id}`;
		dispatch(putApi(request, ACTIONS.EDIT_BOOKING, url));
	};

	const getCities = useCallback(() => {
		let url = `${SERVER_IP}city/?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(getApi("GET_CITIES", url));
	}, [dispatch, globalRedux?.selectedOrganization?._id]);

	const getCustomers = () => {
		let url = `${SERVER_IP}customer?orgId=${globalRedux.selectedOrganization._id}`;
		dispatch(getApi("GET_CUSTOMERS", url));
	};

	const getMaterials = useCallback(() => {
		let url = `${SERVER_IP}material/?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(getApi("GET_MATERIALS", url));
	}, [dispatch, globalRedux?.selectedOrganization?._id]);

	const getVehicles = () => {
		let url = `${SERVER_IP}vehicle?orgId=${globalRedux.selectedOrganization._id}`;
		dispatch(getApi("GET_VEHICLES", url));
	};

	const getGPSs = useCallback(() => {
		let url = `${SERVER_IP}gps/?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(getApi("GET_GPSS", url));
	}, [dispatch, globalRedux?.selectedOrganization?._id]);

	useEffect(() => {
		getMaterials();
	}, [getMaterials]);

	useEffect(() => {
		if (state?.visible) {
			getCustomers();
			getMaterials();
			getVehicles();
			getGPSs();
			getCities();
		}
	}, [state?.visible]);

	const loading =
		globalRedux.apiStatus.ADD_BOOKING === API_STATUS.PENDING ||
		globalRedux.apiStatus.EDIT_BOOKING === API_STATUS.PENDING;
	console.log("🚀 ~ AddBookingFunctional ~ tableData:", tableData);

	return (
		<AddBookingPresentational
			{...{
				state,
				setState,
				classes,
				loading,
				form,
				handleSubmit,
				handleEdit,
				vendors,
				vehicles,
				customers,
				materials,
				vehicles,
				drivers,
				gpss,
				cities,
				columns,
				tableData,
			}}
		/>
	);
};

export default AddBookingFunctional;
