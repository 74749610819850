import React from 'react';
import { Pagination, Row, Col, Modal, Input } from 'antd';
import { AiOutlineSearch } from 'react-icons/ai';
import TableComponent from 'components/table-component';

const RouteCardPresentational = ({
	column,
	filteredData,
	handleTableChange,
	getStartingValue,
	getEndingValue,
	pageSize,
	intialPageSizeOptions,
	initialPageSize,
	currentPage,
	tableLoading,
	rowSelection,
	selectedRowKeys,
	binModal,
	setBinModal,
	binNumber,
	setBinNumber,
	handleSubmitBin,
	setSearchKey,
	quantity,
	setQuantity,
	setSelectedRecordToPrint
}) => {
	const hasData = React.useMemo(
		() =>
			filteredData?.filter(
				(data) =>
					selectedRowKeys?.includes(data?._id) &&
					parseInt(data?.stockSummary?.reduce((acc, curr) => acc + parseInt(curr?.dispatchedQty || 0), 0) || 0) > 0
			)?.length > 0,
		[selectedRowKeys, filteredData]
	);
	return (
		<>
			<Row style={{ padding: '20px 10px' }}>
				<Col xl={24} md={24}>
					<TableComponent
						loading={tableLoading}
						rowSelection={rowSelection}
						className="custom-table"
						style={{ width: '100%' }}
						rowKey={(record) => record._id}
						dataSource={filteredData}

					title={() => (
						<Row justify="space-between">
							<Col md={3}>
								<Input
									placeholder="Search"
									suffix={<AiOutlineSearch />}
									style={{ height: '30px' }}
									onChange={({ target: { value } }) => setSearchKey(value)}
								/>
							</Col>
						</Row>
					)}
						{...{
							columns: column,
							pagination: { current: currentPage, pageSize: pageSize, position: ['none', 'none'] },
							...(!!filteredData?.length && {
								footer: () => (
									<Row justify="space-between">
										<Col span={12}>
											{!!filteredData?.length &&
												`Showing ${getStartingValue()} - ${getEndingValue()} of ${filteredData?.length} Data`}
										</Col>
										<Col span={12}>
											<div style={{ textAlign: 'right' }}>
												<Pagination
													pageSizeOptions={intialPageSizeOptions}
													defaultPageSize={initialPageSize}
													showSizeChanger={true}
													total={filteredData?.length}
													onChange={handleTableChange}
													responsive
												/>
											</div>
										</Col>
									</Row>
								),
							}),
						}}
					/>
				</Col>
			</Row>
			<Modal
				width={'30%'}
				title="Enter QTY & BIN Number"
				visible={binModal}
				okText="Submit"
				onOk={handleSubmitBin}
				// okButtonProps={{ disabled: !!!binNumber }}
				destroyOnClose
				onCancel={() => {
					setBinModal(false);
					setSelectedRecordToPrint(null)
				}}>
				<Row gutter={[10,10]}>
					<Col xl={12} md={12}>
						<Input autoFocus value={quantity} onChange={({ target: { value } }) => setQuantity(value)} placeholder="Qty" />
					</Col>
					<Col xl={12} md={12}>
						<Input value={binNumber} onChange={({ target: { value } }) => setBinNumber(value)} placeholder="BIN number" />
					</Col>
				</Row>
			</Modal>
		</>
	);
};

export default RouteCardPresentational;
