import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col } from 'antd';
import moment from 'moment';
import toast from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { PrinterOutlined } from '@ant-design/icons';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { sendGetRequest } from 'redux/sagas/utils';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { SERVER_IP } from 'assets/Config';
import { ACTIONS, API_STATUS, DATE_FORMAT } from 'constants/app-constants';
import HighlightComponent from 'components/HighlightComponent';
import { objToQs } from 'helpers';
import RouteCardToPrint from './route-card-to-print';
import RouteCardPresentational from './route-card-presentational';

const initialPageSize = 10;
const intialPageSizeOptions = [10, 15, 20];

const RouteCardFunctional = React.memo((props) => {
	const componentRef = React.useRef();
	const [state, setState] = useState({
		visible: false,
	});
	const [binModal, setBinModal] = useState(false);
	const [activeTab, setActiveTab] = useState('0');
	const [binNumber, setBinNumber] = useState('');
	const [quantity, setQuantity] = useState('');
	const [selectedPartNumber, setSelectedPartNumber] = useState(null);
	const [searchKey, setSearchKey] = useState('');
	const [dispatchModalVisible, setDispatchModalVisible] = useState(false);
	const [selectedClass, setSelectedClass] = useState(null);
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedViewRow, setSelectedViewRow] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const globalRedux = useSelector((state) => state.globalRedux);
	const loadOut = useSelector((state) => state.loadOutRedux?.loadOut);
	const [selectedRecordToPrint, setSelectedRecordToPrint] = useState(null);
	const [tableData, setTableData] = useState(loadOut || []);
	const partNumbers = globalRedux?.partNumbers;
	const dispatch = useDispatch();

	const getRouteCard = () => {
		if (activeTab === '0') {
			dispatch(
				getApi(
					ACTIONS.GET_LOAD_OUT,
					`${SERVER_IP}stock/location?${objToQs({
						orgId: globalRedux.selectedOrganization._id,
						partNumberId: selectedPartNumber,
						...(activeTab !== '0' && {
							status: 'Dispatched',
						}),
					})}`
				)
			);
		} else {
			dispatch(
				getApi(
					ACTIONS.GET_LOAD_OUT,
					`${SERVER_IP}dispatch?${objToQs({
						orgId: globalRedux.selectedOrganization._id,
					})}`
				)
			);
		}
		// dispatch(getApi(ACTIONS.GET_LOAD_OUT, `${SERVER_IP}stock?orgId=${globalRedux.selectedOrganization._id}&partNumberId=${selectedPartNumber}`));
		// dispatch(
		// 	getApi(
		// 		ACTIONS.GET_LOAD_OUT,
		// 		`${SERVER_IP}stock?${objToQs({
		// 			orgId: globalRedux.selectedOrganization._id,
		// 			partNumberId: selectedPartNumber,
		// 			...(activeTab !== '0' && {
		// 				status: 'Dispatched',
		// 			}),
		// 		})}`
		// 	)
		// );
	};

	const refreshList = () => {
		setSelectedRowKeys([]);
		getRouteCard();
	};

	const handleGetStudents = (classId) => {
		setSelectedClass(classId);
	};

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((record) => {
			return (
				(record?.vendorId?.vendorName || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.partNumberId?.partNumber || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.invoiceNumber || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.locationId?.locationName || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.remarks || '')?.toLowerCase().includes(searchKey.toLowerCase())
			);
		});
	}, [tableData, searchKey]);
	
	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_UNLOAD === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_UNLOAD'));
			doIt = true;
		}
		if (doIt) {
			getRouteCard();
		}
	}, [globalRedux.apiStatus]);

	const column = [
		{
			title: 'Vendor Name',
			dataIndex: 'vendorId',
			key: 'vendorId',
			sorter: (a, b) => a?.vendorId?.vendorName?.localeCompare(b?.vendorId?.vendorName),
			width: '17%',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.vendorName || ''}
				/>
			),
		},
		{
			title: 'Part Number',
			dataIndex: 'partNumberId',
			key: 'partNumberId',
			sorter: (a, b) => a?.partNumberId?.partNumber?.localeCompare(b?.partNumberId?.partNumber),
			width: '17%',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.partNumber || ''}
				/>
			),
		},
		{
			title: 'Invoice Number',
			dataIndex: 'invoiceNumber',
			sorter: (a, b) => a?.invoiceNumber - b?.invoiceNumber,
			key: 'invoiceNumber',
			width: '17%',
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value || ''} />
			),
		},
		{
			title: 'Invoice Date',
			dataIndex: 'invoiceDate',
			sorter: (a, b) => new Date(a.invoiceDate) - new Date(b.invoiceDate),
			key: 'invoiceDate',
			width: '17%',
			render: (value) => <span>{moment(value).format(DATE_FORMAT.DD_MM_YYYY)}</span>,
		},
		{
			title: 'Location',
			dataIndex: 'locationId',
			key: 'locationId',
			sorter: (a, b) => a?.locationId?.locationName?.localeCompare(b?.vendorId?.locationName),
			width: '17%',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.locationName || ''}
				/>
			),
		},
		{
			title: 'Stock Qty',
			dataIndex: 'stockQty',
			key: 'stockQty',
			align: 'right',
			sorter: (a, b) => a?.stockQty?.localeCompare(b?.stockQty),
			width: '17%',
			render: (value) => <span>{value}</span>,
		},
		{
			title: 'Action',
			align: 'center',
			dataIndex: 'loadNumber',
			width: '5%',
			render: (value, row) => {
				return (
					<Row gutter={10} justify="center">
						<Col
							onClick={() => {
								setBinModal(true);
								setQuantity(row?.stockQty);
								setSelectedRecordToPrint(row);
							}}
							className="edit_icon">
							<PrinterOutlined />
						</Col>
					</Row>
				);
			},
		},
	];

	const getDispatchDetails = async (record, isRowView) => {
		const toastId = toast.loading('Fetching details...');
		const {
			data: { data },
		} = await sendGetRequest(null, `${SERVER_IP}dispatch/detail?orgId=${globalRedux.selectedOrganization._id}&dispatchId=${record?._id}`);
		if (data?.length) {
			toast.dismiss(toastId);
			isRowView
				? handleViewRow(data)
				: setSelectedRecordToPrint({
						commonData: record,
						dispatchDetails: data,
				  });
		} else {
			toast.error('Sorry no data found!');
		}
	};

	const handleSelectRow = (row) => {
		setSelectedRow(row);
	};

	const handleViewRow = (row) => {
		setSelectedViewRow(row);
	};

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage === 0 ? 1 : currentPage);
		setPageSize(pageSize);
	};

	const handleDispatchQtySubmit = (id, stockSummary) => {
		const data = tableData?.map((data) => {
			return {
				...data,
				...(data?._id === id && { stockSummary }),
			};
		});
		setTableData(data);
		setSelectedRow(null);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const onSelectChange = (newSelectedRowKeys, b, c) => {
		if (activeTab === '0') {
			const oldSelectedRowKeys = selectedRowKeys;
			const newId = newSelectedRowKeys.filter((letter) => !oldSelectedRowKeys.includes(letter))?.[0];
			const row = tableData.find((row) => row._id === newId);
			setSelectedRow(row);
		}
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		hideSelectAll: true,
		// getCheckboxProps: (record) => {
		// 	if (badStatuses.includes(record.status))
		// 		return {
		// 			disabled: true,
		// 		};
		// },
	};

	const handleAfterPrint = () => {
		setSelectedRecordToPrint(null);
		setBinNumber('');
		setQuantity('');
	};

	useEffect(() => {
		getRouteCard(selectedPartNumber);
		// setSelectedRowKeys([]);
	}, [selectedPartNumber, activeTab]);

	useEffect(() => {
		setTableData(loadOut);
	}, [loadOut]);

	useEffect(() => {
		selectedRecordToPrint && !binModal && handlePrint();
	}, [selectedRecordToPrint, binModal]);

	useEffect(() => {
		selectedRow &&
			setState({
				...state,
				visible: true,
			});
	}, [selectedRow]);

	useEffect(() => {
		!state?.visible && setSelectedRow(null);
	}, [state?.visible]);

	const reactToPrintContent = React.useCallback(() => {
		return componentRef.current;
	}, [componentRef.current]);

	const handlePrint = useReactToPrint({
		content: reactToPrintContent,
		onAfterPrint: handleAfterPrint,
		documentTitle: 'Receipt',
		removeAfterPrint: true,
	});

	const handleSubmitBin = () => {
		setBinModal(false);
	};

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_LOAD_OUT === API_STATUS.PENDING, [globalRedux.apiStatus]);

	return (
		<>
			<div style={{ display: 'none' }}>
				<RouteCardToPrint ref={componentRef} data={{ ...selectedRecordToPrint, binNumber, quantity }} />
			</div>
			<RouteCardPresentational
				{...{
					column,
					filteredData,
					handleTableChange,
					getStartingValue,
					getEndingValue,
					pageSize,
					intialPageSizeOptions,
					initialPageSize,
					currentPage,
					setSearchKey,
					tableLoading,
					rowSelection: activeTab === '0' ? rowSelection : null,
					handleGetStudents,
					state,
					setState,
					setSelectedRow,
					editData: selectedRow,
					partNumbers,
					selectedPartNumber,
					setSelectedPartNumber,
					handleDispatchQtySubmit,
					dispatchModalVisible,
					setDispatchModalVisible,
					selectedRowKeys,
					refreshList,
					activeTab,
					setActiveTab,
					selectedViewRow,
					setSelectedViewRow,
					binModal,
					setBinModal,
					binNumber,
					setBinNumber,
					handleSubmitBin,
					quantity,
					setQuantity,
					setSelectedRecordToPrint,
				}}
			/>
		</>
	);
});

export default RouteCardFunctional;
