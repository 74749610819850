import React, { useState, useMemo } from 'react';
import { Layout, Menu } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router';
import { MENUS } from 'constants/app-constants';
import './sidebar.scss';

const { Sider } = Layout;
const { SubMenu } = Menu;

const allSubMenus = MENUS.filter((menu) => menu.submenus).map((menu) => menu.subName);

function Sidebar() {
	const [collapsed, setCollapsed] = useState(false);
	const [openKeys, setOpenKeys] = useState([]);
	const history = useHistory();
	const { pathname } = useLocation();
	// const globalRedux = useSelector((state) => state.globalRedux);

	/**
	 * @description This function is used to set the open keys of the menu
	 */
	const onOpenChange = (keys) => {
		if(keys) {
			const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
			setOpenKeys([latestOpenKey]);
			// if (allSubMenus.indexOf(latestOpenKey) === -1) {
			// 	setOpenKeys(keys);
			// } else {
			// 	setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
			// }
		}
		else {
			setOpenKeys([]);
		}
	};

	/**
	 * @description: This function is to get active menu from route
	 */
	const { selectedKey } = useMemo(() => {
		let selectedKey = '1';
		MENUS.find((menu) => {
			const hasSubMenu = !!menu?.submenus;
			if (menu.route === pathname) {
				selectedKey = menu?.key;
				return selectedKey;
			}
			if (hasSubMenu) {
				const subMenu = menu.submenus.find((submenu) => submenu.route === pathname);
				selectedKey = subMenu?.key;
				return selectedKey;
			}
		});
		return { selectedKey };
	}, [pathname]);

	return (
		<Layout style={{}}>
			<Sider breakpoint="md" collapsedWidth={80} collapsible collapsed={collapsed} onCollapse={() => setCollapsed(!collapsed)}>
				<Menu theme="light" mode="inline" selectedKeys={[selectedKey]} openKeys={openKeys} onOpenChange={onOpenChange}>
					{MENUS.map((menu, index) => {
						const hasSubMenu = !!menu?.submenus;
						const Icon = menu?.icon || <MailOutlined />;
						if (hasSubMenu) {
							return (
								<SubMenu
									{...{
										key: menu?.subName,
										icon: Icon,
										title: menu?.name,
									}}>
									{menu?.submenus.map((submenu, index) => {
										return (
											<Menu.Item icon={Icon} onClick={() => submenu?.route && history.push(submenu?.route)} key={submenu.key}>
												{submenu?.name}
											</Menu.Item>
										);
									})}
								</SubMenu>
							);
						} else {
							return (
								<Menu.Item key={menu?.key} icon={Icon} onClick={() => {
									onOpenChange();
									menu?.route && history.push(menu?.route)
								}}>
									{menu?.name}
								</Menu.Item>
							);
						}
					})}
				</Menu>
			</Sider>
		</Layout>
	);
}

export default Sidebar;
