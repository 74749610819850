let initialState = {
	suppliers: [
		{
			createdAt: '',
			displayName: '',
			email: '',
			supplierType: '',
			supplierNumber: '',
			outstandingBalance: 0,
			gstin: '',
		},
	],
};

const supplierReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'LOGOUT': {
			return initialState;
		}

		case 'GET_SUPPLIERS':
			return {
				...state,
				suppliers: action.payload,
			};
		default:
			return state;
	}
};

export { supplierReducer };
