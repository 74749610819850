import React, { useEffect } from 'react';
import { Form, Input, Button, Row, Col, Tabs } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import Slider from 'react-slick';
import { postApi } from 'redux/sagas/postApiDataSaga';
import './login.scss';

const { TabPane } = Tabs;


const settings = {
	dots: true,
	// fade: true,
	speed: 2000,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay: true,
	autoplaySpeed: 2500,
	infinite: true,
	pauseOnHover: false,
};


const Login = (props) => {
	const loginRedux = useSelector((state) => state.loginRedux);
	const globalRedux = useSelector((state) => state.globalRedux);
	const dispatch = useDispatch();
	const [form] = Form.useForm();

	const FormProps = {
		labelCol: {
			span: 8,
		},
		wrapperCol: {
			span: 24,
		},
	};

	useEffect(() => {
		if (!!loginRedux.token && loginRedux.isLogged) {
			props.history.push('/organization');
		}
	}, [loginRedux.token, loginRedux.isLogged, props.history]);

	const login = (data) => {
		dispatch(postApi(data, 'LOGIN'));
	};

	return (
		<Row justify="center" align="middle" className="login_container" style={{ height: '100vh', backgroundColor: '#fff' }}>
			<Col xl={24} md={24} className="login_area">
				<Row style={{ height: '100%' }}>
					<Col xl={16} md={16} className="left_area">
						<div class="content-left">
							<Col xl={15} md={15} className="slider_area">
								{/* <Slider {...settings}>
									<div className="slider_item">
										<img src="https://accounts.zoho.com/v2/components/images/passwordless_illustration2x.png" alt="login_image" />
										<div className="title">Passwordless sign-in</div>
										<p className="para">
											Move away from risky passwords and experience one-tap access.
										</p>
									</div>
									<div className="slider_item">
										<img src="https://accounts.zoho.com/v2/components/images/mfa_illustration2x.png" alt="login_image" />
										<div className="title">MFA for all accounts</div>
										<p className="para">
											Secure online accounts with OneAuth 2FA. Back up OTP secrets and never lose access to your accounts.
										</p>
									</div>
								</Slider> */}
							</Col>
							{/* <div class="shipping-head">
								<span class="logo-wrapper">
									<svg width="169" height="51" viewBox="0 0 169 51" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" clip-rule="evenodd" d="M137.044 45.5205L135.333 45.5302L132.411 45.5173V34.6541L135.334 34.6668H137.044V45.5205ZM135.343 30.0222L132.41 30.0095V30.0056H131.473L130.214 30V30.0056L128 32.3456V47.8331L130.196 50.148V50.1564L135.324 50.1787L139.26 50.1564L141.452 47.8331V32.3456L139.248 30.0222H135.343Z" fill="#ED4136"></path>
										<path fill-rule="evenodd" clip-rule="evenodd" d="M151.312 30.0504V40.1603L147.365 30H142.966V50.0259H147.365V44.6829V39.3716L151.312 50.0259H155.71V30.0504H151.312Z" fill="#ED4136"></path>
										<path d="M147.368 45.8096H142.966V50.1784H147.368V45.8096Z" fill="#ED4136"></path>
										<path fill-rule="evenodd" clip-rule="evenodd" d="M163.629 34.6465L168.981 34.6687L169 30.0221L163.637 30L159.522 30.0221L157.229 32.3455V47.8331L159.535 50.1564H163.618L168.981 50.1787L169 45.5324L163.628 45.51H161.84V42.4743L166.897 42.4744L166.898 37.8279L161.84 37.8277V34.6562L163.629 34.6465Z" fill="#ED4136"></path>
										<path fill-rule="evenodd" clip-rule="evenodd" d="M159 0.0117188H153.205V10.1768L150.85 13.1128L148.496 10.1768V0.0117188H142.7V12.3229L147.953 18.8728V25.8798H153.748V18.8728L159 12.3229V0.0117188Z" fill="#3D445C"></path>
										<path fill-rule="evenodd" clip-rule="evenodd" d="M82.4879 25.9375H76.6926L76.6928 0.143555H82.4883L82.4879 25.9375Z" fill="#3D445C"></path>
										<path fill-rule="evenodd" clip-rule="evenodd" d="M131.871 25.7787H126.076V2.98446L128.974 0H139.756V5.96912H131.871V25.7787Z" fill="#3D445C"></path>
										<path fill-rule="evenodd" clip-rule="evenodd" d="M113.166 19.9241V16.0244L119.522 16.0246L119.522 10.0557L113.166 10.0555V5.98139L115.414 5.96891L122.141 5.99745L122.165 0.0283308L115.425 0L110.253 0.0283308L107.371 3.013V22.9084L110.268 25.8928H115.401L122.141 25.9216L122.165 19.9529L115.413 19.9241H113.166Z" fill="#3D445C"></path>
										<path fill-rule="evenodd" clip-rule="evenodd" d="M97.1464 16.3851L94.792 19.3212L92.4379 16.3851V0.143555H86.6423V18.5311L92.5354 25.8806H97.0489L102.942 18.5311V0.143555H97.1464V16.3851Z" fill="#3D445C"></path>
										<path fill-rule="evenodd" clip-rule="evenodd" d="M11.8856 19.9942L9.63732 20.0067L5.79651 19.9902V6.03516L9.63896 6.05144H11.8856V19.9942ZM9.64986 0.0851828L5.7955 0.0689032V0.063829H4.56463L2.90966 0.0566406V0.063829H0V25.9389H2.88584V25.9497L9.62604 25.9784L14.7979 25.9497L17.6798 22.9652V3.06985L14.7823 0.0851828H9.64986Z" fill="#3D445C"></path>
										<path fill-rule="evenodd" clip-rule="evenodd" d="M29.4459 5.96891L36.1724 5.99745L36.1962 0.0283308L29.4562 0L24.2841 0.0283308L21.402 3.013V22.9084L24.2999 25.8928H29.4324L36.1724 25.9216L36.1962 19.9529L29.4443 19.9241H27.1977V16.0244L33.5536 16.0246L33.5538 10.0557L27.1977 10.0555V5.98139L29.4459 5.96891Z" fill="#3D445C"></path>
										<path fill-rule="evenodd" clip-rule="evenodd" d="M45.2638 0.078125H39.4685V22.9373L42.366 25.9217H53.148V19.953H45.2638V0.078125Z" fill="#3D445C"></path>
										<path fill-rule="evenodd" clip-rule="evenodd" d="M66.9786 0.143243V10.0558H61.7784V0.078125H55.9832V25.9371H61.7784V16.0245L66.9786 16.0248V25.9371H72.7741V0.143243H66.9786Z" fill="#3D445C"></path>
										<path fill-rule="evenodd" clip-rule="evenodd" d="M0.0112305 25.9371H5.78435V19.9912H0.0112305V25.9371Z" fill="#ED4136"></path>
										<path fill-rule="evenodd" clip-rule="evenodd" d="M153.218 5.95954H158.991V0.0136719H153.218V5.95954Z" fill="#ED4136"></path>
									</svg>
								</span>
								<div class="shipping-subhead">Login securely and get to access all Delhivery’s services</div>
							</div>
							<div class="icon-wrap">
								<div class="img-contain"><img src="/brewmaster/resources/272l4/login/ucp/img/ucp/truck-bolt.svg" /></div>
								<div class="text-contain">Ship to 99.5% of India’s population</div>
							</div>
							<div class="icon-wrap">
								<div class="img-contain"><img src="/brewmaster/resources/272l4/login/ucp/img/ucp/money-bill-wave-duotone.svg" /></div>
								<div class="text-contain">Assured COD remittance in 2 days at zero extra charges</div>
							</div>
							<div class="icon-wrap">
								<div class="img-contain"><img src="/brewmaster/resources/272l4/login/ucp/img/ucp/wallet.svg" /></div>
								<div class="text-contain">Ship Internationally to 220+ countries</div>
							</div>
							<div class="icon-wrap">
								<div class="img-contain"><img src="/brewmaster/resources/272l4/login/ucp/img/ucp/cart-circle-check-duotone.svg" /></div>
								<div class="text-contain">Integrate with sales channels in one click</div>
							</div>
							<div class="icon-wrap">
								<div class="img-contain"><img src="/brewmaster/resources/272l4/login/ucp/img/ucp/box-circle-check.svg" /></div>
								<div class="text-contain">Reduce order returns by 40% with RTO predictor &amp; address validation</div>
							</div> */}

							{/* <div class="customer-wrapper">
								<div class="customer-head">India’s eCommerce runs on Delhivery. Trusted by the thousands of
									businesses</div>
								<div class="customer-logo">
									<span class="logo-container">
										<img src="/brewmaster/resources/272l4/login/ucp/img/ucp/nykaa.svg" />
									</span>
									<span class="logo-container">
										<img src="/brewmaster/resources/272l4/login/ucp/img/ucp/amazone.svg" />
									</span>
									<span class="logo-container">
										<img src="/brewmaster/resources/272l4/login/ucp/img/ucp/myglamm.svg" />
									</span>
									<span class="logo-container">
										<img src="/brewmaster/resources/272l4/login/ucp/img/ucp/flipkart.svg" />
									</span>
									<br />
									<span class="logo-container">
										<img src="/brewmaster/resources/272l4/login/ucp/img/ucp/nestasia.png" />
									</span>
									<span class="logo-container">
										<img src="/brewmaster/resources/272l4/login/ucp/img/ucp/meesho.svg" />
									</span>
									<span class="logo-container">
										<img src="/brewmaster/resources/272l4/login/ucp/img/ucp/fp.svg" />
									</span>
								</div>
							</div> */}
						</div>
					</Col>
					<Col xl={8} md={8} className="right_area">
						{/* <LogoComponent style={{ fontSize: 30 }} /> */}
						<Col span={24}>
							<div style={{ fontWeight: 'bold', fontSize: 30, color: '#000' }}>
								Login to your account
								{/* <span style={{ color: '#1979ba' }}>back!</span> */}
							</div>
							{/* <div style={{ color: 'lightgray' }}>Welcome back! Please enter your details.</div> */}
						</Col>
						<Col xl={24} style={{ paddingTop: 20, }}>
							<Tabs defaultActiveKey="LOGIN_WITH_PASSWORD">
								{/* <TabPane tab={'Login with OTP'} key={'LOGIN_WITH_OTP'}>
									<LoginWithOTP />
								</TabPane> */}
								<TabPane tab={'Login with Password'} key={'LOGIN_WITH_PASSWORD'}>
									<Form
										requiredMark={false}
										colon={false}
										layout="vertical"
										labelAlign="left"
										form={form}
										onFinish={login}
										{...FormProps}>
										<Form.Item
											label="Email / Mobile"
											name="mobile"
											hasFeedback
											className="hideFormLabel"
											rules={[
												{
													required: true,
													message: 'Please enter your Mobile',
												},
											]}>
											<Input placeholder='Enter Email / Mobile' />
										</Form.Item>
										<Form.Item
											label="Password"
											name="password"
											hasFeedback
											className="hideFormLabel"
											rules={[
												{
													required: true,
													message: 'Please enter your Password',
												},
											]}>
											<Input.Password placeholder="Password" visibilityToggle />
										</Form.Item>
										<Form.Item>
											<Button
												style={{ width: '100%', fontWeight: 'bold' }}
												type="primary"
												htmlType="submit"
												disabled={globalRedux.apiStatus.LOGIN === 'PENDING'}
												loading={globalRedux.apiStatus.LOGIN === 'PENDING'}>
												LOGIN
											</Button>
										</Form.Item>
									</Form>
								</TabPane>
							</Tabs>
						</Col>
					</Col>

				</Row>
			</Col>
		</Row>
	);
};

export default Login;
