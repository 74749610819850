import React, { forwardRef } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { DATE_FORMAT } from "constants/app-constants";

const ComponentToPrint = forwardRef((props, ref) => {
	const { firstName = "", lastName = "" } = useSelector(
		(state) => state?.loginRedux
	);
	const {
		studentId: {
			admissionNumber,
			studentName,
			section,
			primaryMobile,
			classId: { className } = {},
		} = {},
		receiptNumber,
		receiptDate,
		loadDetails,
		loadNumber,
		vehicleId,
	} = props?.data || {};
	return (
		<div class="invoice-pdf" ref={ref}>
			<div className="invoice-pdf-container">
				<table cellPadding="0" cellSpacing="0">
					<tr class="top">
						<td colSpan="6" style={{ padding: 0, paddingTop: 0 }}>
							<table className="bordered-bottom">
								<tr>
									<td class="title">
										<div className="left">
											{/* <div className="image">
											<img src="https://png2.cleanpng.com/sh/61554d2de5d6be188f3141549aa288ee/L0KzQYm3VsMxN5Rtj5H0aYP2gLBuTgRpcaN6e9pubnT4gn70lgJ2b5JzReZubYDvdX77iPlzfaF1eeRqbXv4fsPojb1ufaN6RadrM0e8QLO3VchibpI6RqgCNUK6RIqBUcU0OGQ7UKMCNkO5Q4S1kP5o/kisspng-thiruchendur-murugan-temple-thirupparamkunram-muru-5b3790b058afa5.6752749815303681763633.png" />
										</div> */}
											<div className="content">
												<div className="name" style={{ color: "#000" }}>
													MMT SUPPLY CHAIN SYSTEMS
												</div>
												{/* <div>LORRY TRANSPORTS</div> */}
												<div className="address">
													36/71, Vivekanantha Nagar, Thekkupalayam Post,{" "}
												</div>
												<div className="address">Coimbatore - 641620.</div>
												<div className="contact">
													<span>Email: tmttransportcbe@gmail.com</span>
													<div>Contact: 9659534566</div>
													<div>www.tmttransport.in</div>
													<div>GSTIN : 33BCGPS9280R2ZL</div>
												</div>
											</div>
										</div>
										<div className="right">
											<div className="content">
												<div className="tax-invoice">RECEIVED</div>
												<div className="copy">(Orginal Copy)</div>
											</div>
											{/* <div>GSTIN : 33BCGPS9280R2ZL</div>
										<div>Mobile : 9659534566</div> */}
										</div>
									</td>
								</tr>
							</table>
						</td>
					</tr>
					<tr class="information">
						<td colSpan="6">
							<table className="bordered-bottom">
								<tr>
									<td>
										<div className="toArea">
											<div className="details" style={{ fontWeight: "bold" }}>
												<div>{`Receipt No : ${loadNumber || ""}`}</div>
												<div>{`Recieved Date: ${moment(
													props?.data?.loadDate
												).format(DATE_FORMAT.DD_MM_YYYY)}`}</div>
											</div>
										</div>
									</td>
									<td />
									<td className="no_padding">
										<div>
											<div className="row" style={{ fontWeight: "bold" }}>
												<div className="label">Vehicle No:</div>
												<div className="value" style={{ fontWeight: "bold" }}>
													{vehicleId?.regNumber}
												</div>
											</div>
										</div>
									</td>
								</tr>
							</table>
						</td>
					</tr>
					<tr class="heading">
						<td>Supplier Name</td>
						<td>LR No</td>
						<td>Invoice No</td>
						<td>Part No</td>
						<td>Total Qty</td>
						<td>Total Weight</td>
					</tr>

					{loadDetails?.map((data) => (
						<tr class="item">
							<td>{data?.vendorId?.vendorName}</td>
							<td>{data?.lrNumber}</td>
							<td>{data?.invoiceNumber}</td>
							<td>{data?.partNumberId?.partNumber}</td>
							<td>{data?.invoiceQty?.toString()}</td>
							<td>{data?.invoiceWeight?.toString()}</td>
						</tr>
					))}
					<tr class="total" style={{ fontWeight: "bold" }}>
						<td />
						<td />
						<td />
						<td style={{ textAlign: "right" }}>Total: </td>
						<td>
							{loadDetails?.reduce(
								(acc, item) => acc + (parseInt(item?.invoiceQty) || 0),
								0
							)}
						</td>
						<td>
							{loadDetails?.reduce(
								(acc, item) => acc + (parseInt(item?.invoiceWeight) || 0),
								0
							)}
						</td>
					</tr>
				</table>
				<div className="signArea">
					<div className="left">
						{/* <span className="title">Document Enclosed</span>
						<div className="paragraph">
							<div>1. All payments to be made by crossed Cheque/DD in favour of the MMT SUPPLY CHAIN SYSTEMS</div>
							<div>
								2. Interest will be charged at 18% per annum on outstanding after one week from the date of this bill until
								realisation
							</div>
						</div> */}
					</div>
					<div className="sign">
						<span style={{ color: "#000" }}>Authorize Signature</span>
					</div>
				</div>
			</div>
		</div>
	);
});

export default ComponentToPrint;
