import React from 'react';
import { Drawer } from 'antd';
import AddAsset from 'pages/master-pages/AddAsset';
import AddAccountBook from 'pages/master-pages/AddAccountBook';
import AddVoucher from 'pages/master-pages/AddVoucher';
import AddVehicle from 'pages/master-pages/AddVehicle';
import AddBatch from 'pages/master-pages/AddBatch';
import AddClass from 'pages/master-pages/AddClass';
import AddPartNumber from 'pages/master-pages/AddPartNumber';
import AddVendor from 'pages/master-pages/AddVendor';
import AddCredential from 'pages/master-pages/AddCredential';
import AddLocation from 'pages/master-pages/AddLocation';
import AddVendorMapping from 'pages/master-pages/AddVendorMapping';
import AddApplicationUsers from 'pages/master-pages/AddApplicationUsers';

const AddDrawer = ({
	customerAddModal,
	setCustomerAddModal = null,
	supplierAddModal,
	setSupplierAddModal = null,
	agentAddModal,
	setAgentAddModal = null,
	getAgents = null,
	refreshList,
	staffsAddModal,
	setStaffsAddModal,
	getStaffs,
	assetsAddModal,
	setAssetsAddModal,
	getAssets,
	editAsset,
	editVehicle,
	accBookAddModal,
	setAccBookAddModal,
	getAccountBooks,
	editAccountBooks,
	accountBookModal,
	setAccountBookModal,
	getVouchers,
	editVoucher,
	itemsAddModal,
	setItemsAddModal,
	vehicleAddModal,
	setVehicleAddModal,
	getVehicle,
	batchAddModal,
	setBatchAddModal,
	getBatch,
	editBatch,
	classAddModal,
	setClassAddModal,
	getClass,
	editClass,
	getItems,
	partNumberAddModal,
	setPartNumberAddModal,
	getPartNumber,
	editPartNumber,
	vendorAddModal,
	setVendorAddModal,
	getVendor,
	editVendor,
	credentialAddModal,
	setCredentialAddModal,
	getCredential,
	editCredential,
	locationsAddModal,
	setLocationsAddModal,
	getLocations,
	editLocation,
	vendorAddMappingModal,
	setVendorAddMapping,
	editVendorMapping,
	applicationUsersModal,
	setApplicationUsersModal,
	editApplicationUsers,
}) => {
	const handleClose = () => {
		setCustomerAddModal?.(false);
		setSupplierAddModal?.(false);
		setAgentAddModal?.(false);
		getAgents?.();
		refreshList?.();
		getStaffs?.();
		getAssets?.();
		getAccountBooks?.();
		getVouchers?.();
		getItems?.();
		getVehicle?.();
		getBatch?.();
		getClass?.();
		getPartNumber?.();
		getVendor?.();
		getCredential?.();
		getLocations?.();
	};

	const getTitle = () => {
		let title = '';
		if (assetsAddModal) {
			title = 'New Assets';
			if(editAsset){
				title="Edit Assets"
			}
		}
		if (vehicleAddModal) {
			title = 'New Vehicle';
			if(editVehicle){
				title="Edit Vehicle"
			}
		}
		if (vendorAddModal) {
			title = 'New Vendor';
			if(editVendor){
				title="Edit Vendor"
			}
		}
		if (vendorAddMappingModal) {
			title = 'New Mapping';
			if(editVendorMapping){
				title="Edit Mapping"
			}
		}
		if (applicationUsersModal) {
			title = 'New Application User';
			if(editApplicationUsers){
				title="Edit Application User"
			}
		}
		if (credentialAddModal) {
			title = 'New Credentials';
			if(editCredential){
				title="Edit Credentials"
			}
		}
		if (partNumberAddModal) {
			title = 'New Part Number';
			if(editPartNumber){
				title="Edit Part Number"
			}
		}
		if (batchAddModal) {
			title = 'New Batch';
			if(editBatch){
				title="Edit Batch"
			}
		}
		if (classAddModal) {
			title = 'New Class';
			if(editClass){
				title="Edit Class"
			}
		}
		if (locationsAddModal) {
			title = 'New Location';
			if(editLocation){
				title="Edit Location"
			}
		}
		if (accBookAddModal) {
			title = 'New Acc Book';
			if(editAccountBooks){
				title="Edit Acc Book"
			}
		}
		if (accountBookModal) {
			title = 'New Acc Book';
			if(editVoucher){
				title="Edit Acc"
			}
		}
		return title
	};
	return (
		<Drawer
			destroyOnClose
			placement="right"
			width={'40%'}
			title={getTitle()}
			className=""
			onClose={() =>
				setCustomerAddModal?.(false) ||
				setSupplierAddModal?.(false) ||
				setAgentAddModal?.(false) ||
				setStaffsAddModal?.(false) ||
				setAssetsAddModal?.(false) ||
				setAccBookAddModal?.(false) ||
				setAccountBookModal?.(false) ||
				setItemsAddModal?.(false) ||
				setVehicleAddModal?.(false) ||
				setBatchAddModal?.() ||
				setClassAddModal?.(false) ||
				setPartNumberAddModal?.(false) ||
				setVendorAddModal?.(false) ||
				setCredentialAddModal?.(false) ||
				setLocationsAddModal?.(false) ||
				setVendorAddMapping?.(false) ||
				setApplicationUsersModal?.()
			}
			visible={
				customerAddModal ||
				supplierAddModal ||
				agentAddModal ||
				staffsAddModal ||
				assetsAddModal ||
				accBookAddModal ||
				accountBookModal ||
				itemsAddModal ||
				vehicleAddModal ||
				batchAddModal ||
				classAddModal ||
				partNumberAddModal ||
				vendorAddModal ||
				credentialAddModal ||
				locationsAddModal ||
				vendorAddMappingModal ||
				applicationUsersModal
			}>
			{assetsAddModal && <AddAsset {...{ handleClose, editAsset, setAssetsAddModal }} />}
			{vehicleAddModal && <AddVehicle {...{ handleClose, setVehicleAddModal, editVehicle }} />}
			{vendorAddModal && <AddVendor {...{ handleClose, setVendorAddModal, editVendor }} />}
			{vendorAddMappingModal && <AddVendorMapping {...{ handleClose, setVendorAddMapping, editVendorMapping }} />}
			{applicationUsersModal && <AddApplicationUsers {...{ handleClose, setApplicationUsersModal, editApplicationUsers }} />}
			{credentialAddModal && <AddCredential {...{ handleClose, setCredentialAddModal, editCredential }} />}
			{partNumberAddModal && <AddPartNumber {...{ handleClose, setPartNumberAddModal, editPartNumber }} />}
			{batchAddModal && <AddBatch {...{ handleClose, setBatchAddModal, editBatch }} />}
			{classAddModal && <AddClass {...{ handleClose, setClassAddModal, editClass }} />}
			{locationsAddModal && <AddLocation {...{ handleClose, setLocationsAddModal, editLocation }} />}
			{accBookAddModal && <AddAccountBook {...{ handleClose, editAccountBooks, setAccBookAddModal }} />}
			{accountBookModal && <AddVoucher {...{ handleClose, editVoucher, setAccountBookModal }} />}
		</Drawer>
	);
};

export default AddDrawer;
