import { SERVER_IP } from '../assets/Config';

const API_ENDPOINTS = {
	LOGIN: `${SERVER_IP}auth/login`,
	ADD_RECEIVED_ENTRY: `${SERVER_IP}load`,
	ADD_PLAN: `${SERVER_IP}plan`,
	ADD_LOAD: `${SERVER_IP}load`,
	ADD_BOOKING: `${SERVER_IP}booking`,
	REGISTER: `${SERVER_IP}auth/sign-up`,
	GET_ALL_ORGANIZATION: `${SERVER_IP}organization`,
	ADD_STUDENT: `${SERVER_IP}student`,
	ADD_VOUCHER: `${SERVER_IP}voucher`,
	ADD_RECEIPT: `${SERVER_IP}receipt`,
	ADD_LOAD_IN: `${SERVER_IP}load/add`,
	ADD_LOAD_IN_NEW: `${SERVER_IP}load/add`,
	UPDATE_LOAD_IN_NEW: `${SERVER_IP}load/stock`,
	UPDATE_LOAD_IN: `${SERVER_IP}load`,
	GET_ALL_PROJECTS: `${SERVER_IP}project`,
	ADD_PROJECT: `${SERVER_IP}project`,
	ADD_MILESTONE: `${SERVER_IP}milestone`,
	ADD_ITEM: `${SERVER_IP}item`,
	ADD_SUB_ITEM: `${SERVER_IP}subitem`,
	ADD_CUSTOMER: `${SERVER_IP}customer`,
	ADD_SUPPLIER: `${SERVER_IP}supplier`,
	ADD_AGENT: `${SERVER_IP}agent`,
	ADD_DAILY_TRANSACTION: `${SERVER_IP}voucher`,
	ADD_DAILY_PRODUCTION: `${SERVER_IP}production`,
	ADD_INVOICE: `${SERVER_IP}invoice`,
	ADD_TICKET: `${SERVER_IP}ticket`,
	ADD_ORGANIZATION: `${SERVER_IP}organization`,
	ADD_ACCOUNT_BOOK: `${SERVER_IP}accbook`,
	ADD_VOUCHER_HEAD: `${SERVER_IP}voucherhead`,
	ADD_ASSETS: `${SERVER_IP}asset`,
	ADD_LOCATION: `${SERVER_IP}location`,
	ADD_VEHICLE: `${SERVER_IP}vehicle`,
	ADD_VENDOR: `${SERVER_IP}vendor`,
	ADD_VENDOR_MAPPING: `${SERVER_IP}vendormapping`,
	ADD_APPLICATION_USERS: `${SERVER_IP}auth/sign-up`,
	ADD_CREDENTIAL: `${SERVER_IP}credential`,
	ADD_PART_NUMBER: `${SERVER_IP}partnumber`,
	ADD_BATCH: `${SERVER_IP}batch`,
	ADD_CLASS: `${SERVER_IP}class`,
	ADD_BATCH_BALANCE: `${SERVER_IP}batchbalance`,
	ADD_MATERIAL: `${SERVER_IP}material`,
	ADD_GRADE: `${SERVER_IP}grade`,
	ADD_DRIVER: `${SERVER_IP}driver`,
	ADD_GPS: `${SERVER_IP}gps`,
	ADD_RC_OWNER: `${SERVER_IP}rcowner`,
	ADD_CITY: `${SERVER_IP}city`,
	ADD_PARTY: `${SERVER_IP}customer`,
	ADD_BANK_DETAIL: `${SERVER_IP}bankdetail`,
};

export default API_ENDPOINTS;
