import React, { useRef } from 'react';
import { Row, Col, Spin, Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { BsFillPersonFill } from 'react-icons/bs';
import { AiOutlineShopping } from 'react-icons/ai';
import { FiArrowUpRight } from 'react-icons/fi';
import AnimatedNumber from 'components/animated-number';
import { DATE_FORMAT } from 'constants/app-constants';
import DatePicker from 'components/date-picker';

const DashboardPresentational = ({
	dashboardData,
	tableData,
	loginRedux,
	specificDate,
	loading,
	setSpecificDate,
	getDashboardData,
}) => {
	const ref1 = useRef(null);
	const ref2 = useRef(null);
	return (
		<Row className="dashboard_container">
			{loading && (
				<div className="dashboard_container_loader">
					<Spin />
				</div>
			)}
			<Col xl={24}>
				<Row gutter={[10, 10]}>
					<Col className="title" span={24} style={{ fontWeight: 'normal' }}>
						<Row>
							<Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
								<h4>
									Hi there, {loginRedux?.firstName} {loginRedux?.lastName}! 👋
								</h4>
								<div>
									<DatePicker
										style={{ width: 150 }}
										format={DATE_FORMAT.DD_MM_YYYY}
										value={specificDate}
										onChange={(date, dateString) => setSpecificDate(date)}
									/>
									<Button
										type="primary"
										icon={<ReloadOutlined />}
										loading={loading}
										onClick={getDashboardData}
										style={{ marginLeft: 10 }}
									/>
								</div>
								{/* <div>
									{generateGreetings()}! {loginRedux?.firstName} {loginRedux?.lastName}
								</div> */}
							</Col>
						</Row>
					</Col>

					<Col span={24} ref={ref1}>
						<Row gutter={[10, 10]}>
							<Col span={24}>
								<Row gutter={[10, 10]}>
									<Col span={4}>
										<Row className="dashboard_card">
											<Col span={24}>
												<Row align="top">
													<Col span={19}>
														<div className="value">
															<AnimatedNumber value={dashboardData?.totalVendors} />
														</div>
														<div className="name">Total Vendors</div>
													</Col>
													<Col span={5} className="icon">
														<BsFillPersonFill />
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
									<Col span={4}>
										<Row className="dashboard_card">
											<Col span={24}>
												<Row align="top">
													<Col span={19}>
														<div className="value">
															<AnimatedNumber value={dashboardData?.totalReceivables || 0} />
														</div>
														<div className="name">Total Receivables</div>
													</Col>
													<Col span={5} className="icon red">
														<FiArrowUpRight />
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
							<Col span={24}>
								<Row gutter={[10, 10]}>
									{dashboardData?.orders?.map((order, index) => (
										<Col key={index} span={4}>
											<Row className="dashboard_card">
												<Col span={24}>
													<Row align="top">
														<Col span={19}>
															<div className="value">
																<AnimatedNumber value={order?.count || 0} />
															</div>
															<div className="name">{order?._id || ''}</div>
														</Col>
														<Col span={5} className="icon">
															<AiOutlineShopping />
														</Col>
													</Row>
												</Col>
											</Row>
										</Col>
									))}
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
			{/* <Tour open={open} onClose={() => setOpen(false)} steps={steps} /> */}
		</Row>
	);
};

export default DashboardPresentational;
