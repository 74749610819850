import React, { useEffect, useState, useCallback } from 'react';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import { SERVER_IP } from 'assets/Config';
import { sendGetRequest } from 'redux/sagas/utils';

const DropdownWithAPI = ({ value, record, vendorId, handleInputChange }) => {
	const [selectedPartNumbers, setSelectedPartNumbers] = useState({ data: [], loading: false });
	const globalRedux = useSelector((state) => state.globalRedux);

	useEffect(() => {
		loadPartNumbers(vendorId);
	}, [vendorId]);

	const loadPartNumbers = useCallback(
		async (vendorId) => {
			// console.log('🚀 ~ file: add-load-in-functional.js:112 ~ vendorId:', vendorId);
			if (vendorId) {
				setSelectedPartNumbers({
					...selectedPartNumbers,
					loading: true,
				});
				const { data: { data } = { data: [] } } = await sendGetRequest(
					null,
					`${SERVER_IP}vendormapping?orgId=${globalRedux.selectedOrganization._id}&vendorId=${vendorId}`
				);
				setSelectedPartNumbers({
					loading: false,
					data: data,
				});
			}
		},
		[globalRedux.selectedOrganization._id]
	);

	return (
		<Select
			// onDropdownVisibleChange={(open) => open && loadPartNumbers(record?.vendorId)}
			allowClear
			optionFilterProp="children"
			showSearch
			filterOption={(input, option) =>
				option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
				option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
			}
			disabled={record?.status !== 'InStock' && !!record?.createdBy}
			loading={selectedPartNumbers?.loading}
			style={{ width: '100%', textAlign: 'left' }}
			onChange={(value) => handleInputChange('partNumberId', value, record?.id)}
			{...{
				...(selectedPartNumbers?.data?.length && { value }),
			}}>
			{selectedPartNumbers?.data?.map((partNumber) => (
				<Select.Option value={partNumber?.partNumberId?._id}>{partNumber?.partNumberId?.partNumber}</Select.Option>
			))}
		</Select>
	);
};

export default DropdownWithAPI;
