import React, { useEffect, useCallback } from "react";
import { Input, Button, Divider, Form, Row, Col, Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { putApi } from "redux/sagas/putApiSaga";
import { SERVER_IP } from "assets/Config";
import { ACCOUNT_TYPE, API_STATUS } from "constants/app-constants";
import { resetApiStatus } from "redux/reducers/globals/globalActions";
import { postApi } from "redux/sagas/postApiDataSaga";
import { getApi } from "redux/sagas/getApiDataSaga";

const { TextArea } = Input;

const AddBankDetail = ({
	handleClose,
	editBankDetail,
	setBankDetailAddModal,
}) => {
	const [form] = Form.useForm();
	const globalRedux = useSelector((state) => state?.globalRedux);
	const rcOwners = globalRedux?.rcOwners || [];
	const dispatch = useDispatch();

	const getRcOwners = useCallback(() => {
		let url = `${SERVER_IP}rcowner/?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(getApi("GET_RC_OWNERS", url));
	}, [dispatch, globalRedux?.selectedOrganization?._id]);

	useEffect(() => {
		getRcOwners();
	}, [getRcOwners]);

	const addBankDetail = (values) => {
		let data = {
			...values,
			orgId: globalRedux?.selectedOrganization?._id,
		};
		dispatch(postApi(data, "ADD_BANK_DETAIL"));
	};

	const handleEdit = (values) => {
		let data = {
			...values,
			orgId: globalRedux?.selectedOrganization?._id,
		};
		let url = `${SERVER_IP}bankdetail/${editBankDetail._id}?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(putApi(data, "EDIT_BANK_DETAIL", url));
	};

	useEffect(() => {
		if (
			globalRedux.apiStatus.ADD_BANK_DETAIL === "SUCCESS" ||
			globalRedux.apiStatus.EDIT_BANK_DETAIL === "SUCCESS"
		) {
			dispatch(
				resetApiStatus(editBankDetail ? "EDIT_BANK_DETAIL" : "ADD_BANK_DETAIL")
			);
			setBankDetailAddModal(false);
			handleClose();
		}
	}, [
		globalRedux.apiStatus,
		editBankDetail,
		setBankDetailAddModal,
		handleClose,
		dispatch,
	]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const loading =
		globalRedux.apiStatus.ADD_BANK_DETAIL === API_STATUS.PENDING ||
		globalRedux.apiStatus.EDIT_BANK_DETAIL === API_STATUS.PENDING;

	return (
		<Row>
			<Col span={24}>
				{/* <Row style={{ backgroundColor: '#fff', padding: '10px 0px' }}>
					<Col>
						<h6 style={{ marginBottom: '0px' }}>{editBankDetail ? 'Edit' : 'New'} Bank Detail</h6>
					</Col>
				</Row>
				<Divider /> */}
				<Row style={{ paddingTop: 0 }}>
					<Col span={24}>
						<Form
							name="add-bankDetail"
							className="required_in_right"
							labelAlign="left"
							form={form}
							onFinish={!editBankDetail ? addBankDetail : handleEdit}
							{...layer1FormCol}
						>
							<Form.Item
								label="RC owner"
								name="rcownerId"
								initialValue={editBankDetail?.rcownerId?._id}
								rules={[
									{
										required: true,
										message: "This Field is required!",
									},
								]}
							>
								<Select
									allowClear
									showSearch
									filterOption={(input, option) =>
										option.props.children
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0 ||
										option.props.value
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
									}
									placeholder="select rcowner"
								>
									{rcOwners?.map((rcOwner) => (
										<Select.Option value={rcOwner?._id}>
											{rcOwner?.rcownerName}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								label="Account Type"
								name="accountType"
								initialValue={editBankDetail?.accountType}
								rules={[
									{
										required: true,
										message: "This Field is required!",
									},
								]}
							>
								<Select placeholder="select account type">
									{ACCOUNT_TYPE.map((type) => (
										<Select.Option key={type} value={type}>
											{type}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								label="Account Holder Name"
								name="accountHolderName"
								initialValue={editBankDetail?.accountHolderName}
								rules={[
									{
										required: true,
										message: "This Field is Required!",
									},
								]}
							>
								<Input placeholder="Account Holder Name" />
							</Form.Item>
							<Form.Item
								label="Account Number"
								name="accountNumber"
								initialValue={editBankDetail?.accountNumber}
								rules={[
									{
										required: true,
										message: "This Field is Required!",
									},
								]}
							>
								<Input placeholder="Account Number" />
							</Form.Item>
							<Form.Item
								label="Bank Name"
								name="bankName"
								initialValue={editBankDetail?.bankName}
								rules={[
									{
										required: true,
										message: "This Field is Required!",
									},
								]}
							>
								<Input placeholder="Bank Name" />
							</Form.Item>
							<Form.Item
								label="Branch Name"
								name="branchName"
								initialValue={editBankDetail?.branchName}
								rules={[
									{
										required: true,
										message: "This Field is Required!",
									},
								]}
							>
								<Input placeholder="Branch Name" />
							</Form.Item>
							<Form.Item
								label="IFSC Code"
								name="ifscCode"
								initialValue={editBankDetail?.ifscCode}
								rules={[
									{
										required: true,
										message: "This Field is Required!",
									},
								]}
							>
								<Input placeholder="IFSC Code" />
							</Form.Item>
							<Form.Item
								label="Remarks"
								name="remarks"
								initialValue={editBankDetail?.remarks}
							>
								<TextArea />
							</Form.Item>
							<Form.Item
								wrapperCol={{
									offset: 0,
									span: 24,
								}}
							>
								<Row justify="space-between">
									<Button
										onClick={() => setBankDetailAddModal(false)}
										style={{ width: "49%" }}
										danger
									>
										Cancel
									</Button>
									<Button
										loading={loading}
										type="primary"
										style={{ width: "49%", marginRight: 5 }}
										htmlType="submit"
									>
										{editBankDetail ? "Update" : "Create"}
									</Button>
								</Row>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default AddBankDetail;
