import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col, Popconfirm, Tag, Tooltip, Spin } from 'antd';
import { FaTruckLoading } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { EditOutlined, PrinterOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { deleteApi } from 'redux/sagas/deleteApiSaga';
import { sendGetRequest } from 'redux/sagas/utils';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { ACTIONS, API_STATUS, DATE_FORMAT, STATUS } from 'constants/app-constants';
import { SERVER_IP } from 'assets/Config';
import { getDateFormat } from 'services/Utils';
import HighlightComponent from 'components/HighlightComponent';
import LoadInPresentational from './load-in-list-presenatational';
import ComponentToPrint from './component-to-print';

const initialPageSize = 10;
const intialPageSizeOptions = [10, 15, 20];

const LoadInFunctional = React.memo(() => {
	const componentRef = React.useRef();
	const [state, setState] = useState({
		visible: false,
	});
	const [pageLoading, setPageLoading] = useState(false);
	const [editPageDetails, setEditPageDetails] = useState(null);
	const [searchKey, setSearchKey] = useState('');
	const [activeTab, setActiveTab] = useState('0');
	const [selectedClass, setSelectedClass] = useState(null);
	const [selectedRow, setSelectedRow] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const globalRedux = useSelector((state) => state.globalRedux);
	const loadIn = useSelector((state) => state.loadInRedux?.loadIn);
	const [selectedRecordToPrint, setSelectedRecordToPrint] = useState(null);
	const [tableData, setTableData] = useState(loadIn);

	const dispatch = useDispatch();

	const getLoadIn = () =>
		activeTab === '0'
			? dispatch(getApi(ACTIONS.GET_LOAD_IN, `${SERVER_IP}load?orgId=${globalRedux.selectedOrganization._id}&status=InTransit,Halting`))
			: dispatch(getApi(ACTIONS.GET_LOAD_IN, `${SERVER_IP}load?orgId=${globalRedux.selectedOrganization._id}&status=Delivered`));

	const handleGetStudents = (classId) => setSelectedClass(classId);

	const getLoadInByClassId = async (selectedClass) => {
		if (selectedClass) {
			const {
				data: { data },
			} = await sendGetRequest(null, `${SERVER_IP}student?orgId=${globalRedux.selectedOrganization._id}&classId=${selectedClass}`);
			setTableData(data);
		}
	};

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_LOAD_IN === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_LOAD_IN'));
			doIt = true;
		}
		if (doIt) {
			getLoadIn();
		}
	}, [globalRedux.apiStatus]);

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((record) => {
			return (
				(record?.loadNumber || '')?.toString()?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.vehicleId?.regNumber || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(moment(record?.loadDate).format(DATE_FORMAT.DD_MM_YYYY)?.toString() || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.loadFrom?.vendorName || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.loadTo?.vendorName || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.status || '')?.toLowerCase().includes(searchKey.toLowerCase())
			);
		});
	}, [tableData, searchKey]);

	const column = [
		{
			title: '#',
			dataIndex: 'loadNumber',
			key: 'loadNumber',
			width: '5%',
			sorter: (a, b) => a?.loadNumber - b?.loadNumber,
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.toString()}
				/>
			),
		},
		{
			title: 'Load Date',
			dataIndex: 'loadDate',
			sorter: (a, b) => new Date(a.loadDate) - new Date(b.loadDate),
			key: 'loadDate',
			width: '10%',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={moment(value).format(DATE_FORMAT.DD_MM_YYYY)?.toString()}
				/>
			),
		},
		{
			title: 'Vehicle',
			dataIndex: 'vehicleId',
			key: 'vehicleId',
			sorter: (a, b) => a?.vehicleId?.regNumber?.localeCompare(b?.vehicleId?.regNumber),
			width: '13%',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.regNumber || ''}
				/>
			),
		},
		{
			title: 'Driver Name',
			dataIndex: 'driverName',
			sorter: (a, b) => a?.driverName?.localeCompare(b?.driverName),
			key: 'driverName',
			width: '10%',
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value || ''} />
			),
		},
		{
			title: 'Load From',
			dataIndex: 'loadFrom',
			sorter: (a, b) => a?.loadFrom?.vendorName?.localeCompare(b?.loadFrom?.vendorName),
			key: 'loadFrom',
			width: '15%',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.vendorName || ''}
				/>
			),
		},
		{
			title: 'Load To',
			dataIndex: 'loadTo',
			sorter: (a, b) => a?.loadTo?.vendorName?.localeCompare(b?.loadTo?.vendorName),
			key: 'loadTo',
			width: '15%',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.vendorName || ''}
				/>
			),
		},
		{
			title: 'Status',
			dataIndex: 'status',
			sorter: (a, b) => a?.status?.localeCompare(b?.status),
			key: 'status',
			width: '8%',
			render: (value) => <Tag color={STATUS[value]}>{value}</Tag>,
		},
		{
			title: 'Created Date',
			dataIndex: 'createdAt',
			align: 'left',
			render: (value) => getDateFormat(value),
		},
		{
			title: 'Created By',
			dataIndex: 'createdBy',
			align: 'left',
			render: (value) => value?.firstName,
		},
		{
			title: 'Action',
			align: 'center',
			dataIndex: 'loadNumber',
			width: '10%',
			render: (value, row, index) => {
				return (
					<Row gutter={10} justify="center">
						<Col onClick={() => handleSelectRow(row)} className="edit_icon">
							{activeTab === '0' ? <FaTruckLoading /> : <EditOutlined />}
						</Col>
						{activeTab !== '0' && (
							<Col
								onClick={() =>
									activeTab === '0' ? handleSelectRow(row) : row?.loadDetails?.length === 0 ? null : setSelectedRecordToPrint(row)
								}
								className="edit_icon">
								{row?.loadDetails?.length === 0 ? (
									<Tooltip placement="left" title={'sorry no load details'}>
										<PrinterOutlined />
									</Tooltip>
								) : (
									<>{pageLoading && selectedRecordToPrint?._id === row?._id ? <Spin size="small" /> : <PrinterOutlined />}</>
								)}
							</Col>
						)}
						{/* <Col className="delete_icon">
							<Col style={{ padding: 0 }}>
								<Popconfirm
									title={`Are You Sure to Delete ${value}?`}
									okText="Delete"
									cancelText="No"
									onConfirm={() => {
										let url = `${SERVER_IP}load/${row._id}?orgId=${globalRedux?.selectedOrganization?._id}`;
										dispatch(deleteApi('DELETE_LOAD_IN', url));
									}}>
									<DeleteOutlined />
								</Popconfirm>
							</Col>
						</Col> */}
					</Row>
				);
			},
		},
	];

	const handleSelectRow = (row) => {
		setSelectedRow(row);
	};

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage === 0 ? 1 : currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const onSelectChange = (selectedRowKeys) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	};

	const handleAfterPrint = () => {
		setSelectedRecordToPrint(null);
	};

	useEffect(() => {
		getLoadIn();
	}, [activeTab]);

	useEffect(() => {
		setTableData(loadIn);
	}, [loadIn]);

	useEffect(() => {
		getLoadInByClassId(selectedClass);
	}, [selectedClass]);

	useEffect(() => {
		selectedRecordToPrint && getDetails();
	}, [selectedRecordToPrint]);

	useEffect(() => {
		editPageDetails && handlePrint();
	}, [editPageDetails]);

	// useEffect(() => {
	// 	selectedRecordToPrint && handlePrint();
	// }, [selectedRecordToPrint]);

	useEffect(() => {
		selectedRow &&
			setState({
				...state,
				visible: true,
			});
	}, [selectedRow]);

	useEffect(() => {
		!state?.visible && setSelectedRow(null);
	}, [state?.visible]);

	const reactToPrintContent = React.useCallback(() => {
		return componentRef.current;
	}, [componentRef.current]);

	const handlePrint = useReactToPrint({
		content: reactToPrintContent,
		documentTitle: 'Receipt',
		onAfterPrint: handleAfterPrint,
		removeAfterPrint: true,
	});

	const getDetails = async () => {
		await setPageLoading(true);
		try {
			const {
				data: { data: response = [] },
			} = await sendGetRequest(null, `${SERVER_IP}load/${selectedRecordToPrint?._id}?orgId=${globalRedux.selectedOrganization._id}`);
			const result = response?.[0] || null;
			if (result) {
				await setEditPageDetails(result);
				await setPageLoading(false);
			}
		} catch (err) {
			await setEditPageDetails(null);
			await setPageLoading(false);
		}
	};

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_LOAD_IN === API_STATUS.PENDING, [globalRedux.apiStatus]);

	return (
		<>
			<div style={{ display: 'none' }}>
				<ComponentToPrint ref={componentRef} data={editPageDetails} />
			</div>
			<LoadInPresentational
				{...{
					column,
					filteredData,
					handleTableChange,
					getStartingValue,
					getEndingValue,
					pageSize,
					intialPageSizeOptions,
					initialPageSize,
					currentPage,
					setSearchKey,
					refreshList: getLoadIn,
					tableLoading,
					rowSelection,
					handleGetStudents,
					state,
					setState,
					editData: selectedRow,
					activeTab,
					setActiveTab,
					handleSelectRow,
				}}
			/>
		</>
	);
});

export default LoadInFunctional;
