import React from 'react';
import { debounce } from 'lodash';
import { Pagination, Row, Col, Select, DatePicker, Input, Button } from 'antd';
import TableComponent from 'components/table-component';
import ExcelDownload from 'components/excel-download';
import PdfDownload from 'components/pdf-download';
import DispatchedDetailsModal from './dispatch-list';

const { RangePicker } = DatePicker;

const StockPresentational = ({
	column,
	filteredData,
	handleTableChange,
	getStartingValue,
	getEndingValue,
	pageSize,
	intialPageSizeOptions,
	initialPageSize,
	currentPage,
	tableLoading,
	partNumbers,
	vendors,
	handleFilterOptions,
	exportData,
	extraFields,
	vehicles,
	getStock,
	dispatchedDetails,
	setDispatchedDetails,
	selectedViewRow,
	setSelectedViewRow,
}) => {
	return (
		<Row style={{ padding: '20px 10px' }}>
			<Col xl={24} md={24}>
				<TableComponent
					scroll={{ x: 1300 }}
					loading={tableLoading}
					className="custom-table"
					style={{ width: '100%' }}
					rowKey={(record) => record._id}
					dataSource={filteredData}
					title={() => (
						<Row justify="space-between">
							<Col xl={24} md={24}>
								<Row gutter={[10, 10]} style={{ width: '100%' }}>
									<Col xl={4} md={4}>
										<h6>Vendor</h6>
										<Select
											showSearch
											allowClear
											placeholder="Select Vendor"
											optionFilterProp="children"
											style={{ width: '100%' }}
											filterOption={(input, option) =>
												option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
												option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
											onChange={(value) => handleFilterOptions('vendorId', value)}>
											{vendors?.map((vendor) => (
												<Select.Option value={vendor._id} key={vendor._id}>
													{vendor?.vendorName}
												</Select.Option>
											))}
										</Select>
									</Col>
									<Col xl={6} md={6}>
										<h6>Partnumber</h6>
										<Select
											showSearch
											allowClear
											optionFilterProp="children"
											filterOption={(input, option) =>
												option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
												option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
											style={{ width: '100%' }}
											placeholder="Select Partnumber"
											onChange={(value) => handleFilterOptions('partNumberId', value)}>
											{partNumbers?.map((partNumber) => (
												<Select.Option value={partNumber._id}>{partNumber?.partNumber}</Select.Option>
											))}
										</Select>
									</Col>
									<Col xl={6} md={6}>
										<h6>Vehicle</h6>
										<Select
											showSearch
											allowClear
											maxTagCount="responsive"
											optionFilterProp="children"
											style={{ width: '100%' }}
											onChange={(value) => handleFilterOptions('vehicleId', value)}
											placeholder="Select Vehicle">
											{vehicles?.map((vehicle) => (
												<Select.Option value={vehicle?._id}>{vehicle?.regNumber}</Select.Option>
											))}
										</Select>
									</Col>
									<Col xl={4} md={4}>
										<h6>LR Number</h6>
										<Input
											placeholder="LR Number"
											onChange={debounce(({ target: { value } }) => handleFilterOptions('lrNumber', value), 500)}
										/>
									</Col>
									<Col xl={4} md={4}>
										<h6>Status</h6>
										<Select
											showSearch
											allowClear
											maxTagCount="responsive"
											mode="multiple"
											placeholder="Select Status"
											optionFilterProp="children"
											style={{ width: '100%' }}
											// filterOption={(input, option) =>
											// 	option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
											// 	option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
											// }
											onChange={(value) => handleFilterOptions('status', value)}>
											{[
												{
													label: 'In Stock',
													value: 'InStock',
												},
												{
													label: 'Dispatched',
													value: 'Dispatched',
												},
												{
													label: 'Partially_Dispatched',
													value: 'Partially_Dispatched',
												},
											]?.map((status) => (
												<Select.Option value={status.value}>{status?.label}</Select.Option>
											))}
										</Select>
									</Col>
									{/* <Col xl={4} md={4}>
										<DatePicker
											placeholder="Select From Date"
											style={{ width: '100%' }}
											format={DATE_FORMAT.DD_MM_YYYY}
											onChange={(value) =>
												handleFilterOptions(
													'fromDate',
													value === 'Invalid date' ? '' : value ? moment(value).format(DATE_FORMAT.YYYY_MM_DD) : ''
												)
											}
										/>
									</Col>
									<Col xl={4} md={4}>
										<DatePicker
											placeholder="Select To Date"
											style={{ width: '100%' }}
											format={DATE_FORMAT.DD_MM_YYYY}
											onChange={(value) =>
												handleFilterOptions(
													'toDate',
													value === 'Invalid date' ? '' : value ? moment(value).format(DATE_FORMAT.YYYY_MM_DD) : ''
												)
											}
										/>
									</Col> */}
									<Col xl={8} md={8}>
										<h6>Inward From/To</h6>
										<RangePicker
											placeholder={['Inward From', 'Inward To']}
											style={{ width: '100%' }}
											onChange={(value) => handleFilterOptions('entryDates', value)}
										/>
									</Col>
									<Col xl={8} md={8}>
										<h6>Dispatched From/To</h6>
										<RangePicker
											placeholder={['Dispatched From Date', 'Dispatched To Date']}
											style={{ width: '100%' }}
											onChange={(value) => handleFilterOptions('dates', value)}
										/>
									</Col>
									<Col
										xl={4}
										md={4}
										style={{
											display: 'flex',
											alignItems: 'flex-end',
										}}>
										<Button loading={tableLoading} style={{ width: '100%' }} onClick={getStock} type="primary">
											Search
										</Button>
									</Col>
									<Col
										xl={4}
										md={4}
										style={{
											display: 'flex',
											alignItems: 'flex-end',
										}}>
										<PdfDownload {...{ ...exportData }} />
									</Col>
									<Col
										xl={4}
										md={4}
										style={{
											display: 'flex',
											alignItems: 'flex-end',
										}}>
										<ExcelDownload {...{ ...extraFields }} />
									</Col>
								</Row>
							</Col>

							{/* <Col xl={24} md={24} style={{ paddingTop: 10 }}>
								<Col xl={8} md={8}>
									<Select
										showSearch
										allowClear
										maxTagCount="responsive"
										mode="multiple"
										placeholder="Select Status"
										optionFilterProp="children"
										style={{ width: '100%' }}
										filterOption={(input, option) =>
											option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
											option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
										}
										onChange={(value) => handleFilterOptions('status', value)}>
										{[
											{
												label: 'In Stock',
												value: 'InStock',
											},
											{
												label: 'Dispatched',
												value: 'Dispatched',
											},
											{
												label: 'Partially_Dispatched',
												value: 'Partially_Dispatched',
											},
										]?.map((status) => (
											<Select.Option value={status.value}>{status?.label}</Select.Option>
										))}
									</Select>
								</Col>
							</Col> */}
							{/* <Col xl={24} md={24} style={{ paddingTop: 10 }}>
								<Row gutter={[10, 10]}>
									<Col xl={4} md={4}>
										<Select
											showSearch
											allowClear
											maxTagCount="responsive"
											optionFilterProp="children"
											style={{ width: '100%' }}
											// filterOption={(input, option) =>
											// 	option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
											// 	option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
											// }
											onChange={(value) => handleFilterOptions('vehicleId', value)}
											placeholder="Select Vehicle">
											{vehicles?.map((vehicle) => (
												<Select.Option value={vehicle?._id}>{vehicle?.regNumber}</Select.Option>
											))}
										</Select>
									</Col>
									<Col xl={4} md={4}>
										<Button loading={tableLoading} style={{ width: '100%' }} onClick={getStock} type="primary">
											Search
										</Button>
									</Col>
									<Col xl={4} md={4}>
										<PdfDownload {...{ ...exportData }} />
									</Col>
									<Col xl={4} md={4}>
										<ExcelDownload {...{ ...extraFields }} />
									</Col>
								</Row>
							</Col> */}
						</Row>
					)}
					{...{
						columns: column,
						pagination: { current: currentPage, pageSize: pageSize, position: ['none', 'none'] },
						...(!!filteredData?.length && {
							footer: () => (
								<Row justify="space-between">
									<Col md={16}>
										{!!filteredData?.length &&
											`Showing ${getStartingValue()} - ${getEndingValue()} of ${filteredData?.length} Data`}
									</Col>
									<Col md={8}>
										<div style={{ textAlign: 'right' }}>
											<Pagination
												pageSizeOptions={intialPageSizeOptions}
												defaultPageSize={initialPageSize}
												showSizeChanger={true}
												total={filteredData?.length}
												onChange={handleTableChange}
												responsive
											/>
										</div>
									</Col>
								</Row>
							),
						}),
					}}
				/>
			</Col>
			<DispatchedDetailsModal {...{ dispatchedDetails, setDispatchedDetails, selectedViewRow, setSelectedViewRow }} />
		</Row>
	);
};

export default StockPresentational;
