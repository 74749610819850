import React from 'react';
import { Modal } from 'antd';
import { getDateFormat } from 'services/Utils';
import { DATE_FORMAT } from 'constants/app-constants';
import TableComponent from 'components/table-component';

const DispatchDetailModal = ({ setSelectedViewRow, selectedViewRow }) => {
	const column = [
		{
			title: '#',
			dataIndex: 'dispatchdetailNumber',
			key: 'dispatchdetailNumber',
			sorter: (a, b) => a?.dispatchdetailNumber?.localeCompare(b?.dispatchdetailNumber),
			width: '5%',
			render: (value) => <span>{value}</span>,
		},
		{
			title: 'Vehicle',
			dataIndex: 'vehicleId',
			key: 'vehicleId',
			sorter: (a, b) => a?.vehicleId?.regNumber?.localeCompare(b?.vehicleId?.regNumber),
			width: '10%',
			render: (value) => <span>{value?.regNumber}</span>,
		},
		{
			title: 'Dispatch Date',
			dataIndex: 'dispatchDate',
			key: 'dispatchDate',
			width: '15%',
			sorter: (a, b) => new Date(a.dispatchDate) - new Date(b.dispatchDate),
			render: (value) => getDateFormat(value),
		},
		{
			title: 'Requested Date',
			dataIndex: 'requestedDate',
			key: 'requestedDate',
			width: '13%',
			sorter: (a, b) => new Date(a.requestedDate) - new Date(b.requestedDate),
			render: (value) => getDateFormat(value, DATE_FORMAT.DD_MM_YYYY),
		},
	];
	return (
		<Modal
			title="Dispatch Details"
			okButtonProps={{ style: { display: 'none' } }}
			onCancel={() => setSelectedViewRow(null)}
			visible={(selectedViewRow?.length || 0) > 0}
			width={'60%'}>
			<TableComponent
				className="custom-table"
				style={{ width: '100%' }}
				rowKey={(record) => record._id}
				dataSource={selectedViewRow}
				columns={column}
				pagination={false}
			/>
		</Modal>
	);
};

export default DispatchDetailModal;
