import React, { useCallback } from 'react';
import { Drawer, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;

const AddModal = ({
	modalTitle = 'Add New Invoice',
	visible = false,
	toggleVisible,
	// form,
	// onSubmit,
	// loading = false,
	// disabled = false,
	children,
	width = '80%',
}) => {
	const showConfirm = useCallback(() => {
		confirm({
			title: 'Do you want to close this window?',
			icon: <ExclamationCircleOutlined />,
			content: 'You will be lost all the details you have entered here.',
			onOk() {
				toggleVisible(false);
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	}, [toggleVisible]);

	// const handleFinish = (values) => {
	// 	toggleVisible(false);
	// 	onSubmit(values);
	// };

	return (
		<Drawer maskClosable={false} title={modalTitle} placement="right" width={width} visible={visible} destroyOnClose onClose={showConfirm}>
			{children}
		</Drawer>
	);
};

export default AddModal;
