import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { Row, Col, Popconfirm, Popover, Button, Tooltip } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
	CloseOutlined,
	EditOutlined,
	PrinterOutlined,
	EyeOutlined,
} from "@ant-design/icons";
import { useReactToPrint } from "react-to-print";
import { AiFillCar } from "react-icons/ai";
import { BsFiletypeJson } from "react-icons/bs";
import { getApi } from "redux/sagas/getApiDataSaga";
import { SERVER_IP } from "assets/Config";
import HighlightComponent from "components/HighlightComponent";
import { ACTIONS, API_STATUS, DATE_FORMAT } from "constants/app-constants";
import { deleteApi } from "redux/sagas/deleteApiSaga";
import { resetApiStatus } from "redux/reducers/globals/globalActions";
import { exportToJson } from "helpers";
import TransportDetailsToPrint from "./transport-to-print";
import WHInvoicePresentational from "./wh-invoice-list-presenatational";
import WHToPrint from "./wh-to-print";

const initialPageSize = 10;
const intialPageSizeOptions = [10, 15, 20];

const WHInvoiceFunctional = React.memo((props) => {
	const [selectedType, setSelectedType] = useState(null);
	const [printoutType, setPrintoutType] = useState(null);
	const invoices = useSelector(
		(state) => state?.whInvoiceRedux?.whInvoices || []
	);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [state, setState] = useState({
		visible: false,
		invoiceType: null,
	});
	const componentRef = React.useRef();
	const [searchKey, setSearchKey] = useState("");
	const [selectedRecordToPrint, setSelectedRecordToPrint] = useState(null);
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedViewRow, setSelectedViewRow] = useState(null);
	const [tableData, setTableData] = useState(invoices || []);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const dispatch = useDispatch();

	const PRINTOUT_TYPES = ["Original", "Duplicate", "Acknowledgement"];

	const PrintoutButton = ({ recordType = "WH", row = row }) => (
		<Popover
			placement="left"
			title={<span>Select type</span>}
			content={
				<Row gutter={[10, 10]} style={{ width: "min-content" }}>
					{PRINTOUT_TYPES?.map((printoutType) => (
						<Col span={24}>
							<Button
								type="primary"
								style={{ width: "100%" }}
								onClick={() => {
									setPrintoutType(printoutType);
									setSelectedType(recordType);
									setSelectedRecordToPrint(row);
								}}
							>
								{printoutType} Copy
							</Button>
						</Col>
					))}
				</Row>
			}
			trigger="click"
		>
			<Col className="edit_icon">
				<Col style={{ padding: 0 }}>
					{recordType === "WH" ? <PrinterOutlined /> : <AiFillCar />}
				</Col>
			</Col>
		</Popover>
	);

	const getWHInvoices = () =>
		dispatch(
			getApi(
				ACTIONS.GET_WH_INVOICES,
				`${SERVER_IP}invoice?orgId=${globalRedux.selectedOrganization._id}`
			)
		);

	const filteredData = useMemo(() => {
		if (searchKey === "") {
			return tableData;
		}
		return tableData.filter((record) => {
			return (
				(record?.invoiceNumber || "")
					?.toString()
					?.toLowerCase()
					.includes(searchKey.toLowerCase()) ||
				(moment(record?.invoiceDate).format(DATE_FORMAT.DD_MM_YYYY) || "")
					?.toLowerCase()
					.includes(searchKey.toLowerCase()) ||
				(record?.customerId?.displayName || "")
					?.toLowerCase()
					.includes(searchKey.toLowerCase()) ||
				(record?.invoiceType || "")
					?.toLowerCase()
					.includes(searchKey.toLowerCase()) ||
				(record?.department || "")
					?.toLowerCase()
					.includes(searchKey.toLowerCase()) ||
				(record?.totalAmount || "")
					?.toString()
					?.toLowerCase()
					.includes(searchKey.toLowerCase())
			);
		});
	}, [tableData, searchKey]);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_INVOICE === "SUCCESS") {
			dispatch(resetApiStatus("DELETE_INVOICE"));
			doIt = true;
		}
		if (doIt) {
			getWHInvoices();
		}
	}, [globalRedux.apiStatus]);

	useEffect(() => {
		getWHInvoices();
	}, []);
	// }, [currentPage, pageSize]);

	const column = [
		{
			title: "Invoice No#",
			dataIndex: "invoiceNumber",
			key: "invoiceNumber",
			width: "5%",
			sorter: (a, b) => a?.invoiceNumber - b?.invoiceNumber,
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.toString()}
				/>
			),
		},
		{
			title: "Invoice Date",
			dataIndex: "invoiceDate",
			key: "invoiceDate",
			sorter: (a, b) => new Date(a.invoiceDate) - new Date(b.invoiceDate),
			width: "10%",
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={moment(value).format(DATE_FORMAT.DD_MM_YYYY)}
				/>
			),
		},
		{
			title: "Vendor Name",
			sorter: (a, b) =>
				a?.customerId?.displayName?.localeCompare(b?.customerId?.displayName),
			dataIndex: "customerId",
			key: "customerId",
			width: "15%",
			render: (value) => (
				<HighlightComponent
					className="bold"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.displayName || ""}
				/>
			),
		},
		{
			title: "Invoice Type",
			dataIndex: "invoiceType",
			sorter: (a, b) => a?.invoiceType?.localeCompare(b?.invoiceType),
			key: "invoiceType",
			width: "10%",
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.toString()}
				/>
			),
		},
		{
			title: 'Department',
			dataIndex: 'department',
			key: 'department',
			width: '10%',
			sorter: (a, b) => a?.department?.localeCompare(b?.department),
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.toString()}
				/>
			),
		},
		{
			title: "Invoice Amount",
			dataIndex: "totalAmount",
			key: "totalAmount",
			width: "10%",
			align: "right",
			sorter: (a, b) => a?.totalAmount - b?.totalAmount,
			render: (value) => parseFloat(value).toFixed(2),
		},
		{
			title: "Paid",
			dataIndex: "paid",
			key: "paid",
			width: "8%",
			align: "right",
			sorter: (a, b) => a?.paid - b?.paid,
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={(value || 0)?.toFixed(2)}
				/>
			),
		},
		{
			title: "Balance",
			dataIndex: "balance",
			key: "balance",
			width: "8%",
			align: "right",
			sorter: (a, b) => a?.balance - b?.balance,
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={(value || 0)?.toFixed(2)}
				/>
			),
		},
		// {
		// 	title: 'Payment Received',
		// 	dataIndex: 'receiptTotal',
		// 	key: 'receiptTotal',
		// 	width: '10%',
		// 	align: 'right',
		// 	render: (value) => parseFloat(value || 0).toFixed(2),
		// },
		// {
		// 	title: 'Balance Amount',
		// 	dataIndex: 'balanceAmount',
		// 	key: 'balanceAmount',
		// 	width: '10%',
		// 	align: 'right',
		// 	render: (value) => parseFloat(value || 0).toFixed(2),
		// },
		{
			title: "Action",
			align: "center",
			dataIndex: "displayName",
			width: "13%",
			align: "center",
			render: (value, row) => {
				return (
					<Row gutter={10} justify="center">
						{row?.receiptData?.length > 0 && (
							<Tooltip placement="left" title={"View Receipt Details"}>
								<Col
									onClick={() => {
										handleViewRow(row?.receiptData);
									}}
									className="edit_icon"
								>
									<EyeOutlined />
								</Col>
							</Tooltip>
						)}
						<Col
							onClick={() => {
								setState({
									...state,
									visible: true,
									invoiceType: row?.transportItems?.length ? "VEHICLE" : "WH",
								});
								handleSelectRow(row);
							}}
							className="edit_icon"
						>
							<EditOutlined />
						</Col>
						<Col
							onClick={() => {
								const isTransport = row?.transportItems?.length > 0;
								const array = isTransport ? row?.transportItems : row?.items;
								const defaultValue = {
									Version: "1.1",
									TranDtls: {
										TaxSch: "GST",
										SupTyp: "B2B",
										IgstOnIntra: "N",
										RegRev: "N",
										EcmGstin: null,
									},
									DocDtls: {
										Typ: "INV",
										No: row?.invoiceNumber?.toString() || null,
										Dt: moment(row?.invoiceDate).format(
											DATE_FORMAT.DD_MM_YYYY_SLASH
										),
									},
									SellerDtls: {
										Gstin: "33BCGPS9280R2ZL",
										LglNm: "MYLSWAMY SRIPATHI",
										Addr1: "36/71, VIVEKANANDA NAGAR,",
										Addr2: "THEKKUPALAYAM",
										Loc: "COIMBATORE",
										Pin: 641035,
										Stcd: "33",
										Ph: "7904149887",
										Em: "tmttransportcbe@gmail.com",
									},
									BuyerDtls: {
										Gstin: row?.customerId?.gstin,
										LglNm: row?.customerId?.displayName,
										Addr1:
											row?.customerId?.billingAddress?.[0]?.addressLine1 ||
											null,
										Addr2:
											row?.customerId?.billingAddress?.[0]?.addressLine2 ||
											null,
										Loc: row?.customerId?.billingAddress?.[0]?.city || null,
										Pin: row?.customerId?.billingAddress?.[0]?.pincode || null,
										Pos: (row?.customerId?.gstin || "")?.slice(0, 2) || null,
										Stcd: (row?.customerId?.gstin || "")?.slice(0, 2) || null,
										Ph: row?.customerId?.mobile || null,
										Em: null,
									},
									ValDtls: {
										AssVal: row?.subTotal || 0,
										IgstVal: row?.igst || 0,
										CgstVal: row?.cgst || 0,
										SgstVal: row?.sgst || 0,
										CesVal: 0,
										StCesVal: 0,
										Discount: 0,
										OthChrg: eval(parseFloat(row?.lrCharges || 0).toFixed(2)),
										RndOffAmt: row?.roundOff || 0,
										TotInvVal: row?.totalAmount || 0,
									},
									RefDtls: {
										InvRm: "NICGEPP2.0",
									},
									ItemList: array?.map((item, index) => {
										// console.log("🚀 ~ file: wh-invoice-list-functional.js:292 ~ ItemList:array?.map ~ item:", row?.isIgst, item)
										const totalGST = item?.totalAmount * (row?.gstRate / 100);
										const gstAmount = eval(
											parseFloat(row?.isIgst ? totalGST : totalGST / 2).toFixed(
												2
											)
										);
										return {
											SlNo: (index + 1)?.toString(),
											PrdDesc: `LR .${item?.lrNumber}`,
											IsServc: "Y",
											HsnCd: item?.hsnSac || null,
											Qty: isTransport ? item?.frightKG : item?.kgs,
											FreeQty: 0,
											Unit: "KGS",
											UnitPrice: eval(item?.rate) || 0,
											TotAmt: item?.totalAmount || 0,
											Discount: 0,
											PreTaxVal: 0,
											AssAmt: item?.totalAmount || 0,
											GstRt: row?.gstRate || 0,
											IgstAmt: row?.isIgst ? gstAmount : 0,
											CgstAmt: !row?.isIgst ? gstAmount : 0,
											SgstAmt: !row?.isIgst ? gstAmount : 0,
											CesRt: 0,
											CesAmt: 0,
											CesNonAdvlAmt: 0,
											StateCesRt: 0,
											StateCesAmt: 0,
											StateCesNonAdvlAmt: 0,
											OthChrg: 0,
											TotItemVal: eval(
												parseFloat(item?.totalAmount + totalGST).toFixed(2)
											),
										};
									}),
								};
								exportToJson(defaultValue, `${row?.invoiceNumber}`);
							}}
							className="edit_icon"
						>
							<BsFiletypeJson />
						</Col>
						{row?.items?.length ? (
							<PrintoutButton recordType="WH" row={row} />
						) : null}
						{row?.transportItems?.length ? (
							<PrintoutButton recordType="VEHICLE" row={row} />
						) : null}
						<Col className="delete_icon">
							<Popconfirm
								title={`Are You Sure to Delete?`}
								okText="Delete"
								cancelText="No"
								placement="rightTop"
								onConfirm={() => {
									let url = `${SERVER_IP}invoice/${row._id}?orgId=${globalRedux.selectedOrganization._id}`;
									dispatch(deleteApi("DELETE_INVOICE", url));
								}}
							>
								<CloseOutlined />
							</Popconfirm>
						</Col>
					</Row>
				);
			},
		},
	];

	const handleSelectRow = (row) => {
		setSelectedRow(row);
	};

	const handleViewRow = (row) => {
		setSelectedViewRow(row);
	};

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage === 0 ? 1 : currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	// const getEndingValue = () => currentPage * pageSize + (tableData?.length || 0) - pageSize;

	const getEndingValue = () => {
		if (currentPage === 1)
			return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const handleAfterPrint = () => {
		setPrintoutType(null);
		setSelectedType(null);
		setSelectedRecordToPrint(null);
	};

	useEffect(() => {
		setTableData(invoices);
	}, [invoices]);

	useEffect(() => {
		selectedRecordToPrint && handlePrint();
	}, [selectedRecordToPrint]);

	useEffect(() => {
		selectedRow &&
			setState({
				...state,
				visible: true,
			});
	}, [selectedRow]);

	useEffect(() => {
		!state?.visible && setSelectedRow(null);
	}, [state?.visible]);

	const reactToPrintContent = React.useCallback(() => {
		return componentRef.current;
	}, [componentRef.current]);

	const handlePrint = useReactToPrint({
		// content: () => {
		// 	const tableStat = componentRef.current.cloneNode(true);
		// 	const PrintElem = document.createElement('div');
		// 	const header =
		// 		//   `<img src="${logo}" alt="" class="watermark"/>` +
		// 		`<div class="page-footer"><div>Whether liable to pay under RCM No</div>${
		// 			selectedRecordToPrint?.transportItems?.length || 0
		// 		} LR Copies<div></div></div>`;
		// 	PrintElem.innerHTML = header;
		// 	PrintElem.appendChild(tableStat);
		// 	return PrintElem;
		// },
		// pageStyle: `@media print {
		// 	.invoice-pdf {
		// 		height: 100%;
		// 	}
		// }`,
		// pageStyle: `@page {
		// 	size: 210mm 148mm;
		// 	}
		// 	@media print {
		// 	@page {  size: a4 landscape;
		// 		margin: 0mm !important;
		// 	}
		// 	@media all {
		// 					.pagebreak {
		// 					  overflow: visible;
		// 					}
		// 				}
		// 			}
		// 		}`,
		content: reactToPrintContent,
		documentTitle: "Receipt",
		onAfterPrint: handleAfterPrint,
		// removeAfterPrint: true,
		onPrintError: (error) => {
			console.error("Error while printing", error);
		},
	});

	const tableLoading = useMemo(
		() => globalRedux.apiStatus.GET_WH_INVOICES === API_STATUS.PENDING,
		[globalRedux.apiStatus]
	);

	return (
		<>
			<>
				{selectedType !== "VEHICLE" ? (
					<div style={{ display: "none" }}>
						<WHToPrint
							ref={componentRef}
							data={selectedRecordToPrint}
							printoutType={printoutType}
						/>
					</div>
				) : (
					<div style={{ display: "none" }}>
						<TransportDetailsToPrint
							ref={componentRef}
							data={selectedRecordToPrint}
							printoutType={printoutType}
						/>
					</div>
				)}
			</>
			<WHInvoicePresentational
				{...{
					column,
					filteredData,
					handleTableChange,
					getStartingValue,
					getEndingValue,
					pageSize,
					intialPageSizeOptions,
					initialPageSize,
					currentPage,
					refreshList: getWHInvoices,
					tableLoading,
					state,
					setState,
					editData: selectedRow,
					setSearchKey,
					selectedViewRow,
					setSelectedViewRow,
					invoices,
				}}
			/>
		</>
	);
});

export default WHInvoiceFunctional;
