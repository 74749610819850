import React, { useEffect, useMemo, useState } from "react";
import { Popconfirm, Button, Pagination, Row, Col, Input } from "antd";
import { AiOutlineSearch } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import { generatePagination } from "helpers";
import TableComponent from "components/table-component";
import AddDrawer from "pages/master/customer-form";
import { API_STATUS } from "constants/app-constants";
import { getApi } from "../../redux/sagas/getApiDataSaga";
import { SERVER_IP } from "../../assets/Config";
import { getDateFormat } from "../../services/Utils";
import { deleteApi } from "../../redux/sagas/deleteApiSaga";
import { resetApiStatus } from "../../redux/reducers/globals/globalActions";
import HighlightComponent from "../../components/HighlightComponent";

const initialPageSize = 10;
const intialPageSizeOptions = [10, 15, 20];

const Vehicles = React.memo(() => {
	const globalRedux = useSelector((state) => state?.globalRedux);
	const dispatch = useDispatch();

	const [vehicleAddModal, setVehicleAddModal] = useState(false);
	const [searchKey, setSearchKey] = useState("");
	const [tableData, setTableData] = useState(globalRedux?.vehicles || []);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const [editVehicle, setEditAsset] = useState(null);

	const getVehicle = () => {
		let url = `${SERVER_IP}vehicle?orgId=${globalRedux.selectedOrganization._id}`;
		dispatch(getApi("GET_VEHICLES", url));
	};

	useEffect(() => {
		getVehicle();
		generatePagination(tableData);
	}, []);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_VEHICLE === "SUCCESS") {
			dispatch(resetApiStatus("DELETE_VEHICLE"));
			doIt = true;
		}
		if (doIt) {
			getVehicle();
		}
	}, [globalRedux.apiStatus]);

	useEffect(() => {
		setTableData(globalRedux?.vehicles || []);
	}, [globalRedux?.vehicles]);

	const filteredData = useMemo(() => {
		if (searchKey === "") {
			return tableData;
		}
		return tableData.filter((asset) => {
			return (
				(asset?.nameboard || "")
					?.toLowerCase()
					.includes(searchKey.toLowerCase()) ||
				(asset?.rcownerId?.rcownerName || "")
					?.toLowerCase()
					.includes(searchKey.toLowerCase()) ||
				(asset?.vehicleNumber || "")
					?.toLowerCase()
					.includes(searchKey.toLowerCase()) ||
				(asset?.vehicleType || "")
					?.toLowerCase()
					.includes(searchKey.toLowerCase()) ||
				(asset?.remarks || "")?.toLowerCase().includes(searchKey.toLowerCase())
			);
		});
	}, [tableData, searchKey]);

	const handleDrawer = (rowData) => {
		setEditAsset(rowData);
		setVehicleAddModal(true);
	};

	const handleStaff = () => {
		setEditAsset(null);
		setVehicleAddModal(true);
	};

	const column = [
		{
			title: "Vehicle name",
			dataIndex: "nameboard",
			key: "nameboard",
			fixed: "left",
			sorter: (a, b) => a?.nameboard?.localeCompare(b?.nameboard),
			width: "15%",
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value}
				/>
			),
		},
		{
			title: "Reg. Number",
			dataIndex: "vehicleNumber",
			key: "vehicleNumber",
			sorter: (a, b) => a?.vehicleNumber?.localeCompare(b?.vehicleNumber),
			fixed: "left",
			width: "10%",
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value}
				/>
			),
		},
		{
			title: "RC Owner",
			dataIndex: "rcownerId",
			key: "rcownerId",
			sorter: (a, b) =>
				a?.rcownerId?.rcownerName?.localeCompare(b?.rcownerId?.rcownerName),
			fixed: "left",
			width: "10%",
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.rcownerName}
				/>
			),
		},
		{
			title: "Vehicle Type",
			dataIndex: "vehicleType",
			key: "vehicleType",
			sorter: (a, b) => a?.vehicleType?.localeCompare(b?.vehicleType),
			fixed: "left",
			width: "10%",
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value}
				/>
			),
		},
		{
			title: "Remarks",
			dataIndex: "remarks",
			width: "10%",
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value}
				/>
			),
		},
		{
			title: "Created Date",
			dataIndex: "createdAt",
			align: "left",
			width: "15%",
			render: (value) => getDateFormat(value),
		},
		{
			title: "Created By",
			dataIndex: "createdBy",
			align: "left",
			width: "10%",
			render: (value) => value?.firstName,
		},
		{
			title: "Action",
			align: "center",
			width: "10%",
			dataIndex: "vehicleName",
			render: (value, row) => {
				return (
					<Row gutter={10} justify="center">
						<Col onClick={() => handleDrawer(row)} className="edit_icon">
							<EditOutlined />
						</Col>
						<Col className="delete_icon">
							<Popconfirm
								title={`Are You Sure to Delete ${value}?`}
								okText="Delete"
								cancelText="No"
								placement="rightTop"
								onConfirm={() => {
									let url = `${SERVER_IP}vehicle/${row._id}?orgId=${globalRedux.selectedOrganization._id}`;
									dispatch(deleteApi("DELETE_VEHICLE", url));
								}}
							>
								<CloseOutlined />
							</Popconfirm>
						</Col>
					</Row>
				);
			},
		},
	];

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1)
			return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const loading = useMemo(
		() => globalRedux.apiStatus.GET_VEHICLES === API_STATUS.PENDING,
		[globalRedux.apiStatus]
	);

	return (
		<>
			<Row style={{ padding: "20px 10px" }}>
				<Col xl={24}>
					<TableComponent
						className="custom-table"
						style={{ width: "100%" }}
						columns={column}
						loading={loading}
						bordered
						rowKey={(record) => record._id}
						dataSource={filteredData}
						title={() => (
							<Row justify="space-between">
								<Col md={3}>
									<Input
										placeholder="Search"
										suffix={<AiOutlineSearch />}
										style={{ height: "30px" }}
										onChange={({ target: { value } }) => setSearchKey(value)}
									/>
								</Col>
								<Col md={7}></Col>
								<Col>
									<Button
										type="primary"
										style={{ height: "30px", width: "100%" }}
										onClick={() => handleStaff(true)}
									>
										Add Vehicle
									</Button>
								</Col>
							</Row>
						)}
						pagination={{
							current: currentPage,
							pageSize: pageSize,
							position: ["none", "none"],
						}}
						footer={() => (
							<Row justify="space-between">
								<Col>
									<div>
										{!!filteredData?.length &&
											`Showing ${getStartingValue()} - ${getEndingValue()} of ${
												filteredData?.length
											} Data`}
									</div>
								</Col>

								<Col md={8}>
									<div style={{ textAlign: "right" }}>
										<Pagination
											pageSizeOptions={intialPageSizeOptions}
											defaultPageSize={initialPageSize}
											showSizeChanger={true}
											total={filteredData?.length}
											onChange={handleTableChange}
											responsive
										/>
									</div>
								</Col>
							</Row>
						)}
						// onChange={handleTableChange}
					/>
				</Col>
			</Row>
			<AddDrawer
				{...{ vehicleAddModal, setVehicleAddModal, getVehicle, editVehicle }}
			/>
		</>
	);
});

export default Vehicles;
