import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Form, Input, Switch } from "antd";
import { v4 as uuidv4 } from "uuid";
import { DeleteOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import { SERVER_IP } from "assets/Config";
import { API_STATUS, DATE_FORMAT } from "constants/app-constants";
import { postApi } from "redux/sagas/postApiDataSaga";
import { resetApiStatus } from "redux/reducers/globals/globalActions";
import { sendGetRequest } from "redux/sagas/utils";
import { putApi } from "redux/sagas/putApiSaga";
import { getApi } from "redux/sagas/getApiDataSaga";
import { convertObjectToString, getTheRoundOffValue } from "helpers";
import { inWords } from "services/Utils";
import InputWithAPI from "components/input-with-api";
import DatePicker from "components/date-picker";
import AddTransportInvoicePresentational from "./add-transport-invoice-presentational";

let itemDefaultRecord = {
	isDirect: false,
	lrNumber: null,
	tripNo: null,
	// hsnSac: null,
	date: moment(),
	kgs: null,
	frightRs: null,
	frightKG: null,
	amcAmount: null,
	loadingHalt: null,
	unloadingHalt: null,
	others: null,
	lrCharges: null,
	totalAmount: null,
	isValid: false,
	id: uuidv4(),
};

const AddTransportInvoiceFunctional = ({
	state,
	setState,
	refreshList,
	editData,
}) => {
	const [form] = Form.useForm();
	const customerIdValue = Form.useWatch("customerId", form);
	const rateTypeValue = Form.useWatch("rateType", form);
	const [gstPercentage, setGstPercentage] = useState(0);
	const [lrCharges, setLrCharges] = useState("");
	const [isGstPercentageEnabled, setIsGstPercentageEnabled] = useState(false);
	const [tableData, setTableData] = useState([
		{ ...itemDefaultRecord, id: uuidv4() },
	]);
	const [searchList, setSearchList] = useState({
		data: [],
		loading: false,
		searchString: "",
	});
	const [receiptState, setReceiptState] = useState({
		studentId: null,
		receiptDate: moment(),
	});
	const globalRedux = useSelector((state) => state.globalRedux);
	const vendors = globalRedux?.vendors;
	const customers = useSelector((state) => state?.customerRedux?.customers);
	const { classes = [] } = globalRedux;
	const dispatch = useDispatch();

	const getCustomers = () => {
		let url = `${SERVER_IP}customer?orgId=${globalRedux.selectedOrganization._id}`;
		dispatch(getApi("GET_CUSTOMERS", url));
	};

	const updateRowValues = useCallback(
		(vendorDetails) => {
			const data = tableData.map((data) => {
				const isTransport = rateTypeValue === "Transport";
				return {
					...data,
					frightRs: (data?.frightKG || 0) * data?.rate || 0,
					hsnSac: isTransport ? "996519" : "996729",
					lrNumber: "",
				};
			});
			setTableData([...data]);
		},
		[tableData, rateTypeValue]
	);

	const vendorDetails = useMemo(() => {
		const vendorDetails = customers?.find(
			(vendor) => vendor?._id === customerIdValue
		);
		updateRowValues(vendorDetails);
		return vendorDetails;
	}, [customerIdValue, rateTypeValue]);

	const resetTableData = () =>
		setTableData([{ ...itemDefaultRecord, id: uuidv4() }]);

	useEffect(() => {
		if (rateTypeValue) {
			if (rateTypeValue === "Transport") {
				setGstPercentage(0);
			}
			if (rateTypeValue === "Warehouse") {
				setGstPercentage(18);
			}
		}
	}, [rateTypeValue]);

	useEffect(() => {
		if (!state?.visible) {
			form.resetFields();
			resetTableData();
		}
	}, [state?.visible]);

	useEffect(() => {
		if (editData) {
			form.setFieldsValue({
				customerId: editData?.customerId?._id,
				billingAddress: editData?.billingAddress,
				poNumber: editData?.poNumber,
				gstin: editData?.gstin,
				notes: editData?.notes,
				invoiceDate: moment(editData?.invoiceDate),
				department: editData?.department,
				rateType: editData?.rateType,
			});
			setTableData(
				editData?.transportItems?.map((data) => ({
					...data,
					isValid: true,
					date: moment(data?.date),
					id: uuidv4(),
				}))
			);
			setGstPercentage(parseInt(editData?.gstRate));
			setLrCharges(parseInt(editData?.lrCharges || 0));
			setIsGstPercentageEnabled(editData?.isIgst);
		} else {
			form.resetFields();
			resetTableData();
		}
	}, [editData]);

	useEffect(() => {
		if (!state?.visible) {
			setLrCharges("");
			setIsGstPercentageEnabled(false);
			setGstPercentage(0);
		}
	}, [state?.visible]);

	useEffect(() => {
		getCustomers();
	}, []);

	useEffect(() => {
		if (!state?.visible) {
			setTableData([{ ...itemDefaultRecord, id: uuidv4() }]);
		}
	}, [state?.visible]);

	const generateTableData = (editData) => {
		if (editData?.receiptDetails?.length > 0) {
			const data = editData.receiptDetails.map((item) => ({
				sno: item?.sno?.toString(),
				tripNo: item?.tripNo?.toString(),
				hsnSac: item?.hsnSac?.toString(),
				date: moment(item?.date),
				frightRs: (item?.frightRs || 0)?.toString(),
				frightKG: (item?.frightKG || 0)?.toString(),
				amcAmount: (item?.amcAmount || 0)?.toString(),
				loadingHalt: (item?.loadingHalt || 0)?.toString(),
				unloadingHalt: (item?.unloadingHalt || 0)?.toString(),
				others: item?.others?.toString(),
				lrCharges: item?.lrCharges?.toString(),
				rate: (item?.rate || 0)?.toString(),
				totalAmount: (item?.totalAmount || 0)?.toString(),
				id: uuidv4(),
			}));
			setTableData([...data]);
		}
	};

	const handleVendorSelect = (customerId) => {
		const vendor = customers?.find((vendor) => {
			return vendor?._id === customerId;
		});
		form.setFieldsValue({
			billingAddress: convertObjectToString(vendor?.billingDetails[0]) || "",
			gstin: vendor?.gstin,
		});
	};

	useEffect(() => {
		if (
			globalRedux.apiStatus.ADD_INVOICE === API_STATUS.SUCCESS ||
			globalRedux.apiStatus.EDIT_INVOICE === API_STATUS.SUCCESS
		) {
			setState({ ...state, visible: false });
			form.resetFields();
			refreshList();
			dispatch(resetApiStatus(editData ? "EDIT_INVOICE" : "ADD_INVOICE"));
		}
		if (editData) {
			generateTableData(editData);
			setReceiptState({
				studentId: editData?.studentId?._id || "",
				receiptDate: editData?.receiptDate || "",
			});
		}
		// !editData && setTableData([{ ...itemDefaultRecord, id: uuidv4() }]);
	}, [globalRedux.apiStatus, editData]);

	const handleRowClick = (data) => {
		form.setFieldsValue({
			customerName: data?.displayName,
			mobile: data?.contact,
		});
	};

	const { subTotal, cgst, sgst, igst, roundOff, totalAmount, amountInWords } =
		useMemo(() => {
			const subTotal = parseFloat(
				(tableData || [])?.reduce(
					(acc, data) =>
						acc +
						parseFloat(
							(data?.frightRs || 0) +
								(data?.amcAmount || 0) +
								(data?.loadingHalt || 0) +
								(data?.unloadingHalt || 0) +
								(data?.others || 0) +
								(data?.lrCharges || 0)
						),
					0
				)
			).toFixed(2);
			const totalGST = parseFloat((subTotal * gstPercentage) / 100).toFixed(2);
			const cgst = isGstPercentageEnabled
				? 0
				: parseFloat(totalGST / 2).toFixed(2);
			const sgst = isGstPercentageEnabled
				? 0
				: parseFloat(totalGST / 2).toFixed(2);
			const igst = isGstPercentageEnabled ? totalGST : 0;
			// const cgst = parseFloat((parseFloat(subTotal) * (gstPercentage / 2)) / 100).toFixed(2);
			// const sgst = parseFloat((parseFloat(subTotal) * (gstPercentage / 2)) / 100).toFixed(2);
			// const igst = parseFloat(parseFloat(subTotal) * (18 / 100)).toFixed(2);
			const grandTotal =
				parseFloat(subTotal) +
				parseFloat(igst) +
				parseFloat(sgst) +
				parseFloat(cgst);
			const roundOff = getTheRoundOffValue(+grandTotal + +lrCharges || 0);
			const amountInWords = inWords(roundOff.value);

			return {
				subTotal,
				cgst,
				sgst,
				igst,
				roundOff: roundOff?.remain || 0,
				totalAmount: parseFloat(roundOff.value).toFixed(2),
				amountInWords,
			};
		}, [tableData, gstPercentage, isGstPercentageEnabled, lrCharges]);

	const handleSubmit = (values) => {
		const request = {
			orgId: globalRedux.selectedOrganization._id,
			customerId: values?.customerId,
			placeOfSupply: "TamilNadu",
			subTotal,
			cgst,
			sgst,
			igst,
			roundOff,
			amountInWords,
			gstRate: gstPercentage,
			isIgst: isGstPercentageEnabled,
			lrCharges: eval(parseFloat(lrCharges || 0).toFixed(2)),
			...values,
			totalAmount,
			items: [],
			invoiceType: "Transport",
			transportItems: tableData
				.filter((data) => data.lrNumber)
				.map((data, sno) => ({
					sno: sno?.toString(),
					lrNumber: data?.lrNumber?.toString(),
					tripNo: data?.tripNo?.toString(),
					hsnSac: data?.hsnSac?.toString(),
					date: data?.date?.toString(),
					frightRs: (data?.frightRs || 0)?.toString(),
					frightKG: data?.frightKG?.toString(),
					rate: data?.rate?.toString(),
					amcAmount: (data?.amcAmount || 0)?.toString(),
					loadingHalt: (data?.loadingHalt || 0)?.toString(),
					unloadingHalt: (data?.unloadingHalt || 0)?.toString(),
					others: data?.others?.toString(),
					lrCharges: (data?.lrCharges || 0)?.toString(),
					rate:
						rateTypeValue === "Transport"
							? parseFloat(vendorDetails?.transportRate || 0).toFixed(2)
							: parseFloat(vendorDetails?.warehouseRate || 0).toFixed(2),
					// totalAmount: data?.totalAmount?.toString(),
					// ...(data?.kgs && { kgs: (data?.kgs || 0)?.toString() }),
					// ...(data?.rate && { rate: (data?.rate || 0)?.toString() }),
					totalAmount: parseFloat(
						(data?.frightRs || 0) +
							(data?.amcAmount || 0) +
							(data?.loadingHalt || 0) +
							(data?.unloadingHalt || 0) +
							(data?.others || 0) +
							(data?.lrCharges || 0)
					)
						.toFixed(2)
						?.toString(),
				})),
		};
		console.log("🚀 ~ handleSubmit ~ request:", request)
		editData
			? dispatch(
					putApi(
						request,
						"EDIT_INVOICE",
						`${SERVER_IP}invoice/${editData?._id}`
					)
			  )
			: dispatch(postApi(request, "ADD_INVOICE"));
	};

	const handleSearch = async (searchString) => {
		setSearchList({
			...searchList,
			searchString,
		});
		if (searchString) {
			setSearchList({
				...searchList,
				loading: true,
			});
			const { data } = await sendGetRequest(
				null,
				`${SERVER_IP}student/search?orgId=${globalRedux.selectedOrganization._id}&searchText=${searchString}`
			);
			setSearchList({
				...searchList,
				data,
				loading: false,
			});
		}
	};

	const debounceFn = useCallback(debounce(handleSearch, 1000), []);

	const handleChange = (selectedValue) => {
		setReceiptState({
			...receiptState,
			studentId: selectedValue,
		});
	};

	const handleCheckIsValidData = (
		id,
		isValid,
		lrNumber,
		rowData,
		tableData
	) => {
		const updatedTableData = tableData?.map((table) => ({
			...table,
			...(table?.id === id && {
				...rowData,
				lrNumber,
				isValid,
			}),
		}));
		setTableData(updatedTableData);
	};

	const handleInputChange = useCallback(
		(label, value, rowId) => {
			console.log("🚀 ~ value:", value);
			const data = tableData.map((data) => {
				if (data.id === rowId) {
					return {
						...data,
						[label]: value,
						...(label === "frightKG" && {
							frightRs: (value || 1) * data?.rate,
						}),
						...(label === "rate" && {
							frightRs: data?.frightKG * (value || 1),
						}),
						// rowTotal: parseFloat((data?.kgs || 0) * (data?.rate || 0)).toFixed(2),
						// ...(label !== 'isDirect' && {
						// 	rowTotal: parseFloat((data?.kgs || 0) * (data?.rate || 0)).toFixed(2),
						// }),
					};
				} else {
					return data;
				}
			});
			setTableData([...data]);
		},
		[tableData, vendorDetails, rateTypeValue]
	);

	const columns = [
		{
			title: "S No",
			dataIndex: "sno",
			key: "sno",
			width: "5%",
			render: (value, record, index) => <div>{index + 1}</div>,
		},
		{
			title: "LR Number",
			dataIndex: "lrNumber",
			key: "lrNumber",
			width: "8%",
			render: (value, record, index) => (
				<InputWithAPI
					{...{
						value,
						record,
						customerId: record?.customerId,
						handleInputChange,
						arr: tableData,
						invoiceType: "Transport",
						rateType: rateTypeValue,
						handleCheckIsValidData,
					}}
				/>
			),
		},
		{
			title: "Trip No",
			dataIndex: "tripNo",
			key: "tripNo",
			width: "10%",
			render: (value, record) => (
				<Input
					value={value}
					// disabled
					placeholder="Trip No"
					// className={`${!value ? 'error' : ''}`}
					onChange={({ target: { value } }) =>
						handleInputChange("tripNo", value, record?.id)
					}
				/>
			),
		},
		// {
		// 	title: "HSNSAC",
		// 	dataIndex: "hsnSac",
		// 	key: "hsnSac",
		// 	width: "10%",
		// 	render: (value, record) => (
		// 		<Input
		// 			value={value}
		// 			// disabled
		// 			placeholder="hsnSac"
		// 			// className={`${!value ? 'error' : ''}`}
		// 			onChange={({ target: { value } }) =>
		// 				handleInputChange("hsnSac", value, record?.id)
		// 			}
		// 		/>
		// 	),
		// },
		{
			title: "Date",
			dataIndex: "date",
			key: "date",
			width: "10%",
			render: (value, record) => (
				<DatePicker
					value={value}
					className={`${!value ? "error" : ""}`}
					style={{ width: "100%" }}
					format={DATE_FORMAT.DD_MM_YYYY}
					onChange={(value) =>
						handleInputChange(
							"date",
							value === "Invalid date" ? "" : value,
							record?.id
						)
					}
				/>
			),
		},
		{
			title: "KGS",
			dataIndex: "frightKG",
			key: "frightKG",
			width: "7%",
			// align: 'right',
			render: (value, record) => (
				<Input
					type="number"
					pattern="^-?[0-9]\d*\.?\d*$"
					value={value}
					placeholder="frightKG"
					onChange={({ target: { value } }) =>
						handleInputChange("frightKG", parseFloat(value), record?.id)
					}
				/>
			),
		},
		{
			title: "Rate/KG",
			dataIndex: "rate",
			key: "rate",
			width: "7%",
			render: (value, record) => (
				<Input
					type="number"
					pattern="^-?[0-9]\d*\.?\d*$"
					value={value}
					placeholder="rate"
					onChange={({ target: { value } }) =>
						handleInputChange("rate", parseFloat(value), record?.id)
					}
				/>
			),
		},
		{
			title: "Fright Rs",
			dataIndex: "frightRs",
			key: "frightRs",
			width: "7%",
			// align: 'right',
			render: (value, record) => (
				<Input
					type="number"
					pattern="^-?[0-9]\d*\.?\d*$"
					value={value}
					placeholder="frightRs"
					disabled
					// className={`${!value ? 'error' : ''}`}
					onChange={({ target: { value } }) =>
						handleInputChange("frightRs", parseFloat(value), record?.id)
					}
				/>
			),
		},
		{
			title: "Amc Amount",
			dataIndex: "amcAmount",
			key: "amcAmount",
			width: "10%",
			// align: 'right',
			render: (value, record) => (
				<Input
					type="number"
					pattern="^-?[0-9]\d*\.?\d*$"
					value={value}
					placeholder="amcAmount"
					// className={`${!value ? 'error' : ''}`}
					onChange={({ target: { value } }) =>
						handleInputChange("amcAmount", parseFloat(value), record?.id)
					}
				/>
			),
		},
		{
			title: "Loading Halt",
			dataIndex: "loadingHalt",
			key: "loadingHalt",
			width: "10%",
			// align: 'right',
			render: (value, record) => (
				<Input
					type="number"
					pattern="^-?[0-9]\d*\.?\d*$"
					value={value}
					placeholder="Halt"
					// className={`${!value ? 'error' : ''}`}
					onChange={({ target: { value } }) =>
						handleInputChange("loadingHalt", parseFloat(value), record?.id)
					}
				/>
			),
		},
		{
			title: "Unloading Halt",
			dataIndex: "unloadingHalt",
			key: "unloadingHalt",
			width: "10%",
			// align: 'right',
			render: (value, record) => (
				<Input
					type="number"
					pattern="^-?[0-9]\d*\.?\d*$"
					value={value}
					placeholder="Halt"
					// className={`${!value ? 'error' : ''}`}
					onChange={({ target: { value } }) =>
						handleInputChange("unloadingHalt", parseFloat(value), record?.id)
					}
				/>
			),
		},
		{
			title: "Others",
			dataIndex: "others",
			key: "others",
			width: "10%",
			// align: 'right',
			render: (value, record) => (
				<Input
					type="number"
					pattern="^-?[0-9]\d*\.?\d*$"
					value={value}
					placeholder="others"
					// className={`${!value ? 'error' : ''}`}
					onChange={({ target: { value } }) =>
						handleInputChange("others", parseFloat(value), record?.id)
					}
				/>
			),
		},
		{
			title: "LR Charges",
			dataIndex: "lrCharges",
			key: "lrCharges",
			width: "10%",
			// align: 'right',
			render: (value, record) => (
				<Input
					type="number"
					pattern="^-?[0-9]\d*\.?\d*$"
					value={value}
					placeholder="lrCharges"
					// className={`${!value ? 'error' : ''}`}
					onChange={({ target: { value } }) =>
						handleInputChange("lrCharges", parseFloat(value), record?.id)
					}
				/>
			),
		},
		{
			title: "Total Rs.",
			dataIndex: "totalAmount",
			key: "totalAmount",
			align: "right",
			width: "5%",
			render: (value, data) =>
				parseFloat(
					(data?.frightRs || 0) +
						(data?.amcAmount || 0) +
						(data?.loadingHalt || 0) +
						(data?.unloadingHalt || 0) +
						(data?.others || 0) +
						(data?.lrCharges || 0)
				).toFixed(2),
		},
		{
			title: "",
			dataIndex: "item",
			key: "item",
			align: "center",
			width: "3%",
			render: (value, record) =>
				tableData.length > 1 ? (
					<DeleteOutlined
						style={{ color: "red" }}
						onClick={() => handleRemove(record.id)}
					/>
				) : null,
		},
	];

	const handleRemove = (id) => {
		const data = tableData.filter((data) => data.id !== id);
		setTableData([...data]);
	};

	const handleAddTableData = useCallback(() => {
		const isTransport = rateTypeValue === "Transport";
		let data = [...tableData];
		data.push({
			...itemDefaultRecord,
			hsnSac: isTransport ? "996519" : "996729",
			id: uuidv4(),
		});
		setTableData(data);
	}, [tableData, rateTypeValue]);

	useMemo(() => {
		const filledList = tableData
			// ?.map((data) => data?.lrNumber && data?.hsnSac && data?.frightRs && data?.frightKG && data?.amcAmount)
			?.map((data) => data?.lrNumber)
			.filter((data) => data);
		if (tableData?.length === filledList.length) {
			handleAddTableData();
		}
	}, [tableData]);

	const loading =
		globalRedux.apiStatus.ADD_INVOICE === API_STATUS.PENDING ||
		globalRedux.apiStatus.EDIT_INVOICE === API_STATUS.PENDING;

	// const keyMap = {
	// 	NEW_ROW: { sequence: 4, action: 'ctrl+n' },
	// };

	// const keyHandlers = {
	// 	NEW_ROW: () => handleAddTableData,
	// };

	return (
		<AddTransportInvoicePresentational
			{...{
				state,
				setState,
				form,
				handleSubmit,
				handleRowClick,
				classes,
				loading,
				columns,
				tableData,
				debounceFn,
				searchList,
				handleChange,
				receiptState,
				setReceiptState,
				editData,
				customers,
				handleVendorSelect,
				subTotal,
				cgst,
				sgst,
				igst,
				roundOff,
				totalAmount,
				amountInWords,
				gstPercentage,
				setGstPercentage,
				vendorDetails,
				rateTypeValue,
				isGstPercentageEnabled,
				setIsGstPercentageEnabled,
				lrCharges,
				setLrCharges,
			}}
		/>
	);
};

export default AddTransportInvoiceFunctional;
