let initialState = {
	loads: [],
};

const LoadsReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'LOGOUT': {
			return initialState;
		}
		case 'SET_LOADS':
			return {
				...state,
				loads: action.payload,
			};
		default:
			return state;
	}
};

export { LoadsReducer };
