import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Tag, Row, Col } from 'antd';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { SERVER_IP } from 'assets/Config';
import { ACTIONS, API_STATUS, DATE_FORMAT, STATUS } from 'constants/app-constants';
import HighlightComponent from 'components/HighlightComponent';
// import ComponentToPrint from 'components/component-to-print';
import { objToQs } from 'helpers';
import LRCopyToPrint from './lr-copy-to-print';
import StockReturnListPresentational from './stock-return-list-presentational';

const initialPageSize = 10;
const intialPageSizeOptions = [10, 15, 20];

const StockReturnListFunctional = React.memo((props) => {
	const componentRef = React.useRef();
	const [state, setState] = useState({
		visible: false,
	});
	const [filterOptions, setFilterOptions] = useState({
		lrNumber: '',
	});
	const [searchKey, setSearchKey] = useState('');
	const [selectedRow, setSelectedRow] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const globalRedux = useSelector((state) => state.globalRedux);
	const stocks = useSelector((state) => state.stocksReturnsRedux?.stocks);
	const [selectedRecordToPrint, setSelectedRecordToPrint] = useState(null);
	const [tableData, setTableData] = useState(stocks || []);
	const partNumbers = globalRedux?.partNumbers;
	const vehicles = globalRedux?.vehicles;
	const vendors = globalRedux?.vendors;
	const dispatch = useDispatch();

	const getStock = () => {
		if (filterOptions?.lrNumber) {
			let params = objToQs({
				orgId: globalRedux.selectedOrganization._id,
				...filterOptions,
			});
			dispatch(getApi('GET_STOCKS_RETURNS', `${SERVER_IP}stock?${params}`));
		} else {
			setTableData([]);
		}
	};

	const handleFilterOptions = (key, value) => {
		setFilterOptions({
			...filterOptions,
			[key]: value,
		});
	};

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((record) => {
			return (
				(record?.ticketType || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.priority || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.customerName || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.mobile || '')?.toLowerCase().includes(searchKey.toLowerCase())
			);
		});
	}, [tableData, searchKey]);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_UNLOAD === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_UNLOAD'));
			doIt = true;
		}
		if (doIt) {
			getStock();
		}
	}, [globalRedux.apiStatus]);

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage === 0 ? 1 : currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const handleAfterPrint = () => {
		setSelectedRecordToPrint(null);
	};

	useEffect(() => {
		getStock(filterOptions);
	}, [filterOptions]);

	useEffect(() => {
		setTableData(stocks);
	}, [stocks]);

	useEffect(() => {
		selectedRecordToPrint && handlePrint();
	}, [selectedRecordToPrint]);

	useEffect(() => {
		selectedRow &&
			setState({
				...state,
				visible: true,
			});
	}, [selectedRow]);

	useEffect(() => {
		!state?.visible && setSelectedRow(null);
	}, [state?.visible]);

	const reactToPrintContent = React.useCallback(() => {
		return componentRef.current;
	}, [componentRef.current]);

	const handlePrint = useReactToPrint({
		content: reactToPrintContent,
		documentTitle: 'Receipt',
		onAfterPrint: handleAfterPrint,
		removeAfterPrint: true,
	});

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_STOCKS_RETURNS === API_STATUS.PENDING, [globalRedux.apiStatus]);

	const column = [
		{
			title: 'LR #',
			dataIndex: 'lrNumber',
			align: 'left',
			fixed: 'left',
			width: 100,
			sorter: (a, b) => a?.lrNumber - b?.lrNumber,
		},
		{
			title: 'Inbound No',
			dataIndex: 'loadId',
			align: 'left',
			fixed: 'left',
			width: 100,
			sorter: (a, b) => a?.loadId?.loadNumber - b?.loadId?.loadNumber,
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.loadNumber?.toString() || ''}
				/>
			),
		},
		{
			title: 'Inbound Date',
			dataIndex: 'loadId',
			align: 'left',
			width: 150,
			sorter: (a, b) => new Date(a.loadId?.loadInDate) - new Date(b.loadId?.loadInDate),
			render: (value) => <span>{moment(value?.loadInDate).format(DATE_FORMAT.DD_MM_YYYY)}</span>,
		},
		{
			title: 'Vehicle No',
			dataIndex: 'loadId',
			sorter: (a, b) => a?.loadId?.vehicleId?.regNumber - b?.loadId?.vehicleId?.regNumber,
			key: 'loadId',
			// fixed: 'left',
			width: 150,
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.vehicleId?.regNumber || ''}
				/>
			),
		},
		{
			title: 'Vendor Name',
			dataIndex: 'vendorId',
			key: 'vendorId',
			sorter: (a, b) => a?.vendorId?.vendorName?.localeCompare(b?.vendorId?.vendorName),
			width: 150,
			render: (value) => <span>{value?.vendorName}</span>,
		},
		{
			title: 'Part No',
			dataIndex: 'partNumberId',
			key: 'partNumberId',
			sorter: (a, b) => a?.partNumberId?.partNumber?.localeCompare(b?.partNumberId?.partNumber),
			width: 100,
			render: (value) => <span>{value?.partNumber}</span>,
		},
		{
			title: 'Sap No',
			dataIndex: 'partNumberId',
			key: 'partNumberId',
			width: 100,
			render: (value) => <span>{value?.sapNumber}</span>,
		},
		{
			title: 'Invoice No',
			dataIndex: 'invoiceNumber',
			width: 100,
			sorter: (a, b) => a?.invoiceNumber - b?.invoiceNumber,
			key: 'invoiceNumber',
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value || ''} />
			),
		},
		{
			title: 'ASN No',
			dataIndex: 'asnNumber',
			sorter: (a, b) => a?.asnNumber - b?.asnNumber,
			key: 'asnNumber',
			width: 100,
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value || ''} />
			),
		},
		{
			title: 'Invoice Date',
			dataIndex: 'invoiceDate',
			sorter: (a, b) => new Date(a.invoiceDate) - new Date(b.invoiceDate),
			key: 'invoiceDate',
			width: 100,
			render: (value) => <span>{moment(value).format(DATE_FORMAT.DD_MM_YYYY)}</span>,
		},
		{
			title: 'Invoice Qty',
			dataIndex: 'invoiceQty',
			align: 'left',
			width: 100,
			sorter: (a, b) => a?.invoiceQty - b?.invoiceQty,
		},
		{
			title: 'Invoice Weight',
			dataIndex: 'invoiceWeight',
			align: 'left',
			width: 100,
			sorter: (a, b) => a?.invoiceWeight - b?.invoiceWeight,
		},
		{
			title: 'Stock Qty',
			dataIndex: 'stockQty',
			align: 'left',
			width: 100,
			sorter: (a, b) => a?.stockQty - b?.stockQty,
		},
		{
			title: 'Status',
			dataIndex: 'status',
			align: 'left',
			width: 100,
			sorter: (a, b) => a?.status?.localeCompare(b?.status),
			render: (value) => <Tag color={STATUS[value]}>{value}</Tag>,
		},
		{
			title: 'Return',
			dataIndex: '_id',
			align: 'center',
			fixed: 'right',
			width: 80,
			render: (value, row) => (
				<Row gutter={10} justify="center">
					<Col
						// onClick={() => setSelectedRecordToPrint(row)}
						className="edit_icon">
						<ArrowLeftOutlined />
					</Col>
				</Row>
			),
		},
	];

	const exportData = useMemo(() => {
		const head = [
			'LR #',
			'Inbound No',
			'Inbound Date',
			'Vehicle No',
			'Load In',
			'Invoice Date',
			'Vendor Name',
			'Part No',
			'Sap No',
			'ASN No',
			'Invoice No',
			'Invoice Weight',
			'Invoice Qty',
			'Status',
		];
		const filteredDataCopy = JSON.parse(JSON.stringify(filteredData)).sort((a, b) => a?.lrNumber - b?.lrNumber);
		return {
			headers: head,
			// headers: (column.filter((col) => col?.title !== 'LR')?.map((col) => col?.title) || []).map((title) =>
			// 	title === 'Stock Qty' ? 'Load In' : title
			// ),
			data:
				filteredDataCopy.map((stock) => ({
					[head[0]]: stock?.lrNumber,
					[head[1]]: stock?.loadId?.loadNumber,
					[head[2]]: moment(stock?.loadId?.loadInDate).format(DATE_FORMAT.DD_MM_YYYY),
					[head[3]]: stock?.loadId?.vehicleId?.regNumber,
					[head[4]]: moment(stock?.loadInDate).format(DATE_FORMAT.DD_MM_YYYY),
					[head[5]]: moment(stock?.invoiceDate).format(DATE_FORMAT.DD_MM_YYYY),
					[head[6]]: stock?.vendorId?.vendorName,
					[head[7]]: stock?.partNumberId?.partNumber,
					[head[8]]: stock?.partNumberId?.sapNumber,
					[head[9]]: stock?.asnNumber,
					[head[10]]: stock?.invoiceNumber,
					[head[11]]: stock?.invoiceWeight,
					[head[12]]: stock?.invoiceQty,
					[head[13]]: stock?.status,
				})) || [],
		};
	}, [filteredData, column]);

	const extraFields = useMemo(() => {
		const head = [
			'LR #',
			'Inbound No',
			'Inbound Date',
			'Vehicle No',
			'Load In',
			'Invoice Date',
			'Vendor Name',
			'Part No',
			'Sap No',
			'ASN No',
			'Invoice No',
			'Invoice Qty',
			'Invoice Weight',
			'Stock Qty',
			'Status',
			'Tax Value',
			'Total Value',
		];
		const filteredDataCopy = JSON.parse(JSON.stringify(filteredData)).sort((a, b) => a?.lrNumber - b?.lrNumber);
		// const excelColumns =
		// 	[...column.filter((col) => col?.title !== 'LR')?.map((col) => col?.title), 'Vehicle No', 'In Date', 'Tax Value', 'Total Value'] || [];
		return {
			headers: head,
			data:
				filteredDataCopy.map((stock) => ({
					[head[0]]: stock?.lrNumber,
					[head[1]]: stock?.loadId?.loadNumber,
					[head[2]]: moment(stock?.loadId?.loadInDate).format(DATE_FORMAT.DD_MM_YYYY),
					[head[3]]: stock?.loadId?.vehicleId?.regNumber,
					[head[4]]: moment(stock?.loadInDate).format(DATE_FORMAT.DD_MM_YYYY),
					[head[5]]: moment(stock?.invoiceDate).format(DATE_FORMAT.DD_MM_YYYY),
					[head[6]]: stock?.vendorId?.vendorName,
					[head[7]]: stock?.partNumberId?.partNumber,
					[head[8]]: stock?.partNumberId?.sapNumber,
					[head[9]]: stock?.asnNumber,
					[head[10]]: stock?.invoiceNumber,
					[head[11]]: stock?.invoiceQty,
					[head[12]]: stock?.invoiceWeight,
					[head[13]]: stock?.stockQty,
					[head[14]]: stock?.status,
					[head[15]]: parseFloat(stock?.taxableValue).toFixed(2),
					[head[16]]: parseFloat(stock?.totalValue).toFixed(2),
				})) || [],
		};
	}, [filteredData, column]);

	return (
		<>
			<div style={{ display: 'none' }}>
				<LRCopyToPrint ref={componentRef} data={selectedRecordToPrint} />
			</div>
			<StockReturnListPresentational
				{...{
					column,
					filteredData,
					handleTableChange,
					getStartingValue,
					getEndingValue,
					pageSize,
					intialPageSizeOptions,
					initialPageSize,
					currentPage,
					tableLoading,
					partNumbers,
					vendors,
					handleFilterOptions,
					exportData,
					extraFields,
					vehicles,
				}}
			/>
		</>
	);
});

export default StockReturnListFunctional;
