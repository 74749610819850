import React from "react";
import { Button, Pagination, Row, Col, Input } from "antd";
import { AiOutlineSearch } from "react-icons/ai";
import TableComponent from "components/table-component";
import AddBooking from "pages/bookings/add-booking";
import TrackingStatusUpdateModal from "./tracking-status-update-modal";

const BookingsListPresentational = ({
	column,
	filteredData,
	handleTableChange,
	getStartingValue,
	getEndingValue,
	pageSize,
	intialPageSizeOptions,
	initialPageSize,
	currentPage,
	refreshList,
	tableLoading,
	rowSelection,
	state,
	setState,
	setSearchKey,
	selectedRowKeys,
	selectedRow, 
	setSelectedRow
}) => {
	return (
		<Row style={{ padding: "20px 10px" }}>
			<Col xl={24}>
				<TableComponent
					loading={tableLoading}
					scroll={{ x: 1300 }}
					className="custom-table"
					columns={column}
					rowKey={(record) => record._id}
					dataSource={filteredData}
					onRow={(record, rowIndex) => {
						return {
						  onClick: event => {
							setSelectedRow(record)
						  }, // click row
						};
					  }}
					title={() => (
						<Row justify="space-between">
							<Col span={21}>
								<Row gutter={[10,10]}>
									<Col span={3}>
										<Input
											placeholder="Search"
											suffix={<AiOutlineSearch />}
											style={{ height: "30px" }}
											onChange={({ target: { value } }) => setSearchKey(value)}
										/>
									</Col>
									<Col span={4}>
										
									</Col>
								</Row>
							</Col>
							<Col span={3}>
								<Button
									type="primary"
									style={{ width: "100%" }}
									onClick={() => {
										setState({
											...state,
											visible: true,
										});
									}}
								>
									Create Booking
								</Button>
							</Col>
						</Row>
					)}
					pagination={{
						current: currentPage,
						pageSize: pageSize,
						position: ["none", "none"],
					}}
					footer={() => (
						<Row justify="space-between">
							<Col>
								<div>
									{!!filteredData?.length &&
										`Showing ${getStartingValue()} - ${getEndingValue()} of ${
											filteredData?.length
										} Data`}
								</div>
							</Col>
							<Col>
								<div style={{ textAlign: "right" }}>
									<Pagination
										pageSizeOptions={intialPageSizeOptions}
										defaultPageSize={initialPageSize}
										showSizeChanger={true}
										total={filteredData?.length}
										onChange={handleTableChange}
										responsive
									/>
								</div>
							</Col>
						</Row>
					)}
				/>
			</Col>
			<AddBooking {...{ state, setState, refreshList }} />
			<TrackingStatusUpdateModal {...{ selectedRow, setSelectedRow, refreshList }} />
		</Row>
	);
};

export default BookingsListPresentational;
