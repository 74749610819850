import React, { useEffect } from "react";
import { Input, Button, Divider, Form, Row, Col } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { putApi } from "redux/sagas/putApiSaga";
import { SERVER_IP } from "assets/Config";
import { API_STATUS } from "constants/app-constants";
import { resetApiStatus } from "redux/reducers/globals/globalActions";
import { postApi } from "redux/sagas/postApiDataSaga";

const { TextArea } = Input;

const AddDriver = ({ handleClose, editDriver, setDriverAddModal }) => {
	const [form] = Form.useForm();
	const globalRedux = useSelector((state) => state?.globalRedux);
	const dispatch = useDispatch();

	const addDriver = (values) => {
		let data = {
			...values,
			driverName: values.driverName,
			driverMobile: values.driverMobile,
			openingBalance: values.openingBalance,
			remarks: values.remarks,
			orgId: globalRedux?.selectedOrganization?._id,
		};
		dispatch(postApi(data, "ADD_DRIVER"));
	};

	const handleEdit = (values) => {
		let data = {
			...values,
			orgId: globalRedux?.selectedOrganization?._id,
		};
		let url = `${SERVER_IP}driver/${editDriver._id}?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(putApi(data, "EDIT_DRIVER", url));
	};

	useEffect(() => {
		if (
			globalRedux.apiStatus.ADD_DRIVER === "SUCCESS" ||
			globalRedux.apiStatus.EDIT_DRIVER === "SUCCESS"
		) {
			dispatch(resetApiStatus(editDriver ? "EDIT_DRIVER" : "ADD_DRIVER"));
			setDriverAddModal(false);
			handleClose();
		}
	}, [
		globalRedux.apiStatus,
		editDriver,
		setDriverAddModal,
		handleClose,
		dispatch,
	]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const loading =
		globalRedux.apiStatus.ADD_DRIVER === API_STATUS.PENDING ||
		globalRedux.apiStatus.EDIT_DRIVER === API_STATUS.PENDING;

	return (
		<Row>
			<Col span={24}>
				{/* <Row style={{ backgroundColor: '#fff', padding: '10px 0px' }}>
					<Col>
						<h6 style={{ marginBottom: '0px' }}>{editDriver ? 'Edit' : 'New'} Driver</h6>
					</Col>
				</Row>
				<Divider /> */}
				<Row style={{ paddingTop: 0 }}>
					<Col span={24}>
						<Form
							name="add-driver"
							className="required_in_right"
							labelAlign="left"
							form={form}
							onFinish={!editDriver ? addDriver : handleEdit}
							{...layer1FormCol}
						>
							<Form.Item
								label="Driver Name"
								name="driverName"
								initialValue={editDriver?.driverName}
								rules={[
									{
										required: true,
										message: "This Field is Required!",
									},
								]}
							>
								<Input placeholder="Driver Name" />
							</Form.Item>
							<Form.Item
								label="Mobile"
								name="driverMobile"
								initialValue={editDriver?.driverMobile}
								rules={[
									{
										required: true,
										message: "This Field is Required!",
									},
								]}
							>
								<Input placeholder="Driver Name" />
							</Form.Item>
							<Form.Item
								label="Licence No"
								name="licenceNumber"
								initialValue={editDriver?.licenceNumber}
								rules={[
									{
										required: true,
										message: "This Field is Required!",
									},
								]}
							>
								<Input placeholder="Licence No" />
							</Form.Item>
							<Form.Item
								label="Remarks"
								name="remarks"
								initialValue={editDriver?.remarks}
							>
								<TextArea />
							</Form.Item>
							<Form.Item
								wrapperCol={{
									offset: 0,
									span: 24,
								}}
							>
								<Row justify="space-between">
									<Button
										onClick={() => setDriverAddModal(false)}
										style={{ width: "49%" }}
										danger
									>
										Cancel
									</Button>
									<Button
										loading={loading}
										type="primary"
										style={{ width: "49%", marginRight: 5 }}
										htmlType="submit"
									>
										{editDriver ? "Update" : "Create"}
									</Button>
								</Row>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default AddDriver;
