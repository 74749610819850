import toast from 'react-hot-toast';
import { call, put, takeEvery } from 'redux-saga/effects';
import { showToast } from 'helpers';
import { setApiStatus } from '../reducers/globals/globalActions';
import { setLoginSuccess } from '../reducers/login/loginActions';
import { store } from '../store';
import { RESTCallError, sendPostRequest } from './utils';

function postApi(body, apiName, url, extras) {
	return {
		type: 'POST_API_DATA',
		url: url,
		body: body,
		apiName: apiName,
		extras: extras,
	};
}

function* postApiDataSaga() {
	yield takeEvery('POST_API_DATA', postApiDataWorker);
}

function* postApiDataWorker(param) {
	let apiName = param.apiName;
	try {
		store.dispatch(setApiStatus(apiName, 'PENDING'));
		// toast.loading('Processing...');
		const result = yield call(sendPostRequest, param);
		if (result.status >= 200 && result.status <= 204) {
			yield postApiDataSuccess(result.data, apiName, param.extras);
		} else if (result.status === 'Error') {
			toast.error(result?.error?.response?.data?.message || 'Something went wrong!');
			RESTCallError(result, apiName);
		}
	} catch (error) {
		// console.log('error?.response?.data', error);
	}
}

function* postApiDataSuccess(response, apiName, extras) {
	switch (apiName) {
		case 'LOGIN':
			showToast('Success', 'Logged In!');
			yield put(setLoginSuccess(response));
			break;
		case 'ADD_RECEIVED_ENTRY':
			showToast('Success', 'Received entry Added Successfully!');
			break;
		case 'ADD_LOAD':
			showToast('Success', 'Received entry Added Successfully!');
			break;
		case 'EDIT_LOAD':
			showToast('Success', 'Load updated Successfully!');
			break;
		case 'ADD_STUDENT':
			showToast('Success', 'Student Added Successfully!');
			break;
		case 'ADD_VOUCHER':
			showToast('Success', 'Voucher Added Successfully!');
			break;
		case 'ADD_RECEIPT':
			// showToast("Success",'Receipt Added Successfully!');
			showToast('Success', 'Receipt Added Successfully!');
			break;
		case 'ADD_INVOICE':
			showToast('Success', 'Invoice Added Successfully!');
			break;
		case 'ADD_LOAD_IN':
			showToast('Success', 'Load In Added Successfully!');
			break;
		case 'ADD_LOAD_IN_NEW':
			showToast('Success', 'Load In Added Successfully!');
			break;
		case 'ADD_ACCOUNT_BOOK':
			showToast('Success', 'Account book added successfully!');
			break;
		case 'ADD_VOUCHER_HEAD':
			showToast('Success', 'Voucher head added successfully!');
			break;
		case 'ADD_STAFF':
			showToast('Success', 'Staff added successfully!');
			break;
		case 'ADD_ASSETS':
			showToast('Success', 'Asset added successfully!');
			break;
		case 'ADD_LOCATION':
			showToast('Success', 'Location added successfully!');
			break;
		case 'ADD_VEHICLE':
			showToast('Success', 'Vehicle added successfully!');
			break;
		case 'ADD_VENDOR':
			showToast('Success', 'Vendor added successfully!');
			break;
		case 'ADD_VENDOR_MAPPING':
			showToast('Success', 'Vendor Mapping added successfully!');
			break;
		case 'ADD_APPLICATION_USERS':
			showToast('Success', 'User has been created!');
			break;
		case 'ADD_CREDENTIAL':
			showToast('Success', 'Credential added successfully!');
			break;
		case 'ADD_PART_NUMBER':
			showToast('Success', 'PartNumber added successfully!');
			break;
		case 'ADD_BATCH':
			showToast('Success', 'Batch added successfully!');
			break;
		case 'ADD_CLASS':
			showToast('Success', 'Class added successfully!');
			break;
		case 'ADD_BATCH_BALANCE':
			showToast('Success', 'Updated!');
			break;
		case 'DISPATCH_LOAD_OUT':
			showToast('Success', 'Load Dispatched!');
			break;

		default:
			break;
	}
	yield put(setApiStatus(apiName, 'SUCCESS'));
}

export { postApi, postApiDataSaga };
