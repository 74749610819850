import React, { useEffect, useMemo, useRef, useState } from "react";
import {
	Form,
	Input,
	Select,
	Drawer,
	Row,
	Col,
	InputNumber,
	Divider,
} from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { postApi } from "redux/sagas/postApiDataSaga";
import { SERVER_IP } from "assets/Config";
import { resetApiStatus } from "redux/reducers/globals/globalActions";
import {
	API_STATUS,
	BOOKING_STATUS_DROPDOWN,
	DATE_FORMAT,
} from "constants/app-constants";
import DatePicker from "components/date-picker";
import ReactGoogleMaps from "components/react-google-maps";
import { sendGetRequest } from "redux/sagas/utils";
import "./styles.scss";

// const DATA = [
// 	{
// 		latitude: 12.675883333333333,
// 		longitude: 77.86411555555556,
// 		speed: 0,
// 		date: 1722648190228,
// 		isoDate: "2024-08-03T01:23:10.228Z",
// 		odoDistance: 246.8792915974106,
// 		ignitionStatus: "OFF",
// 		status: "OFF",
// 		vehicleStatus: "NODATA",
// 		address: "6 kms from NH 48, Sukkasagaram, Tamil Nadu 635109, India",
// 		regNo: "DEVICE-1",
// 		vehicleType: "BIKE",
// 		vehicleId: "rflRvV",
// 		deviceId: "0869689043714898",
// 		expiryDate: "2025-07-29",
// 		onboardDate: "2024-07-29",
// 		fuelLitre: 0,
// 		bearing: 120,
// 		serverTime: 1722659658179,
// 		insideGeoFence: "N",
// 		triggeredGeoFences: [],
// 		rowId: 0,
// 	},
// 	{
// 		latitude: 12.675866666666666,
// 		longitude: 77.86409777777777,
// 		speed: 0,
// 		date: 1722648190749,
// 		isoDate: "2024-08-03T01:23:10.749Z",
// 		odoDistance: 24.818560877693184,
// 		ignitionStatus: "OFF",
// 		status: "OFF",
// 		vehicleStatus: "NODATA",
// 		address: "6 kms from NH 48, Sukkasagaram, Tamil Nadu 635109, India",
// 		regNo: "LICENSE 79799",
// 		vehicleType: "BUS",
// 		vehicleId: "DLEaHp",
// 		deviceId: "0869689043715473",
// 		expiryDate: "2025-08-03",
// 		onboardDate: "2024-08-03",
// 		fuelLitre: 0,
// 		bearing: 110,
// 		serverTime: 1722659658179,
// 		insideGeoFence: "N",
// 		triggeredGeoFences: [],
// 		rowId: 1,
// 	},
// 	{
// 		latitude: 12.675883333333333,
// 		longitude: 77.86411555555556,
// 		speed: 0,
// 		date: 1722648191487,
// 		isoDate: "2024-08-03T01:23:11.487Z",
// 		odoDistance: 24.929632539325564,
// 		ignitionStatus: "OFF",
// 		status: "OFF",
// 		vehicleStatus: "NODATA",
// 		address: "6 kms from NH 48, Sukkasagaram, Tamil Nadu 635109, India",
// 		regNo: "LICENSE 79800",
// 		vehicleType: "CAR",
// 		vehicleId: "ZQDMke",
// 		deviceId: "0869689043714815",
// 		expiryDate: "2025-08-03",
// 		onboardDate: "2024-08-03",
// 		fuelLitre: 0,
// 		bearing: 100,
// 		serverTime: 1722659658179,
// 		insideGeoFence: "N",
// 		triggeredGeoFences: [],
// 		rowId: 2,
// 	},
// 	{
// 		latitude: 12.675883333333333,
// 		longitude: 77.86409777777777,
// 		speed: 0,
// 		date: 1722648190776,
// 		isoDate: "2024-08-03T01:23:10.776Z",
// 		odoDistance: 24.509817991169943,
// 		ignitionStatus: "OFF",
// 		status: "OFF",
// 		vehicleStatus: "NODATA",
// 		address: "6 kms from NH 48, Sukkasagaram, Tamil Nadu 635109, India",
// 		regNo: "LICENSE 79801",
// 		vehicleType: "CAR",
// 		vehicleId: "tT8ATQ",
// 		deviceId: "0869689043717750",
// 		expiryDate: "2025-08-03",
// 		onboardDate: "2024-08-03",
// 		fuelLitre: 0,
// 		bearing: 80,
// 		serverTime: 1722659658180,
// 		insideGeoFence: "N",
// 		triggeredGeoFences: [],
// 		rowId: 3,
// 	},
// 	{
// 		latitude: 12.675966666666667,
// 		longitude: 77.86408,
// 		speed: 0,
// 		date: 1722649474872,
// 		isoDate: "2024-08-03T01:44:34.872Z",
// 		odoDistance: 24.797147271740492,
// 		ignitionStatus: "OFF",
// 		status: "OFF",
// 		vehicleStatus: "NODATA",
// 		address: "6 kms from NH 48, Sukkasagaram, Tamil Nadu 635109, India",
// 		regNo: "DEVICE-2",
// 		vehicleType: "CAR",
// 		vehicleId: "gbY44K",
// 		deviceId: "0869689043715101",
// 		expiryDate: "2025-08-01",
// 		onboardDate: "2024-08-01",
// 		fuelLitre: 0,
// 		bearing: 0,
// 		serverTime: 1722659658180,
// 		insideGeoFence: "N",
// 		triggeredGeoFences: [],
// 		rowId: 4,
// 	},
// ];

const formItemLayout = {
	labelCol: { span: 24 },
	wrapperCol: { span: 22 },
};

const TrackingStatusUpdateModal = ({
	selectedRow,
	setSelectedRow,
	refreshList,
}) => {
	const globalRedux = useSelector((state) => state.globalRedux);
	const [form] = Form.useForm();
	const organizationId = globalRedux.selectedOrganization._id;
	const [mapData, setMapData] = useState({
		data: null,
		loading: false,
	});
	const statusValue = Form.useWatch("status", form);
	const dispatch = useDispatch();
	const isFirstRender = useRef(true);
	console.log("🚀 ~ mapData:", mapData);

	const getData = async (selectedRow) => {
		try {
			await setMapData({
				...mapData,
				loading: true,
			});
			const res = await sendGetRequest(
				null,
				`${SERVER_IP}booking/track/${selectedRow?.trackingNumber}?orgId=${globalRedux.selectedOrganization._id}`
			);
			await setMapData({
				...mapData,
				loading: false,
				data: res?.data,
			});
			console.log("🚀 ~ getData ~ res?.data:", res?.data);
		} catch (error) {
			console.log("🚀 ~ useEffect ~ error:", error);
		}
	};

	useEffect(async () => {
		if (selectedRow?.trackingNumber) {
			getData(selectedRow);
		}
	}, [selectedRow?.trackingNumber]);

	useEffect(() => {
		if (selectedRow) {
			console.log("🚀 ~ useEffect ~ selectedRow:", selectedRow);
			form.setFieldsValue({
				bookingDate: moment(selectedRow?.bookingDate),
				to: selectedRow?.to?.cityName,
				from: selectedRow?.from?.cityName,
				consignee: selectedRow?.consignee?.displayName,
				consignor: selectedRow?.consignor?.displayName,
				customerFreight: selectedRow?.customerFreight || 0,
				invoiceNumber: selectedRow?.invoiceNumber,
				invoiceDate: moment(selectedRow?.invoiceDate),
				materialId: selectedRow?.materialId?.materialName,
				netValue: selectedRow?.netValue || 0,
				grossValue: selectedRow?.grossValue || 0,
				vehicleId: selectedRow?.vehicleId?.vehicleNumber,
				driverId: selectedRow?.driverId?.driverName,
				gpsId: selectedRow?.gpsId?.gpsName,
				lrNumber_manual: selectedRow?.lrNumber_manual,
				vehicleFreight: selectedRow?.vehicleFreight || 0,
				remarks: selectedRow?.remarks,
				status: selectedRow?.status,
			});
		} else {
			form.resetFields();
		}
		isFirstRender.current = true; // Reset the flag when selectedRow changes
	}, [selectedRow]);

	useEffect(() => {
		if (!isFirstRender.current && statusValue) {
			handleStatusSubmit({ status: statusValue });
		}
		isFirstRender.current = false; // After the first render, set the flag to false
	}, [statusValue]);

	const handleStatusSubmit = (values) => {
		let url = `${SERVER_IP}booking/status`;
		dispatch(
			postApi(
				{
					bookingId: selectedRow?._id,
					status: values?.status,
					orgId: organizationId,
				},
				"UPDATE_BOOKING_STATUS",
				url
			)
		);
	};

	useEffect(() => {
		if (globalRedux.apiStatus.UPDATE_BOOKING_STATUS === API_STATUS.SUCCESS) {
			setSelectedRow(null);
			refreshList();
			dispatch(resetApiStatus("UPDATE_BOOKING_STATUS"));
		}
	}, [globalRedux.apiStatus, dispatch, refreshList]);

	const loading = useMemo(
		() => globalRedux.apiStatus.UPDATE_BOOKING_STATUS === API_STATUS.PENDING,
		[globalRedux.apiStatus]
	);

	return (
		<Drawer
			// okButtonProps={{ form: 'category-editor-form', key: 'submit', htmlType: 'submit' }}
			onOk={form.submit}
			onClose={() => setSelectedRow(null)}
			open={Boolean(selectedRow)}
			destroyOnClose
			width={"100%"}
			title="Booking Details"
			className="add_booking_disabled"
			okButtonProps={{
				loading,
			}}
			okText="Submit"
		>
			<Row gutter={[10, 10]}>
				<Col span={10}>
					{mapData?.data?.trackingDetails?.latitude ? (
						<ReactGoogleMaps
							position={{
								lat: mapData?.data?.trackingDetails?.latitude,
								lng: mapData?.data?.trackingDetails?.longitude,
							}}
							dataLoading={mapData?.loading}
							data={mapData?.data}
						/>
					) : (
						<Row justify="center" align="middle" style={{ height: "100%" }}>
							<Col>
								<h3>No map data found</h3>
							</Col>
						</Row>
					)}
				</Col>
				<Col
					span={14}
					style={{
						overflowY: "scroll",
						height: "calc(100vh - 57px)",
					}}
				>
					<Row justify="center">
						<Col xl={24} md={24}>
							<Form
								form={form}
								requiredMark={false}
								className="form-container"
								{...formItemLayout}
							>
								<Divider>Customer & Booking Details</Divider>
								<Row gutter={[0, 10]}>
									<Col xl={6} md={6}>
										<Form.Item
											label="Consignee(From)"
											rules={[{ required: true }]}
											name="consignee"
										>
											<Input disabled placeholder="Enter customer name" />
										</Form.Item>
									</Col>
									<Col xl={6} md={6}>
										<Form.Item
											label="Consignor(To)"
											rules={[{ required: true }]}
											name="consignor"
										>
											<Input disabled placeholder="Enter customer name" />
										</Form.Item>
									</Col>
									<Col xl={6} md={6}>
										<Form.Item
											label="Booking date"
											initialValue={moment()}
											rules={[{ required: true }]}
											name="bookingDate"
										>
											<DatePicker
												disabled
												style={{ width: "100%" }}
												format={DATE_FORMAT.DD_MM_YYYY}
												placeholder="Select load date"
											/>
										</Form.Item>
									</Col>
									<Col xl={6} md={6}>
										<Form.Item
											label="From"
											rules={[{ required: true }]}
											name="from"
										>
											<Input disabled placeholder="Select from" />
										</Form.Item>
									</Col>
									<Col xl={6} md={6}>
										<Form.Item
											label="To"
											rules={[{ required: true }]}
											name="to"
										>
											<Input disabled placeholder="Select to" />
										</Form.Item>
									</Col>
									<Col xl={6} md={6}>
										<Form.Item
											label="Freight Rs."
											name="customerFreight"
											rules={[{ required: false }]}
										>
											<InputNumber
												disabled
												style={{ width: "100%" }}
												placeholder="Enter customer freight"
											/>
										</Form.Item>
									</Col>
								</Row>
								<Divider>Consignment Details</Divider>
								<Row gutter={[0, 10]}>
									<Col xl={6} md={6}>
										<Form.Item
											label="Invoice No:"
											name="invoiceNumber"
											rules={[{ required: false }]}
										>
											<Input disabled placeholder="Enter invoice number" />
										</Form.Item>
									</Col>
									<Col xl={6} md={6}>
										<Form.Item
											label="Invoice date"
											initialValue={moment()}
											rules={[{ required: true }]}
											name="invoiceDate"
										>
											<DatePicker
												style={{ width: "100%" }}
												format={DATE_FORMAT.DD_MM_YYYY}
												placeholder="Select invoice date"
												disabled
											/>
										</Form.Item>
									</Col>
									<Col xl={6} md={6}>
										<Form.Item
											label="Consignment"
											rules={[{ required: true }]}
											name="materialId"
										>
											<Input disabled placeholder="Select material" />
										</Form.Item>
									</Col>
									<Col xl={6} md={6}>
										<Form.Item
											label="Net Value Rs."
											name="netValue"
											rules={[{ required: false }]}
										>
											<InputNumber
												disabled
												style={{ width: "100%" }}
												placeholder="Enter net value"
											/>
										</Form.Item>
									</Col>
									<Col xl={6} md={6}>
										<Form.Item
											label="Gross Value Rs."
											rules={[{ required: false }]}
											name="grossValue"
										>
											<InputNumber
												disabled
												style={{ width: "100%" }}
												placeholder="Enter gross value"
											/>
										</Form.Item>
									</Col>
								</Row>
								<Divider>Vehicle Details</Divider>
								<Row gutter={[0, 10]}>
									<Col xl={6} md={6}>
										<Form.Item
											label="Vehicle"
											rules={[{ required: true }]}
											name="vehicleId"
										>
											<Input disabled placeholder="Select vehicle" />
										</Form.Item>
									</Col>
									<Col xl={6} md={6}>
										<Form.Item
											label="Driver"
											rules={[{ required: true }]}
											name="driverId"
										>
											<Input disabled placeholder="Select driver" />
										</Form.Item>
									</Col>
									<Col xl={6} md={6}>
										<Form.Item
											label="GPS"
											rules={[{ required: true }]}
											name="gpsId"
										>
											<Input disabled placeholder="Select gps" />
										</Form.Item>
									</Col>

									<Col xl={6} md={6}>
										<Form.Item
											label="LR Number(Manual)"
											rules={[{ required: false }]}
											name="lrNumber_manual"
										>
											<Input
												style={{ width: "100%" }}
												placeholder="Enter LR number (manual)"
												disabled
											/>
										</Form.Item>
									</Col>

									<Col xl={6} md={6}>
										<Form.Item
											label="Vehicle Freight Rs."
											rules={[{ required: false }]}
											name="vehicleFreight"
										>
											<InputNumber
												style={{ width: "100%" }}
												placeholder="Enter vehicle freight"
												disabled
											/>
										</Form.Item>
									</Col>
								</Row>
								<Divider>Other Details</Divider>
								<Row gutter={[0, 10]}>
									<Col xl={12} md={12}>
										<Form.Item label="Remarks" name="remarks">
											<Input.TextArea placeholder="Enter remarks" disabled />
										</Form.Item>
									</Col>
									<Col xl={5} md={5}>
										<Form.Item
											label="Change Status"
											name="status"
											rules={[
												{
													required: true,
													message: "Please Select One!",
												},
											]}
										>
											<Select placeholder="Select Status">
												{BOOKING_STATUS_DROPDOWN?.map((value) => (
													<Select.Option key={value} value={value}>
														{value}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
									</Col>
								</Row>
							</Form>
						</Col>
					</Row>
				</Col>
			</Row>
		</Drawer>
	);
};

export default TrackingStatusUpdateModal;
