import * as FA from "react-icons/fa";
import * as FI from "react-icons/fi";
import * as MD from "react-icons/md";
import * as FC from "react-icons/fc";
import * as RI from "react-icons/ri";
import Board from "pages/board";
import Dashboard from "pages/dashboard";
import StudentsList from "pages/students/students-list";
import Master from "pages/master/master";
import VoucherList from "pages/voucher/voucher-list";
import OutEntry from "pages/load-out/load-out-list";
import LoadIn from "pages/load-in/load-in-list";
import Stock from "pages/stock/stock-list";
import ReceivedEntry from "pages/received-entry/received-entry-list";
import CreatedLoad from "pages/create-load/create-load-list";
import Invoice from "pages/invoice/invoice-list";
import WHInvoice from "pages/wh-invoice/wh-invoice-list";
import InvoiceReport from "pages/invoice-report";
import StockByLocation from "pages/stock-by-location/stock-list";
import ReceiptList from "pages/receipt/receipt-list";
import OutStandingReportList from "pages/outstanding-report";
import RouteCard from "pages/route-card/route-card-list";
import StockReturnList from "pages/stock/stock-return-list";
import InvoiceValidation from "pages/invoice-validation";
import Planning from "pages/plannings/planning-list";
import BookingsList from "pages/bookings/bookings-list";
import UserList from "pages/users/user-list";

const ORDER_STATUS_DROPDOWN = [
	"Pending",
	"Ready to Dispatch",
	"Completed",
	"Cancelled",
];
const BOOKING_STATUS_DROPDOWN = [
	"In Progress",
	"InTransit",
	"Halting",
	"Delivered",
	"Cancelled",
];

const API_STATUS = {
	PENDING: "PENDING",
	SUCCESS: "SUCCESS",
	ERROR: "ERROR",
};

const ACTIONS = {
	ADD_STUDENT: "ADD_STUDENT",
	ADD_RECEIVED_ENTRY: "ADD_RECEIVED_ENTRY",
	ADD_PLAN: "ADD_PLAN",
	ADD_LOAD: "ADD_LOAD",
	EDIT_BOOKING: "EDIT_BOOKING",
	ADD_BOOKING: "ADD_BOOKING",
	ADD_RECEIPT: "ADD_RECEIPT",
	EDIT_RECEIPT_NEW: "EDIT_RECEIPT_NEW",
	ADD_VOUCHER: "ADD_VOUCHER",
	ADD_VOUCHER_HEAD: "ADD_VOUCHER_HEAD",
	EDIT_STUDENT: "EDIT_STUDENT",
	EDIT_VOUCHER: "EDIT_VOUCHER",
	GET_AGENTS: "GET_AGENTS",
	GET_INVOICES: "GET_INVOICES",
	GET_WH_INVOICES: "GET_WH_INVOICES",
	GET_TICKETS: "GET_TICKETS",
	GET_STUDENTS: "GET_STUDENTS",
	GET_LOADS: "GET_LOADS",
	GET_STUDENTS_FEES: "GET_STUDENTS_FEES",
	GET_VOUCHERS: "GET_VOUCHERS",
	GET_RECEIPTS: "GET_RECEIPTS",
	GET_LOAD_IN: "GET_LOAD_IN",
	GET_LOAD_OUT: "GET_LOAD_OUT",
	GET_STOCKS: "GET_STOCKS",
	GET_OUTSTANDING_REPORTS: "GET_OUTSTANDING_REPORTS",
	GET_STOCKS_BY_LOCATIONS: "GET_STOCKS_BY_LOCATIONS",
	GET_VOUCHERS_HEAD: "GET_VOUCHERS_HEAD",
	GET_DASHBOARD_DATA: "GET_DASHBOARD_DATA",
	GET_ITEMS: "GET_ITEMS",
	GET_CLASSES: "GET_CLASSES",
	GET_ACCOUNT_BOOKS: "GET_ACCOUNT_BOOKS",
	SET_ACCOUNT_BOOKS: "SET_ACCOUNT_BOOKS",
	GET_MATERIALS: "GET_MATERIALS",
	SET_MATERIALS: "SET_MATERIALS",
	GET_GPSS: "GET_GPSS",
	SET_GPSS: "SET_GPSS",
	GET_GRADES: "GET_GRADES",
	SET_GRADES: "SET_GRADES",
	GET_CITIES: "GET_CITIES",
	SET_CITIES: "SET_CITIES",
	GET_PARTIES: "GET_PARTIES",
	SET_PARTIES: "SET_PARTIES",
	GET_BANK_DETAILS: "GET_BANK_DETAILS",
	SET_BANK_DETAILS: "SET_BANK_DETAILS",
	GET_RC_OWNERS: "GET_RC_OWNERS",
	SET_RC_OWNERS: "SET_RC_OWNERS",
	GET_DRIVERS: "GET_DRIVERS",
	SET_DRIVERS: "SET_DRIVERS",
};

export const DATE_FORMAT = {
	YYYY_MM_DD: "YYYY-MM-DD",
	DD_MM_YYYY: "DD-MM-YYYY",
	DD_MM_YYYY_SLASH: "DD/MM/YYYY",
	MM_DD_YYYY: "MM-DD-YYYY",
};

const SIDE_MENUS = [
	{
		name: "Users",
		icon: <FI.FiUsers />,
		route: "/users",
		key: "1",
	},
	{
		name: "Preferences",
		icon: <FI.FiSettings />,
		route: "/preferences",
		key: "3",
	},
	{
		name: "Settlings",
		icon: <FI.FiSettings />,
		route: "/settings",
		key: "2",
	},
	// {
	// 	name: 'Masters',
	// 	icon: <FI.FiSettings />,
	// 	route: '/projects',
	// 	key: '8',
	// },
];

const MENUS = [
	{
		name: "Dashboard",
		icon: <MD.MdDashboard />,
		route: "/",
		key: "1",
	},
	// {
	// 	name: 'Route card',
	// 	icon: <MD.MdDashboard />,
	// 	route: '/route-card',
	// 	key: '17',
	// },
	// {
	// 	name: 'New Bookings',
	// 	icon: <RI.RiBillLine />,
	// 	route: '/create-booking',
	// 	key: '3',
	// },
	{
		name: "Bookings",
		icon: <RI.RiBillLine />,
		route: "/bookings",
		key: "4",
	},
	// {
	// 	name: 'Load IN',
	// 	icon: <RI.RiBillLine />,
	// 	route: '/load-in',
	// 	key: '4',
	// },
	// {
	// 	name: 'Loads',
	// 	icon: <RI.RiBillLine />,
	// 	key: '2',
	// 	submenus: [
	// 		{
	// 			name: 'Create Load',
	// 			icon: <RI.RiBillLine />,
	// 			route: '/create-load',
	// 			key: '3',
	// 		},
	// 		{
	// 			name: 'Load IN',
	// 			icon: <FC.FcDownload />,
	// 			route: '/load-in',
	// 			key: '4',
	// 		},
	// 	],
	// },
	// {
	// 	name: 'Planning',
	// 	icon: <RI.RiBillLine />,
	// 	route: '/planning',
	// 	key: '20',
	// },
	// {
	// 	name: 'Dispatch',
	// 	icon: <RI.RiBillLine />,
	// 	route: '/dispatch',
	// 	key: '5',
	// },
	{
		name: "Invoices",
		icon: <RI.RiBillLine />,
		key: "6",
		submenus: [
			{
				name: 'Invoice List',
				icon: <RI.RiBillLine />,
				route: '/invoice',
				key: '7',
			},
			{
				name: 'Invoice Validation',
				icon: <RI.RiBillLine />,
				route: '/invoice-validation',
				key: '19',
			},
			{
				name: 'Receipt',
				icon: <FC.FcDownload />,
				route: '/receipt',
				key: '8',
			},
		],
	},
	{
		name: "Vouchers",
		icon: <RI.RiBillLine />,
		route: "/voucher",
		key: "9",
	},
	// {
	// 	name: 'Stock report',
	// 	icon: <RI.RiBillLine />,
	// 	route: '/stocks',
	// 	key: '11',
	// },
	// {
	// 	name: 'Stock Return',
	// 	icon: <RI.RiBillLine />,
	// 	route: '/stock-return',
	// 	key: '18',
	// },
	{
		name: "Reports",
		icon: <RI.RiBillLine />,
		key: "10",
		submenus: [
			// {
			// 	name: 'Stock report by location',
			// 	icon: <FC.FcDownload />,
			// 	route: '/stocks-by-location',
			// 	key: '12',
			// },
			{
				name: "Invoice Report",
				icon: <FA.FaFileInvoice />,
				route: "/invoice-report",
				key: "13",
			},
			// {
			// 	name: 'Customer Balance Report',
			// 	icon: <FA.FaFileInvoice />,
			// 	route: '/customer-balance-report',
			// 	key: '14',
			// },
			{
				name: "Outstanding report",
				icon: <RI.RiBillLine />,
				route: "/outstanding-report",
				key: "15",
			},
		],
	},
	// {
	// 	name: 'Received Entry',
	// 	icon: <MD.MdDashboard />,
	// 	route: '/received-entry',
	// 	key: '7',
	// },
	// {
	// 	name: 'Load IN',
	// 	icon: <FC.FcDownload />,
	// 	route: '/load-in',
	// 	key: '3',
	// },
	// {
	// 	name: 'Load OUT',
	// 	icon: <FC.FcUpload />,
	// 	route: '/load-out',
	// 	key: '4',
	// },
	// {
	// 	name: 'Stocks',
	// 	icon: <FA.FaFileInvoice />,
	// 	route: '/stocks',
	// 	key: '5',
	// },
	// {
	// 	name: 'Stocks by locations',
	// 	icon: <FA.FaFileInvoice />,
	// 	route: '/stocks-by-location',
	// 	key: '6',
	// },
	// {
	// 	name: 'Invoice',
	// 	icon: <FA.FaFileInvoice />,
	// 	route: '/invoice',
	// 	key: '7',
	// },
	// {
	// 	name: 'Invoice Report',
	// 	icon: <FA.FaFileInvoice />,
	// 	route: '/invoice-report',
	// 	key: '8',
	// },
	// {
	// 	name: 'Receipt',
	// 	icon: <FA.FaReceipt />,
	// 	route: '/fees-receipt',
	// 	key: '9',
	// },
	{
		name: "Masters",
		icon: <FI.FiSettings />,
		route: "/masters",
		key: "16",
	},
];

const ROUTES = [
	{
		route: "/received-entry",
		component: ReceivedEntry,
	},
	{
		route: "/students",
		component: StudentsList,
	},
	// {
	// 	route: '/loading',
	// 	component: FeesBalance,
	// },
	{
		route: "/load-in",
		component: LoadIn,
	},
	{
		route: "/dispatch",
		component: OutEntry,
	},
	{
		route: "/planning",
		component: Planning,
	},
	{
		route: "/route-card",
		component: RouteCard,
	},
	{
		route: "/stocks",
		component: Stock,
	},
	{
		route: "/stock-return",
		component: StockReturnList,
	},
	{
		route: "/outstanding-report",
		component: OutStandingReportList,
	},
	{
		route: "/stocks-by-location",
		component: StockByLocation,
	},
	// {
	// 	route: '/invoice',
	// 	component: Invoice,
	// },
	{
		route: "/invoice",
		component: WHInvoice,
	},
	{
		route: "/invoice-validation",
		component: InvoiceValidation,
	},
	{
		route: "/invoice-report",
		component: InvoiceReport,
	},
	{
		route: "/create-booking",
		component: CreatedLoad,
	},
	{
		route: "/users",
		component: UserList,
	},
	{
		route: "/bookings",
		component: BookingsList,
	},
	{
		route: "/create-booking",
		component: CreatedLoad,
	},
	{
		route: "/receipt",
		component: ReceiptList,
	},
	{
		route: "/masters",
		component: Master,
	},
	{
		route: "/voucher",
		component: VoucherList,
	},
	{
		route: "/board",
		component: Board,
	},
	{
		route: "/",
		component: Dashboard,
	},
];

export {
	ACTIONS,
	MENUS,
	SIDE_MENUS,
	ROUTES,
	API_STATUS,
	ORDER_STATUS_DROPDOWN,
	BOOKING_STATUS_DROPDOWN,
};

const CUSTOMER_TYPE = [
	{ label: "Individual", value: "individual" },
	{ label: "Business", value: "business" },
];
const PARTY_TYPE = ["Customer", "Supplier"];
const ACCOUNT_TYPE = ["Current", "Savings"];
const CATEGORIES = ["Individual", "Business"];
const USER_TYPE = ["SuperAdmin", "Admin", "Staff"];
const GST_TREATMENT = [
	{ label: "Consumer", value: "consumer" },
	{ label: "Registered Business", value: "registered" },
];
const PLACE_OF_SUPPLY = ["Tamilnadu"];

const STATUS_DROPDOWN = [
	"Open",
	"Assigned",
	"Accepted",
	"In Progress",
	"Completed",
	"Cancelled",
	"Pending",
];

export {
	CUSTOMER_TYPE,
	GST_TREATMENT,
	PLACE_OF_SUPPLY,
	USER_TYPE,
	CATEGORIES,
	STATUS_DROPDOWN,
	PARTY_TYPE,
	ACCOUNT_TYPE,
};

export const STATUS = {
	InStock: "processing",
	InTransit: "processing",
	Delivered: "success",
	Dispatched: "success",
	Partially_Dispatched: "warning",
	Halting: "warning",
	Cancelled: "error",
};

export const ROLE = {
	manager: "processing",
	staff: "warning",
	admin: "success",
};

export const NOTIFICATION_STATUS_TYPES = {
	SUCCESS: "SUCCESS",
	INFO: "INFO",
	ERROR: "ERROR",
	WARNING: "WARNING",
};

export const DEPARTMENT_LIST = [
	"Rufcasting",
	"Scrap",
	"Rejection",
	"Others ",
	"Invoice ",
];
