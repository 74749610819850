import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Select, Input, DatePicker } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { DeleteOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import { SERVER_IP } from 'assets/Config';
import { API_STATUS, DATE_FORMAT } from 'constants/app-constants';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { sendGetRequest } from 'redux/sagas/utils';
import AddInvoicePresentational from './add-invoice-presentational';
import { putApi } from 'redux/sagas/putApiSaga';
import moment from 'moment';
import { getApi } from 'redux/sagas/getApiDataSaga';

const { Option } = Select;
const { TextArea } = Input;

let itemDefaultRecord = {
	lrNumber: null,
	sacNumber: null,
	date: null,
	whousers: null,
	whkg: null,
	amc: null,
	others: null,
	lrCharges: null,
	id: uuidv4(),
};

const AddInvoiceFunctional = ({ state, setState, refreshList, editData }) => {
	const [form] = Form.useForm();
	const [tableData, setTableData] = useState([{ ...itemDefaultRecord, id: uuidv4() }]);
	const [searchList, setSearchList] = useState({
		data: [],
		loading: false,
		searchString: '',
	});
	const [receiptState, setReceiptState] = useState({
		studentId: null,
		receiptDate: moment(),
	});
	const globalRedux = useSelector((state) => state.globalRedux);
	const { accountBooks, categories } = globalRedux;
	const vendors = globalRedux?.vendors;
	const { classes = [] } = globalRedux;
	const dispatch = useDispatch();

	const getVendor = () => {
		let url = `${SERVER_IP}vendor?orgId=${globalRedux.selectedOrganization._id}`;
		dispatch(getApi('GET_VENDORS', url));
	};

	useEffect(() => {
		// getVendor();
	}, []);

	const generateTableData = (editData) => {
		if (editData?.receiptDetails?.length > 0) {
			const data = editData.receiptDetails.map((item) => ({
				categoryId: item?.categoryId?.id || '',
				transactionType: item?.transactionType || '',
				accbookId: item?.accbookId?.id || '',
				amount: item?.amount || '',
				particulars: item?.particulars || '',
				id: uuidv4(),
			}));
			setTableData(data);
		}
	};

	const handleVendorSelect = (vendorId) => {
		const vendor = vendors?.find((vendor) => {
			return vendor?._id === vendorId;
		});
		form.setFieldsValue({
			billingAddress: vendor?.billingAddress||'',
			gstin: vendor?.gstin
		});
		
	};

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_RECEIPT === API_STATUS.SUCCESS || globalRedux.apiStatus.EDIT_RECEIPT === API_STATUS.SUCCESS) {
			setState({ ...state, visible: false });
			refreshList();
			dispatch(resetApiStatus(editData ? 'EDIT_RECEIPT' : 'ADD_RECEIPT'));
		}
		if (editData) {
			generateTableData(editData);
			setReceiptState({
				studentId: editData?.studentId?._id || '',
				receiptDate: editData?.receiptDate || '',
			});
		}
		!editData && setTableData([{ ...itemDefaultRecord, id: uuidv4() }]);
	}, [globalRedux.apiStatus, editData]);

	const handleRowClick = (data) => {
		form.setFieldsValue({
			customerName: data?.displayName,
			mobile: data?.contact,
		});
	};

	const handleSubmit = () => {
		const request = {
			orgId: globalRedux.selectedOrganization._id,
			...receiptState,
			totalAmount,
			receiptDetails: tableData
				.filter((data) => data.categoryId)
				.map(({ accbookId, categoryId, particulars, transactionType, amount }) => ({
					accbookId,
					categoryId,
					particulars,
					transactionType,
					amount,
				})),
		};

		editData ? dispatch(putApi(request, 'EDIT_RECEIPT', `${SERVER_IP}receipt/${editData?._id}`)) : dispatch(postApi(request, 'ADD_RECEIPT'));
	};

	const handleSearch = async (searchString) => {
		setSearchList({
			...searchList,
			searchString,
		});
		if (searchString) {
			setSearchList({
				...searchList,
				loading: true,
			});
			const { data } = await sendGetRequest(
				null,
				`${SERVER_IP}student/search?orgId=${globalRedux.selectedOrganization._id}&searchText=${searchString}`
			);
			setSearchList({
				...searchList,
				data,
				loading: false,
			});
		}
	};

	const debounceFn = useCallback(debounce(handleSearch, 1000), []);
	const handleChange = (selectedValue) => {
		setReceiptState({
			...receiptState,
			studentId: selectedValue,
		});
	};

	const handleInputChange = useCallback(
		(label, value, rowId) => {
			const data = tableData.map((data) => {
				if (data.id === rowId) {
					return {
						...data,
						[label]: value,
					};
				} else {
					return data;
				}
			});
			setTableData([...data]);
		},
		[tableData]
	);

	const columns = [
		{
			title: 'LR. No',
			dataIndex: 'lrNumber',
			key: 'lrNumber',
			width: '10%',
			// align: 'right',
			render: (value, record) => (
				<Input
					type="number"
					pattern="^-?[0-9]\d*\.?\d*$"
					value={value}
					placeholder="lrNumber"
					className={`${record?.lrNumber && !value ? 'error' : ''}`}
					onChange={({ target: { value } }) => handleInputChange('lrNumber', parseFloat(value), record?.id)}
				/>
			),
		},
		{
			title: 'SAC',
			dataIndex: 'sacNumber',
			key: 'sacNumber',
			width: '10%',
			// align: 'right',
			render: (value, record) => (
				<Input
					type="number"
					pattern="^-?[0-9]\d*\\d*$"
					value={value}
					disabled={!record?.lrNumber}
					placeholder="sacNumber"
					className={`${record?.lrNumber && !value ? 'error' : ''}`}
					onChange={({ target: { value } }) => handleInputChange('sacNumber', parseFloat(value), record?.id)}
				/>
			),
		},
		{
			title: 'Date',
			dataIndex: 'date',
			key: 'date',
			width: '10%',
			render: (value, record) => (
				<DatePicker
					value={value}
					disabled={!record?.lrNumber}
					className={`${record?.lrNumber && !value ? 'error' : ''}`}
					style={{ width: '100%' }}
					format={DATE_FORMAT.DD_MM_YYYY}
					onChange={(value) => handleInputChange('date', value === 'Invalid date' ? '' : value, record?.id)}
				/>
			),
		},
		{
			title: 'WHouse Rs',
			dataIndex: 'whousers',
			key: 'whousers',
			width: '10%',
			render: (value, record) => (
				<Input
					type="number"
					pattern="^-?[0-9]\d*\.?\d*$"
					value={value}
					placeholder="whousers"
					disabled={!record?.lrNumber}
					className={`${record?.lrNumber && !value ? 'error' : ''}`}
					onChange={({ target: { value } }) => handleInputChange('whousers', parseFloat(value), record?.id)}
				/>
			),
		},
		{
			title: 'WH KG',
			dataIndex: 'whkg',
			key: 'whkg',
			width: '10%',
			// align: 'right',
			render: (value, record) => (
				<Input
					type="number"
					pattern="^-?[0-9]\d*\.?\d*$"
					value={value}
					placeholder="whkg"
					disabled={!record?.lrNumber}
					className={`${record?.lrNumber && !value ? 'error' : ''}`}
					onChange={({ target: { value } }) => handleInputChange('whkg', parseFloat(value), record?.id)}
				/>
			),
		},
		{
			title: 'AMC',
			dataIndex: 'amc',
			key: 'amc',
			width: '10%',
			render: (value, record) => (
				<Input
					type="number"
					pattern="^-?[0-9]\d*\.?\d*$"
					value={value}
					placeholder="amc"
					disabled={!record?.lrNumber}
					className={`${record?.lrNumber && !value ? 'error' : ''}`}
					onChange={({ target: { value } }) => handleInputChange('amc', parseFloat(value), record?.id)}
				/>
			),
		},
		{
			title: 'Others',
			dataIndex: 'others',
			key: 'others',
			width: '10%',
			render: (value, record) => (
				<Input
					type="number"
					pattern="^-?[0-9]\d*\.?\d*$"
					value={value}
					placeholder="others"
					disabled={!record?.lrNumber}
					className={`${record?.lrNumber && !value ? 'error' : ''}`}
					onChange={({ target: { value } }) => handleInputChange('others', parseFloat(value), record?.id)}
				/>
			),
		},
		{
			title: 'LR Charges',
			dataIndex: 'lrCharges',
			key: 'lrCharges',
			width: '10%',
			render: (value, record) => (
				<Input
					type="number"
					pattern="^-?[0-9]\d*\.?\d*$"
					value={value}
					placeholder="lrCharges"
					disabled={!record?.lrNumber}
					className={`${record?.lrNumber && !value ? 'error' : ''}`}
					onChange={({ target: { value } }) => handleInputChange('lrCharges', parseFloat(value), record?.id)}
				/>
			),
		},
		{
			title: 'Total Rs.',
			dataIndex: 'totalRs',
			key: 'totalRs',
			align: 'center',
			width: '3%',
			render: (value, data) => (
				// <span>{tableData?.reduce((acc, data) => acc + data?.whousers + data?.amc + data?.others + data?.lrCharges, 0)}</span>
				<span>{parseFloat((data?.whousers || 0) + (data?.amc || 0) + (data?.others || 0) + (data?.lrCharges || 0)).toFixed(2)}</span>
			),
		},
		{
			title: '',
			dataIndex: 'item',
			key: 'item',
			align: 'center',
			width: '3%',
			render: (value, record) =>
				tableData.length > 1 ? <DeleteOutlined style={{ color: 'red' }} onClick={() => handleRemove(record.id)} /> : null,
		},
	];

	const handleRemove = (id) => {
		const data = tableData.filter((data) => data.id !== id);
		setTableData([...data]);
	};

	const handleAddTableData = useCallback(() => {
		let data = [...tableData];
		data.push({
			...itemDefaultRecord,
			id: uuidv4(),
		});
		setTableData(data);
	}, [tableData]);

	const {} = useMemo(() => {
		const selectedList = tableData?.map((data) => data.accBookId).filter((data) => data);
		const filledList = tableData
			?.map(
				(data) =>
					data?.lrNumber && data?.sacNumber && data?.date && data?.whousers && data?.whkg && data?.amc && data?.others && data?.lrCharges
			)
			.filter((data) => data);
		if (tableData?.length === filledList.length) {
			handleAddTableData();
		}
		return {
			selectedList,
		};
	}, [tableData]);

	const loading = globalRedux.apiStatus.ADD_RECEIPT === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_RECEIPT === API_STATUS.PENDING;
	const totalAmount = useMemo(() => tableData.reduce((accum, item) => accum + item.amount, 0) || 0, [tableData]);

	// const keyMap = {
	// 	NEW_ROW: { sequence: 4, action: 'ctrl+n' },
	// };

	// const keyHandlers = {
	// 	NEW_ROW: () => handleAddTableData,
	// };

	return (
		<AddInvoicePresentational
			{...{
				state,
				setState,
				form,
				handleSubmit,
				handleRowClick,
				classes,
				loading,
				columns,
				tableData,
				debounceFn,
				searchList,
				handleChange,
				totalAmount,
				receiptState,
				setReceiptState,
				editData,
				vendors,
				handleVendorSelect,
				// keyMap,
				// keyHandlers,
			}}
		/>
	);
};

export default AddInvoiceFunctional;
