import { combineReducers } from 'redux';
import storageSession from 'redux-persist/lib/storage/session';
import { persistReducer } from 'redux-persist';
import { globalReducer } from './globals/globalReducer';
import { loginReducer } from './login/loginReducer';
import { customerReducer } from './customers/customerReducer';
import { supplierReducer } from './supplier/supplierReducer';
import { projectReducer } from './projects/reducer';
// import { userReducer } from './users/reducer';
import { itemReducer } from './items/reducer';
import { voucherReducer } from './vouchers/voucherReducer';
import { agentReducer } from './agent/agentReducer';
import { subItemReducer } from './sub-items/reducer';
import { invoiceReducer } from './invoices/invoiceReducer';
import { dashboardReducer } from './dashboard/dashboardReducer';
import { staffReducer } from './staffs/staffReducer';
import { purchaseReducer } from './purchse/purchaseReducer';
import { AccBooksReducer } from './accBooks/accBookReducer';
import { dailyTransactionReducer } from './daily-transaction/dailyTransactionReducer';
import { StudentsReducer } from './students/studentsReducer';
import { ReceiptsReducer } from './receipts/receiptReducer';
import { StudentsFeesReducer } from './studentFees/studentFeesReducer';
import { LoadInReducer } from './load-in/loadInReducer';
import { LoadOutReducer } from './load-out/loadOutReducer';
import { StocksReducer } from './stocks/stocksReducer';
import { LoadsReducer } from './loads/loadsReducer';
import { whInvoiceReducer } from './wh-invoices/whInvoiceReducer';
import { StocksByLocationsReducer } from './stocks-by-locations/stocksByLocaitonsReducer';
import { OutstandingReportsReducer } from './outstanding-reports/outstandingReportsReducer';
import { StocksReturnsReducer } from './stocks-return/stocksReturnReducer';

const persistConfig = {
	key: 'root',
	storage: storageSession,
};

const allReducers = combineReducers({
	loginRedux: loginReducer,
	globalRedux: globalReducer,
	customerRedux: customerReducer,
	projectRedux: projectReducer,
	// userRedux: userReducer,
	itemRedux: itemReducer,
	supplierRedux: supplierReducer,
	agentRedux: agentReducer,
	voucherRedux: voucherReducer,
	dashboardRedux: dashboardReducer,
	subItemRedux: subItemReducer,
	invoiceRedux: invoiceReducer,
	whInvoiceRedux: whInvoiceReducer,
	purchaseRedux: purchaseReducer,
	staffRedux: staffReducer,
	accBookRedux: AccBooksReducer,
	studentsRedux: StudentsReducer,
	studentsFeesRedux: StudentsFeesReducer,
	receiptsRedux: ReceiptsReducer,
	loadInRedux: LoadInReducer,
	loadOutRedux: LoadOutReducer,
	loadsRedux: LoadsReducer,
	stocksRedux: StocksReducer,
	stocksReturnsRedux: StocksReturnsReducer,
	outstandingReportsRedux: OutstandingReportsReducer,
	stocksByLocationsRedux: StocksByLocationsReducer,
	dailyTransactionRedux: dailyTransactionReducer,
});

export default persistReducer(persistConfig, allReducers);
