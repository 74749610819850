import React, { forwardRef } from 'react';
import moment from 'moment';
import ESign from 'assets/images/eSign.jpeg';
import { DATE_FORMAT } from 'constants/app-constants';

const RouteCardToPrint = forwardRef((props, ref) => {
	// const { firstName = '', lastName = '' } = useSelector((state) => state?.loginRedux);
	const {
		invoiceDate,
		lrNumber,
		stockQty,
		invoiceNumber,
		binNumber,
		locationId,
		quantity,
		vehicleId: { regNumber } = { regNumber: '' },
		vendorId: { vendorName } = { vendorName: '' },
		partNumberId: { partNumber } = { partNumber: '' },
	} = props?.data || {};

	return (
		<div class="routeCard" ref={ref}>
			<div class="routeCard-pdf">
				<div className="routeCard-pdf-container">
					<table cellPadding="0" cellSpacing="0">
						<tr class="routeCard-top">
							<td colSpan="7" style={{ padding: 0, paddingTop: 0 }}>
								<table className="bordered-bottom">
									<tr>
										<td style={{ height: 46 }} class="title">
											<div className="content">
												<div className="name" style={{ color: '#000', fontSize: 24, fontWeight: 'bold' }}>
													{vendorName || ''}
												</div>
											</div>
										</td>
									</tr>
								</table>
							</td>
						</tr>
						<tr class="heading">
							<table className="value-table">
								<tr>
									<td>INVOICE NO</td>
									<td style={{ fontSize: 22, fontWeight: 'bold' }}>{invoiceNumber || ''}</td>
									<td>LR NO</td>
									<td style={{ fontSize: 22, fontWeight: 'bold' }}>{lrNumber || ''}</td>
								</tr>
								<tr>
									<td>INVOICE DATE</td>
									<td style={{ fontSize: 22, fontWeight: 'bold' }}>
										{invoiceDate ? moment(invoiceDate).format(DATE_FORMAT.DD_MM_YYYY) : ''}
									</td>
									<td>VEHICLE NO</td>
									<td style={{ fontSize: 22, fontWeight: 'bold' }}>{regNumber}</td>
								</tr>
								<tr>
									<td>QTY</td>
									<td style={{ fontSize: 22, fontWeight: 'bold' }}>{quantity}</td>
									<td>GRN NO</td>
									<td style={{ fontSize: 22, fontWeight: 'bold' }}></td>
								</tr>
								<tr>
									<td>BIN No</td>
									<td style={{ fontSize: 22, fontWeight: 'bold' }}>{binNumber}</td>
									<td>Location</td>
									<td style={{ fontSize: 22, fontWeight: 'bold' }}>{locationId?.locationName}</td>
								</tr>
								<tr>
									<td>PART NO</td>
									<td colSpan="3" style={{ fontSize: 24, fontWeight: 'bold' }}>
										{partNumber}
									</td>
								</tr>
							</table>
						</tr>
						<tr class="heading">
							<table className="value-table">
								<tr>
									<td>UNLOADING SIGN</td>
									<td>SUPERVISOR SIGN</td>
									<td>OPERATOR SIGN</td>
								</tr>
								<tr>
									<td> </td>
									<td> </td>
									<td> </td>
								</tr>
								<tr>
									<td colSpan="3">MATERIAL LOADING</td>
								</tr>
								<tr>
									<td>OPERATOR SIGN</td>
									<td>SUPERVISOR SIGN</td>
									<td>SCHAEFFLER SIGN</td>
								</tr>
								<tr>
									<td> </td>
									<td> </td>
									<td> </td>
								</tr>
							</table>
						</tr>
					</table>
				</div>
			</div>
		</div>
	);
});

export default RouteCardToPrint;
