import React, { useCallback } from 'react';
import { Button, Form, Input, Select, Modal, Drawer, Row, DatePicker, Col } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { DATE_FORMAT } from 'constants/app-constants';
import moment from 'moment';

const { confirm } = Modal;

const formItemLayout = {
	labelCol: { span: 24 },
	wrapperCol: { span: 22 },
};

const AddReceivedEntryPresentational = ({ state, setState, form, handleSubmit, classes = [], loading, handleEdit, vendors, vehicles }) => {
	const showConfirm = useCallback(() => {
		confirm({
			title: 'Do you want to close this window?',
			icon: <ExclamationCircleOutlined />,
			content: 'You will be lost all the details you have entered here.',
			onOk() {
				setState({
					...state,
					visible: false,
				});
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	}, []);

	const isEditMode = !!state?.selectedRow;

	return (
		<Drawer
			maskClosable={false}
			title={`${isEditMode ? 'Edit' : 'Add New'} Received Entry`}
			placement="right"
			width={'50%'}
			visible={state?.visible}
			destroyOnClose
			className="add_student"
			onClose={showConfirm}
			footer={
				<Row>
					<Col
						xl={{
							span: 9,
							offset: 15,
						}}
						md={12}>
						<Row gutter={[10, 10]} style={{ width: '100%' }} justify="end">
							<Col>
								<Button
									onClick={() =>
										setState({
											...state,
											visible: false,
										})
									}>
									Cancel
								</Button>
							</Col>
							<Col>
								<Button loading={loading} type="primary" htmlType="submit" onClick={() => form.submit()}>
									{isEditMode ? 'Update' : 'Submit'}
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			}>
			<Row justify="center">
				<Col xl={20} md={20}>
					<Form
						form={form}
						className="form-container"
						{...formItemLayout}
						onFinish={(values) => (state?.selectedRow ? handleEdit(values) : handleSubmit(values))}>
						<Row gutter={[0, 10]}>
							<Col xl={12} md={12}>
								<Form.Item label="Load date" initialValue={moment()} rules={[{ required: true }]} name="loadDate">
									<DatePicker style={{ width: '100%' }} format={DATE_FORMAT.DD_MM_YYYY} />
								</Form.Item>
							</Col>
							<Col xl={12} md={12}>
								<Form.Item label="Vehicle" rules={[{ required: true }]} name="vehicleId">
									<Select placeholder="select vehicle">
										{vehicles?.map((vehicle) => (
											<Select.Option value={vehicle?._id}>{vehicle?.regNumber}</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={[0, 10]}>
							<Col xl={12} md={12}>
								<Form.Item label="Driver Name" name="driverName" rules={[{ required: true }]}>
									<Input />
								</Form.Item>
							</Col>
							<Col xl={12} md={12}>
								<Form.Item label="Driver Mobile" rules={[{ required: true }]} name="driverMobile">
									<Input />
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={[0, 10]}>
							<Col xl={12} md={12}>
								<Form.Item label="Load From" rules={[{ required: true }]} name="loadFrom">
									<Select placeholder="select load from">
										{vendors?.map((vendor) => (
											<Select.Option value={vendor?._id}>{vendor?.vendorName}</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xl={12} md={12}>
								<Form.Item label="Load To" rules={[{ required: true }]} name="loadTo">
									<Select placeholder="select load to">
										{vendors?.map((vendor) => (
											<Select.Option value={vendor?._id}>{vendor?.vendorName}</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={[0, 10]}>
							<Col xl={24} md={24}>
								<Form.Item label="Remarks" name="remarks">
									<Input.TextArea />
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Col>
			</Row>
		</Drawer>
	);
};

export default AddReceivedEntryPresentational;
