import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { ACTIONS, DATE_FORMAT } from 'constants/app-constants';
import { SERVER_IP } from 'assets/Config';
import { sendGetRequest } from 'redux/sagas/utils';
import { exportPDF } from 'components/pdf-download';
import { objToQs } from 'helpers';
import { saveExcelHook } from 'components/excel-download';
import InvoiceReportPresentational from './invoice-report-presentational';

const InvoiceReportFunctional = () => {
	const [filters, setFilters] = React.useState({
		fromDate: null,
		toDate: null,
	});
	// const [invoices, setInvoices] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const globalRedux = useSelector((state) => state.globalRedux);

	const handleExport = (data, headers, type = 'PDF') => {
		if (type === 'PDF') {
			exportPDF(data, headers, globalRedux);
		} else {
			saveExcelHook(data, headers);
		}
	};

	const getInvoices = async (type = 'PDF') => {
		setLoading(true);
		let params = objToQs({
			...filters,
		});
		const res = await sendGetRequest(ACTIONS.GET_INVOICES, `${SERVER_IP}invoice?orgId=${globalRedux.selectedOrganization._id}&${params}`);
			if (res?.data?.length > 0) {
				// setInvoices(res?.data?.data || []);
				const headers = ['#', 'Date', 'Customer', 'Sub Total', 'CGST', 'SGST', 'IGST', 'Round Off', 'Grand Total', 'Invoice For', "Rate Type"] || [];
				const data =
					res?.data.map((data) => ({
					[headers[0]]: data?.invoiceNumber,
					[headers[1]]: moment(data?.invoiceDate).format(DATE_FORMAT.DD_MM_YYYY),
					[headers[2]]: data?.customerId?.displayName?.toString(),
					[headers[3]]: parseFloat(data?.subTotal).toFixed(2),
					[headers[4]]: parseFloat(data?.cgst).toFixed(2),
					[headers[5]]: parseFloat(data?.sgst).toFixed(2),
					[headers[6]]: parseFloat(data?.igst).toFixed(2),
					[headers[7]]: parseFloat(data?.roundOff).toFixed(2),
					[headers[8]]: parseFloat(data?.totalAmount).toFixed(2),
					[headers[9]]: data?.invoiceType,
					[headers[10]]: data?.rateType,
				})) || [];
			handleExport(data, headers, type);
			setLoading(false);
		} else {
			setLoading(false);
		}
	};

	// React.useEffect(() => {
	// 	filters?.fromDate && filters?.toDate && getInvoices();
	// }, [filters?.fromDate, filters?.toDate]);

	const handleDataChange = (value) => {
		if (value) {
			setFilters({
				...filters,
				fromDate: value[0],
				toDate: value[1],
			});
		} else {
			setFilters({
				...filters,
				fromDate: null,
				toDate: null,
			});
		}
	};

	const handleChange = (label, value) => {
		setFilters({
			...filters,
			[label]: value,
		});
	};

	// const loading = React.useMemo(() => globalRedux.apiStatus.GET_INVOICES === API_STATUS.PENDING, [globalRedux.apiStatus]);

	return (
		<InvoiceReportPresentational
			handleDataChange={handleDataChange}
			isValid
			// isValid={filters?.fromDate && filters?.toDate}
			loading={loading}
			handleGeneratePdf={() => getInvoices('PDF')}
			handleGenerateExcel={getInvoices}
			handleChange={handleChange}
		/>
	);
};

export default InvoiceReportFunctional;
