import React, { useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Form, Input, Button } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { postApi } from 'redux/sagas/postApiDataSaga';

const Register = (props) => {
	const registerRedux = useSelector((state) => state.registerRedux);
	const globalRedux = useSelector((state) => state.globalRedux);
	const dispatch = useDispatch();
	const [form] = Form.useForm();

	const FormProps = {
		labelCol: {
			span: 8,
		},
		wrapperCol: {
			span: 24,
		},
	};

	useEffect(() => {
		if (!!registerRedux.token && registerRedux.isLogged) {
			props.history.push('/organization');
		}
	}, [registerRedux.token, registerRedux.isLogged, props.history]);

	useEffect(() => {
		if (globalRedux.apiStatus.REGISTER === 'SUCCESS') {
			props.history.push('/login');
		}
	}, [globalRedux.apiStatus]);

	const register = (data) => {
		delete data.confirmPassword;
		const request = {
			...data,
			device: 'web',
		};
		console.log(request);
		dispatch(postApi(request, 'REGISTER'));
	};

	const isLoading = globalRedux.apiStatus.REGISTER === 'PENDING';

	return (
		<Container style={{ height: '100vh' }}>
			<Row style={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
				<Col xl={4} lg={4} style={{ padding: '2%' }}>
					<Card className="box_shadow">
						<Container style={{ padding: 15 }}>
							<Row
								style={{
									width: '100%',
									textAlign: 'center',
									padding: '20px',
									paddingTop: '0px',
								}}>
								<h6 style={{ fontWeight: 'bold', color: '#0a4a8c' }}>Register</h6>
							</Row>
							<Row>
								<Col>
									<Form
										requiredMark={false}
										colon={false}
										layout="vertical"
										labelAlign="left"
										form={form}
										onFinish={register}
										{...FormProps}>
										<Form.Item
											requiredMark={false}
											colon={false}
											label="First Name"
											name="firstName"
											rules={[
												{
													required: true,
													message: 'This Field is Required!',
												},
											]}>
											<Input />
										</Form.Item>

										<Form.Item
											label="Last Name"
											name="lastName"
											rules={[
												{
													required: false,
													message: 'This Field is Required!',
												},
											]}>
											<Input />
										</Form.Item>
										<Form.Item
											label="Email"
											name="email"
											rules={[
												{
													required: true,
													message: 'Please Enter Your Email',
												},
											]}>
											<Input />
										</Form.Item>
										<Form.Item
											label="Mobile"
											name="mobile"
											rules={[
												{
													required: true,
													message: 'Please Enter Your Mobile',
												},
											]}>
											<Input />
										</Form.Item>

										<Form.Item
											label="Password"
											name="password"
											rules={[
												{
													required: true,
													message: 'Please Enter Your Password!',
												},
											]}>
											<Input.Password visibilityToggle />
										</Form.Item>
										<Form.Item
											label="Confirm Password"
											name="confirmPassword"
											dependencies={['password']}
											rules={[
												{
													required: true,
													message: 'Please confirm your password!',
												},
												({ getFieldValue }) => ({
													validator(_, value) {
														if (!value || getFieldValue('password') === value) {
															return Promise.resolve();
														}
														return Promise.reject(new Error('The two passwords that you entered do not match!'));
													},
												}),
											]}>
											<Input.Password visibilityToggle />
										</Form.Item>

										<Row>
											<Col>
												<Row>
													{/* <Link className="nav-link" to="/forgot-password" style={{ fontSize: '12px' }}>
														Forgot Password?
													</Link> */}
													<br />
												</Row>
											</Col>
											<Col>
												<Row>
													<Link className="nav-link" to="/login" style={{ fontSize: '12px', textAlign: 'right' }}>
														Already User?
													</Link>
													<br />
												</Row>
											</Col>
										</Row>

										<Form.Item>
											<Button
												style={{ width: '100%' }}
												type="primary"
												htmlType="submit"
												disabled={isLoading}
												loading={isLoading}>
												Register
											</Button>
										</Form.Item>
									</Form>
								</Col>
							</Row>
						</Container>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default Register;
