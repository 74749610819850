import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Input, InputNumber, Modal, Row } from 'antd';
import moment from 'moment';
import DatePicker from 'components/date-picker';
import { ACTIONS, API_STATUS, DATE_FORMAT } from 'constants/app-constants';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';

const AddPlanModal = ({ editData, setSelectedRow, setSelectedRowKeys, refreshList }) => {
	const dispatch = useDispatch();
	// const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();
	const globalRedux = useSelector((state) => state.globalRedux);

	useEffect(() => {
		form.setFieldsValue({
			partNumber: editData?.partNumberId?.partNumber,
			sapNumber: editData?.partNumberId?.sapNumber,
			vendorName: editData?.vendorId?.vendorName,
			lrNumber: editData?.lrNumber,
		});
	}, [editData?._id]);

	const handleSubmit = (values) => {
		const request = {
			orgId: globalRedux.selectedOrganization._id,
			stockId: editData?._id,
			planDate: moment(),
			requestedDate: values?.requestedDate,
			planQty: values?.planQty,
			remarks: values?.remarks,
		};
		dispatch(postApi(request, ACTIONS.ADD_PLAN));
	};

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_PLAN === API_STATUS.SUCCESS) {
			refreshList();
			form.resetFields();
			dispatch(resetApiStatus('ADD_PLAN'));
			setSelectedRowKeys([]);
			setSelectedRow(null);
		}
	}, [globalRedux.apiStatus]);

	const FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 24,
		},
	};

	const buttonLoading = globalRedux.apiStatus.ADD_PLAN === API_STATUS.PENDING;
	return (
		<Modal
			width={'40%'}
			visible={!!editData?._id}
			title="Create Plan"
			okText="Submit"
			okButtonProps={{ loading: buttonLoading }}
			// onOk={() => handleDispatchQtySubmit(editData?._id, dataSource)}
			onOk={() => form.submit()}
			destroyOnClose
			onCancel={() => {
				setSelectedRowKeys([]);
				setSelectedRow(null);
				form.resetFields();
			}}>
			<Row>
				<Col xl={24} md={24}>
					<Form
						form={form}
						colon={false}
						id="category-editor-form"
						layout="vertical"
						onFinish={handleSubmit}
						{...FormCol}>
						<Row gutter={[10, 10]}>
							<Col span={12}>
								<Form.Item label="Part Number" name="partNumber">
									<Input style={{ width: '100%' }} disabled />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label="SAP Number" name="sapNumber">
									<Input style={{ width: '100%' }} disabled />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label="Vendor Name" name="vendorName">
									<Input style={{ width: '100%' }} disabled />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label="LR Number" name="lrNumber">
									<Input style={{ width: '100%' }} disabled />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									initialValue={moment()}
									label="Requested Date"
									name="requestedDate"
									rules={[{ required: true, message: 'Requested Date is required!' }]}>
									<DatePicker style={{ width: '100%' }} format={DATE_FORMAT.DD_MM_YYYY} />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label="Requested Qty" name="planQty" rules={[{ required: true, message: 'Requested Qty is required!' }]}>
									<InputNumber style={{ width: '100%' }} placeholder="Requested qty" />
								</Form.Item>
							</Col>
							<Col span={24}>
								<Form.Item label="Remarks" name="remarks">
									<Input.TextArea style={{ width: '100%' }} placeholder="Remarks" />
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Col>
			</Row>
		</Modal>
	);
};

export default AddPlanModal;
