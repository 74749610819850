import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Input, Button, Divider, Form } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { putApi } from 'redux/sagas/putApiSaga';
import { SERVER_IP } from 'assets/Config';
import { API_STATUS } from 'constants/app-constants';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';

const AddLocation = ({ handleClose, editLocation, setLocationsAddModal }) => {
	const [form] = Form.useForm();
	const globalRedux = useSelector((state) => state.globalRedux);
	const dispatch = useDispatch();

	const addLocation = (values) => {
		let data = {
			...values,
			orgId: globalRedux.selectedOrganization._id,
		};
		dispatch(postApi(data, 'ADD_LOCATION'));
	};

	const handleEdit = (values) => {
		let data = {
			...values,

			orgId: globalRedux.selectedOrganization._id,
		};
		let url = `${SERVER_IP}location/${editLocation._id}?orgId=${globalRedux.selectedOrganization._id}`;
		dispatch(putApi(data, 'EDIT_LOCATION', url));
	};

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_LOCATION === 'SUCCESS' || globalRedux.apiStatus.EDIT_LOCATION === 'SUCCESS') {
			dispatch(resetApiStatus(editLocation ? 'EDIT_LOCATION' : 'ADD_LOCATION'));
			setLocationsAddModal(false);
			handleClose();
		}
	}, [globalRedux.apiStatus]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const loading = globalRedux.apiStatus.ADD_LOCATION === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_LOCATION === API_STATUS.PENDING;

	return (
		<Container fluid>
			<Row style={{ backgroundColor: '#fff', padding: '10px 0px' }}>
				<Col>
					<h6 style={{ marginBottom: '0px' }}>New Location</h6>
				</Col>
			</Row>
			<Divider />
			<Row className="mt-3 m-2 pt-2">
				<Form
					name="add-location"
					style={{}}
					requiredMark={false}
					colon={false}
					labelAlign="left"
					form={form}
					onFinish={!editLocation ? addLocation : handleEdit}
					{...layer1FormCol}>
					<Form.Item
						label="Location Name"
						name="locationName"
						initialValue={editLocation?.locationName}
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<Input defaultValue={editLocation?.locationName} />
					</Form.Item>
					<Form.Item
						label="Remarks"
						name="remarks"
						initialValue={editLocation?.remarks}
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<Input.TextArea defaultValue={editLocation?.remarks} />
					</Form.Item>
					<Form.Item
						wrapperCol={{
							offset: 0,
							span: 24,
						}}>
						<Row className="space-between" style={{ paddingTop: 20 }}>
							<Button loading={loading} style={{ marginRight: '10px', width: '49%' }} type="primary" htmlType="submit">
								Save
							</Button>
							<Button danger style={{ width: '49%' }} onClick={() => setLocationsAddModal(false)}>
								Cancel
							</Button>
						</Row>
					</Form.Item>
				</Form>
			</Row>
		</Container>
	);
};

export default AddLocation;
