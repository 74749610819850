import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Popconfirm, Button, Pagination, Row, Col, Input } from "antd";
import { AiOutlineSearch } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import AddDrawer from "components/drawer-component";
import { generatePagination } from "helpers";
import TableComponent from "components/table-component";
import { getApi } from "redux/sagas/getApiDataSaga";
import { SERVER_IP } from "assets/Config";
import { getDateFormat } from "services/Utils";
import { deleteApi } from "redux/sagas/deleteApiSaga";
import { resetApiStatus } from "redux/reducers/globals/globalActions";
import HighlightComponent from "components/HighlightComponent";
import { API_STATUS } from "constants/app-constants";

const initialPageSize = 10;
const intialPageSizeOptions = [10, 15, 20];

const Customers = React.memo(() => {
	const globalRedux = useSelector((state) => state?.globalRedux);
	const [customerAddModal, setCustomerAddModal] = useState(false);
	const [searchKey, setSearchKey] = useState("");
	const [tableData, setTableData] = useState(globalRedux?.parties || []);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const [editCustomer, setEditCustomer] = useState(null);
	const dispatch = useDispatch();

	const getCustomers = useCallback(() => {
		let url = `${SERVER_IP}customer/?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(getApi("GET_PARTIES", url));
	}, [dispatch, globalRedux?.selectedOrganization?._id]);

	useEffect(() => {
		getCustomers();
	}, [getCustomers]);

	useEffect(() => {
		generatePagination(tableData);
	}, [tableData]);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_PARTY === "SUCCESS") {
			dispatch(resetApiStatus("DELETE_PARTY"));
			doIt = true;
		}
		if (doIt) {
			getCustomers();
		}
	}, [globalRedux.apiStatus, getCustomers, dispatch]);

	useEffect(() => {
		setTableData(globalRedux?.parties || []);
	}, [globalRedux?.parties]);

	const filteredData = useMemo(() => {
		if (searchKey === "") {
			return tableData;
		}
		return tableData.filter((customer) => {
			return (
				(customer?.displayName || "")
					?.toLowerCase()
					.includes(searchKey.toLowerCase()) ||
				(customer?.customerType || "")
					?.toLowerCase()
					.includes(searchKey.toLowerCase()) ||
				(customer?.email || "")
					?.toLowerCase()
					.includes(searchKey.toLowerCase()) ||
				(customer?.mobile || "")
					?.toLowerCase()
					.includes(searchKey.toLowerCase()) ||
				(customer?.gstin || "")?.toLowerCase().includes(searchKey.toLowerCase())
			);
		});
	}, [tableData, searchKey]);

	const handleDrawer = (rowData) => {
		setEditCustomer(rowData);
		setCustomerAddModal(true);
	};

	const handleAdd = () => {
		setCustomerAddModal(true);
		setEditCustomer(null);
	};

	const column = [
		{
			title: "Name",
			dataIndex: "displayName",
			key: "displayName",
			sorter: (a, b) => a.displayName.localeCompare(b.displayName),
			fixed: "left",
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value}
				/>
			),
		},
		{
			title: "Type",
			dataIndex: "customerType",
			key: "customerType",
			sorter: (a, b) => a.customerType.localeCompare(b.customerType),
			fixed: "left",
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value}
				/>
			),
		},
		{
			title: "Transport Rate",
			dataIndex: "transportRate",
			key: "transportRate",
			fixed: "left",
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={(value || "-")?.toString()}
				/>
			),
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
			sorter: (a, b) => a.email.localeCompare(b.email),
			fixed: "left",
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value}
				/>
			),
		},
		{
			title: "Mobile",
			dataIndex: "mobile",
			key: "mobile",
			sorter: (a, b) => a.mobile.localeCompare(b.mobile),
			fixed: "left",
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value}
				/>
			),
		},
		{
			title: "GSTIN",
			dataIndex: "gstin",
			key: "gstin",
			sorter: (a, b) => a.gstin.localeCompare(b.gstin),
			fixed: "left",
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value}
				/>
			),
		},
		// {
		// 	title: "Opening Balance",
		// 	dataIndex: "openingBalance",
		// 	key: "openingBalance",
		// 	// sorter: (a, b) => a.displayName.localeCompare(b.displayName),
		// 	fixed: "left",
		// },
		// {
		// 	title: 'Billing Address',
		// 	dataIndex: 'billingAddress',
		// 	key: 'billingAddress',
		// 	// sorter: (a, b) => a.displayName.localeCompare(b.displayName),
		// 	fixed: 'left',
		// 	render: (value) => (
		// 		<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />
		// 	),
		// },
		// {
		// 	title: 'Shipping Address',
		// 	dataIndex: 'shippingAddress',
		// 	key: 'shippingAddress',
		// 	// sorter: (a, b) => a.displayName.localeCompare(b.displayName),
		// 	fixed: 'left',
		// 	render: (value) => (
		// 		<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />
		// 	),
		// },
		{
			title: "Remarks",
			dataIndex: "remarks",
			key: "remarks",
			// sorter: (a, b) => a.displayName.localeCompare(b.displayName),
			fixed: "left",
		},
		{
			title: "Created Date",
			dataIndex: "createdAt",
			align: "left",
			// sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
			render: (value) => getDateFormat(value),
		},
		{
			title: "Created By",
			dataIndex: "createdBy",
			align: "left",
			// sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
			render: (value) => value?.firstName,
		},
		{
			title: "Action",
			align: "center",
			dataIndex: "displayName",
			render: (value, row) => {
				return (
					<Row gutter={10} justify="center">
						<Col onClick={() => handleDrawer(row)} className="edit_icon">
							<EditOutlined />
						</Col>
						<Col className="delete_icon">
							<Popconfirm
								title={`Are You Sure to Delete ${value}?`}
								okText="Delete"
								cancelText="No"
								onConfirm={() => {
									let url = `${SERVER_IP}customer/${row._id}?orgId=${globalRedux?.selectedOrganization?._id}`;
									dispatch(deleteApi("DELETE_PARTY", url));
								}}
								placement="rightTop"
							>
								<CloseOutlined />
							</Popconfirm>
						</Col>
					</Row>
				);
			},
		},
	];

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1)
			return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const loading = useMemo(
		() => globalRedux.apiStatus.GET_PARTIES === API_STATUS.PENDING,
		[globalRedux.apiStatus]
	);

	return (
		<>
			<Row style={{ padding: "20px 10px" }}>
				<Col xl={24}>
					<TableComponent
						loading={loading}
						className="custom-table"
						style={{ width: "100%" }}
						columns={column}
						bordered
						rowKey={(record) => record._id}
						dataSource={filteredData}
						title={() => (
							<Row justify="space-between">
								<Col md={3}>
									<Input
										placeholder="Search"
										suffix={<AiOutlineSearch />}
										style={{ height: "30px", borderRadius: "8px" }}
										onChange={({ target: { value } }) => setSearchKey(value)}
									/>
								</Col>
								<Col md={7}></Col>
								<Col>
									<Button
										type="primary"
										style={{ height: "30px", width: "100%" }}
										onClick={() => handleAdd(true)}
									>
										Add Customer
									</Button>
								</Col>
							</Row>
						)}
						pagination={{
							current: currentPage,
							pageSize: pageSize,
							position: ["none", "none"],
						}}
						footer={() => (
							<Row justify="space-between">
								<Col>
									<div>
										{!!filteredData.length &&
											`Showing ${getStartingValue()} - ${getEndingValue()} of ${
												filteredData.length
											} Data`}
									</div>
								</Col>
								<Col md={8}>
									<div style={{ textAlign: "right" }}>
										<Pagination
											pageSizeOptions={intialPageSizeOptions}
											defaultPageSize={initialPageSize}
											showSizeChanger={true}
											total={filteredData.length}
											onChange={handleTableChange}
											responsive
										/>
									</div>
								</Col>
							</Row>
						)}
						// onChange={handleTableChange}
					/>
				</Col>
			</Row>
			<AddDrawer
				{...{
					customerAddModal,
					setCustomerAddModal,
					getCustomers,
					editCustomer,
				}}
			/>
		</>
	);
});

export default Customers;
