import React, { useEffect, useMemo, useState } from 'react';
import { Popconfirm, Row, Col, Tag } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { SERVER_IP } from 'assets/Config';
import { deleteApi } from 'redux/sagas/deleteApiSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import HighlightComponent from 'components/HighlightComponent';
import { ACTIONS, API_STATUS, DATE_FORMAT, STATUS } from 'constants/app-constants';
import { generatePagination } from 'helpers';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import CreatedLoadPresentational from './create-load-list-presentational';
import { getDateFormat } from 'services/Utils';

const initialPageSize = 20;
const intialPageSizeOptions = [20, 40, 80];

const CreatedLoadFunctional = React.memo((props) => {
	const loadsRedux = useSelector((state) => state.loadsRedux);
	const users = useSelector((state) => state?.userRedux?.users);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [visible, toggleVisible] = useState(false);
	const [searchKey, setSearchKey] = useState('');
	const [tableData, setTableData] = useState(loadsRedux?.loads || []);
	const [currentPage, setCurrentPage] = useState(1);
	const [state, setState] = useState({
		selectedRow: null,
		visible: false,
	});
	const [pageSize, setPageSize] = useState(initialPageSize);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const dispatch = useDispatch();

	const getLoads = () => {
		dispatch(getApi(ACTIONS.GET_LOADS, `${SERVER_IP}load?orgId=${globalRedux.selectedOrganization._id}&status=InTransit,Halting`));
	};

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_LOAD === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_LOAD'));
			doIt = true;
		}
		if (doIt) {
			getLoads();
		}
	}, [globalRedux.apiStatus]);

	useEffect(() => {
		getLoads();
		generatePagination(tableData);
	}, []);

	useEffect(() => {
		setTableData(loadsRedux?.loads);
	}, [loadsRedux?.loads]);

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((record) => {
			return (
				(record?.loadTo?.vendorName || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.vehicleId?.regNumber || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.driverName || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.driverMobile || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.remarks || '')?.toLowerCase().includes(searchKey.toLowerCase())
			);
		});
	}, [tableData, searchKey]);

	const handleEdit = (rowData) => {
		setState({
			...state,
			selectedRow: rowData,
			visible: true,
		});
	};

	const column = [
		{
			title: '#',
			dataIndex: 'loadNumber',
			key: 'loadNumber',
			sorter: (a, b) => a?.loadNumber - b?.loadNumber,
			width: '5%',
		},
		{
			title: 'Load Date',
			sorter: (a, b) => new Date(a.loadDate) - new Date(b.loadDate),
			dataIndex: 'loadDate',
			key: 'loadDate',
			// fixed: 'left',
			width: 100,
			render: (value) => getDateFormat(value, DATE_FORMAT.DD_MM_YYYY),
		},
		{
			title: 'Load From',
			dataIndex: 'loadFrom',
			key: 'loadFrom',
			fixed: 'left',
			sorter: (a, b) => a?.loadFrom?.vendorName?.localeCompare(b?.loadFrom?.vendorName),
			width: 150,
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.vendorName}
				/>
			),
		},
		{
			title: 'Load To',
			dataIndex: 'loadTo',
			sorter: (a, b) => a?.loadTo?.vendorName?.localeCompare(b?.loadTo?.vendorName),
			key: 'loadTo',
			fixed: 'left',
			width: 150,
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.vendorName}
				/>
			),
		},
		{
			title: 'Vehicle',
			dataIndex: 'vehicleId',
			key: 'vehicleId',
			sorter: (a, b) => a?.vehicleId?.regNumber?.localeCompare(b?.vehicleId?.regNumber),
			fixed: 'left',
			width: '15%',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.regNumber}
				/>
			),
			// render: (value) => (
			// 	<Row>
			// 		<Col>
			// 			Number: <span style={{ fontWeight: 'bold' }}>{value?.regNumber}</span>
			// 		</Col>
			// 		<Col>
			// 			Contact: <span style={{ fontWeight: 'bold' }}>{value?.contact}</span>
			// 		</Col>
			// 	</Row>
			// ),
		},
		{
			title: 'Driver Name',
			dataIndex: 'driverName',
			key: 'driverName',
			sorter: (a, b) => a?.driverName?.localeCompare(b?.driverName),
			width: 150,
		},
		{
			title: 'Driver Mobile',
			dataIndex: 'driverMobile',
			key: 'driverMobile',
			sorter: (a, b) => a?.driverMobile?.localeCompare(b?.driverMobile),
			width: 100,
		},
		{
			title: 'Remarks',
			dataIndex: 'remarks',
			key: 'remarks',
			// fixed: 'left',
			width: 100,
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />
			),
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			fixed: 'left',
			width: '8%',
			render: (value) => (value ? <Tag color={STATUS[value]}>{value}</Tag> : null),
		},
		{
			title: 'Action',
			align: 'center',
			dataIndex: 'studentName',
			width: 100,
			render: (value, row) => {
				return (
					<Row gutter={10} justify="center">
						<Col onClick={() => handleEdit(row)} className="edit_icon">
							<EditOutlined />
						</Col>
						<Col className="delete_icon">
							<Popconfirm
								title={`Are You Sure to Delete?`}
								okText="Delete"
								cancelText="No"
								placement="rightTop"
								onConfirm={() => {
									let url = `${SERVER_IP}load/${row._id}?orgId=${globalRedux.selectedOrganization._id}`;
									dispatch(deleteApi('DELETE_LOAD', url));
								}}>
								<CloseOutlined />
							</Popconfirm>
						</Col>
					</Row>
				);
			},
		},
	];

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_TICKETS === API_STATUS.PENDING, [globalRedux.apiStatus]);

	const onSelectChange = (selectedRowKeys) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	};

	return (
		<CreatedLoadPresentational
			{...{
				column,
				filteredData,
				visible,
				toggleVisible,
				handleTableChange,
				getStartingValue,
				getEndingValue,
				pageSize,
				intialPageSizeOptions,
				initialPageSize,
				currentPage,
				setSearchKey,
				refreshList: getLoads,
				tableLoading,
				rowSelection,
				users,
				state,
				setState,
			}}
		/>
	);
});

export default CreatedLoadFunctional;
