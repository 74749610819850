import React from 'react';
import { useSelector } from 'react-redux';
import { Pagination, Row, Col, Button, Form, Radio, Input } from 'antd';
import TableComponent from 'components/table-component';

const layer1FormCol = {
	labelCol: {
		span: 8,
	},
	wrapperCol: {
		span: 16,
	},
};

const InvoiceValidationPresentational = ({
	column,
	filteredData,
	handleTableChange,
	getStartingValue,
	getEndingValue,
	pageSize,
	intialPageSizeOptions,
	initialPageSize,
	currentPage,
	getBillingItems,
	form,
	fetchingData,
	handleClear,
	setSelectedRecordToPrint,
	invoiceTypeValue,
}) => {
	const globalRedux = useSelector((state) => state.globalRedux);
	const { voucherHeads = [] } = globalRedux;
	return (
		<Row>
			<Col span={24} style={{ padding: 20 }}>
				<TableComponent
					loading={fetchingData}
					className="custom-table"
					style={{ width: '100%' }}
					rowKey={(record) => record._id}
					dataSource={filteredData}
					title={() => (
						<Row gutter={[10, 10]}>
							<Col xl={16} md={16}>
								<Form
									labelAlign="left"
									name="unit-sections"
									className="required_in_right"
									form={form}
									onFinish={getBillingItems}
									{...layer1FormCol}>
									<Row gutter={[20, 0]}>
										{/* <Col xl={12} md={12}>
											<Row>
												<Col span={24}>
													<Form.Item
														label={`Invoice Type`}
														name="invoiceType"
														initialValue={'Transport'}
														rules={[
															{
																required: true,
																message: 'This Field is required!',
															},
														]}>
														<Radio.Group>
															<Radio value={'Warehouse'}>Warehouse</Radio>
															<Radio value={'Transport'}>Transport</Radio>
														</Radio.Group>
													</Form.Item>
												</Col>
												<Col span={24}>
													{invoiceTypeValue === 'Transport' && (
														<Form.Item
															label={`Rate Type`}
															name="rateType"
															initialValue={'Transport'}
															rules={[
																{
																	required: true,
																	message: 'This Field is required!',
																},
															]}>
															<Radio.Group>
																<Radio value={'Warehouse'}>Warehouse</Radio>
																<Radio value={'Transport'}>Transport</Radio>
															</Radio.Group>
														</Form.Item>
													)}
												</Col>
											</Row>
										</Col> */}
										<Col xl={12} md={12}>
											<Row>
												<Col span={24}>
													<Form.Item
														label="LR No."
														name="lrNumber"
														rules={[
															{
																required: true,
																message: 'This Field is required!',
															},
														]}>
														<Input placeholder="LR No" style={{ width: '100%' }} />
													</Form.Item>
												</Col>
											</Row>
										</Col>
										<Col xl={24} md={24}>
											<Row gutter={[10, 0]}>
												<Col span={24}>
													<Row gutter={[10, 10]}>
														<Col span={8}>
															<Button style={{ width: '100%' }} loading={fetchingData} danger onClick={handleClear}>
																Clear
															</Button>
														</Col>
														<Col span={8}>
															<Button style={{ width: '100%' }} loading={fetchingData} type="primary" htmlType="submit">
																Submit
															</Button>
														</Col>
													</Row>
												</Col>
											</Row>
										</Col>
									</Row>
								</Form>
							</Col>
						</Row>
					)}
					{...{
						columns: column,
						pagination: { current: currentPage, pageSize: pageSize, position: ['none', 'none'] },
						...(!!filteredData?.length && {
							footer: () => (
								<Row justify="space-between">
									<Col md={16}>
										{!!filteredData?.length &&
											`Showing ${getStartingValue()} - ${getEndingValue()} of ${filteredData?.length} Data`}
									</Col>
									<Col md={8}>
										<div style={{ textAlign: 'right' }}>
											<Pagination
												pageSizeOptions={intialPageSizeOptions}
												defaultPageSize={initialPageSize}
												showSizeChanger={true}
												total={filteredData?.length}
												onChange={handleTableChange}
												responsive
											/>
										</div>
									</Col>
								</Row>
							),
						}),
					}}
				/>
			</Col>
		</Row>
	);
};

export default InvoiceValidationPresentational;
