import React, { forwardRef } from "react";
import moment from "moment";
import { getDateFormat } from "services/Utils";
import { DATE_FORMAT } from "constants/app-constants";
import ESign from "assets/images/eSign.jpeg";

const LabelValue = ({ label, value }) => (
	<div className="label_value">
		<div className="label">{label}</div>
		<div className="colon">:</div>
		<div className="value">{value}</div>
	</div>
);

const DispatchDetailsToPrint = forwardRef((props, ref) => {
	// const { firstName = '', lastName = '' } = useSelector((state) => state?.loginRedux);
	const {
		gstin = 0,
		driverName = "",
		driverMobile = "",
		requestedDate,
		dispatchDate,
		dispatchNumber,
		vehicleId: { regNumber } = { regNumber: "" },
		invoiceDate,
	} = props?.data?.commonData || {};

	const dispatchDetails = props?.data?.dispatchDetails;

	const printoutType = props?.printoutType;

	return (
		<div class="invoice-pdf dispatch-detail-pdf" ref={ref}>
			<div className="invoice-pdf-container">
				<table cellPadding="0" cellSpacing="0">
					<tr class="top">
						<td colSpan="7" style={{ padding: 0, paddingTop: 0 }}>
							<table className="bordered-bottom">
								<tr>
									<td class="title">
										<div className="left">
											{/* <div className="image">
											<img src="https://png2.cleanpng.com/sh/61554d2de5d6be188f3141549aa288ee/L0KzQYm3VsMxN5Rtj5H0aYP2gLBuTgRpcaN6e9pubnT4gn70lgJ2b5JzReZubYDvdX77iPlzfaF1eeRqbXv4fsPojb1ufaN6RadrM0e8QLO3VchibpI6RqgCNUK6RIqBUcU0OGQ7UKMCNkO5Q4S1kP5o/kisspng-thiruchendur-murugan-temple-thirupparamkunram-muru-5b3790b058afa5.6752749815303681763633.png" />
										</div> */}
											<div className="content">
												<div className="name" style={{ color: "#000" }}>
													MMT SUPPLY CHAIN SYSTEMS
												</div>
												{/* <div>LORRY TRANSPORTS</div> */}
												{/* <div className="address">36/71, Vivekanantha Nagar, Thekkupalayam Post, </div>
												<div className="address">Coimbatore - 641035.</div>
												<div className="contact">
													<span>Email: tmttransportcbe@gmail.com</span>
													<div>Contact: 9659534566</div>
													<div>www.tmttransport.in</div>
													<div>GSTIN : 33BCGPS9280R2ZL</div>
												</div> */}
											</div>
										</div>
										<div className="right">
											<div className="content">
												<div className="tax-invoice">Delivery Challan</div>
												<div className="copy">({printoutType} Copy)</div>
											</div>
											{/* <div>GSTIN : 33BCGPS9280R2ZL</div>
										<div>Mobile : 9659534566</div> */}
										</div>
									</td>
								</tr>
							</table>
						</td>
					</tr>
					<tr class="information">
						<td colSpan="7">
							<table className="bordered-bottom">
								<tr>
									<td style={{ width: "50%" }}>
										<div className="toArea">
											{/* <p>To:</p> */}
											<div className="details">
												<div>{`Vehicle No : ${regNumber || ""}`}</div>
												<div>{`Driver Name : ${driverName || ""}`}</div>
												<div>{`Mobile : ${driverMobile || ""}`}</div>
												{/* <div style={{ width: '100%' }}>{vendorId?.vendorName}</div>
												<div style={{ width: '100%' }}>{billingAddress}</div> */}
												{/* <div>SURVEY NO-950 ROYAKOTTAI ROAD,</div>
												<div>HOSUR - 635109</div> */}
												{/* <div>{`PAN NO: BCGPS9280R`}</div> */}
											</div>
										</div>
									</td>
									<td />
									<td className="no_padding">
										<div style={{ paddingTop: 10, fontSize: 14 }}>
											<div className="row">
												<div className="label">Dispatch No:</div>
												<div className="value">{dispatchNumber || ""}</div>
											</div>
											<div className="row">
												<div className="label">Requested Date:</div>
												<div className="value">
													{getDateFormat(requestedDate) || ""}
												</div>
											</div>
											<div className="row">
												<div className="label">Dispatched Date:</div>
												<div className="value">
													{getDateFormat(dispatchDate) || ""}
												</div>
											</div>
										</div>
									</td>
								</tr>
							</table>
						</td>
					</tr>
					<tr class="heading">
						<td>Vendor Name</td>
						<td>Part NO</td>
						<td>SAP NO</td>
						<td>Invoice wgt</td>
						<td>Invoice NO</td>
						<td>Invoice qty</td>
					</tr>
					{dispatchDetails?.slice(0, 10)?.map((data, index) => (
						<tr class="item">
							<td>{data?.stockId?.vendorId?.vendorName}</td>
							<td>{data?.stockId?.partNumberId?.partNumber}</td>
							<td>{data?.stockId?.partNumberId?.sapNumber}</td>
							<td>{data?.stockId?.invoiceWeight}</td>
							<td>{data?.stockId?.invoiceNumber}</td>
							<td>{data?.stockId?.invoiceQty}</td>
						</tr>
					))}
					{/* <tr class="total">
					<td></td>
					<td>Total: {receiptDetails?.reduce((acc, item) => acc + item.amount, 0)}</td>
				</tr> */}
				</table>
				<div class="invoice-footer">
					{/* <div class="invoice-footer-left">Payer's Signature</div>
					<div class="invoice-footer-right">Authorized Signature</div> */}
					<div
						style={{ fontSize: 14, width: "100%" }}
						className="amount_gst_area"
					>
						<div className="amount_area">
							<div
								className="amount_area_container"
								style={{ lineHeight: "normal", height: "100%" }}
							>
								{/* <div>
									<div className="bold" style={{ paddingRight: 10 }}>
										Amount in words
									</div>
									<div>{amountInWords}</div>
								</div> */}
								{/* <div>
									<div className="bold" style={{ paddingRight: 10 }}>
										Notes:
									</div>
									<div>{notes}</div>
								</div> */}
							</div>
						</div>
						{/* <div className="gst_area">
							<LabelValue {...{ label: 'Sub Total', value: parseFloat(subTotal).toFixed(2) }} />
							<LabelValue {...{ label: `SGST ${(props?.data?.gstRate || 0) / 2}%`, value: (sgst || 0) }} />
							<LabelValue {...{ label: `CGST ${(props?.data?.gstRate || 0) / 2}%`, value: (cgst || 0) }} />
							<LabelValue {...{ label: `IGST ${props?.data?.gstRate || 0}%`, value: (igst || 0) }} />
							<LabelValue {...{ label: 'Round Off (+/-)', value: (roundOff || 0) }} />
							<LabelValue {...{ label: 'Grand Total', value: parseFloat(totalAmount).toFixed(2) }} />
						</div> */}
					</div>
				</div>
				<div className="signArea">
					<div className="left">
						<span className="title">Document Enclosed</span>
						{/* <span className="paragraph" style={{ textAlign: 'center', fontWeight: 'bold' }}>
							Whether liable to pay under RCM No
						</span>
						<span className="paragraph" style={{ textAlign: 'center', fontWeight: 'bold' }}>
							{transportItems?.length || 0} LR Copies
						</span> */}
						<div className="paragraph">
							<div>
								1. All payments to be made by crossed Cheque/DD in favour of the
								MMT SUPPLY CHAIN SYSTEMS
							</div>
							<div>
								2. Interest will be charged at 18% per annum on outstanding
								after one week from the date of this bill until realisation
							</div>
						</div>
					</div>
					<div className="sign">
						<span style={{ color: "#000" }}>For MMT SUPPLY CHAIN SYSTEMS</span>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<img
								src={ESign}
								alt="e-sign"
								style={{}}
								height="80px"
								width="80px"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
});

export default DispatchDetailsToPrint;
