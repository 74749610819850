import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Input, Button, Divider, Form } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { putApi } from 'redux/sagas/putApiSaga';
import { SERVER_IP } from 'assets/Config';
import { postApi } from '../../redux/sagas/postApiDataSaga';
import { resetApiStatus } from '../../redux/reducers/globals/globalActions';
import { API_STATUS } from 'constants/app-constants';

const AddClass = ({ handleClose, editClass, setClassAddModal }) => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const globalRedux = useSelector((state) => state.globalRedux);

	const addClass = (values) => {
		let data = {
			...values,
			orgId: globalRedux.selectedOrganization._id,
		};

		dispatch(postApi(data, 'ADD_CLASS'));
	};
	const handleEdit = (values) => {
		let data = {
			...values,
			orgId: globalRedux.selectedOrganization._id,
		};
		let url = `${SERVER_IP}class/${editClass._id}?orgId=${globalRedux.selectedOrganization._id}`;
		dispatch(putApi(data, 'EDIT_CLASS', url));
	};

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_CLASS === 'SUCCESS' || globalRedux.apiStatus.EDIT_CLASS === 'SUCCESS') {
			dispatch(resetApiStatus(editClass ? 'EDIT_CLASS' : 'ADD_CLASS'));
			setClassAddModal(false);
			handleClose();
		}
	}, [globalRedux.apiStatus]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const loading = globalRedux.apiStatus.ADD_CLASS === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_CLASS === API_STATUS.PENDING;

	return (
		<Container fluid>
			{/* <Row style={{ backgroundColor: '#fff', padding: '10px 0px' }}>
				<Col>
					<h6 style={{ marginBottom: '0px' }}>{editClass ? 'Edit' : 'New'} Class</h6>
				</Col>
			</Row> */}
			{/* <Divider /> */}
			<Row className="mt-3 m-2 pt-2">
				<Form
					name="add-category"
					style={{}}
					requiredMark={false}
					colon={false}
					labelAlign="left"
					form={form}
					onFinish={!editClass ? addClass : handleEdit}
					{...layer1FormCol}>
					<Form.Item
						label="Class Name"
						name="className"
						initialValue={editClass?.className}
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<Input />
					</Form.Item>
					<Form.Item label="Remarks" name="remarks" initialValue={editClass?.remarks}>
						<Input.TextArea />
					</Form.Item>
					<Form.Item
						wrapperCol={{
							offset: 0,
							span: 24,
						}}>
						<Row className="space-between" style={{ paddingTop: 20 }}>
							<Button loading={loading} style={{ marginRight: '10px', width: '49%' }} type="primary" htmlType="submit">
								Save
							</Button>

							<Button danger style={{ width: '49%' }} onClick={() => setClassAddModal(false)}>
								Cancel
							</Button>
						</Row>
					</Form.Item>
				</Form>
			</Row>
		</Container>
	);
};

export default AddClass;
