import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Form, Select, Input, DatePicker, InputNumber, Button, Row, Col } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { SERVER_IP } from 'assets/Config';
import { API_STATUS, DATE_FORMAT } from 'constants/app-constants';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { putApi } from 'redux/sagas/putApiSaga';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { sendGetRequest } from 'redux/sagas/utils';
import DropdownWithAPI from 'components/dropdown-with-api';
import AddLoadInPresentational from './add-load-in-presentational';

const { TextArea } = Input;

let itemDefaultRecord = {
	vendorId: null,
	partNumberId: null,
	invoiceNumber: null,
	invoiceDate: moment(),
	invoiceQty: null,
	invoiceWeight: null,
	taxableValue: null,
	taxValue: null,
	totalValue: null,
	remarks: '',
	locationDetails: [],
	id: uuidv4(),
};

const AddLoadInFunctional = ({ state, setState, refreshList, editData, activeTab }) => {
	const [loadingState, setLoadingState] = useState({
		selectedRow: null,
	});
	const [pageLoading, setPageLoading] = useState(false);
	const [tableData, setTableData] = useState([]);
	const [deletedStocks, setDeletedStocks] = useState([]);
	const [selectedPartNumbers, setSelectedPartNumbers] = useState({ data: [], loading: false });
	const globalRedux = useSelector((state) => state.globalRedux);
	const locations = globalRedux?.locations;
	const vendors = globalRedux?.vendors;
	const [form] = Form.useForm();

	const dispatch = useDispatch();

	const getDetails = async () => {
		await setPageLoading(true);
		try {
			const {
				data: { data: response = [] },
			} = await sendGetRequest(null, `${SERVER_IP}load/${editData?._id}?orgId=${globalRedux.selectedOrganization._id}`);
			const result = response?.[0] || null;
			if (result) {
				form.setFieldsValue({
					vehicleId: result?.vehicleId?._id,
					loadDate: moment(result?.loadDate),
					driverName: result?.driverName,
					driverMobile: result?.driverMobile,
				});
				generateTableData(result);
				await setPageLoading(false);
			}
		} catch (err) {
			await setPageLoading(false);
		}
	};

	useEffect(() => {
		console.log('editData...editData', editData);
		editData?._id && getDetails();
	}, [editData]);

	const generateTableData = (editData) => {
		if (editData?.loadDetails?.length > 0) {
			const data = editData?.loadDetails.map((item) => ({
				vendorId: item?.vendorId?.id,
				partNumberId: item?.partNumberId?.id,
				invoiceNumber: item?.invoiceNumber,
				invoiceDate: moment(item?.invoiceDate),
				asnNumber: item?.asnNumber,
				invoiceQty: item?.invoiceQty,
				rate: item?.rate,
				invoiceWeight: item?.invoiceWeight,
				taxableValue: item?.taxableValue,
				taxValue: item?.taxValue,
				totalValue: item?.totalValue,
				remarks: item?.remarks,
				status: item?.status,
				locationDetails: item?.locationDetails,
				id: uuidv4(),
				...(item?.createdBy && { createdBy: item?.createdBy, stockId: item?.id, lrNumber: item?.lrNumber }),
			}));
			setTableData([...data]);
		}
	};

	const getVendorMapping = () => {
		let url = `${SERVER_IP}vendormapping?orgId=${globalRedux.selectedOrganization._id}`;
		dispatch(getApi('GET_VENDORS_MAPPING', url));
	};

	useEffect(() => {
		getVendorMapping();
	}, []);

	useEffect(() => {
		if (
			globalRedux.apiStatus.UPDATE_LOAD_IN_NEW === API_STATUS.SUCCESS ||
			globalRedux.apiStatus.ADD_LOAD_IN_NEW === API_STATUS.SUCCESS ||
			globalRedux.apiStatus.UPDATE_LOAD_IN === API_STATUS.SUCCESS
		) {
			setState({ ...state, visible: false });
			refreshList();
			setDeletedStocks([]);
			// dispatch(resetApiStatus(editData ? 'UPDATE_LOAD_IN' : 'ADD_LOAD_IN'));
			dispatch(resetApiStatus(activeTab === '0' ? 'ADD_LOAD_IN_NEW' : 'UPDATE_LOAD_IN_NEW'));
		}
		// if (editData) {
		// 	generateTableData(editData);
		// 	form.setFieldsValue({
		// 		vehicleId: editData?.vehicleId?._id,
		// 		loadDate: moment(editData?.loadDate),
		// 		driverName: editData?.driverName,
		// 		driverMobile: editData?.driverMobile,
		// 	});
		// }
	}, [globalRedux.apiStatus]);

	useEffect(() => {
		if (!state?.visible) {
			setTableData([]);
			setLoadingState({
				selectedRow: null,
			});
		}
	}, [state?.visible]);

	const loadPartNumbers = useCallback(
		async (vendorId) => {
			setSelectedPartNumbers({
				...selectedPartNumbers,
				loading: true,
			});
			const { data: { data } = { data: [] } } = await sendGetRequest(
				null,
				`${SERVER_IP}vendormapping?orgId=${globalRedux.selectedOrganization._id}&vendorId=${vendorId}`
			);
			setSelectedPartNumbers({
				loading: false,
				data: data,
			});
			// if(vendorId) {
			// 	setSelectedPartNumbers({
			// 		...selectedPartNumbers,
			// 		loading: true,
			// 	});
			// 	const {
			// 		data: { data } = { data:[]},
			// 	} = await sendGetRequest(null, `${SERVER_IP}vendormapping?orgId=${globalRedux.selectedOrganization._id}&vendorId=${vendorId}`);
			// 	setSelectedPartNumbers({
			// 		loading: false,
			// 		data: data,
			// 	});
			// }
			// else {
			// 	setSelectedPartNumbers({
			// 		loading: false,
			// 		data: [],
			// 	});
			// }
		},
		[globalRedux.selectedOrganization._id]
	);
	// const handleRowClick = (data) =>
	// 	form.setFieldsValue({
	// 		customerName: data?.displayName,
	// 		mobile: data?.contact,
	// 	});

	const handleSubmit = (values) => {
		const request = {
			orgId: globalRedux.selectedOrganization._id,
			loadId: editData?._id,
			// deletedStocks,
			// ...values,
			// driverMobile: values.driverMobile?.toString() || '',
			loadDetails: tableData
				.filter((data) => data.vendorId)
				.map(
					({
						vendorId,
						partNumberId,
						invoiceNumber,
						asnNumber,
						invoiceDate,
						invoiceWeight,
						rate,
						taxableValue,
						taxValue,
						locationDetails,
						remarks,
					}) => ({
						vendorId,
						partNumberId,
						invoiceDate,
						invoiceNumber,
						asnNumber,
						rate,
						locationDetails: locationDetails?.map(({ locationId, qty, remarks }) => ({
							locationId,
							qty,
							remarks,
						})),
						invoiceQty: locationDetails?.reduce((acc, curr) => acc + curr.qty, 0) || 0,
						invoiceWeight: invoiceWeight ?? 0,
						taxValue: taxValue ?? 0,
						taxableValue: taxableValue ?? 0,
						totalValue: parseFloat(taxValue ?? 0) + parseFloat(taxableValue ?? 0),
						remarks,
					})
				),
		};
		dispatch(postApi(request, 'ADD_LOAD_IN_NEW'));
		// editData ? dispatch(putApi(request, 'UPDATE_LOAD_IN', `${SERVER_IP}load/${editData?._id}`)) : dispatch(postApi(request, 'ADD_LOAD_IN'));
	};

	const handleUpdate = () => {
		const request = {
			orgId: globalRedux.selectedOrganization._id,
			loadId: editData?._id,
			deletedStocks,
			loadDetails: tableData
				.filter((data) => data.vendorId)
				.map(
					({
						vendorId,
						partNumberId,
						invoiceNumber,
						asnNumber,
						invoiceDate,
						invoiceWeight,
						rate,
						taxableValue,
						taxValue,
						locationDetails,
						remarks,
						stockId,
						lrNumber,
					}) => ({
						stockId,
						vendorId,
						partNumberId,
						invoiceDate,
						invoiceNumber,
						asnNumber,
						rate,
						locationDetails: locationDetails?.map(({ locationId, qty, remarks }) => ({
							locationId,
							qty,
							remarks,
						})),
						invoiceQty: locationDetails?.reduce((acc, curr) => acc + curr.qty, 0) || 0,
						invoiceWeight: invoiceWeight ?? 0,
						taxValue: taxValue ?? 0,
						taxableValue: taxableValue ?? 0,
						totalValue: parseFloat(taxValue ?? 0) + parseFloat(taxableValue ?? 0),
						remarks,
					})
				),
		};
		dispatch(putApi(request, 'UPDATE_LOAD_IN_NEW', `${SERVER_IP}load/stock/${editData?._id}`));
		// editData ? dispatch(putApi(request, 'UPDATE_LOAD_IN', `${SERVER_IP}load/${editData?._id}`)) : dispatch(postApi(request, 'ADD_LOAD_IN'));
	};

	const handleInvoiceQuantitySubmit = (row, locationDetails) => {
		handleInputChange('locationDetails', locationDetails, row?.id);
		setLoadingState({
			...loadingState,
			selectedRow: null,
		});
	};

	const handleInputChange = useCallback(
		(label, value, rowId) => {
			const data = tableData.map((data) => {
				if (data.id === rowId) {
					const invoiceQTY = data?.locationDetails?.reduce((acc, curr) => acc + curr.qty, 0) || 0;
					const taxableValue = (invoiceQTY || 0) * (value || 0);
					return {
						...data,
						[label]: value,
						...(label === 'vendorId' && { partNumberId: null }),
						...(label === 'rate' && {
							taxableValue: parseFloat(taxableValue).toFixed(2),
							taxValue: parseFloat((taxableValue || 0) * (18 / 100)).toFixed(2),
						}),
						...(label === 'taxableValue' && { taxValue: parseFloat((value || 0) * (18 / 100)).toFixed(2) }),
					};
				} else {
					return data;
				}
			});
			setTableData([...data]);
		},
		[tableData]
	);

	const columns = [
		{
			title: 'Vendor',
			dataIndex: 'vendorId',
			key: 'vendorId',
			width: '18%',
			align: 'center',
			render: (value, record) => (
				<Select
					showSearch
					allowClear
					disabled={record?.status !== 'InStock' && !!record?.createdBy}
					optionFilterProp="children"
					filterOption={(input, option) =>
						option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
						option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
					}
					value={value}
					style={{ width: '100%', textAlign: 'left' }}
					onChange={(value) => handleInputChange('vendorId', value, record?.id)}>
					{vendors?.map((vendor) => (
						<Select.Option value={vendor._id}>{vendor?.vendorName}</Select.Option>
					))}
				</Select>
			),
		},
		{
			title: 'Part Number',
			dataIndex: 'partNumberId',
			key: 'partNumberId',
			align: 'center',
			width: '15%',
			render: (value, record) => {
				console.log(
					'🚀 ~ file: add-load-in-functional.js:245 ~ AddLoadInFunctional ~ record:',
					record?.vendorId,
					record?.status,
					activeTab === '1' && !!!record?.vendorId && record?.status !== 'InStock'
				);
				return (
					<DropdownWithAPI
						{...{
							value,
							record,
							vendorId: record?.vendorId,
							handleInputChange,
						}}
					/>
					// <Select
					// 	onDropdownVisibleChange={(open) => open && loadPartNumbers(record?.vendorId)}
					// 	allowClear
					// 	optionFilterProp="children"
					// 	showSearch
					// 	filterOption={(input, option) =>
					// 		option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
					// 		option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
					// 	}
					// 	disabled={record?.status !== 'InStock'  && !!record?.createdBy}
					// 	value={value}
					// 	loading={selectedPartNumbers?.loading}
					// 	style={{ width: '100%', textAlign: 'left' }}
					// 	onChange={(value) => handleInputChange('partNumberId', value, record?.id)}>
					// 	{selectedPartNumbers?.data?.map((partNumber) => (
					// 		<Select.Option value={partNumber?.partNumberId?._id}>{partNumber?.partNumberId?.partNumber}</Select.Option>
					// 	))}
					// </Select>
				);
			},
		},
		{
			title: 'Invoice No.',
			dataIndex: 'invoiceNumber',
			key: 'invoiceNumber',
			align: 'center',
			width: '10%',
			render: (value, record) => (
				<Input
					style={{ width: '100%' }}
					onChange={({ target: { value } }) => handleInputChange('invoiceNumber', value, record?.id)}
					value={value}
					className={`${record?.vendorId && !value ? 'error' : ''}`}
					format={DATE_FORMAT.DD_MM_YYYY}
					disabled={record?.status !== 'InStock' && !!record?.createdBy}
				/>
			),
		},
		{
			title: 'ASN No.',
			dataIndex: 'asnNumber',
			key: 'asnNumber',
			align: 'center',
			width: '10%',
			render: (value, record) => (
				<Input
					style={{ width: '100%' }}
					onChange={({ target: { value } }) => handleInputChange('asnNumber', value, record?.id)}
					value={value}
					// className={`${record?.vendorId && !value ? 'error' : ''}`}
					format={DATE_FORMAT.DD_MM_YYYY}
					disabled={record?.status !== 'InStock' && !!record?.createdBy}
				/>
			),
		},
		{
			title: 'Invoice Date',
			dataIndex: 'invoiceDate',
			key: 'invoiceDate',
			align: 'center',
			width: '10%',
			render: (value, record) => (
				<DatePicker
					// style={{ width: '100%' }}
					onChange={(value) => handleInputChange('invoiceDate', value, record?.id)}
					value={value}
					className={`${record?.vendorId && !value ? 'error' : ''}`}
					format={DATE_FORMAT.DD_MM_YYYY}
					disabled={record?.status !== 'InStock' && !!record?.createdBy}
				/>
			),
		},
		{
			title: 'Invoice Weight',
			dataIndex: 'invoiceWeight',
			key: 'invoiceWeight',
			width: '10%',
			// width: '10%',
			render: (value, record) => (
				<InputNumber
					style={{ width: '100%' }}
					pattern="^-?[0-9]\d*\.?\d*$"
					value={value}
					className={`${record?.vendorId && !value ? 'error' : ''}`}
					onChange={(value) => handleInputChange('invoiceWeight', parseFloat(value), record?.id)}
					disabled={record?.status !== 'InStock' && !!record?.createdBy}
				/>
			),
		},
		{
			title: 'Invoice Qty',
			dataIndex: 'invoiceQty',
			key: 'invoiceQty',
			// width: '10%',
			width: '10%',
			render: (value, record) => {
				const invoiceQTY = record?.locationDetails?.reduce((acc, curr) => acc + curr.qty, 0) || 0;
				return (
					<Row justify="center">
						<Col>
							<Button
								className={`${record?.vendorId && invoiceQTY < 1 ? 'error' : ''}`}
								onClick={() =>
									setLoadingState({
										...loadingState,
										selectedRow: record,
									})
								}
								disabled={record?.status !== 'InStock' && !!record?.createdBy}>
								{invoiceQTY} <EditOutlined />
							</Button>
						</Col>
					</Row>
				);
			},
		},
		{
			title: 'Rate',
			dataIndex: 'rate',
			key: 'rate',
			// width: '10%',
			width: '10%',
			render: (value, record) => (
				<InputNumber
					style={{ width: '100%' }}
					pattern="^-?[0-9]\d*\.?\d*$"
					value={value}
					className={`${record?.vendorId && !value ? 'error' : ''}`}
					onChange={(value) => handleInputChange('rate', parseFloat(value), record?.id)}
					//
					disabled={record?.status !== 'InStock' && !!record?.createdBy}
				/>
			),
		},
		{
			title: 'Taxable Value',
			dataIndex: 'taxableValue',
			key: 'taxableValue',
			// width: '10%',
			// render: (value, record) => (
			// 	<InputNumber
			// 		style={{ width: '100%' }}
			// 		pattern="^-?[0-9]\d*\.?\d*$"
			// 		disabled
			// 		value={value}
			// 		className={`${record?.vendorId && !value ? 'error' : ''}`}
			// 		onChange={(value) => handleInputChange('taxableValue', parseFloat(value), record?.id)}
			// 	/>
			// ),
		},
		{
			title: 'Tax Value',
			dataIndex: 'taxValue',
			key: 'taxValue',
			// width: '10%',
			// render: (value, record) => (
			// 	<InputNumber
			// 		style={{ width: '100%' }}
			// 		pattern="^-?[0-9]\d*\.?\d*$"
			// 		value={value}
			// 		className={`${record?.vendorId && !value ? 'error' : ''}`}
			// 		onChange={(value) => handleInputChange('taxValue', parseFloat(value), record?.id)}
			// 	/>
			// ),
		},
		{
			title: 'Total Value',
			dataIndex: 'totalValue',
			key: 'totalValue',
			// width: '10%',
			align: 'right',
			render: (value, record) => (
				<span>{parseFloat(parseFloat(record?.taxValue || 0) + parseFloat(record?.taxableValue || 0)).toFixed(2)}</span>
			),
		},
		{
			title: 'Remarks',
			dataIndex: 'remarks',
			key: 'remarks',
			// width: '15%',
			align: 'center',
			render: (value, record) => (
				<TextArea
					style={{ width: 100 }}
					value={value}
					placeholder="remarks"
					onChange={({ target: { value } }) => handleInputChange('remarks', value, record?.id)}
					disabled={record?.status !== 'InStock' && !!record?.createdBy}
				/>
			),
		},
		{
			title: '',
			dataIndex: 'item',
			key: 'item',
			align: 'center',
			width: '3%',
			render: (value, record) =>
				tableData.length > 1 && record?.vendorId && (record?.status === 'InStock' || !!!record?.createdBy) ? (
					<DeleteOutlined style={{ color: 'red' }} onClick={() => handleRemove(record.id, record)} />
				) : null,
		},
	];

	const handleRemove = (id, record) => {
		const data = tableData.filter((data) => data.id !== id);
		setTableData([...data]);
		if (record?.createdBy && activeTab === '1') {
			const newDeletedStocks = [...deletedStocks, { stockId: record.stockId }];
			setDeletedStocks([...newDeletedStocks]);
		}
	};

	const handleAddTableData = () => {
		let data = [...tableData];
		data.push({
			...itemDefaultRecord,
			id: uuidv4(),
		});
		setTableData(data);
		// if (activeTab === '0') {
		// 	let data = [...tableData];
		// 	data.push({
		// 		...itemDefaultRecord,
		// 		id: uuidv4(),
		// 	});
		// 	setTableData(data);
		// }
	};

	const {} = useMemo(() => {
		const selectedList = tableData?.map((data) => data.accBookId).filter((data) => data);
		const filledList = tableData?.map((data) => data.vendorId).filter((data) => data);
		if (tableData?.length === filledList.length) handleAddTableData();
		return {
			selectedList,
		};
	}, [tableData]);

	const loading =
		globalRedux.apiStatus.UPDATE_LOAD_IN_NEW === API_STATUS.PENDING ||
		globalRedux.apiStatus.ADD_LOAD_IN_NEW === API_STATUS.PENDING ||
		globalRedux.apiStatus.UPDATE_LOAD_IN === API_STATUS.PENDING;

	return (
		<AddLoadInPresentational
			{...{
				state,
				setState,
				handleSubmit,
				handleUpdate,
				columns,
				loading,
				tableData,
				editData,
				form,
				locations,
				loadingState,
				setLoadingState,
				handleInvoiceQuantitySubmit,
				activeTab,
				pageLoading
			}}
		/>
	);
};

export default AddLoadInFunctional;
