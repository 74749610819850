import React from 'react';
import { Row, Col, DatePicker, Button, Select } from 'antd';
import { FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons';
import { DATE_FORMAT, DEPARTMENT_LIST } from 'constants/app-constants';

const { RangePicker } = DatePicker;

const InvoiceReportPresentational = ({ handleDataChange, handleChange, isValid, loading, handleGeneratePdf, handleGenerateExcel }) => {
	return (
		<Row style={{ padding: 20 }}>
			<Col span={24}>
				<Row gutter={[10, 10]}>
					<Col span={24}>
						<Row gutter={[10, 10]}>
							<Col span={4}>
								<RangePicker onChange={(e) => handleDataChange(e)} format={DATE_FORMAT.DD_MM_YYYY} />
							</Col>
							<Col span={4}>
								<Select
									allowClear
									onChange={(value) => handleChange('department', value)}
									style={{ width: '100%' }}
									placeholder="select department">
									{DEPARTMENT_LIST?.map((department) => (
										<Select.Option value={department}>{department}</Select.Option>
									))}
								</Select>
							</Col>
							<Col span={4}>
								<Select
									allowClear
									onChange={(value) => handleChange('invoiceType', value)}
									style={{ width: '100%' }}
									placeholder="select invoice type">
									{['Transport', 'Warehouse']?.map((invoiceType) => (
										<Select.Option value={invoiceType}>{invoiceType}</Select.Option>
									))}
								</Select>
							</Col>
							<Col span={4}>
								<Select
									allowClear
									onChange={(value) => handleChange('rateType', value)}
									style={{ width: '100%' }}
									placeholder="select rate type">
									{['Transport', 'Warehouse']?.map((rateType) => (
										<Select.Option value={rateType}>{rateType}</Select.Option>
									))}
								</Select>
							</Col>
							<Col span={4}>
								<Select
									allowClear
									onChange={(value) => handleChange('status', value)}
									style={{ width: '100%' }}
									placeholder="select rate type">
									{['Invoiced', 'Sent', 'Paid', 'Cancelled']?.map((status) => (
										<Select.Option value={status}>{status}</Select.Option>
									))}
								</Select>
							</Col>
						</Row>
					</Col>
					<Col xl={24} md={24}>
						<Row gutter={[10, 10]}>
							<Col xl={4} md={4}>
								<Button
									loading={loading}
									disabled={!isValid}
									style={{ marginRight: 10, width: '100%' }}
									type="primary"
									icon={<FilePdfOutlined />}
									onClick={handleGeneratePdf}>
									Export PDF
								</Button>
							</Col>
							<Col xl={4} md={4}>
								<Button
									loading={loading}
									disabled={!isValid}
									style={{ width: '100%' }}
									type="primary"
									icon={<FileExcelOutlined />}
									onClick={handleGenerateExcel}>
									Export Excel
								</Button>
							</Col>
						</Row>
					</Col>
					{/* <Col span={24}>
						<Button
							loading={loading}
							disabled={!isValid}
							style={{ marginRight: 10 }}
							type="primary"
							icon={<FilePdfOutlined />}
							onClick={handleGeneratePdf}>
							Export PDF
						</Button>
						<Button loading={loading} disabled={!isValid} type="primary" icon={<FileExcelOutlined />} onClick={handleGenerateExcel}>
							Export Excel
						</Button>
					</Col> */}
				</Row>
			</Col>
		</Row>
	);
};

export default InvoiceReportPresentational;
