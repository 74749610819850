import React, { useState, useCallback, useRef } from "react";
import { Input } from "antd";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { SERVER_IP } from "assets/Config";
import { sendPostRequest } from "redux/sagas/utils";

const InputWithAPI = ({
	value,
	record,
	handleInputChange,
	invoiceType = "Warehouse",
	rateType = "Warehouse",
	arr,
	handleCheckIsValidData,
}) => {
	const globalRedux = useSelector((state) => state.globalRedux);
	const inputRef = useRef(null);
	const [searchList, setSearchList] = useState({
		data: [],
		loading: false,
		searchString: "",
		isAlreadyExist: false,
	});

	const handleSearch = async (searchString, arr, rateType = "Warehouse") => {
		setSearchList({
			...searchList,
			searchString,
			isAlreadyExist: false,
		});
		const filteredArray = arr?.filter(
			(data) => (data?.lrNumber || "")?.toString() === searchString
		);
		const isAlreadyExist = filteredArray?.length > 1;
		// const isAlreadyExist =
		// 	!!filteredArray?.find((data) => (data?.lrNumber || '')?.toString() === searchString)?.lrNumber || false;
		if (searchString) {
			if (isAlreadyExist) {
				console.log(
					"🚀 ~ file: input-with-api.js:24 ~ handleSearch ~ isAlreadyExist:",
					searchString,
					record?.id,
					arr,
					filteredArray,
					isAlreadyExist
				);
				handleCheckIsValidData(record?.id, false, searchString, record, arr);
				setSearchList({
					...searchList,
					isAlreadyExist: true,
				});
			} else {
				setSearchList({
					...searchList,
					loading: true,
				});
				const { data } = await sendPostRequest({
					url: `${SERVER_IP}invoice/lr`,
					body: {
						orgId: globalRedux.selectedOrganization._id,
						lrNumber: searchString,
						invoiceType,
						rateType,
					},
				});
				setSearchList({
					...searchList,
					data: data?.data,
					loading: false,
					isAlreadyExist: false,
				});
				data?.data?.length > 0
					? handleCheckIsValidData(record?.id, false, searchString, record, arr)
					: handleCheckIsValidData(record?.id, true, searchString, record, arr);
			}
		}
	};

	// const debounceFn = useCallback(debounce(handleSearch, 500), []);

	return (
		<div>
			<Input
				pattern={`[0-9A-Za-z\s\-!@#$%^&*()_+={}\[\]|:;"'<>,.?/]*`}
				value={value}
				placeholder="LR number"
				className={`${
					searchList?.data?.length > 0 || searchList?.isAlreadyExist
						? "error"
						: ""
				}`}
				onChange={({ target: { value } }) => {
					// debounceFn(value, arr, rateType);
					handleInputChange("lrNumber", value, record?.id);
				}}
				onBlur={({ target: { value } }) => {
					handleSearch(value, arr, rateType);
				}}
				ref={inputRef}
			/>
			<div
				style={{
					color: "tomato",
				}}
			>
				{searchList?.loading ? (
					<div>Loading...</div>
				) : (
					<div>{searchList?.data?.length > 0 ? "LR already exist" : ""}</div>
				)}
				{!searchList?.loading && searchList?.isAlreadyExist && (
					<div>You can't add same LR in single entry</div>
				)}
			</div>
		</div>
	);
};

export default InputWithAPI;
