import React, { useEffect } from "react";
import { Input, Button, Divider, Form, Row, Col } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { putApi } from "redux/sagas/putApiSaga";
import { SERVER_IP } from "assets/Config";
import { API_STATUS } from "constants/app-constants";
import { resetApiStatus } from "redux/reducers/globals/globalActions";
import { postApi } from "redux/sagas/postApiDataSaga";

const { TextArea } = Input;

const AddRcOwner = ({ handleClose, editRcOwner, setRcOwnerAddModal }) => {
	const [form] = Form.useForm();
	const globalRedux = useSelector((state) => state?.globalRedux);
	const dispatch = useDispatch();

	const addRcOwner = (values) => {
		let data = {
			...values,
			rcownerName: values.rcownerName,
			mobile: values.mobile,
			address: values.address,
			remarks: values.remarks,
			orgId: globalRedux?.selectedOrganization?._id,
		};
		dispatch(postApi(data, "ADD_RC_OWNER"));
	};

	const handleEdit = (values) => {
		let data = {
			...values,
			orgId: globalRedux?.selectedOrganization?._id,
		};
		let url = `${SERVER_IP}rcowner/${editRcOwner._id}?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(putApi(data, "EDIT_RC_OWNER", url));
	};

	useEffect(() => {
		if (
			globalRedux.apiStatus.ADD_RC_OWNER === "SUCCESS" ||
			globalRedux.apiStatus.EDIT_RC_OWNER === "SUCCESS"
		) {
			dispatch(resetApiStatus(editRcOwner ? "EDIT_RC_OWNER" : "ADD_RC_OWNER"));
			setRcOwnerAddModal(false);
			handleClose();
		}
	}, [
		globalRedux.apiStatus,
		editRcOwner,
		setRcOwnerAddModal,
		handleClose,
		dispatch,
	]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const loading =
		globalRedux.apiStatus.ADD_RC_OWNER === API_STATUS.PENDING ||
		globalRedux.apiStatus.EDIT_RC_OWNER === API_STATUS.PENDING;

	return (
		<Row>
			<Col span={24}>
				{/* <Row style={{ backgroundColor: '#fff', padding: '10px 0px' }}>
					<Col>
						<h6 style={{ marginBottom: '0px' }}>{editRcOwner ? 'Edit' : 'New'} RcOwner</h6>
					</Col>
				</Row>
				<Divider /> */}
				<Row style={{ paddingTop: 0 }}>
					<Col span={24}>
						<Form
							name="add-rcowner"
							className="required_in_right"
							labelAlign="left"
							form={form}
							onFinish={!editRcOwner ? addRcOwner : handleEdit}
							{...layer1FormCol}
						>
							<Form.Item
								label="Rc Owner Name"
								name="rcownerName"
								initialValue={editRcOwner?.rcownerName}
								rules={[
									{
										required: true,
										message: "This Field is Required!",
									},
								]}
							>
								<Input placeholder="Rc Owner Name" />
							</Form.Item>
							<Form.Item
								label="Mobile"
								name="mobile"
								initialValue={editRcOwner?.mobile}
								rules={[
									{
										required: true,
										message: "This Field is Required!",
									},
								]}
							>
								<Input maxLength={10} type="number" placeholder="Mobile" />
							</Form.Item>
							<Form.Item
								label="Address"
								placeholder="Address"
								name="address"
								initialValue={editRcOwner?.address}
							>
								<TextArea />
							</Form.Item>
							<Form.Item
								label="Remarks"
								placeholder="Remarks"
								name="remarks"
								initialValue={editRcOwner?.remarks}
							>
								<TextArea />
							</Form.Item>
							<Form.Item
								wrapperCol={{
									offset: 0,
									span: 24,
								}}
							>
								<Row justify="space-between">
									<Button
										onClick={() => setRcOwnerAddModal(false)}
										style={{ width: "49%" }}
										danger
									>
										Cancel
									</Button>
									<Button
										loading={loading}
										type="primary"
										style={{ width: "49%", marginRight: 5 }}
										htmlType="submit"
									>
										{editRcOwner ? "Update" : "Create"}
									</Button>
								</Row>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default AddRcOwner;
