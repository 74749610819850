import React, { memo, useCallback, useMemo } from 'react';
import moment from 'moment';
import { Button, Select, Modal, Drawer, Row, DatePicker, Col, Form, Input, InputNumber, Spin } from 'antd';
import { useSelector } from 'react-redux';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import TableComponent from 'components/table-component';
import { DATE_FORMAT } from 'constants/app-constants';
import '../styles.scss';

const { confirm } = Modal;

const layer1FormCol = {
	labelCol: {
		span: 24,
	},
	wrapperCol: {
		span: 24,
	},
};

const LocationInvoiceModal = memo(({ locations, locationInvoiceModal = false, loadingState, setLoadingState, handleInvoiceQuantitySubmit }) => {
	const [locationDetails, setLocationDetails] = React.useState(loadingState?.selectedRow?.locationDetails || []);
	const [selectedLocation, setSelectedLocation] = React.useState('');
	const [form] = Form.useForm();

	React.useEffect(() => {
		setLocationDetails(loadingState?.selectedRow?.locationDetails || []);
	}, [loadingState?.selectedRow?.locationDetails]);

	const handleAdd = (values) => {
		const obj = {
			locationId: values?.locationName,
			partNumberId: loadingState?.selectedRow?.partNumberId,
			qty: values?.qty,
			remarks: '',
			locationName: selectedLocation,
		};
		setLocationDetails([...locationDetails, obj]);
		form.resetFields();
	};

	const handleSubmit = () => {
		handleInvoiceQuantitySubmit(loadingState?.selectedRow, locationDetails);
	};

	const handleRemoveLocation = (locationId) => {
		if (locationId) {
			const newLocationDetails = locationDetails.filter((location) => location.locationId !== locationId);
			setLocationDetails(newLocationDetails);
		}
	};

	const columns = [
		{
			title: 'Location Name',
			dataIndex: 'locationName',
			key: 'locationName',
			width: '70%',
		},
		{
			title: 'Quantity',
			dataIndex: 'qty',
			key: 'qty',
			width: '15%',
			align: 'center',
		},
		{
			title: 'Action',
			dataIndex: '',
			key: '',
			width: '15%',
			align: 'center',
			render: (value, record) => (
				<Col
					onClick={() => handleRemoveLocation(record?.locationId)}
					style={{
						color: '#eb5353',
						cursor: 'pointer',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					<DeleteOutlined />
				</Col>
			),
		},
	];

	const locationsDropDown = useMemo(
		() => locations?.filter((location) => !locationDetails?.find((detail) => detail.locationId === location._id)),
		[locationDetails, locations]
	);

	return (
		<Modal
			width={'40%'}
			visible={locationInvoiceModal}
			title={`Invoice - (Total Quantity ${locationDetails?.reduce((acc, curr) => acc + curr.qty, 0) || 0})`}
			okText="Submit"
			onOk={handleSubmit}
			destroyOnClose
			onCancel={() => {
				setLoadingState({
					...loadingState,
					selectedRow: null,
				});
				form.resetFields();
			}}>
			<Form form={form} onFinish={handleAdd}>
				<Row gutter={[10, 0]}>
					<Col span={11}>
						<Form.Item
							rules={[
								{
									required: true,
									message: 'Please select location!',
								},
							]}
							name={'locationName'}
							label={'LocationName'}
							{...layer1FormCol}>
							<Select
								showSearch
								optionFilterProp="children"
								filterOption={(input, option) =>
									option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
									option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								style={{ width: '100%' }}
								onChange={(value, { children }) => setSelectedLocation(children)}>
								{locationsDropDown?.map((location) => (
									<Select.Option key={location._id} value={location._id}>
										{location?.locationName}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					<Col span={5}>
						<Form.Item
							rules={[
								{
									required: true,
									message: 'Quantity!',
								},
							]}
							name={'qty'}
							label={'Quantity'}
							{...layer1FormCol}>
							<InputNumber style={{ width: '100%' }} />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item className="form-no-padding" label={'  '} {...layer1FormCol}>
							<Button style={{ width: '100%' }} type="primary" htmlType="submit">
								Add
							</Button>
						</Form.Item>
					</Col>
					<Col span={24}>
						<TableComponent dataSource={locationDetails} columns={columns} pagination={false} />
						{/* <Row justify="space-between">
							{locationDetails?.map((location) => (
								<Col md={11} xl={11} className="location-card">
									<Row align="center">
										<Col span={14}>{location?.locationName}</Col>
										<Col span={2}>:</Col>
										<Col span={5}>{location?.qty}</Col>
										<Col
											onClick={() => handleRemoveLocation(location?.locationId)}
											span={3}
											style={{
												color: '#eb5353',
												cursor: 'pointer',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
											}}>
											<DeleteOutlined />
										</Col>
									</Row>
								</Col>
							))}
						</Row> */}
					</Col>
				</Row>
			</Form>
		</Modal>
	);
});

const AddLoadInPresentational = ({
	state,
	setState,
	handleSubmit,
	columns,
	loading,
	tableData,
	editData,
	form,
	locations,
	loadingState,
	setLoadingState,
	handleInvoiceQuantitySubmit,
	activeTab,
	handleUpdate,
	pageLoading,
}) => {
	const globalRedux = useSelector((state) => state.globalRedux);
	const vehicles = globalRedux?.vehicles;

	const showConfirm = useCallback(() => {
		confirm({
			title: 'Do you want to close this window?',
			icon: <ExclamationCircleOutlined />,
			content: 'You will be lost all the details you have entered here.',
			onOk() {
				setState({ ...state, visible: false });
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	}, []);

	React.useEffect(() => {
		!state?.visible && form.resetFields();
	}, [state?.visible]);

	return (
		<Drawer
			maskClosable={false}
			title={`${editData ? 'Edit' : 'Add New'} Unload`}
			placement="right"
			width={'100%'}
			visible={state?.visible}
			destroyOnClose
			className="add_load_in"
			onClose={showConfirm}
			footer={
				pageLoading ? null : (
					<Row>
						<Col
							xl={{
								span: 8,
								offset: 16,
							}}
							md={12}>
							<Row gutter={[10, 10]} style={{ width: '100%' }} justify="end">
								<Col>
									<Button onClick={() => setState({ ...state, visible: false })}>Cancel</Button>
								</Col>
								<Col>
									{activeTab === '0' ? (
										<Button loading={loading} type="primary" htmlType="submit" onClick={form.submit}>
											Submit
										</Button>
									) : (
										<Button loading={loading} type="primary" onClick={form.submit}>
											Update
										</Button>
									)}
								</Col>
							</Row>
						</Col>
					</Row>
				)
			}>
			{pageLoading ? (
				<Row style={{ height: '100%' }} justify="center" align="middle">
					<Col span={12}>
						<Spin tip="Fetching data..." size="large">
							<div className="content" />
						</Spin>
					</Col>
				</Row>
			) : (
				<Row justify="center" className="edit_loadin">
					<Col xl={24} md={24}>
						<TableComponent
							{...{
								columns,
								dataSource: tableData,
								pagination: false,
								// footer: () => (
								// 	<Row style={{ fontSize: 18 }}>
								// 		<Col className="bold" style={{ paddingRight: 10 }}>
								// 			Total:
								// 		</Col>
								// 		<Col className="bold">{totalAmount}</Col>
								// 	</Row>
								// ),
								title: () => (
									<Row justify="space-between">
										<Col xl={24} md={24}>
											<Form
												name="add-load-in"
												style={{}}
												form={form}
												onFinish={activeTab === '0' ? handleSubmit : handleUpdate}
												{...layer1FormCol}>
												<Row gutter={[10, 10]} style={{ width: '100%' }}>
													<Col xl={6} md={6}>
														<Form.Item
															label="Vehicle"
															name="vehicleId"
															rules={[
																{
																	required: true,
																	message: 'This Field is required!',
																},
															]}>
															<Select disabled={!!editData} placeholder="select vehicle">
																{vehicles?.map((vehicle) => (
																	<Select.Option value={vehicle._id}>{vehicle?.regNumber}</Select.Option>
																))}
															</Select>
														</Form.Item>
													</Col>
													<Col xl={6} md={6}>
														<Form.Item
															label="Load Date"
															name="loadDate"
															initialValue={moment()}
															rules={[
																{
																	required: true,
																	message: 'This Field is required!',
																},
															]}>
															<DatePicker disabled style={{ width: '100%' }} format={DATE_FORMAT.DD_MM_YYYY} />
														</Form.Item>
													</Col>
													<Col xl={6} md={6}>
														<Form.Item
															label="Driver Name"
															name="driverName"
															rules={[
																{
																	required: false,
																	message: 'This Field is required!',
																},
															]}>
															<Input disabled placeholder="enter driver name" />
														</Form.Item>
													</Col>
													<Col xl={6} md={6}>
														<Form.Item
															label="Driver Mobile"
															name="driverMobile"
															rules={[
																{
																	required: false,
																	message: 'This Field is required!',
																},
															]}>
															<InputNumber disabled placeholder="enter driver mobile" style={{ width: '100%' }} />
														</Form.Item>
													</Col>
												</Row>
											</Form>
										</Col>
									</Row>
								),
							}}
						/>
					</Col>
				</Row>
			)}
			{/* <Row className="space-between" style={{ paddingTop: 20 }}>
				<Button loading={loading} onClick={form.submit} style={{ marginRight: '10px', width: '49%' }} type="primary" htmlType="submit">
					Save
				</Button>
				<Button danger style={{ width: '49%' }}>
					Cancel
				</Button>
			</Row> */}
			<LocationInvoiceModal
				{...{ locationInvoiceModal: !!loadingState?.selectedRow, locations, loadingState, setLoadingState, handleInvoiceQuantitySubmit }}
			/>
		</Drawer>
	);
};

export default AddLoadInPresentational;
