import React, { useMemo, useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { Modal, Row, Col } from "antd";
import moment from "moment";
import TableComponent from "components/table-component";
import { getTheRoundOffValue } from "helpers";
import { DATE_FORMAT } from "constants/app-constants";
import { sendGetRequest } from "redux/sagas/utils";
import { SERVER_IP } from "assets/Config";
import "sweetalert2/src/sweetalert2.scss";

const DispatchedDetailsModal = ({ selectedViewRow, setSelectedViewRow }) => {
	const [dispatchedDetails, setDispatchedDetails] = useState([]);
	const [dispatchedDetailsLoading, setDispatchedDetailsLoading] =
		useState(false);
	const globalRedux = useSelector((state) => state.globalRedux);
	const column = [
		{
			title: "#",
			dataIndex: "dispatchNumber",
			key: "dispatchNumber",
			width: "5%",
			align: "center",
		},
		{
			title: "Dispatch Date",
			dataIndex: "dispatchDate",
			key: "dispatchDate",
			width: "10%",
			align: "left",
			render: (value) => (
				<div>{moment(value).format(DATE_FORMAT.DD_MM_YYYY)}</div>
			),
		},
		{
			title: "Reg Number",
			dataIndex: "regNumber",
			key: "regNumber",
			width: "10%",
		},
		{
			title: "Driver Name",
			dataIndex: "driverName",
			key: "driverName",
			width: "10%",
		},
		{
			title: "Location Name",
			dataIndex: "locationName",
			key: "locationName",
			width: "10%",
		},
		{
			title: "Dispatched Qty",
			dataIndex: "dispatchedQty",
			key: "dispatchedQty",
			align: "center",
			width: "10%",
		},
	];

	const getDispatchedDetails = useCallback(
		async (id) => {
			setDispatchedDetailsLoading(true);
			const res = await sendGetRequest(
				null,
				`${SERVER_IP}stock/dispatched?orgId=${globalRedux?.selectedOrganization?._id}&stockId=${id}`
			);
			if (res?.data?.length > 0) {
				setDispatchedDetails(res?.data || []);
			} else {
				setSelectedViewRow(null);
				Swal.fire({
					title: "No Data Found!",
					timer: 2000,
					timerProgressBar: true,
					didOpen: () => {
						Swal.showLoading();
					},
				}).then((result) => {
					/* Read more about handling dismissals below */
					if (result.dismiss === Swal.DismissReason.timer) {
						console.log("I was closed by the timer");
					}
				});
			}
			setDispatchedDetailsLoading(false);
		},
		[setDispatchedDetailsLoading, globalRedux?.selectedOrganization?._id]
	);

	useEffect(() => {
		selectedViewRow?._id && getDispatchedDetails(selectedViewRow?._id);
	}, [selectedViewRow?._id]);

	const totalAmount = useMemo(
		() =>
			dispatchedDetails?.items?.reduce(
				(accum, item) => accum + item.totalAmount,
				0
			) || 0,
		[dispatchedDetails?.items]
	);
	const roundOff = getTheRoundOffValue(totalAmount);

	return (
		<Modal
			title={
				<Row>
					<Col span={24}>
						<Row>
							<Col span={24}>{selectedViewRow?.vendorId?.vendorName}</Col>
							<Col span={24}># {selectedViewRow?.invoiceNumber}</Col>
						</Row>
					</Col>
				</Row>
			}
			// title={`${dispatchedDetails?.customerId?.displayName} | ${dispatchedDetails?.orderNumber}`}
			okButtonProps={{ style: { display: "none" } }}
			onCancel={() => {
				setSelectedViewRow(null);
				setDispatchedDetails([]);
			}}
			visible={Boolean(selectedViewRow?._id)}
			width={"60%"}
		>
			<TableComponent
				className="custom-table"
				style={{ width: "100%" }}
				rowKey={(record) => record._id}
				dataSource={dispatchedDetails || []}
				columns={column}
				pagination={false}
				loading={dispatchedDetailsLoading}
				// footer={() => (
				// 	<Row style={{ fontSize: 14 }}>
				// 		<Col xl={21} md={21} style={{ textAlign: 'right', paddingRight: 10 }}>
				// 			Grand Total
				// 		</Col>
				// 		<Col xl={1} md={1}>
				// 			:
				// 		</Col>
				// 		<Col xl={2} md={2} style={{ textAlign: 'right', fontWeight: 'bold' }}>
				// 			{parseFloat(roundOff?.value).toFixed(2)}
				// 		</Col>
				// 	</Row>
				// )}
			/>
		</Modal>
	);
};

export default DispatchedDetailsModal;
