import React, { useEffect } from "react";
import {
	Input,
	Button,
	Form,
	Row,
	Col,
	Select,
	InputNumber,
	Divider,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { putApi } from "redux/sagas/putApiSaga";
import { SERVER_IP } from "assets/Config";
import {
	API_STATUS,
	CUSTOMER_TYPE,
	GST_TREATMENT,
} from "constants/app-constants";
import { resetApiStatus } from "redux/reducers/globals/globalActions";
import { postApi } from "redux/sagas/postApiDataSaga";

const { TextArea } = Input;

const AddCustomer = ({ handleClose, editCustomer, setCustomerAddModal }) => {
	const [form] = Form.useForm();
	const globalRedux = useSelector((state) => state?.globalRedux);
	const dispatch = useDispatch();

	const addCustomer = (values) => {
		let data = {
			...values,
			billingDetails: [
				{
					addressLine1: values?.addressLine1,
					addressLine2: values?.addressLine2,
					city: values?.city,
					pincode: values?.pincode,
					state: values?.state,
					country: values?.country,
					isDefault: true,
				},
			],
			orgId: globalRedux?.selectedOrganization?._id,
		};
		dispatch(postApi(data, "ADD_PARTY"));
	};

	const handleEdit = (values) => {
		let data = {
			...values,
			billingDetails: [
				{
					addressLine1: values?.addressLine1,
					addressLine2: values?.addressLine2,
					city: values?.city,
					pincode: values?.pincode,
					state: values?.state,
					country: values?.country,
					isDefault: true,
				},
			],
			orgId: globalRedux?.selectedOrganization?._id,
		};
		let url = `${SERVER_IP}customer/${editCustomer._id}?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(putApi(data, "EDIT_PARTY", url));
	};

	useEffect(() => {
		if (
			globalRedux.apiStatus.ADD_PARTY === "SUCCESS" ||
			globalRedux.apiStatus.EDIT_PARTY === "SUCCESS"
		) {
			dispatch(resetApiStatus(editCustomer ? "EDIT_PARTY" : "ADD_PARTY"));
			setCustomerAddModal(false);
			handleClose();
		}
	}, [
		globalRedux.apiStatus,
		editCustomer,
		setCustomerAddModal,
		handleClose,
		dispatch,
	]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const loading =
		globalRedux.apiStatus.ADD_PARTY === API_STATUS.PENDING ||
		globalRedux.apiStatus.EDIT_PARTY === API_STATUS.PENDING;

	return (
		<Row>
			<Col span={24}>
				{/* <Row style={{ backgroundColor: '#fff', padding: '10px 0px' }}>
					<Col>
						<h6 style={{ marginBottom: '0px' }}>{editCustomer? 'Edit' : 'New'} Customer</h6>
					</Col>
				</Row>
				<Divider /> */}
				<Row style={{ paddingTop: 0 }}>
					<Col span={24}>
						<Form
							name="add-customer"
							className="required_in_right"
							labelAlign="left"
							form={form}
							onFinish={!editCustomer ? addCustomer : handleEdit}
							{...layer1FormCol}
						>
							<Form.Item
								label="Customer Type"
								name="customerType"
								initialValue={editCustomer?.customerType}
								rules={[
									{
										required: true,
										message: "This Field is required!",
									},
								]}
							>
								<Select placeholder="select customer type">
									{CUSTOMER_TYPE.map((type) => (
										<Select.Option key={type?.value} value={type?.value}>
											{type?.label}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								label="Customer Name"
								name="displayName"
								initialValue={editCustomer?.displayName}
								rules={[
									{
										required: true,
										message: "This Field is Required!",
									},
								]}
							>
								<Input placeholder="Customer Name" />
							</Form.Item>
							<Form.Item
								label="Email"
								name="email"
								initialValue={editCustomer?.email}
							>
								<Input placeholder="Email" />
							</Form.Item>
							<Form.Item
								label="Mobile"
								name="mobile"
								initialValue={editCustomer?.mobile}
							>
								<Input maxLength={10} placeholder="Mobile" />
							</Form.Item>
							<Form.Item
								label="GST Treatment"
								name="gstTreatment"
								initialValue={editCustomer?.gstTreatment}
								rules={[
									{
										required: true,
										message: "This Field is required!",
									},
								]}
							>
								<Select placeholder="select gst treatment">
									{GST_TREATMENT.map((type) => (
										<Select.Option key={type?.value} value={type?.value}>
											{type?.label}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								label="GSTIN"
								name="gstin"
								initialValue={editCustomer?.gstin}
							>
								<Input placeholder="GSTIN" />
							</Form.Item>
							<Form.Item
								label="Transport Rate"
								name="transportRate"
								initialValue={editCustomer?.transportRate}
							>
								<InputNumber
									style={{
										width: "100%",
									}}
									placeholder="transportRate"
								/>
							</Form.Item>
							<Divider>Billing Address</Divider>
							<Form.Item
								label="Address Line1"
								name="addressLine1"
								initialValue={editCustomer?.billingDetails[0]?.addressLine1}
							>
								<Input placeholder="addressLine1" />
							</Form.Item>
							<Form.Item
								label="Address Line2"
								name="addressLine2"
								initialValue={editCustomer?.billingDetails[0]?.addressLine2}
							>
								<Input placeholder="addressLine2" />
							</Form.Item>
							<Form.Item
								label="City"
								name="city"
								initialValue={editCustomer?.billingDetails[0]?.city}
							>
								<Input placeholder="city" />
							</Form.Item>
							<Form.Item
								label="Pincode"
								name="pincode"
								initialValue={editCustomer?.billingDetails[0]?.pincode}
							>
								<InputNumber
									style={{
										width: "100%",
									}}
									placeholder="pincode"
								/>
							</Form.Item>
							<Form.Item
								label="State"
								name="state"
								initialValue={editCustomer?.billingDetails[0]?.state}
							>
								<Input placeholder="state" />
							</Form.Item>
							<Form.Item
								label="Country"
								name="country"
								initialValue={
									editCustomer?.billingDetails[0]?.country || "India"
								}
							>
								<Input placeholder="country" />
							</Form.Item>
							<Form.Item
								label="Remarks"
								name="remarks"
								initialValue={editCustomer?.remarks}
							>
								<TextArea placeholder="Remarks" />
							</Form.Item>
							<Form.Item
								wrapperCol={{
									offset: 0,
									span: 24,
								}}
							>
								<Row justify="space-between">
									<Button
										onClick={() => setCustomerAddModal(false)}
										style={{ width: "49%" }}
										danger
									>
										Cancel
									</Button>
									<Button
										loading={loading}
										type="primary"
										style={{ width: "49%", marginRight: 5 }}
										htmlType="submit"
									>
										{editCustomer ? "Update" : "Create"}
									</Button>
								</Row>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default AddCustomer;
